import { Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react'
import Label from 'Components/Label'
import { NavbarLink } from 'Components/NavbarLink/NavbarLink'
import { useTranslation } from 'react-i18next'
import { BsChevronDown } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'

export function NavbarUserMenu() {
   const navigate = useNavigate()
   const { t } = useTranslation()
   const [userData, setUserData] = useRecoilState(userDataAtom)

   const handleLogout = () => {
      setUserData(null)
      navigate('/')
      setTimeout(() => navigate(0), 500)
   }

   return (
      <>
         {userData ? (
            <Menu>
               <MenuButton px={0} fontSize='sm' as={Button}>
                  <div className='flex items-center gap-2'>
                     {/* <Label child={userData?.user_data?.subscription?.type_text} /> */}
                     <div className='flex items-center gap-[10px] font-semibold'>
                        <div className='text-base font-nuinto'>{userData?.user_data.first_name} {userData?.user_data.last_name}</div>
                        <BsChevronDown />
                     </div>
                  </div>
               </MenuButton>
               <MenuList borderColor={'light-gray'} shadow='md'>
                  <MenuItem onClick={() => navigate('/dashboard')}>Il mio account</MenuItem>
                  <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
               </MenuList>
            </Menu>
         ) : (
            <NavbarLink destension='/login' title='ACCEDI' />
         )}
      </>
   )
}

{/* <Avatar
name={userData.user_data.first_name + ' ' + userData.user_data.last_name}
width={'50px'}
rounded='full'
height={'50px'}
   bg={'blackAlpha.400'}
   border={`${
      userData?.user_data?.subscription?.type_text === 'FREE'
         ? '3px solid #AA5260'
         : '3px solid #496492'
   }`}>
   <AvatarBadge
      border={0}
      boxSize='full'
      h={'13px'}
      w='40px'
      left={'-7.8px'}
      bottom={'3.1px'}
      roundedTop={0}
      bg={`${
         userData.user_data?.subscription?.type_text === 'FREE'
            ? '#AA5260'
            : '#496492'
      }`}>
      <span className='text-[8px] text-white'>
         {userData.user_data?.subscription?.type_text}
      </span>
   </AvatarBadge>
</Avatar> */}
{/* <b>{userData.user_data.first_name + ' ' + userData.user_data.last_name}</b> */ }