import SubscriptionStyles from 'Configs/SubscripationStyles'
import PlanTypes from 'Types/Subscripe'
import { Link, useNavigate } from 'react-router-dom'

const Card = ({
  desc,
  link,
  title,
  type,
  className,
}: {
  title: string
  desc: string
  link: string
  type: PlanTypes
  className: string
}) => {
  const navigate = useNavigate()

  // min-h-[300px] max-h-[425px] sm:max-h-[380px] md:max-h-[470px] lg:max-h-[425px] xl:max-h-[380px] 2xl:max-h-[470px]

  return (
    <div
      className={`rounded-[5px] h-auto pb-[30px] px-[25px] font-saira-normal text-white flex flex-col ${SubscriptionStyles[type].bg} justify-between gap-4 ${className}`}>
      <div className='flex flex-col gap-3'>
        <h4 className='text-[100px] font-bold'>{title}</h4>
        <p className='text-[30px]'>{desc}</p>
      </div>

      <button
        className={`w-fit px-5 text-[25px] font-bold rounded-[5px] bg-white ${SubscriptionStyles[type].text} shadow-md`}
        onClick={() => {
          navigate(link)
        }}>
        VAI
      </button>
    </div>
  )
}

const Skeleton = ({ className }: { className: string }) => {
  return (
    <div
      className={`rounded-[5px] min-h-[300px] pb-[30px] px-[25px] flex flex-col h-full justify-between gap-4 ${className} transition-all duration-300 animate-pulse bg-slate-100`}>
      <div className='flex flex-col gap-3'>
        <h4 className='max-w-[200px] h-[100px] my-[25px] animate-pulse bg-slate-200'></h4>
        <div>
          <p className='h-[45px] w-full animate-pulse bg-slate-200'></p>
        </div>
      </div>

      <Link
        to={'/'}
        className={
          'w-[80px] h-[37px] px-5 rounded-[5px] shadow-md animate-pulse bg-slate-200'
        }></Link>
    </div>
  )
}

export default { Card, Skeleton }
