import { useMediaQuery } from '@chakra-ui/react'
import MobileNavbar from './MobileNavbar'
import { DesktopNavbar } from './DesktopNavbar'
import { useRecoilState } from 'recoil'
import { selectedModuleAtom } from 'Recoil/atoms'

export const Navbar = () => {
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const [selectedLesson] = useRecoilState(selectedModuleAtom)

   return <>{smallScreen ? <>{!selectedLesson && <MobileNavbar />}</> : <DesktopNavbar />}</>
}
