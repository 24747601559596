/* eslint-disable no-console */
import { useQueryClient } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { selectedModuleAtom } from 'Recoil/atoms'

export const Timer = () => {
   const [selectedModule] = useRecoilState(selectedModuleAtom)
   const [totalTime, setTotalTime] = useState(0)
   const { updateProgress } = useApiRequests()
   const queryClient = useQueryClient()
   const [time, setTime] = useState(0)

   const fetchData = async () => {
      if (!selectedModule) return

      const result = await updateProgress(selectedModule.id)

      if (result.data.data.finished) queryClient.invalidateQueries(['modules'])
   }

   useEffect(() => {
      if (time > Number((selectedModule as any)?.module_user?.time_spent) * 60 && time % 60 === 0) {
         fetchData()
      }
   }, [time])

   useEffect(() => {
      if (Math.floor(time / 60) >= totalTime) return

      const interval = setInterval(() => {
         setTime((prevTime) => prevTime + 1)
      }, 1000)

      return () => clearInterval(interval)
   }, [selectedModule?.id, totalTime])

   useEffect(() => {
      setTime(Number((selectedModule as any)?.module_user?.time_spent) * 60)
      setTotalTime(Number((selectedModule as any)?.module_user?.total_time))
   }, [selectedModule?.id])

   const minutes = Math.floor(time / 60)
   const seconds = time % 60

   return (
      <div>
         <div className='flex lg:bg-blueDark bg-primary rounded-lg lg:p-3 p-0 w-[150px] justify-center text-white text-sm font-bold gap-2 items-center '>
            <svg
               width='15'
               height='17'
               viewBox='0 0 15 17'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'>
               <path
                  d='M12.5587 4.83924C12.6028 4.79094 12.6409 4.75068 12.681 4.71043C12.9697 4.4186 13.2643 4.13281 13.547 3.83695C13.7414 3.63367 13.7334 3.34788 13.543 3.16473C13.3566 2.9856 13.0819 2.98762 12.8855 3.17882C12.687 3.37002 12.4946 3.56524 12.3021 3.76449C12.1698 3.90135 12.0435 4.04425 11.9092 4.19117C10.6904 3.14259 9.31329 2.56899 7.75772 2.45024V1.43587C7.84192 1.43587 7.91809 1.43587 7.99427 1.43587C8.07244 1.43587 8.15062 1.43789 8.2268 1.43587C8.4473 1.42581 8.6197 1.27889 8.67182 1.06555C8.71792 0.868312 8.6197 0.661011 8.42926 0.554341C8.39919 0.53824 8.36913 0.518114 8.34106 0.5H6.22421C6.22421 0.5 6.21418 0.518114 6.20617 0.522139C5.95158 0.650948 5.84133 0.84416 5.89345 1.06555C5.94958 1.30505 6.134 1.43587 6.41665 1.43789C6.54895 1.43789 6.68126 1.43789 6.80955 1.43789V2.45024C5.05152 2.59716 3.54206 3.27744 2.30522 4.53533C1.06839 5.79323 0.394844 7.31478 0.294614 9.07785C0.128232 12.0022 1.79205 14.7696 4.58645 15.947C7.42897 17.1445 10.6243 16.3575 12.6489 13.9706C13.6512 12.7871 14.1925 11.4024 14.2727 9.85271C14.3709 7.95882 13.7815 6.29035 12.5587 4.83924ZM7.29065 14.9084C4.27975 14.9084 1.84016 12.4591 1.84016 9.4361C1.84016 6.41312 4.27975 3.96374 7.29065 3.96374C10.3016 3.96374 12.7411 6.41312 12.7411 9.4361C12.7411 12.4591 10.3016 14.9084 7.29065 14.9084Z'
                  fill='white'
               />
               <path
                  d='M7.75947 4.80273L7.2904 9.43582L3.09277 11.4163C3.83447 12.9982 5.43414 14.0931 7.2904 14.0931C9.85227 14.0931 11.929 12.008 11.929 9.43582C11.929 7.02267 10.1008 5.03821 7.75947 4.80273Z'
                  fill='white'
               />
            </svg>

            <span className=''>
               {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`} /{' '}
               {totalTime
                  .toString()
                  .split(':')
                  .map((num) => num.padStart(2, '0'))
                  .join(':')}
               :00
            </span>
         </div>
      </div>
   )
}
