import { useToast } from '@chakra-ui/react'
// import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'

function ProtectedRoute({ children }: { children: any }) {
   const toast = useToast()
   const [userData] = useRecoilState(userDataAtom)
   const location = useLocation()
   // const { t } = useTranslation()

   if (userData && userData?.accessToken.abilities[0] === 'make_consent') {
      // toast({
      //    title: t('Not Verified'),
      //    position: 'top',
      //    description: t('Please accept privacy policy.'),
      //    status: 'error',
      //    isClosable: true
      // })
      return <Navigate to='/consent' replace />
   }

   if (userData && userData?.accessToken.abilities[0] === 'update_profile') {
      // toast({
      //    title: t('Not Verified'),
      //    position: 'top',
      //    description: t('Please accept privacy policy.'),
      //    status: 'error',
      //    isClosable: true
      // })
      return <Navigate to='/update-profile' replace />
   }

   if (!userData && location.pathname === '/dashboard') {
      toast({
         title: 'Non hai ancora effettuato il login!',
         position: 'top',
         status: 'error',
         isClosable: true
      })
      return <Navigate to='/login' replace />
   }

   if (!userData && location.pathname.includes('offerta')) {
      toast({
         title: 'Non hai ancora effettuato il login!',
         position: 'top',
         status: 'error',
         isClosable: true
      })

      localStorage.setItem('wasInOfferta', location.pathname)

      return <Navigate to='/login' state={{ redirectTo: location.pathname }} />
   }

   if (!userData && location.pathname.includes('checkout')) {
      toast({
         title: 'Non hai ancora effettuato il login!',
         position: 'top',
         status: 'error',
         isClosable: true
      })

      localStorage.setItem('wasInCheckout', window.location.pathname + window.location.search)

      return (
         <Navigate
            to='/login'
            state={{ redirectTo: window.location.pathname + window.location.search }}
         />
      )
   }

   return <>{children}</>
}

export default ProtectedRoute
