import { Radio } from '@chakra-ui/react'
import { TQuestionOption } from 'Types/Models'

type Props = {
   options: TQuestionOption[]
   selectedAnswer: string
}

export const AnswersList = ({ options, selectedAnswer }: Props) => {
   return (
      <div className='flex flex-col gap-4 my-5'>
         {options.map((option) => (
            <div
               key={option.id}
               className={`${
                  selectedAnswer === String(option.id)
                     ? 'bg-secondary'
                     : 'bg-[#ffffff2b] lg:bg-white '
               }  rounded-[5px] py-4 transition-all hover:shadow-lg px-6`}>
               <Radio
                  w={'full'}
                  _focus={{ boxShadow: 'none' }}
                  borderColor={selectedAnswer !== String(option.id) ? 'gray' : 'white'}
                  colorScheme='orange'
                  alignItems={'center'}
                  display='flex'
                  value={String(option.id)}>
                  <span
                     className={`text-xs lg:text-base text-white ${
                        selectedAnswer !== String(option.id) ? 'lg:text-black' : 'text-white'
                     } ${(option as any).is_true && 'underline'}`}>
                     {option.text}
                  </span>
               </Radio>
            </div>
         ))}
      </div>
   )
}
