import { Spinner } from '@chakra-ui/spinner'
import { useApiRequests } from 'API/ApiRequests'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useRecoilState } from 'recoil'
import { selectedCourseAtom } from 'Recoil/atoms'
import { ModalWrapper } from './ModalWrapper'

export const CourseCompletedModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: any }) => {
   const { courseId } = useParams()

   const { getCerteficatiom } = useApiRequests()

   const [type, setType] = useState(0)

   const [isLoading, setIsLoading] = useState(false)

   const { t } = useTranslation()

   const [selectedCourse] = useRecoilState(selectedCourseAtom)

   useEffect(() => {
      if (type === 1 || type === 2) fetchCertificate()
   }, [type])

   const fetchCertificate = async () => {
      try {
         setIsLoading(true)
         const res = await getCerteficatiom(courseId || '', type)
         const blob = new Blob([res.data], { type: 'application/pdf' })
         const link = document.createElement('a')
         link.href = URL.createObjectURL(blob)
         link.download = 'certification.pdf'
         link.click()
      } catch (error) {
         // console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <ModalWrapper
         size='sm'
         isOpen={isOpen}
         onClose={() => {
            onClose()
         }}>
         <div className='p-5 flex flex-col items-center gap-7'>
            <span className='text-blueDark text-2xl font-bold'>{t('Hai concluso il corso!')}</span>
            <div className='flex flex-col gap-4 items-center w-full'>
               {selectedCourse && selectedCourse?.can_download_both && (
                  <button
                     onClick={() => setType(1)}
                     className='outline-none text-white h-[60px] bg-blueDark rounded-lg text-base flex justify-center items-center font-bold w-full'>
                     {isLoading && type === 1 ? <Spinner /> : 'Scarica il certificato ECM'}
                  </button>
               )}
               <button
                  onClick={() => setType(2)}
                  className='outline-none text-white h-[60px] bg-[#AA5260] rounded-lg flex justify-center items-center text-base font-bold w-full'>
                  {isLoading && type === 2 ? <Spinner /> : ' Scarica attestato di partecipazione'}
               </button>
            </div>
            <p className='text-sm text-center'>
               {t(
                  'Per eventuali utilizzi futuri ricorda che gli attestati dei corsi sono disponibili nella tua dashboard personale'
               )}
            </p>
         </div>
      </ModalWrapper>
   )
}
