import logo from 'Assets/logo.svg'
import { Link, Slide } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'
import freeLogo from 'Assets/freeUserLogo.svg'
import { Twirl as Hamburger } from 'hamburger-react'
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Label from 'Components/Label'
import { NavbarLink } from 'Components/NavbarLink/NavbarLink'

const MobileNavbar = () => {
   const [userData, setUserData] = useRecoilState(userDataAtom)
   const [isOpen, setIsOpen] = useState(false)

   const navigate = useNavigate()

   const handleLogout = () => {
      setUserData(null)
      navigate('/')
      setTimeout(() => navigate(0), 500)
   }

   const handleSlideClose = () => setIsOpen((o) => !o)

   return (
      <div className='sticky top-0 z-50'>
         <div className='px-4 py-3 border-b-[1px] border-b-free mx-auto backdrop-blur-md bg-white flex lg:flex relative z-30'>
            <div className='flex items-center justify-between flex-1'>
               <Link className='' href='/'>
                  {userData ? (
                     <>
                        {userData?.user_data.subscription.type_text !== 'FREE' ? (
                           <img className='w-32 h-11' src={logo} />
                        ) : (
                           <img className='w-32 h-11' src={freeLogo} />
                        )}
                     </>
                  ) : (
                     <>
                        <img className='w-32 h-11' src={freeLogo} />
                     </>
                  )}
               </Link>

               <div className='flex gap-4 items-center'>
                  {userData?.user_data?.subscription?.type_text ? (
                     <Label child={userData?.user_data?.subscription?.type_text} />
                  ) : (
                     <NavbarLink destension='/login' title='ACCEDI' />
                  )}

                  <Hamburger
                     size={35}
                     color='#b1b1b1'
                     toggle={() => setIsOpen((o) => !o)}
                     toggled={isOpen}
                  />
               </div>
            </div>
         </div>
         <Slide in={isOpen} className='bg-white z-[20] !h-[100vh] font-nuinto mt-[73px] lg:hidden'>
            <div className='flex flex-col justify-between !h-[100svh]'>
               <div className='flex flex-col font-bold text-[15px] text-active'>
                  <NavLink
                     to='/'
                     onClick={handleSlideClose}
                     className={
                        'text-active px-[15px] transition-colors duration-300 py-[15px] border-b-[1px] border-b-[#49649233]'
                     }>
                     HOME
                  </NavLink>
                  <NavLink
                     to={userData?.accessToken ? '/corsi-ecm' : '/tutti-i-corsi'}
                     onClick={handleSlideClose}
                     className={
                        'text-active px-[15px] transition-colors duration-300 py-[15px] border-b-[1px] border-b-[#49649233]'
                     }>
                     CORSI ECM
                  </NavLink>
                  {userData?.accessToken && (
                     <NavLink
                        to='/corsi-inziati'
                        onClick={handleSlideClose}
                        className={
                           'text-active px-[15px] transition-colors duration-300 py-[15px] border-b-[1px] border-b-[#49649233]'
                        }>
                        CORSI INIZIATI
                     </NavLink>
                  )}
                  {userData?.accessToken && (
                     <NavLink
                        to='/scelti-per-te'
                        onClick={handleSlideClose}
                        className={
                           'text-active px-[15px] transition-colors duration-300 py-[15px] border-b-[1px] border-b-[#49649233]'
                        }>
                        SCELTI PER TE
                     </NavLink>
                  )}
                  <NavLink
                     to='/canali'
                     onClick={handleSlideClose}
                     className={
                        'text-active px-[15px] transition-colors duration-300 py-[15px] border-b-[1px] border-b-[#49649233]'
                     }>
                     CANALI
                  </NavLink>
                  {userData?.accessToken && (
                     <NavLink
                        to='/dashboard'
                        onClick={handleSlideClose}
                        className={
                           'text-active px-[15px] transition-colors duration-300 py-[15px]'
                        }>
                        IL MIO ACCOUNT
                     </NavLink>
                  )}
               </div>
               <div className='flex flex-col gap-5 mt-10 px-[15px] mb-[10svh]'>
                  {userData?.accessToken && (
                     <NavLink
                        to='/preferiti'
                        onClick={handleSlideClose}
                        className='text-active transition-colors duration-300'>
                        PREFERITI
                     </NavLink>
                  )}
                  <NavLink
                     to='/faq'
                     onClick={handleSlideClose}
                     className='text-active transition-colors duration-300'>
                     FAQ
                  </NavLink>
                  {userData?.accessToken && (
                     <button
                        onClick={() => {
                           handleSlideClose()
                           handleLogout()
                        }}
                        className='text-active transition-colors duration-300 w-fit'>
                        ESCI
                     </button>
                  )}
               </div>
            </div>
         </Slide>
      </div>
   )
}

export default MobileNavbar
