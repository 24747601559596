import { Box, Spinner } from '@chakra-ui/react'
import { initPlayer, playingTime, audioDuration } from 'Utils/AudioPlayerInitialize'
import { useEffect, useState } from 'react'
import { BsPauseCircle } from 'react-icons/bs'
import { VscPlayCircle } from 'react-icons/vsc'
import file from 'Assets/file_example_MP3_700KB.mp3'
import { LessonOverviewMobile } from 'Components/LessonOverviewMobile'

let wavesurfer: WaveSurfer
const AudioPlayer: React.FunctionComponent = () => {
   const [play, setPlay] = useState(false)
   const [duration, setduration] = useState('')
   const [currentTime, setcurrentTime] = useState('')

   useEffect(() => {
      if (wavesurfer) {
         wavesurfer.destroy()
      }
      wavesurfer = initPlayer(file)
      wavesurfer.on('ready', () => {
         setduration(playingTime)
      })
      wavesurfer.on('audioprocess', () => {
         setcurrentTime(audioDuration())
      })
      wavesurfer.on('seek', () => {
         setcurrentTime(audioDuration())
      })
   }, [file])

   return (
      <Box className='lg:px-16 items-center flex flex-col lg:py-8 h-full lg:bg-[#F8F8F8] bg-transparent rounded-lg'>
         <LessonOverviewMobile />
         <Box className='flex  lg:mb-4 mb-10 py-3 px-5 lg:bg-transparent bg-white flex-col gap-y-4 lg:justify-center items-center lg:h-full h-fit w-full'>
            <Box className='h-fit pt-7 pb-12 w-full border-b border-gray/70' id='waveform'></Box>
            <Box className='flex self-start text-gray'>
               {currentTime}/{duration}
            </Box>
         </Box>
         {wavesurfer && wavesurfer.isReady ? (
            play ? (
               <BsPauseCircle
                  onClick={() => {
                     wavesurfer.pause()
                     setPlay(false)
                  }}
                  className='text-[85px] lg:text-primary cursor-pointer text-white'
               />
            ) : (
               <VscPlayCircle
                  onClick={() => {
                     wavesurfer.play()
                     setPlay(true)
                  }}
                  className='text-[90px] lg:text-primary cursor-pointer text-white'
               />
            )
         ) : (
            <Spinner size='xl' color='primary' />
         )}
      </Box>
   )
}
export default AudioPlayer
