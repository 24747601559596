import { RadioGroup } from '@chakra-ui/react'
import { AnswersList } from 'Components/Lists/AnswersList'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { moduileQuizAtom } from 'Recoil/atoms'
import { TQuestion } from 'Types/Models'

type Props = {
   totalQuestions: number
   question: TQuestion
}

export const QuestionAndAnswers = ({ question, totalQuestions }: Props) => {
   const { t } = useTranslation()

   const [quizState, setQuizState] = useRecoilState(moduileQuizAtom)

   const handleSelectAnswer = (nextValue: string, id: string) => {
      setQuizState({
         ...quizState,
         answers: { ...quizState.answers, [id]: nextValue }
      })
   }

   return (
      <div className='flex flex-col'>
         <h5 className='lg:text-[15px] text-xs font-semibold  lg:mb-14 mb-4 text-dark-gray'>
            {t('Question')} {quizState.step + 1} {t('of')} {totalQuestions}
         </h5>
         <div className='mb-6 text-xs lg:text-[18px] lg:text-black text-white leading-6 lg:font-normal font-bold '>
            {question.text}
         </div>
         <RadioGroup
            onChange={(nextValue: string) => handleSelectAnswer(nextValue, String(question.id))}
            value={quizState.answers[String(question.id)]}>
            <AnswersList
               selectedAnswer={quizState.answers[String(question.id)]}
               options={question.options}
            />
         </RadioGroup>
      </div>
   )
}
