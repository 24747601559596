import { Button, Link, useMediaQuery } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import AudioPlayer from 'Components/Players/AudioPlayer'
import { Timer } from 'Components/Timer'
import { useEffect } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { moduileQuizAtom, selectedModuleAtom } from 'Recoil/atoms'
import { TModule } from 'Types/Models'
import { LessonPdfWindow } from './LessonPdfWindow/LessonPdfWindow'
import { LessonQuizWindow } from './LessonQuizWindow/LessonQuizWindow'
import { LessonVideoWindow } from './LessonVideoWindow/LessonVideoWindow'
import { ServeyWindow } from './ServeyWindow/LessonQuizWindow'

export const LessonWindow = () => {
   const [selectedModule, setselectedModule] = useRecoilState(selectedModuleAtom)

   const resetQuizProgress = useResetRecoilState(moduileQuizAtom)

   const [smallScreen] = useMediaQuery('(max-width: 1024px)')

   const { startModule } = useApiRequests()

   const startModuleMutation = useMutation({ mutationFn: startModule })

   useEffect(() => {
      if (selectedModule) {
         if (
            selectedModule.type_text !== 'QUIZ' &&
            selectedModule.type_text !== 'SURVEY' &&
            !(selectedModule as TModule).module_user.started_at
         ) {
            startModuleMutation.mutate(selectedModule.id)
         }
         document.getElementById(String(selectedModule.id))?.scrollIntoView({ behavior: 'smooth' })
      }
   }, [selectedModule?.id])

   useEffect(() => {
      return () => {
         setselectedModule(null)
         resetQuizProgress()
      }
   }, [])

   return (
      <div className='w-full lg:bg-transparent lg:rounded-md h-screen lg:h-[80vh] bg-primary'>
         {smallScreen && (
            <span
               onClick={() => setselectedModule(null)}
               className='absolute top-5 cursor-pointer right-5 text-white text-xl'>
               X
            </span>
         )}
         {(() => {
            switch (selectedModule?.type_text.toLocaleLowerCase()) {
               case 'audio':
                  return <AudioPlayer />
               case 'video':
                  return <LessonVideoWindow />
               case 'pdf':
                  return <LessonPdfWindow />
               case 'quiz':
                  return <LessonQuizWindow />
               case 'survey':
                  return <ServeyWindow />
               case 'text':
                  return (
                     <>
                        {smallScreen && (
                           <div className='flex gap-5 items-start py-6'>
                              <span className='text-xs uppercase font-bold mb-2 text-secondary pl-5'>
                                 TESTO{' '}
                              </span>
                              {smallScreen && <Timer />}
                           </div>
                        )}
                        <div
                           className='lg:h-full md:h-[95%] h-[82%] lg:bg-white lg:bg-transparent lg:text-black text-white p-4 overflow-y-auto py-6 rounded-lg flex justify-between px-5 flex-col gap-4'
                           dangerouslySetInnerHTML={{
                              __html: (selectedModule as any)?.content_body
                           }}
                        />{' '}
                     </>
                  )
               case 'html':
                  return (
                     <>
                        {smallScreen && (
                           <div className='flex gap-5 items-start py-6'>
                              <span className='text-xs font-bold mb-2 text-secondary pl-5'>
                                 HTML
                              </span>
                              {smallScreen && <Timer />}
                           </div>
                        )}
                        <iframe
                           src={(selectedModule as TModule)?.content_url}
                           className='lg:h-full h-[82%] w-full md:p-0 '
                        />
                     </>
                  )
               default:
                  return (
                     <div className='h-full flex justify-center items-center text-primary font-bold'></div>
                  )
            }
         })()}

         {smallScreen && (
            <>
               {selectedModule?.attachment && (
                  <Link
                     target={'_blank'}
                     w='150px'
                     href={process.env.REACT_APP_MEDIA_URL + (selectedModule?.attachment || '')}
                     className='py-4 w-1/5 self-center mt-6'
                     _hover={{ textDecoration: 'nome' }}>
                     <Button fontSize='sm' variant='primary'>
                        SCARICA IL PDF
                     </Button>
                  </Link>
               )}
            </>
         )}
      </div>
   )
}
