import { FadeBox } from 'Components/FadeBox'
import { LoginForm } from 'Components/Forms/LoginForm'
import { Footer } from 'Layouts/Footer/Footer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const Login = () => {
   const { t } = useTranslation()

   return (
      <>
         <FadeBox>
            <div className='flex justify-center items-center'>
               <div className='bg-white my-14 pb-10 container flex flex-col'>
                  <div className='w-full text-center'>
                     <h3 className='text-primary text-3xl py-10 font-semibold'>Utente registrato</h3>
                     <p className='text-base font-semibold pb-9'>
                        {t('Welcome back! Please login to your account.')}{' '}
                     </p>
                  </div>

                  <LoginForm />

                  <div className='w-full justify-center mt-10 flex gap-1'>
                     <span>{t('New User?')}</span>
                     <Link to='/register' className='text-primary font-semibold'>
                        {t('Register')}
                     </Link>
                  </div>
               </div>
            </div>
         </FadeBox>
         <div className='mt-14'>
            <Footer />
         </div>
      </>
   )
}
