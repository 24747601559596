import React from 'react'
import ReactDOM from 'react-dom/client'
import "./fonts.css"
import './index.css'
import App from './App'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './Configs/ChakraTheme'
import { RecoilRoot } from 'recoil'
import './i18n'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { MatomoProvider } from '@datapunt/matomo-tracker-react'
// import { instance } from 'Configs/Motomo'
// import * as Sentry from '@sentry/react'
// import { BrowserTracing } from '@sentry/tracing'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         refetchOnWindowFocus: false
      }
   }
})

// if (process.env.REACT_ENABLE_SYNTRY === 'true') {
//    Sentry.init({
//       dsn: process.env.REACT_APP_DSN,
//       integrations: [new BrowserTracing()],

//       // Set tracesSampleRate to 1.0 to capture 100%
//       // of transactions for performance monitoring.
//       // We recommend adjusting this value in production
//       tracesSampleRate: 1.0
//    })
// }

root.render(
   <ChakraProvider theme={theme}>
      <RecoilRoot>
         <QueryClientProvider client={queryClient}>
            {/* <MatomoProvider value={instance}> */}
               <App />
            {/* </MatomoProvider> */}
         </QueryClientProvider>
      </RecoilRoot>
   </ChakraProvider>
)
