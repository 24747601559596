export const titleOptions = [
   {
      id: 1,
      name: 'Dott.'
   },
   {
      id: 2,
      name: 'Dott.ssa'
   },
   {
      id: 3,
      name: 'Sig.'
   },
   {
      id: 4,
      name: 'Sig.ra'
   }
]

export const ospedaleOptions = [
   { id: 1, name: 'Ospedale' },
   { id: 2, name: 'Territorio' }
]

export const ordineOptions = [
   { id: 1, name: 'FNOMCEO' },
   { id: 2, name: 'FNOPI' },
   { id: 3, name: 'FOFI' },
   { id: 4, name: 'Altro' }
]

export const operativitàOptions = [
   { id: 1, name: 'Libero professionista' },
   { id: 2, name: 'Dipendente' },
   { id: 3, name: 'Convenzionato' },
   { id: 4, name: 'Privo di occupazione' }
]

export const nascitaOptions = [
   { id: 1, name: 'Italia' },
   { id: 2, name: 'Estero' }
]

export const genderOptions = [
   { id: 'F', name: 'Femmina' },
   { id: 'M', name: 'Maschio' }
]

export const tipoOptions = [
   { id: 1, name: 'Privato' },
   { id: 2, name: 'Lavorativo' }
]
