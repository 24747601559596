import { useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { CourseCard } from 'Components/Cards/CourseCard/CourseCard'
import { Skeleton } from 'Components/Cards/CourseCard/Skeleton'
import Container from 'Components/shared/Container'
import SectionHeader from 'Components/shared/SectionHeader'
import { Footer } from 'Layouts/Footer/Footer'
import { NewTCourse } from 'Types/Models'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const FavouredCourses = () => {
  const [isLargerThan768] = useMediaQuery(['(min-width: 768px)'])

  const { getFavouriteCoursesV2 } = useApiRequests()

  const [allCourses, setAllCourses] = useState<any[]>([])
  const [page, setPage] = useState(1)

  const { ref, inView } = useInView()

  const { data, isFetching } = useQuery(['favourated', page], {
    queryFn: ({ queryKey }) => getFavouriteCoursesV2(+queryKey[1]),
    select: (res) => res.data.data,
    onSuccess: (res) => setAllCourses((prev) => [...prev, ...res.courses.data])
  })

  useEffect(() => {
    inView && setPage((prev) => prev + 1)
  }, [inView])

  return (
    <>
      <Container extraClasses='mb-10 gap-0 md:px-0 md:pt-0'>
        <div className='mt-[25px] md:mt-[50px] pb-[30px] px-[15px] md:px-0'>
          <SectionHeader
            title='I tuoi corsi preferiti'
            description={isLargerThan768 ? 'Elenco dei tuoi corsi preferiti' : ''}
          />
        </div>
        <div>
          {allCourses.length === 0 && !isFetching && (
            <div className='text-4xl text-center mt-10 font-bold text-active font-saira'>
              Niente qui
            </div>
          )}
        </div>
        <div className='px-[15px] lg:px-0 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-9 2xl:grid-cols-4'>
          {allCourses.map((course: NewTCourse) => {
            return <CourseCard key={course.id} {...course} />
          })}

          {isFetching && Array.from(Array(4).keys()).map((item) => <Skeleton key={item} />)}

          {page < data?.courses.last_page && !isFetching && <div ref={ref}>{''}</div>}
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default FavouredCourses
