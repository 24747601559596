import { FadeBox } from 'Components/FadeBox'
import React from 'react'

export const Skeleton = () => {
   return (
      <FadeBox>
         <div className='shadow-3xl animate-pulse hover:shadow-2xl transition-all duration-500 min-h-[400px] h-full rounded-md'>
            <div className='relative overflow-hidden'>
               <div className='object-cover transition-all cursor-pointer bg-slate-300 duration-500 hover:scale-105 min-h-[298px] w-full rounded-t-md' />
            </div>

            <div className=' flex flex-col gap-3 md:gap-5 px-6 py-6'>
               <div className='flex flex-wrap justify-between gap-1'>
                  <div className='flex gap-1'>
                     <span className='text-sm text-[#848484] font-saira'>
                        <div className='rounded-lg bg-slate-300 h-4 w-16'></div>
                     </span>
                  </div>
                  <div className='flex gap-1'>
                     <span className='text-sm text-[#848484] font-saira'>
                        <div className='rounded-lg bg-slate-300 h-4 w-16'></div>
                     </span>
                  </div>
                  <div className='flex items-center gap-1'>
                     <div className='rounded-lg bg-slate-300 h-4 w-16'></div>
                  </div>
                  <div className='flex items-center gap-1'>
                     <div className='rounded-lg bg-slate-300 h-4 w-16'></div>
                  </div>
               </div>
               <p className='text-primaryLighter font-bold text-xl line-clamp-3 cursor-pointer'>
                  <div className='rounded-lg bg-slate-300 h-10 w-full'></div>
               </p>
               <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
               <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
               <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
               <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
            </div>
         </div>
      </FadeBox>
   )
}
