import { Spinner, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { moduileQuizAtom, selectedModuleAtom } from 'Recoil/atoms'
import { QuizInormation } from './QuizInormation'
import { QuizInProgress } from './QuizInProgress'
import { useTranslation } from 'react-i18next'
import { QuizFooter } from './QuizFooter'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { useEffect } from 'react'
import { CourseCompletedModal } from 'Components/Modals/CourseCompletedModal'

export const ServeyWindow = () => {
   const [quizState, setQuizState] = useRecoilState(moduileQuizAtom)
   const resetQuiz = useResetRecoilState(moduileQuizAtom)
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const [selectedModule, setSelectedModule] = useRecoilState(selectedModuleAtom)
   const { t } = useTranslation()
   const { getSurveyData } = useApiRequests()
   const { isLoading, refetch } = useQuery(['quiz', selectedModule?.id], {
      queryFn: () => getSurveyData(selectedModule?.id || 0),
      select: (res) => res.data.data,
      onSuccess: (res) => {
         setSelectedModule(res)
         setQuizState({ ...quizState, questionsList: res.questions })
      }
   })
   const { isOpen, onClose, onOpen } = useDisclosure()

   useEffect(() => {
      quizState.finished && onOpen()
   }, [quizState.finished])

   const handleClose = () => {
      setSelectedModule(null)
      resetQuiz()
      onClose()
   }
   return (
      <>
         {isLoading ? (
            <div className='flex w-full h-full justify-center items-center'>
               <Spinner />
            </div>
         ) : (
            <div className='lg:h-full h-[95%] py-6 rounded-lg overflow-y-scroll flex justify-between px-5 flex-col gap-4 lg:p-0'>
               {smallScreen && (
                  <span className='text-xs font-bold text-secondary'>{t('QUIZ')}</span>
               )}
               {quizState.finished ? (
                  <CourseCompletedModal onClose={handleClose} isOpen={isOpen} />
               ) : (
                  <>
                     <div className=' lg:p-7 w-full flex-col lg:bg-[#F8F8F8] overflow-y-scroll bg-transparent h-[85%] flex gap-5 rounded-lg'>
                        {!quizState.started && quizState.questionsList ? (
                           <QuizInormation />
                        ) : (
                           <QuizInProgress questionsList={quizState.questionsList || []} />
                        )}
                     </div>
                     <QuizFooter refetch={() => refetch()} />
                  </>
               )}
            </div>
         )}

         {smallScreen && (
            <div className='h-[5%] flex justify-between items-center px-3 bg-white'>
               <div className='flex gap-1 h-full items-center '>
                  {/* <img className='w-7 h-7' src={timer} /> */}
                  {/* <span className='text-primary text-xs font-semibold'>06:34/10:00</span> */}
               </div>
               <div className='flex gap-12 justify-between'>
                  <span className='cursor-pointer'>
                     <svg
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                           d='M7.866 9.28704C7.48632 9.74799 6.80737 9.74799 6.38749 9.28704L2.81541 5.67174C2.39687 5.28761 2.39687 4.60071 2.81541 4.17591C3.23395 3.75563 3.9129 3.75563 4.33144 4.17591L7.14685 7.02748L13.5343 0.561966C13.9542 0.138524 14.6332 0.138524 15.0128 0.561966C15.4685 0.985408 15.4685 1.67232 15.0128 2.09576L7.866 9.28704ZM7.866 19.4099C7.48632 19.8708 6.80737 19.8708 6.38749 19.4099L0.31397 13.2639C-0.104657 12.8797 -0.104657 12.1928 0.31397 11.768C0.732552 11.3478 1.4115 11.3478 1.83004 11.768L7.14685 17.1503L18.1798 5.98356C18.5997 5.56328 19.2786 5.56328 19.6583 5.98356C20.1139 6.40836 20.1139 7.09526 19.6583 7.47939L7.866 19.4099Z'
                           fill='#335F6B'
                        />
                     </svg>
                  </span>
                  {/* <span>
                     {(selectedModule as TQuiz).quiz_user.status.attempts}/
                     {(selectedModule as TQuiz).max_attempts}
                  </span> */}
               </div>
            </div>
         )}
      </>
   )
}
