import { Button } from '@chakra-ui/react'
import { ModalWrapper } from 'Components/Modals/ModalWrapper'
import { PaymentModal } from 'Components/Modals/PaymentModal'
import React, { useState } from 'react'

export const Payment = () => {
   const [showPaymentModal, setShowPaymentModal] = useState(false)
   return (
      <>
         <div className='sm:container w-[96%] mx-auto flex h-[70vh] justify-between flex-col mx-auto py-8'>
            <div className='flex flex-col w-96 gap-3'>
               <h3 className='font-bold text-blueDark'>Gentile Mario Rossi,</h3>
               <p className='text-[15px]'>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                  praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                  excepturi sint occaecati cupiditate non provident
               </p>
            </div>
            <Button
               w='315px'
               alignSelf={'center'}
               onClick={() => setShowPaymentModal(true)}
               height={'70px'}
               bg='#3C8287'
               variant={'primary'}
            >
               Acquista
            </Button>
         </div>
         <ModalWrapper size='sm' isOpen={showPaymentModal} onClose={() => setShowPaymentModal(false)}>
            <PaymentModal />
         </ModalWrapper>
      </>
   )
}
