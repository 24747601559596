import { Button } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { FaRegTimesCircle } from 'react-icons/fa'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { moduileQuizAtom, modulesListAtom, selectedModuleAtom } from 'Recoil/atoms'
import { TModule, TQuiz } from 'Types/Models'

export const QuizFailedOverTake = () => {
   const reset = useResetRecoilState(moduileQuizAtom)
   const queryClient = useQueryClient()
   const { t } = useTranslation()
   const [quizState] = useRecoilState(moduileQuizAtom)
   const [modulesList] = useRecoilState(modulesListAtom)
   const [selectedModule, setselectedModule] = useRecoilState(selectedModuleAtom)

   const returnToQuizModule = () => {
      modulesList.forEach((module) => {
         if (module.id === (selectedModule as TQuiz).module_id) {
            const moduleObj = { ...module } as TModule
            moduleObj.activity_tracking = false
            setselectedModule(moduleObj)
         }
      })
   }

   return (
      <div className='font-semibold max-w-[700px] flex flex-col justify-center items-center mx-auto h-[70%] gap-6'>
         <FaRegTimesCircle className='text-orange' size={'100px'} />
         <h3 className='text-orange text-center whitespace-pre-line text-xl'>
            {t('QUIZ_NOT_PASSED')}
         </h3>
         <div className='text-primary text-center'>
            <h6>
               {t('You scored')} {quizState.score}/100
            </h6>
            <h6>{t('Status Not Qualified')}</h6>
            <h6>
               {t('Attempts')} {quizState.user_attempts}/5
            </h6>
         </div>
         <Button
            mt='50px'
            w='300px'
            onClick={() => {
               returnToQuizModule()
               reset()
               queryClient.invalidateQueries({ queryKey: ['quiz'] })
            }}
            variant={'primary'}>
            GUARDA DI NUOVO IL MODULO
         </Button>
      </div>
   )
}
