import React, { useState } from 'react'
import { CourseWelcomingCard } from '../../Cards/CourseWelcomingCard'
import { ModuleCard } from '../../Cards/ModuleCard/ModuleCard'
import { ModuleOverview } from '../../ModuleOverview'
import { useMediaQuery } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FadeBox } from '../../FadeBox'
import { TCourseDetails } from 'Types/Models'
import { getColor } from 'Helpers'
import { CourseEnrollButton } from './CourseEnrollButton'
import { useApiRequests } from 'API/ApiRequests'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Love } from 'Components/Buttons/Love'
import moment from 'moment'
import { CoursePlan } from './CoursePlan'
import 'moment/locale/it'
import { FaClock, FaRegClock } from 'react-icons/fa'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'

export const CourseDetailsHeader = ({ data }: { data: TCourseDetails }) => {
   const [userData] = useRecoilState(userDataAtom)
   const [smallScreen] = useMediaQuery('(max-width: 768px)')
   const { t } = useTranslation()
   const { favouriteCourse } = useApiRequests()

   const { mutate } = useMutation({ mutationFn: favouriteCourse })

   const [isLiked, setisLiked] = useState(data.is_favourite)

   const queryClient = useQueryClient()

   const handleToggleLike = () => {
      setisLiked((prev) => !prev)
      mutate(data.id, { onSuccess: () => queryClient.invalidateQueries(['favouriteCourses']) })
   }

   function truncateText(text: any, maxLength: any) {
      if (text.length <= maxLength) {
         return text
      }

      return text.slice(0, maxLength) + '...'
   }

   return (
      <FadeBox>
         <div
            style={{
               backgroundPosition: 'center',
               backgroundRepeat: 'no-repeat',
               backgroundSize: 'cover',
               backgroundImage: `linear-gradient(
               to right,
               rgb(0 0 0 / 80%), rgb(0 0 0 / 70%), rgb(0 0 0 / 50%)), url(${process.env.REACT_APP_MEDIA_URL + data.cover
                  })`
            }}
            className='relative md:block flex rounded-md min-h-[192px] w-full '>
            <div className='absolute top-4 right-4 flex items-center justify-center'>
               {userData?.plainTextToken && <Love isLiked={isLiked} handleToggleLike={handleToggleLike} />}
            </div>


            <div className='rounded-md pt-10 md:justify-between w-full justify-end flex flex-col gap-9 '>
               <CoursePlan planId={data.plan_id} />
               {!smallScreen && (
                  <ModuleOverview description={data.abstract_description} title={data.title} />
               )}

               <div className='md:flex hidden no-scrollbar px-10  min-h-[250px] gap-8 overflow-x-scroll'>
                  <CourseWelcomingCard
                     title={data.introduction}
                     description={data.introduction}
                     introductionDetails={data.introduction_details}
                  />
                  {data.models
                     .sort((a, b) => a.sort_order + b.sort_order)
                     .map((model) => {
                        return (
                           <ModuleCard
                              key={model.id}
                              bgColor={getColor(model)}
                              textColor='white'
                              isLocked={model.module_user.status.locked}
                              title={model.title}
                              secondTitle={model.type_text === 'TEXT' ? 'TESTO' : model.type_text}
                              secondDescription={
                                 (model.module_user.status.progress &&
                                    `CONSULTATO PER IL ${model.module_user.status.progress}%`) ||
                                 ''
                              }
                              description={truncateText(
                                 model.description,
                                 model.module_user.status.progress ? 80 : 180
                              )}
                              status={
                                 model.start_date ? (
                                    <div className='text-[#AA5260] uppercase font-bold flex gap-1 items-center'>
                                       <FaRegClock className='text-base' />
                                       DISPONIBILE DAL{' '}
                                       {moment(model.start_date).locale('it').format('DD MMMM')}
                                    </div>
                                 ) : (
                                    (model.module_user.finished_at &&
                                       `COMPLETATO IL ${moment(model.module_user.finished_at)
                                          .locale('it')
                                          .format('DD MMMM YYYY')}`) ||
                                    ''
                                 )
                              }
                           />
                        )
                     })}
               </div>
               <div className='flex justify-between gap-5 md:border-t-4 px-4 border-white items-center'>
                  <div className='h-[80px] flex md:w-[65%] overflow-x-scroll no-scrollbar w-full justify-between items-center md:divide-x divide-zinc-400/50'>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <span className='text-center'> {t('Credits')}</span>
                        <span className='font-semibold'>{data?.info.completion_credits}</span>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <span className='text-center'> {t('Online since')}</span>
                        <span className='font-semibold'>
                           {moment(data.info.start_date).format('DD-MM-YYYY')}
                        </span>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <span className='text-center'> {t('Valid until')}</span>
                        <span className='font-semibold'>
                           {moment(data.info.end_date).format('DD-MM-YYYY')}
                        </span>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <span className='text-center'> {t('Event ID')}</span>
                        <span className='font-semibold'>{data.ag_id ? data.ag_id : '--'}</span>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <span className='text-center'>{t('Hours')}</span>
                        <span className='font-semibold'>{data.info.completion_hours}</span>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <span className='text-center'> {t('Channel')}</span>
                        <span className='font-semibold'>{data.channel.name}</span>
                     </div>
                  </div>
                  {!smallScreen && (
                     <div className='w-[30%]'>
                        <CourseEnrollButton data={data} />
                     </div>
                  )}
               </div>
            </div>
         </div>
      </FadeBox>
   )
}
