/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-this-alias */
import React, { useEffect, useRef, useState } from 'react'
import videojs from 'video.js'
import videojsqualityselector from 'videojs-hls-quality-selector'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import IdleTimeOutHandler from 'Components/IdleHandler'
import { useRecoilState } from 'recoil'
import { isSessionActive, selectedModuleAtom, userDataAtom } from 'Recoil/atoms'
import { TModule } from 'Types/Models'
import { useApiRequests } from 'API/ApiRequests'

export const Player = ({
   url,
   handleFinishVideo
}: {
   url: string
   handleFinishVideo: () => void
}) => {
   const video = useRef(null)
   const [player, setPlayer] = useState<any>(null)
   const [isActive, setIsActive] = useRecoilState(isSessionActive)
   const [selectedModule] = useRecoilState(selectedModuleAtom)
   const [userData] = useRecoilState(userDataAtom)
   const { update_pointer } = useApiRequests()

   const lastKnownTime =
      (selectedModule as any).module_user?.status?.video_pointer ||
      parseFloat(
         localStorage.getItem(String(`${userData?.user_data.id}:${selectedModule?.id}`)) || '0'
      )

   const [currentTabActive, setCurrentTabActive] = useState(true)

   const handleUpdateProgress = async (seconds: number) => {
      if (!selectedModule || (selectedModule as TModule).module_user.finished_at) return

      await update_pointer(selectedModule.id, seconds)
   }

   useEffect(() => {
      const videoJsOptions = {
         autoplay: false,
         preload: 'auto',
         controls: true,
         fill: true,
         fluid: true,

         poster: '',
         sources: [
            {
               src: url,
               type: 'application/x-mpegURL'
            }
         ],
         html5: {
            nativeAudioTracks: true,
            nativeVideoTracks: true,
            nativeTextTracks: true
         }
      }
      const p = videojs(video.current as any, videoJsOptions as any)

      p.currentTime(lastKnownTime)

      setPlayer(p)
      return () => {
         if (player) player.dispose()
      }
   }, [])

   useEffect(() => {
      if (player) {
         player.hlsQualitySelector = videojsqualityselector
         player.hlsQualitySelector()
      }
   }, [player])

   useEffect(() => {
      videojs('video').ready(function () {
         const myPlayer = this

         let currentTime = lastKnownTime

         myPlayer.on('seeking', function (event) {
            if (
               (selectedModule as TModule)?.activity_tracking &&
               (selectedModule as TModule)?.module_user.finished_at === null
            ) {
               if (currentTime < myPlayer.currentTime()) {
                  myPlayer.currentTime(currentTime)
               } else {
                  if (myPlayer.currentTime() < currentTime) {
                     // console.log(myPlayer.currentTime(), 'Send seek api')
                  }
               }
            }
         })

         myPlayer.on('play', function (event) {
            if (myPlayer.currentTime() === 0) {
               // console.log(myPlayer.currentTime(), 'video started')
            }
         })
            ; (myPlayer.controlBar as any).playToggle.on('click', function () {
               if (!myPlayer?.paused()) {
                  // console.log('Video is played')
               } else {
                  // console.log('Video is paused')
               }
            })

         myPlayer.on('ended', function (event) {
            if (
               myPlayer.currentTime() ===
               parseFloat(
                  window.localStorage.getItem(
                     String(`${userData?.user_data.id}:${selectedModule?.id}`)
                  ) || ''
               )
            ) {
               myPlayer.currentTime(0)
            } else {
               handleFinishVideo()
            }
         })

         let lastMinute = -1

         myPlayer.on('timeupdate', function () {
            const currentTime = Math.floor(myPlayer.currentTime())
            const currentMinute = Math.floor(currentTime / 60)

            if (!(selectedModule as TModule)?.activity_tracking) return

            // ! Update every 5 min
            if (currentMinute !== lastMinute && currentMinute % 5 === 0 && currentTime >= 300) {
               handleUpdateProgress(currentTime)
               lastMinute = currentMinute
            }
         })

         // window.onunload = function () {
         //    window.localStorage.setItem(
         //       String(`${userData?.user_data.id}:${selectedModule?.id}`),
         //       String(myPlayer.currentTime())
         //    )
         // }

         setInterval(function () {
            if (!myPlayer?.paused()) {
               currentTime = myPlayer.currentTime()
            }
         }, 1000)
      })

      return () => {
         // videojs('video').ready(function () {
         //    const myPlayer = this
         //    window.localStorage.setItem(
         //       String(`${userData?.user_data.id}:${selectedModule?.id}`),
         //       String(myPlayer.currentTime())
         //    )
         // })
      }
   }, [])

   useEffect(() => {
      document.addEventListener('visibilitychange', function (event) {
         if (document.hidden) {
            setCurrentTabActive(false)
         }
      })
   }, [])

   useEffect(() => {
      if (!isActive) {
         videojs('video').pause()
      }
   }, [isActive])

   return (
      <>
         {!currentTabActive && (
            <IdleTimeOutHandler
               onActive={() => {
                  setIsActive(true)
               }}
               onIdle={() => {
                  setIsActive(false)
               }}
               tabIsBack={() => setCurrentTabActive(true)}
            />
         )}

         <div data-vjs-player>
            <video
               preload='none'
               ref={video}
               id='video'
               className='video-js vjs-default-skin vjs-big-play-centered'></video>
         </div>
      </>
   )
}
