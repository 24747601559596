import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { Love } from 'Components/Buttons/Love'
import DetailsLabel from 'Components/DetailsLabel'
import SubscriptionStyles from 'Configs/SubscripationStyles'
import { userDataAtom } from 'Recoil/atoms'
import { TUserCourses } from 'Types/Models'
import PlanTypes from 'Types/Subscripe'
import moment from 'moment'
import { useState } from 'react'
import { MdOutlineFavorite, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

const OngoingCard = ({
  plan_name,
  title,
  cover,
  slug,
  is_favourite,
  end_date,
  completion_credits,
  progress,
  enrolled,
  id,
}:
  TUserCourses) => {
  const [userData] = useRecoilState(userDataAtom)


  const finished = progress === 100

  const expired = (moment(Date.now()).isAfter(end_date) && !finished)

  const navigate = useNavigate()
  const { favouriteCourse } = useApiRequests()
  const [isLiked, setIsLiked] = useState(is_favourite)

  const { mutate } = useMutation({ mutationFn: favouriteCourse })
  const queryClient = useQueryClient()
  const handleToggleLike = () => {
    setIsLiked((prev) => !prev)
    mutate(id, { onSuccess: () => queryClient.invalidateQueries(['favouriteCourses']) })
  }

  const NavButton = (
    <MdOutlineKeyboardArrowRight
      onClick={() => navigate(`/courses/${slug}`)}
      className={`w-[25px] md:w-[40px] h-[25px] md:h-[40px] z-40 relative drop-shadow-lg hover:bg-white transition-all bottom-0 hover:bottom-[5px] ${finished
        ? `${SubscriptionStyles[plan_name as PlanTypes].border} ${SubscriptionStyles[plan_name as PlanTypes].text
        }`
        : 'text-white border-white'
        }
cursor-pointer border-[2px] md:border-4 self-end rounded-full hover:${SubscriptionStyles[plan_name as PlanTypes].text
        }`}
    />
  )

  return (
    <div
      className={
        'rounded-[3px] lg:rounded-[10px] relative overflow-hidden h-full shadow hover:shadow-lg transition-shadow duration-150 min-h-[200px]'
      }>
      {cover.match('https') ? (
        <div
          className={`w-full h-full object-cover absolute z-0 ${expired ? 'opacity-50' : 'bg-slate-300 '
            }`}
        />
      ) : (
        <Link to={`/courses/${slug}`} draggable={false}>
          <img
            src={process.env.REACT_APP_MEDIA_URL + cover}
            className={`w-full h-full object-cover absolute z-0 ${expired && 'opacity-50'}`}
            draggable={false}
          />
        </Link>
      )}
      <div
        className={`text-white z-40 relative h-full ${!expired ? SubscriptionStyles[plan_name as PlanTypes].gradient : 'disabled-gradient'
          }`}>
        <div className='flex flex-col gap-2 justify-between h-full'>
          <h4 className='font-nuinto font-bold text-xl lg:text-[23px] line-clamp-3 pt-[53px] md:pt-[65px] px-[10px] md:px-5'>
            {title}
          </h4>
          <div className={'w-full relative'}>
            {enrolled && !finished && !expired ? (
              <div className='flex items-center justify-between pb-[10px] px-[10px] md:px-5'>
                <div
                  className='font-saira text-[15px] lg:text-lg flex flex-col leading-3 flex-1 pb-[10px]'
                  style={{ lineHeight: 1 }}>
                  <span>STATO: {Math.round(progress)}%</span>
                  <span>FINE: {moment(end_date).format('DD/MM/YYYY')}</span>
                </div>
                <div>{NavButton}</div>
              </div>
            ) : expired ? (
              <div className='flex items-center justify-between w-full pb-[10px] px-[10px] md:px-5'>
                <span className='text-[30px] font-bold font-saira-normal self-end'>
                  CHIUSO
                </span>
                <span className='pb-[10px]'>
                  {NavButton}
                </span>
              </div>
            ) : finished ? (
              <div className='flex items-center justify-between font-saira-normal w-full z-30 bg-white pb-[10px] px-[10px] md:px-5 pt-[7px] md:py-4'>
                <div style={{ lineHeight: 1 }}>
                  <h4
                    className={`text-[30px] font-bold ${SubscriptionStyles[plan_name as PlanTypes].text
                      }`}>
                    COMPLETATO
                  </h4>
                  <p
                    className={`hidden lg:block text-[15px] ${SubscriptionStyles[plan_name as PlanTypes].text
                      }`}>
                    Apri il corso per scaricare l’attestato
                  </p>
                </div>
                {NavButton}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='absolute top-0 left-0 w-full flex flex-col justify-between z-30'>
          <div className='flex items-center justify-between w-full pt-[10px] px-[10px] md:pt-5 md:px-5'>
            <DetailsLabel
              expired={expired}
              plan_name={plan_name as PlanTypes}
              completion_credits={completion_credits}
              showPlanName={false}
            />
            {userData?.plainTextToken && <Love
              handleToggleLike={handleToggleLike}
              isLiked={isLiked}
            />}
          </div>
        </div>
      </div>
    </div>
  )
}

const Skeleton = () => {
  return (
    <div className='bg-slate-100 pt-[53px] pr-5 pl-[11px] pb-5 px-[10px] transition-all duration-500 shadow animate-pulse rounded-[3px] lg:rounded-[10px] flex-1 min-h-full min-w-full relative flex'>
      <div className='absolute top-0 px-[10px] pt-[10px] left-0 w-full flex items-center justify-between z-10'>
        <div className='h-8 animate-pulse bg-slate-300 w-[75px]' />
        <MdOutlineFavorite className='text-3xl text-slate-300 animate-pulse' />
      </div>
      <div className='flex flex-col justify-between flex-1 h-full'>
        <div className='h-full flex flex-col items-start gap-1 flex-1'>
          <div className='flex flex-col items-start w-full h-8 rounded-md bg-slate-300 animate-pulse' />
          <div className='flex flex-col items-start w-[150px] h-8 rounded-md bg-slate-300 animate-pulse' />
        </div>
        <div className='flex items-center w-full justify-between'>
          <div className='flex flex-col gap-[1px]'>
            <div className='flex flex-col items-start w-[150px] gap-1 h-4 rounded-md bg-slate-300 animate-pulse' />
          </div>
          <MdOutlineKeyboardArrowRight
            className={
              'w-10 h-10 drop-shadow-lg text-slate-300 rounded-full animate-pulse bg-slate-300'
            }
          />
        </div>
      </div>
    </div>
  )
}

export default { Card: OngoingCard, Skeleton }
