import React from 'react'

export const EndDateSVG = () => {
   return (
      <svg
         width='16'
         height='19'
         viewBox='0 0 16 19'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'>
         <path
            d='M8.4375 10.362L10.2902 12.2147C10.5082 12.4362 10.8668 12.4362 11.0848 12.2147C11.3063 11.9967 11.3063 11.6381 11.0848 11.4202L8.27227 8.60768C8.0543 8.38619 7.6957 8.38619 7.47773 8.60768L4.66523 11.4202C4.44375 11.6381 4.44375 11.9967 4.66523 12.2147C4.8832 12.4362 5.2418 12.4362 5.45977 12.2147L7.3125 10.362V14.6299C7.3125 14.9393 7.56563 15.1924 7.90664 15.1924C8.18437 15.1924 8.46914 14.9393 8.46914 14.6299L8.4375 10.362ZM11.25 2.25494H4.5V0.567444C4.5 0.256803 4.24687 0.00494385 3.9375 0.00494385C3.62813 0.00494385 3.375 0.256803 3.375 0.567444V2.25494H2.25C1.00898 2.25494 0 3.26217 0 4.50494V15.7549C0 16.996 1.00898 18.0049 2.25 18.0049H13.5C14.7428 18.0049 15.75 16.996 15.75 15.7549V4.50494C15.75 3.26217 14.7428 2.25494 13.5 2.25494H12.375V0.567444C12.375 0.256803 12.1219 0.00494385 11.8125 0.00494385C11.5031 0.00494385 11.25 0.256803 11.25 0.567444V2.25494ZM14.625 15.7549C14.625 16.3772 14.1212 16.8799 13.5 16.8799H2.25C1.62773 16.8799 1.125 16.3772 1.125 15.7549V6.75494H14.625V15.7549ZM14.625 4.50494V5.62994H1.125V4.50494C1.125 3.88268 1.62773 3.37994 2.25 3.37994H13.5C14.1212 3.37994 14.625 3.88268 14.625 4.50494Z'
            fill='#848484'
         />
      </svg>
   )
}
