import SubscriptionStyles from "Configs/SubscripationStyles"
import { TDiscount } from "Types/Models"
import PlanTypes from "Types/Subscripe"
import { useTranslation } from "react-i18next"
import { MdOutlineKeyboardArrowRight } from "react-icons/md"
import { useNavigate } from "react-router-dom"

export const GoToDetailsButton = ({
   expired,
   coming_soon,
   slug,
   started_at,
   finished_at,
   plan_name,
   discount,
   discount_label,
}: {
   expired: boolean
   started_at: string
   finished_at: string
   coming_soon: boolean
   slug: string
   plan_name: PlanTypes
   discount?: TDiscount
   discount_label: string
}) => {
   const navigate = useNavigate()
   const { t } = useTranslation()

   if (coming_soon) {
      return (
         <div
            className={`flex justify-between p-4 items-center absolute bottom-0 w-full ${SubscriptionStyles[plan_name].bg} text-white font-bold font-saira`}>
            <span className='text-xl'>
               {t("DISPONIBILE DAL")} {started_at}
            </span>
            <MdOutlineKeyboardArrowRight
               onClick={() => navigate(`/courses/${slug}`)}
               className={`text-[32px] md:text-[42px] hover:bottom-[22px] drop-shadow-lg hover:${SubscriptionStyles[plan_name].text} hover:bg-white
                transition-all cursor-pointer text-white border-[2px] md:border-4 border-white rounded-full`}
            />
         </div>
      )
   }

   if (expired) {
      return (
         <div
            className={`flex justify-between p-4 items-center absolute bottom-0 w-full ${SubscriptionStyles[plan_name].bg} text-white font-bold font-saira`}>
            <span className='text-xl'>
               {t("CORSO CHIUSO IL")} {finished_at}
            </span>
            <MdOutlineKeyboardArrowRight
               onClick={() => navigate(`/courses/${slug}`)}
               className={`text-[32px] md:text-[42px] hover:bottom-[22px] drop-shadow-lg hover:${SubscriptionStyles[plan_name].text} hover:bg-white transition-all
                cursor-pointer text-white border-[2px] md:border-4 border-white rounded-full`}
            />
         </div>
      )
   }

   return (
      //  right-[11px] md:right-[17px]
      <div
         className={`absolute bottom-0 right-0 px-[11px] md:px-[17px] py-[14px] flex items-center justify-between w-full ${
            discount && SubscriptionStyles[plan_name].bg
         }`}>
         {discount && discount.status ? (
            <>
               <div className={`font-nuinto ${SubscriptionStyles[plan_name].text} borderedText`}>
                  {discount.percentage}%
               </div>
               <div className='absolute bottom-[66px] bg-plus px-[13px] py-[9px] rounded-[4px] text-[15px] font-nuinto text-white font-bold -rotate-[4deg]'>
                  {discount_label}
               </div>
            </>
         ) : (
            <div></div>
         )}
         <MdOutlineKeyboardArrowRight
            onClick={() => navigate(`/courses/${slug}`)}
            className={`text-[32px] md:text-[42px] hover:bottom-[22px] drop-shadow-lg ${
               discount
                  ? `hover:bg-white hover:${SubscriptionStyles[plan_name].text}`
                  : `hover:${SubscriptionStyles[plan_name].bg} hover:${SubscriptionStyles[plan_name].text}`
            }  transition-all
          cursor-pointer text-white border-[2px] md:border-4 border-white rounded-full`}
         />
      </div>
   )
}
