import { Button } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const LoginAndSubscribe = () => {
   const navigate = useNavigate()
   const location = useLocation()

   return (
      <Button
         onClick={() => navigate('/login', { state: { redirectTo: location.pathname } })}
         height={'55px'}
         variant={'secondary'}
         w='full'>
         <span className='text-xs lg:text-base'>ACCEDI E ISCRIVITI</span>
      </Button>
   )
}
