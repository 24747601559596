import { FadeBox } from 'Components/FadeBox'
import { LessonOverviewMobile } from 'Components/LessonOverviewMobile'
import VideoPlayer from 'Components/VideoPlayer'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { selectedModuleAtom } from 'Recoil/atoms'

export const LessonVideoWindow = () => {
   const [show, setShow] = useState(false)
   const [selectedModule] = useRecoilState(selectedModuleAtom)

   useEffect(() => {
      setShow(false)
      setTimeout(() => setShow(true), 500)
   }, [selectedModule?.id])

   return (
      <FadeBox>
         <div className='lg:bg-white lg:p-0 w-full h-full'>
            <LessonOverviewMobile />
            {show && <VideoPlayer />}
         </div>
      </FadeBox>
   )
}
