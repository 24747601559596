import { userDataAtom } from 'Recoil/atoms'
import React, { Dispatch, SetStateAction } from 'react'
import { useRecoilState } from 'recoil'

export const CourseCard = ({
   data,
   isSelected,
   setSelectedCard
}: {
   data: any
   isSelected?: boolean
   setSelectedCard: Dispatch<SetStateAction<any>>
}) => {
   const [userData] = useRecoilState(userDataAtom)

   return (
      <div
         onClick={() => setSelectedCard(data)}
         className='flex justify-start cursor-pointer hover:bg-slate-100 transition-all items-start gap-2.5 p-[15px] rounded-lg bg-white'
         style={{
            boxShadow: isSelected ? '0px 0px 10px 0 #496492' : '0px 0px 10px 0 rgba(0,0,0,0.25)'
         }}>
         <div className='flex justify-start items-center gap-2.5 p-2.5'>
            {isSelected ? (
               <svg
                  width={18}
                  height={18}
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className=' w-[18px] h-[18px] relative'
                  preserveAspectRatio='none'>
                  <rect x={1} y={1} width={16} height={16} rx={8} fill='#496492' />
                  <path
                     d='M5.5 9.5L7.5 11.5L12.5 6.5'
                     stroke='white'
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
                  <rect
                     x={1}
                     y={1}
                     width={16}
                     height={16}
                     rx={8}
                     stroke='#496492'
                     strokeWidth={2}
                  />
               </svg>
            ) : (
               <div className='flex justify-start items-start w-[18px] h-[18px] overflow-hidden gap-2.5 p-[5px] rounded-full bg-white border-2 border-[#496492]' />
            )}
         </div>
         <div className='flex flex-col justify-start items-start gap-2.5 flex-1'>
            <div className='flex justify-start items-start  gap-2.5'>
               <div className='flex justify-start items-start  relative overflow-hidden gap-2.5 p-[5px] rounded-[5px] bg-[#a6aac4]'>
                  <p className=' text-[9px] font-bold text-left uppercase text-white'>
                     SINGOLO CORSO
                  </p>
               </div>
            </div>

            <div className='flex flex-col justify-start items-start relative gap-[7px] w-full'>
               <p className='self-stretch font-bold text-left text-[#496492]'>
                  <span className='self-stretch text-xs font-bold text-left text-[#496492]'>
                     Acquista il corso
                  </span>
                  <br />
                  <span className='self-stretch text-xl font-bold text-left text-[#496492]'>
                     {data.title}{' '}
                  </span>
               </p>

               <p
                  className='text-xs text-left text-black'
                  dangerouslySetInnerHTML={{ __html: data.description }}
               />

               <div className='flex gap-4'>
                  <div className='flex flex-col items-center  py-2 justefy-center gap-1 w-[92px]  rounded-lg bg-[#FFEBEE]'>
                     <span className='text-[8px] text-center text-[#aa5260]'>UTENTI FREE</span>
                     <span
                        className={`${Number(data.price_with_discount) !== Number(data.price) &&
                           !(
                              data.type === 'SEMI' &&
                              userData?.user_data.subscription.type_text === 'FREE'
                           ) &&
                           'line-through'
                           } text-lg font-bold text-left text-[#aa5260]`}>
                        €{data.price}
                     </span>
                     <span className=' text-[7px] text-center text-[#aa5260]'>IVA INCLUSA</span>
                  </div>
                  {data?.price_with_discount && (
                     <div className='flex flex-col justefy-between w-[92px] h-[64px] rounded-lg bg-[#E9F1FE]'>
                        <div className='flex flex-col items-center  py-2 justefy-center gap-1 w-[92px]  rounded-lg bg-[#E9F1FE]'>
                           <span className='text-[8px] text-center text-[#496492]'>
                              UTENTI PLUS
                           </span>
                           <span className=' text-lg font-bold text-left text-[#496492]'>
                              €{data.price_with_discount}
                           </span>
                           <span className=' text-[7px] text-center text-[#496492]'>
                              IVA INCLUSA
                           </span>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   )
}
