import { ResetPasswordForm } from 'Components/Forms/ResetPasswordForm'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const ForgetPassword = () => {
   const { t } = useTranslation()

   return (
      <div className='flex justify-center items-center'>
         <div className='bg-white my-14 pb-10 container flex flex-col'>
            <div className='w-full text-center'>
               <h3 className='text-primary text-3xl py-10 font-semibold'>
                  {t('Reset you password')}
               </h3>
               <p className='text-base font-semibold pb-9'>
                  Inserisci l`indirizzo email a cui desideri inviare le informazioni per reimpostare
                  la password
               </p>
            </div>
            <ResetPasswordForm />
            <div className='w-full justify-center mt-10 flex gap-1'>
               <span>{t('Go back to')}</span>
               <Link to='/login' className='text-primary font-semibold'>
                  Login
               </Link>
            </div>
         </div>
      </div>
   )
}
