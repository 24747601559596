import { Button } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { VscPass } from 'react-icons/vsc'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { moduileQuizAtom, modulesListAtom, selectedModuleAtom } from 'Recoil/atoms'

export const QuizCompleted = () => {
   const { t } = useTranslation()
   const reset = useResetRecoilState(moduileQuizAtom)
   const queryClient = useQueryClient()
   const [quizState] = useRecoilState(moduileQuizAtom)
   const [modulesList] = useRecoilState(modulesListAtom)
   const [selectedModule] = useRecoilState(selectedModuleAtom)

   useEffect(() => {
      return () => {
         reset()
      }
   }, [])

   const isLastQuiz = () => {
      let index = 0
      modulesList.forEach((module, i) => {
         if (module.id === selectedModule?.id) {
            index = i
         }
      })
      return index === modulesList.length - 2
   }

   return (
      <div
         className='font-semibold w-full h-full flex flex-col lg:bg-[#F8F8F8] 
         bg-primary justify-center items-center mx-auto gap-6'>
         <VscPass className='text-secondary' size={'100px'} />
         <h3 className='lg:text-primary text-white text-xl'>{t('Quiz Completed')}</h3>
         <div className='lg:text-primary text-white flex flex-col items-center'>
            <h6>
               {t('You scored')} {quizState.score}/100
            </h6>
            <h6>{t('Status Qualified')}</h6>
         </div>
         <Button
            onClick={() => {
               queryClient.invalidateQueries({ queryKey: ['quiz'] })
               queryClient.invalidateQueries({ queryKey: ['modules'] })
            }}
            mt='50px'
            w='300px'
            variant={'secondary'}>
            {isLastQuiz() ? 'INIZIA IL QUESTIONARIO' : t('Continue')}
         </Button>
      </div>
   )
}
