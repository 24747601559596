import React, { useEffect, useState } from 'react'
import { RegisterInput } from 'Components/Inputs/RegisterInput'
import { RegisterSelect } from 'Components/Inputs/RegisterSelect'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TRegisterPersonalForm, TRegisterSecondStepForm } from 'Types/FormsTypes'
import { Button } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { otherSpecializationsAtom, registerFormAtom, specializationsAtom } from 'Recoil/atoms'
import { useApiRequests } from 'API/ApiRequests'
import { useMutation, useQuery } from '@tanstack/react-query'
import { operativitàOptions, ordineOptions, ospedaleOptions, titleOptions } from 'Configs/Constants'
import { useValidationSchemas } from 'Schemas/RegisterSchemas'

export const FirstStep = () => {
   const [specializations, setspecializations] = useRecoilState(specializationsAtom)
   const [otherSpecializations, setotherSpecializations] = useRecoilState(otherSpecializationsAtom)
   const [subSpecializations, setsubSpecializations] = useState([])
   const [state, setState] = useRecoilState(registerFormAtom)
   const { getProvinces, getProfession, getProvinceRegion, validateEmail } = useApiRequests()
   const validateEmailMutation = useMutation({ mutationFn: validateEmail })
   const { RegisterPersonalFormSchema } = useValidationSchemas()
   const {
      handleSubmit,
      register,
      setValue,
      setError,
      control,
      formState: { errors }
   } = useForm<TRegisterPersonalForm>({
      resolver: yupResolver(RegisterPersonalFormSchema),
      defaultValues: state.firstStepData
   })
   const watchProfessions = useWatch({ control, name: 'profession' })
   const watchSpecialization = useWatch({ control, name: 'specialization' })
   const watchSpecialization2 = useWatch({ control, name: 'specialization_2' })
   const watchProvience = useWatch({ control, name: 'province_enployment' })

   const provincesQuery = useQuery({
      queryKey: ['provinces'],
      queryFn: getProvinces,
      select: (data) => {
         return Object.keys(data.data.data).map((prov: any) => {
            return { id: prov, name: data.data.data[prov] }
         })
      }
   })

   const professionQuery = useQuery({
      queryKey: ['profession'],
      queryFn: getProfession
   })

   const onSubmit: SubmitHandler<TRegisterPersonalForm> = (data: TRegisterPersonalForm) => {
      validateEmailMutation.mutate(
         { email: data.email },
         {
            onSuccess: (res) => {
               if (res) {
                  setState({ ...state, firstStepData: data, step: 2 })
               }
            },
            onError: () => {
               setError('email', {
                  type: 'custom',
                  message: 'L’email risulta già registrata! Effettua il login'
               })
            }
         }
      )
   }

   useEffect(() => {
      if (watchProfessions) {
         const selectedProfession = professionQuery.data?.data.data.find(
            (profess: any) => profess.name === watchProfessions
         )
         setspecializations(selectedProfession?.specializations || [])
         setotherSpecializations(selectedProfession?.specializations || [])
      }
   }, [watchProfessions])

   useEffect(() => {
      if (watchSpecialization) {
         const selectedSpecialization = specializations.find((spec: any) => {
            return spec.name === watchSpecialization
         })
         setsubSpecializations((selectedSpecialization as any)?.sub_specializations || [])
         setotherSpecializations(
            specializations.filter((spec: any) => spec.name !== watchSpecialization)
         )
      }
   }, [watchSpecialization])

   useEffect(() => {
      if (watchProvience) {
         getProvinceRegion(watchProvience).then((res) => {
            setValue('regione_enployment', res.data.data)
         })
      }
   }, [watchProvience])

   // eslint-disable-next-line no-console
   // console.log(errors)

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <div className='flex flex-col gap-8 my-4 container mx-auto'>
            <div className='grid md:grid-cols-3 grid-cols-1 gap-4'>
               <RegisterSelect
                  name='title'
                  register={register}
                  errorMessage={errors.title?.message}
                  label='Titolo*'
                  options={titleOptions}
                  placeholder='Seleziona titolo'
               />
               <RegisterInput
                  errorMessage={errors.first_name?.message}
                  name='first_name'
                  infoText='Inserire il nome completo, come da codice fiscale'
                  register={register}
                  label='Name*'
                  placeholder='Mario'
               />
               <RegisterInput
                  errorMessage={errors.last_name?.message}
                  name='last_name'
                  infoText='Inserire il cognome completo, come da codice fiscale'
                  register={register}
                  label='Cognome*'
                  placeholder='Rossi'
               />
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
               <RegisterInput
                  errorMessage={errors.email?.message}
                  name='email'
                  infoText="Inserire un indirizzo email, evitando l'utilizzo di PEC o email aziendali. Preferire indirizzi personali"
                  register={register}
                  label='Email*'
                  placeholder='Indirizzo email'
               />
               <RegisterInput
                  infoText="Inserire nuovamente l'indirizzo email scelto per convalida"
                  errorMessage={errors.email_confirmation?.message}
                  name='email_confirmation'
                  register={register}
                  label='Ripetere email*'
                  placeholder='Ripetere indirizzo email'
               />
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
               <RegisterInput
                  errorMessage={errors.password?.message}
                  name='password'
                  isPassword
                  infoText='Si consiglia di scegliere una password sicura che contenga almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale (!#?@)'
                  register={register}
                  label='Password*'
                  placeholder='Password'
               />
               <RegisterInput
                  isPassword
                  errorMessage={errors.password_confirmation?.message}
                  name='password_confirmation'
                  infoText='Inserire nuovamente la password scelta'
                  register={register}
                  label='Ripetere Password*'
                  placeholder='Ripetere Password'
               />
            </div>
            <div className='grid md:grid-cols-[70%_27%] grid-cols-1 justify-between gap-4'>
               <RegisterSelect
                  name='profession'
                  register={register}
                  options={professionQuery.data?.data.data || []}
                  errorMessage={errors.profession?.message}
                  label='Professione*'
                  placeholder='Seleziona professione '
               />
               <RegisterSelect
                  name='territory'
                  register={register}
                  options={ospedaleOptions}
                  errorMessage={errors.territory?.message}
                  label='Territorio*'
                  placeholder='Seleziona'
               />
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
               <RegisterSelect
                  name='specialization'
                  register={register}
                  errorMessage={errors.specialization?.message}
                  label='Disciplina*'
                  options={specializations}
                  placeholder='Seleziona Disciplina'
               />
               {subSpecializations.length > 0 && (
                  <RegisterSelect
                     options={subSpecializations}
                     name='sub_specialization'
                     register={register}
                     errorMessage={errors.sub_specialization?.message}
                     label='Specializzazione*'
                     placeholder='Seleziona Specializzazione '
                  />
               )}
               {specializations[1] && (
                  <RegisterSelect
                     infoText='Se ha ulteriori titoli, la invitiamo ad indicare qui la 2 disciplina'
                     name={'specialization_2' as keyof TRegisterSecondStepForm}
                     register={register}
                     label={'Disciplina 2'}
                     options={otherSpecializations}
                     placeholder={'Seleziona Disciplina 2'}
                  />
               )}
               {specializations[2] && (
                  <RegisterSelect
                     infoText='Se ha ulteriori titoli, la invitiamo ad indicare qui la 3 disciplina'
                     name={'specialization_3' as keyof TRegisterSecondStepForm}
                     register={register}
                     label={'Disciplina 3'}
                     options={otherSpecializations.filter(
                        (spec: any) => spec.name !== watchSpecialization2
                     )}
                     placeholder={'Seleziona Disciplina 3'}
                  />
               )}
            </div>
            <div className='grid md:grid-cols-3 grid-cols-1 gap-4'>
               <RegisterSelect
                  name='employment'
                  register={register}
                  errorMessage={errors.employment?.message}
                  label='Operatività*'
                  options={operativitàOptions}
                  placeholder='Seleziona Operatività'
               />
               <RegisterSelect
                  name='province_enployment'
                  register={register}
                  selectId
                  options={provincesQuery.data || []}
                  errorMessage={errors.province_enployment?.message}
                  label='Provincia attività lavorativa*'
                  placeholder='Seleziona provincia'
               />
               <RegisterInput
                  errorMessage={errors.regione_enployment?.message}
                  name='regione_enployment'
                  infoText='Se ha selezionato "privo di occupazione" indicare l&apos;ultima provincia nella quale si è lavorato'
                  register={register}
                  disabled
                  label='Regione attività lavorativa*'
                  placeholder='Lombardia'
               />
               <RegisterSelect
                  name='board_member'
                  register={register}
                  options={ordineOptions}
                  errorMessage={errors.board_member?.message}
                  label='Ordine professionale*'
                  placeholder='Seleziona Ordine'
               />
               <RegisterSelect
                  name='province_board'
                  register={register}
                  selectId
                  errorMessage={errors.province_board?.message}
                  label="Provincia di iscrizione all'ordine*"
                  options={provincesQuery.data || []}
                  placeholder=''
               />
               <RegisterInput
                  errorMessage={errors.board_number?.message}
                  name='board_number'
                  infoText="Indicare il numero d'iscrizione all'ordine professionale"
                  register={register}
                  label='Numero* iscrizione Ordine*'
                  placeholder='Inserisci numero'
               />
            </div>
            <div className='w-full justify-end flex'>
               <Button type='submit' variant={'primary'} w='150px' height={'55px'}>
                  Avanti
               </Button>
            </div>
         </div>
      </form>
   )
}
