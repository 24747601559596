import {
   Button,
   Drawer,
   DrawerBody,
   DrawerCloseButton,
   DrawerContent,
   DrawerOverlay,
   Textarea,
   useToast
} from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export const CommentsDrawer = ({ showCommentsDrawer, hanldeCloseComments }: any) => {
   const [text, setText] = useState('')
   const { getCourseComments, addComment } = useApiRequests()
   const { courseId } = useParams()
   const queryClint = useQueryClient()
   const { data } = useQuery(['comments', courseId], {
      queryFn: () => getCourseComments(courseId || ''),
      select: (res) => res.data.data
   })
   const toast = useToast()
   const { t } = useTranslation()
   const { mutate, isLoading } = useMutation({ mutationFn: addComment })

   const handleChange = (e: any) => {
      setText(e.target.value)
   }

   const handleSubmitComment = () => {
      mutate(
         { courseId, comment: text },
         {
            onSuccess: (res) => {
               queryClint.invalidateQueries(['comments'])
               if (!res) {
                  toast({
                     title: 'Oh no! Sembra che al momento i commenti non stiano funzionano... Riprova tra 2 minuti',
                     status: 'error'
                  })
               }
               setText('')
            },
            onError: () => {
               toast({
                  title: 'Grazie, il tuo commento è stato inviato alla redazione!',
                  status: 'info'
               })
            }
         }
      )
   }

   return (
      <Drawer
         size={'md'}
         isOpen={showCommentsDrawer}
         placement='right'
         onClose={hanldeCloseComments}>
         <DrawerOverlay />
         <DrawerContent>
            <DrawerCloseButton onClick={hanldeCloseComments} />
            <DrawerBody py={10}>
               <div className='flex flex-col gap-4'>
                  <b>{t('Lascia un commento')}</b>
                  <Textarea
                     onChange={handleChange}
                     value={text}
                     placeholder={`${t('Enter your comment')}`}
                  />
                  <Button
                     alignSelf={'flex-end'}
                     w='fit-content'
                     onClick={handleSubmitComment}
                     fontSize='12px'
                     isLoading={isLoading}
                     variant={'primary'}>
                     {t('Invia Commento')}
                  </Button>
               </div>
               <div className='my-7 flex flex-col gap-4'>
                  {data?.map((comment: any) => (
                     <div
                        key={comment.id}
                        className='rounded-lg bg-slate-100 p-4 flex flex-col gap-2 min-h-[100px]'>
                        <b className='text-primary'>{comment.user}</b>
                        <span className='text-gray'>
                           {moment(comment.comment_date).format('DD-MM-YYYY')}
                        </span>
                        <p className='text-black'>{comment.comment}</p>
                     </div>
                  ))}
               </div>
            </DrawerBody>
         </DrawerContent>
      </Drawer>
   )
}
