import { extendTheme } from '@chakra-ui/react'
import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'
import {
   createMultiStyleConfigHelpers,
} from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
   control: {
      borderRadius: 3,
      borderColor: '#496492',
      borderWidth: 0.5
   }
})

export const checkboxTheme = defineMultiStyleConfig({
   baseStyle,
})


const theme = extendTheme({
   fonts: {
      heading: 'Montserrat',
      body: 'Montserrat'
   },
   colors: {
      yellow: {
         500: '#F3A84D'
      },
      primary: '#325C68',
      orange: '#D34100',
      secondary: '#F3A84D',
      'light-gray': '#F8F8F8',
      gray: '#B3B3B3',
      'dark-grey': '#5F5F5F',
      blueDark: '#496391',
      activeDark: '#496492'
   },
   components: {
      Button: {
         baseStyle: {
            _focus: { boxShadow: 'none' },
            textTransform: 'uppercase'
         },
         variants: {
            primary: () => ({
               bg: 'primary',
               color: 'white',
               height: '10',
               rounded: '5px',
               w: 'full',
               _disabled: {
                  bgColor: 'primary',
                  opacity: 0.5
               },
               _hover: {
                  bg: '#2c7488',
                  _disabled: {
                     bgColor: 'primary'
                  }
               },
               _active: { bg: '#224048' }
            }),
            secondary: () => ({
               bg: 'secondary',
               rounded: '5px',
               color: 'white',
               height: '10',
               w: 'full',
               _disabled: {
                  bgColor: 'secondary',
                  opacity: 0.5
               },
               _hover: {
                  bg: '#ffb254',
                  _disabled: {
                     bgColor: 'secondary'
                  }
               },
               _active: { bg: '#ff920c' }
            }),
            fade: () => ({
               bg: 'gray-light',
               w: 'full',
               rounded: 'lg',
               color: 'gray-base'
            }),
            white: () => ({
               bg: 'white',
               rounded: 'lg',
               borderRadius: '5px',
               color: 'gray-base'
            })
         }
      },
      Input: {
         variants: {
            filled: {
               field: {
                  border: '2px solid',
                  borderColor: 'gray',
                  textColor: 'blue-dark',
                  rounded: 'sm',
                  height: 12,
                  fontSize: 'md',
                  _focus: {
                     borderColor: 'primary',
                     boxShadow: 'none'
                  }
               }
            }
         }
      },
      CheckBox: checkboxTheme
   }
})

export default theme
