import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Navbar } from '../Layouts/Navbar/Navbar'
import Home from 'Pages/Home'
import { CourseDetails } from 'Pages/CourseDetails'
import { Module } from 'Pages/Module'
import { useScrollToTop as ScrollToTop } from 'Hooks/useScrollToTop'
import { Register } from 'Pages/Register'
import { Login } from 'Pages/Login'
import { ForgetPassword } from 'Pages/ForgetPassword'
import { Consent } from 'Pages/Consent'
import ProtectedRoute from './ProtectedRoute'
import { Activation } from 'Pages/Activation'
import { Payment } from 'Pages/Payment'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import AlreadyLoggedinRedirect from './AlreadyLoggedinRedirect'
import { ResetPassword } from 'Pages/ResetPassword'
import { Profile } from 'Components/HomeTabs/Profile/Profile'
import Termini from 'Pages/Termini'
import PrivacyPolicy from 'Pages/PrivacyPolicy'
import Politica from 'Pages/Politica'
import PaymentOk from 'Pages/Payments/PaymentOk'
import PaymentCancelled from 'Pages/Payments/PaymentCancelled'
// import { FavouriteCourses } from 'Pages/FavouriteCourses'
import FAQ from 'Pages/FAQ'
import { Offerta } from 'Pages/Offerta'
// import { Canale } from 'Pages/Canale'
import Billing from 'Pages/Billing'
import { Packages } from 'Pages/Packages'
// import Category from 'Pages/Category'
import Canali from 'Pages/Canali'
import ACanali from 'Pages/ACanali'
import FavouredCourses from 'Pages/FavouredCourses'
import { HomePage } from 'Pages/home/HomePage'
import OnGoing from 'Pages/OnGoing'
import CorsiEcm from 'Pages/corsiEcm/CorsiEcm'
import ForYou from 'Pages/ForYou'
import AllCourses from 'Pages/AllCourses'
import NewCategoryPage from 'Pages/NewCategoryPage'
const stripePromise = loadStripe(
   'pk_test_51LsojILZ14kd1O19OsZVmzeqSZeCIdsnhVHY0CgaqQOJSAO4oczGU1kgqjpuMz8JmkTsPdGuEDOFPgbrm7eRTyXk00dcZXXYUY'
)

export default function AppRoutes() {
   return (
      <BrowserRouter>
         <ScrollToTop />
         <Navbar />

         <Routes>
            <Route
               path='/dashboard'
               element={
                  <ProtectedRoute>
                     <Home />
                  </ProtectedRoute>
               }
            />
            <Route
               path='/register'
               element={
                  <AlreadyLoggedinRedirect>
                     <Register />
                  </AlreadyLoggedinRedirect>
               }
            />
            <Route
               path='/consent'
               element={
                  <AlreadyLoggedinRedirect>
                     <Consent />
                  </AlreadyLoggedinRedirect>
               }
            />
            <Route
               path='/forgot-password'
               element={
                  <ProtectedRoute>
                     <ForgetPassword />
                  </ProtectedRoute>
               }
            />
            <Route
               path='/password-reset'
               element={
                  <ProtectedRoute>
                     <ResetPassword />
                  </ProtectedRoute>
               }
            />
            <Route
               path='/login'
               element={
                  <AlreadyLoggedinRedirect>
                     <Login />
                  </AlreadyLoggedinRedirect>
               }
            />

            <Route
               path='/'
               element={
                  <ProtectedRoute>
                     <HomePage />
                  </ProtectedRoute>
               }
            />

            {/* <Route
               path='/'
               element={
                  <ProtectedRoute>
                     <AllCourses type='all' />
                  </ProtectedRoute>
               }
            />
            <Route
               path='/corsi-free'
               element={
                  <ProtectedRoute>
                     <AllCourses type='FREE' />
                  </ProtectedRoute>
               }
            />
            <Route
               path='/corsi-plus'
               element={
                  <ProtectedRoute>
                     <AllCourses type='PLUS' />
                  </ProtectedRoute>
               }
            /> */}
            {/* <Route
               path='/favourite-courses'
               element={
                  <ProtectedRoute>
                     <FavouriteCourses />
                  </ProtectedRoute>
               }
            /> */}
            <Route path='/user-activation' element={<Activation />} />

            <Route
               path='/courses/:slug'
               element={
                  <ProtectedRoute>
                     <CourseDetails />
                  </ProtectedRoute>
               }
            />

            {/* <Route
               path='/plan/:id'
               element={
                  <ProtectedRoute>
                     <NewCategoryPage />
                  </ProtectedRoute>
               }
            /> */}
            <Route
               path='/corsi/:slug'
               element={
                  <ProtectedRoute>
                     <NewCategoryPage />
                  </ProtectedRoute>
               }
            />
            <Route
               path='/tutti-i-corsi'
               element={
                  <ProtectedRoute>
                     <AllCourses />
                  </ProtectedRoute>
               }
            />
            {/* AllCourses */}
            {/* // ! Redirect */}
            <Route
               path='/corsi'
               element={
                  <Navigate to={'../'} />
               }
            />

            <Route
               path='/corsi-inziati'
               element={
                  <ProtectedRoute>
                     <OnGoing />
                  </ProtectedRoute>
               }
            />

            <Route
               path='/scelti-per-te'
               element={
                  <ProtectedRoute>
                     <ForYou />
                  </ProtectedRoute>
               }
            />

            <Route
               path='/corsi-ecm'
               element={
                  <ProtectedRoute>
                     <CorsiEcm />
                  </ProtectedRoute>
               }
            />

            <Route
               path='/canali/:slug'
               element={
                  <ProtectedRoute>
                     <ACanali />
                  </ProtectedRoute>
               }
            />
            <Route
               path='/canali/'
               element={
                  <ProtectedRoute>
                     <Canali />
                  </ProtectedRoute>
               }
            />

            <Route
               path='/preferiti'
               element={
                  <ProtectedRoute>
                     <FavouredCourses />
                  </ProtectedRoute>
               }
            />

            <Route
               path='/payment'
               element={
                  <Elements stripe={stripePromise}>
                     <Payment />
                  </Elements>
               }
            />

            <Route
               path='/module/:courseId'
               element={
                  <ProtectedRoute>
                     <Module />
                  </ProtectedRoute>
               }
            />

            <Route
               path='/update-profile'
               element={
                  <AlreadyLoggedinRedirect>
                     <Profile />
                  </AlreadyLoggedinRedirect>
               }
            />

            <Route path='/termini-duso' element={<Termini />} />

            <Route path='/privacy-policy' element={<PrivacyPolicy />} />

            <Route path='/politica-della-qualita' element={<Politica />} />

            <Route
               path='/payment-ok'
               element={
                  <ProtectedRoute>
                     <PaymentOk />
                  </ProtectedRoute>
               }
            />

            <Route
               path='/payment-cancelled'
               element={
                  <ProtectedRoute>
                     <PaymentCancelled />
                  </ProtectedRoute>
               }
            />
            <Route path='/faq' element={<FAQ />} />
            <Route
               path='/offerta'
               element={
                  <ProtectedRoute>
                     <Offerta />
                  </ProtectedRoute>
               }
            />
            <Route
               path='/checkout'
               element={
                  <ProtectedRoute>
                     <Billing />
                  </ProtectedRoute>
               }
            />
            <Route path='/packages' element={<Packages />} />
         </Routes>
      </BrowserRouter>
   )
}
