import { useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import React from 'react'

const AdBannerMain = () => {
  const { getMainBanner } = useApiRequests()
  const { data, isLoading } = useQuery(['main-banners'], {
    queryFn: getMainBanner,
    select: (data) => data.data
  })

  const [isLargerThan768] = useMediaQuery(['(min-width: 768px)'])

  return (
    <>
      {isLoading ? (
        <div className='h-[135px] bg-[#D9D9D9]'></div>
      ) : isLargerThan768 ? (
        <a href={data.data[0].link} target='_blank' rel="noreferrer">
          <img src={`${process.env.REACT_APP_MEDIA_URL + data.data[0].desktop_image}`} alt='Main Banner' />
        </a>

      ) : (
        <a href={data.data[0].link}>
          <img src={`${process.env.REACT_APP_MEDIA_URL + data.data[0].mobile_image}`} alt='Main Banner' />
        </a>
      )}
    </>
  )
}

export default AdBannerMain
