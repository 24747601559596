// text-secondary_root-dark text-success-dark text-primary_root-dark text-danger-dark
// hover:text-secondary_root-dark hover:text-success-dark hover:text-primary_root-dark hover:text-danger-dark
// bg-secondary_root-dark bg-success-dark bg-primary_root-dark bg-danger-dark
// md:bg-secondary_root-dark md:bg-success-dark md:bg-primary_root-dark md:bg-danger-dark
// hover:text-free bg-free border-free 
// hover:text-semi bg-semi border-semi 
// hover:text-plus bg-plus border-plus 
// hover:text-full bg-full border-full

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import SubscriptionStyles from 'Configs/SubscripationStyles'
import { userDataAtom } from 'Recoil/atoms'
import { NewTCourse } from 'Types/Models'
import PlanTypes from 'Types/Subscripe'
import { motion } from 'framer-motion'
import moment from 'moment'
import { useState } from 'react'
import { MdOutlineFavorite, MdOutlineFavoriteBorder, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { t } from 'i18next'

const DetailsLabel = ({
  plan_name,
  completion_credits,
  expired,
  showPlanName = true
}: {
  plan_name: PlanTypes
  completion_credits?: string
  expired: boolean
  showPlanName?: boolean
}) => {
  return (
    <div className={`h-8 md:h-10 text-[15px] md:text-[23px] flex drop-shadow-lg font-saira ${expired && 'opacity-50'}`}>
      <span
        className={`${SubscriptionStyles[plan_name].text} bg-white flex items-center font-bold px-4`}>
        {completion_credits + ' ECM' || t('NO ECM')}
      </span>
      {showPlanName && (
        <span
          className={`text-white ${SubscriptionStyles[plan_name].bg}  flex items-center font-bold px-3`}>
          {plan_name}
        </span>
      )}
    </div>
  )
}

export const Love = ({
  isLiked,
  handleToggleLike
}: {
  isLiked: boolean
  handleToggleLike: () => void
}) => {
  const variants = {
    open: { scale: [1, 1.5, 1.2, 1.1, 1] },
    closed: {}
  }
  return (
    <motion.button
      className='outline-none'
      animate={isLiked ? 'open' : 'closed'}
      variants={variants}>
      {isLiked ? (
        <MdOutlineFavorite className='text-3xl md:text-[60px] text-rose-600' onClick={handleToggleLike} />
      ) : (
        <MdOutlineFavoriteBorder
          className='text-white text-3xl md:text-[60px] hover:text-rose-600 transition-all'
          onClick={handleToggleLike}
        />
      )}
    </motion.button>
  )
}

const Card = ({
  // info,
  completion_credits,
  cover,
  abstract_description,
  title,
  slug,
  // expired,
  plan_name,
  is_favourite,
  // is_liked,
  id,
  end_date
}: NewTCourse) => {
  const [userData] = useRecoilState(userDataAtom)
  const navigate = useNavigate()
  const { favouriteCourse } = useApiRequests()
  const { mutate } = useMutation({ mutationFn: favouriteCourse })
  const [isLiked, setisLiked] = useState(is_favourite)
  const queryClient = useQueryClient()
  const handleToggleLike = () => {
    setisLiked((prev) => !prev)
    mutate(id, { onSuccess: () => queryClient.invalidateQueries(['favouriteCourses']) })
  }

  const expired = moment(Date.now()).isAfter(end_date)

  return (
    <div className={'rounded-[10px] overflow-hidden relative h-full'}>
      {cover.match('https') ? (
        <div className='object-cover transition-all cursor-pointer bg-slate-300 duration-500 hover:scale-105 min-h-[298px] w-full rounded-t-md' />
      ) : (
        <Link to={`/courses/${slug}`} draggable={false}>
          <img
            src={process.env.REACT_APP_MEDIA_URL + cover}
            className={`w-full h-full object-cover absolute z-0 ${expired && 'opacity-50'}`}
            draggable={false}
          />
        </Link>
      )}
      <div
        className={`relative ${SubscriptionStyles[plan_name as PlanTypes].gradient
          } p-[10px]  md:p-5 z-10 w-full h-full flex justify-between shadow-3xl flex-col items-start text-white`}>
        <div className='flex items-start justify-between w-full'>
          <DetailsLabel
            expired={false}
            plan_name={plan_name as PlanTypes}
            completion_credits={completion_credits}
          />
          {userData?.plainTextToken && <Love isLiked={isLiked} handleToggleLike={handleToggleLike} />}
        </div>
        <div className='mb-3 text-[20px] leading-[22px] md:leading-[33px] md:text-[30px] pt-[11px]  md:pt-[34px] font-bold font-nuinto flex-1 line-clamp-4'>
          {title}
        </div>
        <div
          className={'font-nuinto flex flex-row items-center justify-between lg:gap-5 w-full'}>
          <div className='block lg:hidden'></div>
          <div className='hidden lg:block !w-fit'>
            <div
              dangerouslySetInnerHTML={{ __html: abstract_description || '' }}
              className='text-sm line-clamp-4 h-20'
            />
          </div>
          <div className='flex items-center justify-center w-fit'>
            <MdOutlineKeyboardArrowRight
              onClick={() => navigate(`/courses/${slug}`)}
              className={`w-[25px] h-[25px] md:w-[73px] md:h-[73px] bottom-0 hover:bottom-[22px] drop-shadow-lg hover:bg-white transition-all
          cursor-pointer text-white border-[2px] md:border-4 border-white rounded-full hover:${SubscriptionStyles[plan_name as PlanTypes].text}`}
            />
            {/* <div className='w-6 h-6 lg:w-[73px] lg:h-[73px] flex items-center justify-center rounded-full border-2 border-white'>
              <BsChevronRight className='text-base lg:text-2xl' />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const Skeleton = () => {
  return (
    <div
      className={
        'shadow animate-pulse bg-slate-100 rounded-[10px] h-full w-full transition-all duration-500 flex-1'
      }>
      <div className={'p-[10px]  md:px-8 md:pt-10 md:pb-12 flex-1 flex flex-col h-full'}>
        <div className='flex flex-col flex-1 h-full'>
          <div className='h-8 animate-pulse bg-slate-300 max-w-[126px] rounded-md' />
          <div className='mb-1 mt-[11px] md:mt-8 h-10 animate-pulse bg-slate-300 rounded-md' />
          <div className='mb-3 h-10 animate-pulse bg-slate-300 max-w-[300px] rounded-md' />
        </div>
        <div
          className={'flex flex-row items-center justify-between gap-2 md:gap-3 w-full animate-pulse'}>
          <div className='lg:hidden'></div>
          <div className='hidden lg:flex flex-1 flex-col gap-1'>
            <div className='text-sm bg-slate-300 animate-pulse max-w-[250px] h-2 md:h-3 rounded-md' />
            <div className='text-sm bg-slate-300 animate-pulse max-w-[250px] h-2 md:h-3 rounded-md' />
            <div className='text-sm bg-slate-300 animate-pulse max-w-[250px] h-2 md:h-3 rounded-md hidden md:block' />
          </div>
          <div className='flex items-center justify-center px-5'>
            <MdOutlineKeyboardArrowRight
              className={`w-10 h-10 lg:w-[73px] lg:h-[73px] drop-shadow-lg 
      text-slate-300 rounded-full animate-pulse bg-slate-300`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default { Card, Skeleton }
