import React from 'react'

export const StartDateSvg = () => {
   return (
      <svg
         width='17'
         height='19'
         viewBox='0 0 17 19'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'>
         <path
            d='M8.75891 13.2729L10.6116 11.4202C10.8296 11.1987 11.1882 11.1987 11.4062 11.4202C11.6277 11.6381 11.6277 11.9967 11.4062 12.2147L8.59368 15.0272C8.37571 15.2487 8.01711 15.2487 7.79915 15.0272L4.98665 12.2147C4.76516 11.9967 4.76516 11.6381 4.98665 11.4202C5.20461 11.1987 5.56321 11.1987 5.78118 11.4202L7.63391 13.2729V9.00494C7.63391 8.69557 7.88704 8.44244 8.19641 8.44244C8.50579 8.44244 8.75891 8.69557 8.75891 9.00494V13.2729ZM4.82141 2.25494H11.5714V0.567444C11.5714 0.256803 11.8245 0.00494385 12.1339 0.00494385C12.4433 0.00494385 12.6964 0.256803 12.6964 0.567444V2.25494H13.8214C15.0624 2.25494 16.0714 3.26217 16.0714 4.50494V15.7549C16.0714 16.996 15.0624 18.0049 13.8214 18.0049H2.57141C1.32864 18.0049 0.321411 16.996 0.321411 15.7549V4.50494C0.321411 3.26217 1.32864 2.25494 2.57141 2.25494H3.69641V0.567444C3.69641 0.256803 3.94954 0.00494385 4.25891 0.00494385C4.56829 0.00494385 4.82141 0.256803 4.82141 0.567444V2.25494ZM1.44641 15.7549C1.44641 16.3772 1.9502 16.8799 2.57141 16.8799H13.8214C14.4437 16.8799 14.9464 16.3772 14.9464 15.7549V6.75494H1.44641V15.7549ZM1.44641 4.50494V5.62994H14.9464V4.50494C14.9464 3.88268 14.4437 3.37994 13.8214 3.37994H2.57141C1.9502 3.37994 1.44641 3.88268 1.44641 4.50494Z'
            fill='#848484'
         />
      </svg>
   )
}
