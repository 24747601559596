 import { Spinner, useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const Activation = () => {
   const navigate = useNavigate()

   const toast = useToast()

   const { activateUser } = useApiRequests()

   const [searchParams] = useSearchParams()

   const {t} = useTranslation()

   const { mutate } = useMutation(activateUser, {
      onSuccess: () => {
         navigate('/login')
         toast({
            title: t('Account activated successfully'),
            position: 'top',
            status: 'success',
            isClosable: true
         })
      },
      onError: () => {
         toast({
            title: t('Account has not activated'),
            position: 'top',
            status: 'error'
         })
      }
   })

   useEffect(() => {
      mutate({ code: searchParams.get('code') || '' })
   }, [])

   return (
      <div className='h-screen flex justify-center items-center'>
         <Spinner />
      </div>
   )
}
