const SmallBtn = ({
  children,
  onClick,
  extraClasses
}: {
  children: React.ReactNode
  onClick?: () => void
  extraClasses?: string
}) => {
  return (
    <button
      onClick={onClick}
      className={`text-[15px] lg:text-xl font-nuinto text-active lg:text-white lg:bg-active hover:opacity-90 px-[9px] py-[5px] leading-[17px] lg:leading-[22px] lg:rounded-[3px] lg:min-w-[155px] text-center ${extraClasses}`}>
      {children}
    </button>
  )
}

export default SmallBtn
