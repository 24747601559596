/* eslint-disable no-console */
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { useParams } from 'react-router-dom'
import { modulesListAtom, selectedCourseAtom, selectedModuleAtom } from 'Recoil/atoms'
import { useRecoilState } from 'recoil'
import { TModule } from 'Types/Models'
import { useState } from 'react'

export const useGetModules = () => {
   const { getCourseModules } = useApiRequests()

   const { courseId } = useParams()

   const [selectedCourse, setSelectedCourse] = useRecoilState(selectedCourseAtom)

   const [modulesList, setModulesList] = useRecoilState(modulesListAtom)

   const [, setselectedModule] = useRecoilState(selectedModuleAtom)

   const [isNotAvailable, setIsNotAvailable] = useState('')

   const [courseCompleted, setCourseCompleted] = useState(false)

   const { data, isLoading } = useQuery(['modules', courseId], {
      queryFn: () => getCourseModules(courseId || ''),
      select: (res) => {
         const modules: any = []
         res.data.data.modules.forEach((module: TModule) => {
            modules.push(module)
            if (module.quizzes.length) {
               modules.push(...module.quizzes)
            }
         })
         res.data.data?.survey && modules.push(res.data.data?.survey)
         const respones = { ...res.data.data, modules: modules }
         return respones
      },
      onSuccess: (data) => {
         setSelectedCourse(data)
         if (data?.course_user.status.finished) {
            setCourseCompleted(true)
            return
         }
         setModulesList(data.modules)
         data.modules.forEach((module: any) => {
            if (
               module.type_text === 'SURVEY' &&
               data.course_user?.can_survey_start?.can_start === true
            ) {
               setselectedModule(module)
               return
            }
            if (
               (module.type_text === 'PDF' ||
                  module.type_text === 'VIDEO' ||
                  module.type_text === 'TEXT' ||
                  module.type_text === 'HTML') &&
               !module?.module_user?.status.finished &&
               !module?.module_user?.status.locked
            ) {
               setselectedModule(module)
            }
            if (
               module.type_text === 'QUIZ' &&
               !module?.quiz_user?.status.last_success_attempt &&
               !module?.quiz_user?.status.locked
            ) {
               setselectedModule(module)
            }
         })
      },
      onError: (err) => {
         if (
            (err as any)?.response.status === 422 &&
            (err as any)?.response.data.data.reason === 'course-expired'
         ) {
            setIsNotAvailable((err as any)?.response.data.data.date_of_expireation)
         }
      },
      retry: false
   })

   return {
      modules: data?.modules || [],
      isLoading,
      isNotAvailable,
      totalHours: data?.completion_hours,
      userCompletedHour: data?.user_completed_hours,
      servey: {
         servey: data?.survey || null,
         can_survey_start: data?.course_user.can_survey_start
      },
      isLiked: data?.insights?.is_liked || false,
      canComment: data?.comments_enabled || false,
      likesCount: data?.likes_count || 0,
      courseProgres: data?.course_user.status.progress || 0,
      commentsCount: data?.comments_count || 0,
      courseCompleted,
      setCourseCompleted,
      moudleInfo: {
         completion_credits: data?.completion_credits,
         start_date: data?.start_date,
         canDownloadBoth: data?.can_download_both,
         end_date: data?.end_date,
         completion_hours: data?.completion_hours,
         professions: data?.professions,
         specializations: data?.specializations,
         education: data?.education,
         event_id: data?.event_id,
         ag_id: data?.ag_id,
         edition_number: data?.edin_number
      }
   }
}
