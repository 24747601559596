import { Button } from '@chakra-ui/react'
import { AuthorsList } from 'Components/AuthorsList'
import { CoursePartners } from 'Components/CoursePartners'
import { Footer } from 'Layouts/Footer/Footer'
import { useTranslation } from 'react-i18next'
import { CourseDescription } from '../CourseDescription/CourseDescription'
import { CourseDescriptionSkeleton } from '../CourseDescription/CourseDescriptionSkeleton'
import { useApiRequests } from 'API/ApiRequests'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Fragment } from 'react'

export const CourseOtherDetails = ({
   description,
   course_program_file,
   course_specializations,
   course_professions
}: {
   course_professions: string[]
   course_specializations: string[]
   description: string
   course_program_file: string
}) => {
   const { t } = useTranslation()

   const { getCourseSecondaryDetails } = useApiRequests()
   const params = useParams()

   const { data, isLoading } = useQuery(['courseSecondaryDetails', params?.slug], {
      queryFn: ({ queryKey }) => getCourseSecondaryDetails(queryKey[1] || ''),
      select: (data) => data.data.data
   })
   return (
      <>
         <div className='w-[96%] container hidden py-3 mx-auto md:flex gap-20'>
            <div className='w-[70%]'>
               {!isLoading ? (
                  <CourseDescription description={description} />
               ) : (
                  <CourseDescriptionSkeleton />
               )}
            </div>
            <div className='w-[30%] flex-col flex gap-5'>
               {data?.channel.url || data?.channel.slug ? (
                  <a
                     target={data?.channel.url && '_blank'}
                     style={{ cursor: !data?.channel.slug ? 'text' : 'pointer' }}
                     href={data?.channel.url ? data?.channel.url : `/canali/${data?.channel.slug}`}
                     className='rounded-md bg-[#F2F2F2] flex flex-col gap-2 p-4 '>
                     <span className='text-primary font-bold'>{t('Channel')}</span>
                     {data?.channel.logo && (
                        <img
                           className='w-8/12 mx-auto'
                           src={`${process.env.REACT_APP_MEDIA_URL || ''}${data?.channel.logo}`}
                        />
                     )}
                  </a>
               ) : (
                  <div className='rounded-md bg-[#F2F2F2] flex flex-col gap-2 p-4 '>
                     <span className='text-primary font-bold'>{t('Channel')}</span>
                     {data?.channel.logo && (
                        <img
                           className='w-8/12 mx-auto'
                           src={`${process.env.REACT_APP_MEDIA_URL || ''}${data?.channel.logo}`}
                        />
                     )}
                  </div>
               )}

               {data?.main_authors && (
                  <AuthorsList title='Responsabile scientifico' data={data.main_authors} />
               )}

               {data && <AuthorsList data={data?.authors} />}

               <div className='rounded-md bg-[#F2F2F2] flex flex-col gap-2 p-4   max-h-[250px] overflow-y-auto'>
                  <span className='text-primary font-bold'>Professioni accreditate</span>
                  <div className='leading-none relative '>
                     <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                        {'Professioni: '}
                     </p>
                     <p className='inline m-0 text-[13px] leading-[1.3]'>
                        {course_professions?.map((item: string, index: number) => (
                           <Fragment key={index}>
                              {item}
                              {index + 1 !== course_professions?.length && ','}{' '}
                           </Fragment>
                        ))}
                     </p>
                  </div>
                  <div className='leading-none relative'>
                     <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                        {'Discipline: '}
                     </p>
                     <p className='inline m-0 text-[13px] leading-[1.3]'>
                        {course_specializations?.map((item: string, index: number) => (
                           <Fragment key={index}>
                              {item}
                              {index + 1 !== course_specializations?.length && ','}{' '}
                           </Fragment>
                        ))}
                     </p>
                  </div>
               </div>
               {data?.widgets.map((widget) => (
                  <div key={widget.id} className='rounded-md bg-[#F2F2F2] flex flex-col gap-2 p-4'>
                     <span className='text-primary font-bold'>{widget.title}</span>
                     <div dangerouslySetInnerHTML={{ __html: widget.content }} />
                  </div>
               ))}

               {course_program_file !== '' && (
                  <Button
                     disabled={course_program_file === ''}
                     height={'60px'}
                     size='xs'
                     variant={'primary'}>
                     <a
                        href={`${process.env.REACT_APP_MEDIA_URL}${course_program_file}`}
                        target='_blank'
                        rel='noreferrer'>
                        <span className='text-xs'>{t('Scarica il programma del corso')}</span>
                     </a>
                  </Button>
               )}
            </div>
         </div>
         {data && <CoursePartners sponsor_text={data.sponsor_text} data={data.sponsors} />}
         <Footer />
      </>
   )
}
