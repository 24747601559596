import {
  Button,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  useOutsideClick
} from '@chakra-ui/react'
import { defaultFilter, filterState } from 'Recoil/atoms'
import { useRecoilState } from 'recoil'
import Icons from './Icons'
import Sort from './Sort'
import Filter from './Filter/Filter'
import { useDeferredValue, useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'

const Drawer = () => {
  const [state, setCState] = useRecoilState(filterState)

  const handleClose = () => {
    setCState((prev) => ({
      ...prev,
      open: false
    }))
  }

  return (
    <ChakraDrawer isOpen={state.open} placement='right' onClose={handleClose} size={'sm'}>
      <DrawerOverlay />
      <DrawerContent className='fix-filter-modal'>
        <DrawerHeader className='flex items-center justify-between'>
          <h2 className='font-saira text-active text-[30px]'>ORDINA & FILTRA</h2>
          <DrawerCloseButton />
        </DrawerHeader>

        <DrawerBody className='flex flex-col gap-10'>
          <Sort />
          <Filter />
        </DrawerBody>
        <DrawerFooter>
          <Button
            variant={'primary'}
            onClick={() => {
              setCState(prev => ({
                ...defaultFilter,
                filter: {
                  ...defaultFilter.filter,
                  plan: prev.hidePlans ? prev.filter.plan : []
                },
                open: true,
                hidePlans: prev.hidePlans
              }))
            }}>
            Pulisci
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  )
}

const Search = ({ stroke = '#111827' }: { stroke?: string }) => {
  const [state, setCState] = useRecoilState(filterState)
  const ref = useRef<any>(null)
  const [search, setSearch] = useState('')
  const toSearchValue = useDeferredValue(search)
  const [firstLoad, setFirstLoad] = useState(true);

  useOutsideClick({
    ref,
    handler: () => {
      if (search.length < 1 && state.openSearch) {
        setCState((prev) => ({
          ...prev,
          hasSearch: false,
          openSearch: false
        }))
      }
    }
  })

  useEffect(() => {
    if (toSearchValue.length === 0) {
      if (!firstLoad) {
        setCState(prev => ({
          ...prev,
          hasSearch: false,
          keyword: ''
        }))
      } else {
        setFirstLoad(false)
      }
    } else {
      const debouncedValue = setTimeout(() => {
        setCState(prev => ({
          ...prev,
          keyword: toSearchValue,
          hasSearch: true,
          openSearch: true
        }))
      }, 2000)

      return () => clearTimeout(debouncedValue)
    }
  }, [toSearchValue])

  useEffect(() => {
    if (state.openSearch) {
      ref.current.focus()
    }
  }, [state.openSearch])

  return (
    <div
      className='!flex !items-center !gap-1 !w-fit'
      style={{
        boxShadow: state.openSearch ? '0 0 0 1px #3182ce' : 'none',
        borderRadius: state.openSearch ? '2px' : 0,
        paddingLeft: state.openSearch ? '12px' : 0
      }}>
      <button
        onClick={() => {
          setCState((prev) => ({
            ...prev,
            openSearch: !prev.openSearch
          }))
        }}
        className='!flex !items-center !gap-1 !w-fit'>
        <Icons.Search stroke={stroke} />
        <p
          className={`transition-all duration-300 overflow-hidden ${state.openSearch ? 'w-0' : 'w-[50px]'
            }`}>
          <span style={{ overflowWrap: 'normal' }}>CERCA</span>
        </p>
      </button>
      <div
        className='relative transition-all duration-300'
        style={{
          width: state.openSearch ? 150 : 0
        }}>
        <Input
          p={state.openSearch ? '12px' : 0}
          value={search}
          // value={search.toLowerCase()}
          onChange={(e) => {
            setSearch(e.target.value)
            // setSearch(e.target.value.toLowerCase())
          }}
          _focusVisible={{
            boxShadow: 'none'
          }}
          py={0}
          pl={0}
          ref={ref}
          border={'none'}
          placeholder='Cerca...'
          _placeholder={{
            fontFamily: 'Nunito',
            textSize: '16px',
            fontStyle: 'italic'
          }}
          size={'sm'}
          className='pr-4'
        />
        {state.keyword.length > 0 && state.openSearch && (
          <FaTimes
            className='absolute top-1/2 right-3 -translate-y-1/2 z-20 cursor-pointer'
            onClick={() => {
              setCState((prev) => ({
                ...prev,
                search: '',
                hasSearch: false,
              }))
              setSearch('')
            }}
          />
        )}
      </div>
    </div>
  )
}

const Trigger = ({ stroke = '#111827' }: { stroke?: string }) => {
  const [, setCState] = useRecoilState(filterState)

  return (
    <div className='flex items-center gap-2 sm:gap-7 text-sm'>
      <Search stroke={stroke} />
      <div className=''>
        <button
          className='flex items-center gap-1'
          onClick={() => {
            setCState((prev) => ({
              ...prev,
              open: !prev.open
              // openSearch: false
            }))
          }}>
          <Icons.SmallFilter stroke={stroke} />
          ORDINA & FILTRA
        </button>
      </div>
    </div>
  )
}

export default { Drawer, Trigger }
