import { useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { CourseCard } from 'Components/Cards/CourseCard/CourseCard'
import { Skeleton } from 'Components/Cards/CourseCard/Skeleton'
// import Filters from 'Components/Filters/Filters'
import { TUserCourses } from 'Types/Models'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SubscriptionStyles from 'Configs/SubscripationStyles'
// import FilterNSort from 'Components/FilterNSortHolder/FilterNSort'
import { Footer } from 'Layouts/Footer/Footer'
import Container from 'Components/shared/Container'

const ACanali = () => {
  const navigate = useNavigate()

  const { slug } = useParams()

  if (!slug) {
    navigate(-1)
  }

  const [isLargerThan768] = useMediaQuery(['(min-width: 768px)'])

  const { channelDetails } = useApiRequests()
  const [allCourses, setAllCourses] = useState<any[]>([])
  const [data, setData] = useState<any>({})

  const { isFetching } = useQuery(['allCourses', slug], {
    queryFn: () => {
      let id = 0

      if (slug) {
        id = +slug || 0
      }

      return channelDetails(id)
    },
    select: (res) => res.data.data,
    onSuccess: (res) => {
      // eslint-disable-next-line no-console
      // console.log(res.channel.courses)
      setData(res.channel)
      return setAllCourses([...res.channel.courses])
    }
  })

  const [linkHasCopied, setLinkHasCopied] = useState(false)
  useEffect(() => {
    let timer: NodeJS.Timeout
    if (linkHasCopied) {
      timer = setTimeout(() => {
        setLinkHasCopied(false)
        navigate('/offerta')
      }, 1000)
    }
    return () => clearTimeout(timer)
  }, [linkHasCopied])

  return (
    <>
      <Container style={{
        backgroundColor: !isLargerThan768 ? (data.color ? data.color : '') : ''
      }} extraClasses='px-[15px] md:px-0 pt-4 md:pt-0'>
        <div>
          <div className='text-white md:hidden text-center'>
            <img
              src={`${process.env.REACT_APP_MEDIA_URL}${data?.light_logo ? data?.light_logo : data?.logo
                }`}
              alt=''
              className='w-fit mx-auto px-[30px] max-h-[200px]'
            />

            <p
              dangerouslySetInnerHTML={{ __html: data.description || '' }}
              className='mt-[11px] text-lg leading-[22px] line-clamp-4'
            />
          </div>
          <div className='grid items-start gap-5 grid-cols-12 mt-[30px]'>
            <div
              className={`rounded-[5px] h-full hidden md:block col-span-6 lg:col-span-4 text-white xl:col-span-3 px-[24px] py-[60px] ${isFetching ? 'animation-pulse transition-all duration-300 bg-slate-300' : !data.color && SubscriptionStyles.FREE.bg
                }`}
              style={{ backgroundColor: data.color ? data.color : '' }}>
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}${data?.light_logo ? data?.light_logo : data?.logo
                  }`}
                alt=''
                className='w-full'
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: data.highlight_text || ''
                }}
                className='mt-5 text-xl lg:text-xl'
              />
            </div>
            {allCourses.map((course: TUserCourses) => (
              <div
                key={course.id}
                className='col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 h-full'>
                <CourseCard {...course} />
              </div>
            ))}
            {isFetching &&
              Array.from(Array(4).keys()).map((item) => (
                <div
                  key={item}
                  className='col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3'>
                  <Skeleton />
                </div>
              ))}
          </div>
        </div>
        {/* </FilterNSort> */}
      </Container>
      <Footer />
    </>

  )
}

export default ACanali
