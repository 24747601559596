import { Spinner, useMediaQuery } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { moduileQuizAtom, selectedModuleAtom, showAttemptsDrawerAtom } from 'Recoil/atoms'
import { QuizCompleted } from './QuizCompleted'
// import { QuizFailed } from './QuizFailed'
// import { QuizFailedOverTake } from './QuizFailedOverTake'
import { QuizInormation } from './QuizInormation'
import { QuizInProgress } from './QuizInProgress'
import { useTranslation } from 'react-i18next'
import { TQuiz } from 'Types/Models'
import { QuizFooter } from './QuizFooter'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { QuizFailedOverTake } from './QuizFailedOverTake'

export const LessonQuizWindow = () => {
   const [quizState, setQuizState] = useRecoilState(moduileQuizAtom)
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const [selectedModule, setSelectedModule] = useRecoilState(selectedModuleAtom)
   const { t } = useTranslation()
   const { getQuizData } = useApiRequests()
   const [, setshowAttemptsDrawer] = useRecoilState(showAttemptsDrawerAtom)
   const { isLoading, refetch } = useQuery(['quiz', selectedModule?.id], {
      queryFn: () => getQuizData(selectedModule?.id || 0),
      select: (res) => res.data.data,
      onSuccess: (res) => {
         setSelectedModule(res)
         setQuizState({ ...quizState, questionsList: res.questions })
      }
   })

   return (
      <div className='h-screen lg:h-[80vh] flex flex-col gap-3 pt-3 lg:pt-0'>
         {isLoading ? (
            <div className='flex w-full h-full justify-center items-center'>
               <Spinner />
            </div>
         ) : (
            <div className='rounded-lg flex justify-between px-5 flex-col gap-4 lg:p-0 flex-1'>
               {smallScreen && (
                  <span className='text-xs font-bold text-secondary'>{t('QUIZ')}</span>
               )}
               {quizState.finished ? (
                  <>{quizState.passed ? <QuizCompleted /> : <QuizFailedOverTake />}</>
               ) : (
                  <>
                     <div className=' lg:p-7 w-full flex-col lg:bg-[#F8F8F8] overflow-y-scroll bg-transparent h-[85%] flex gap-5 rounded-lg'>
                        {
                           (!quizState.started && quizState.questionsList)
                              ||
                              ((selectedModule as TQuiz).quiz_user.status.attempts ===
                                 (selectedModule as TQuiz).max_attempts) ? (
                              <QuizInormation />
                           ) : (
                              <QuizInProgress questionsList={quizState.questionsList || []} />
                           )}
                     </div>
                     <QuizFooter refetch={() => refetch()} />
                  </>
               )}
            </div>
         )}

         {smallScreen && (
            <div className='h-[15%] flex justify-between items-center px-3 bg-white'>
               <div className='flex gap-1 h-full items-center '>
                  {/* <img className='w-7 h-7' src={timer} /> */}
                  {/* <span className='text-primary text-xs font-semibold'>06:34/10:00</span> */}
               </div>
               <div className='flex gap-12 justify-between'>
                  <span
                     className='cursor-pointer text-[#496492]'
                     onClick={() => setshowAttemptsDrawer(true)}>
                     Vedi i tentativi precedenti
                  </span>
                  <span>
                     {(selectedModule as TQuiz).quiz_user.status.attempts}/
                     {(selectedModule as TQuiz).max_attempts}
                  </span>
               </div>
            </div>
         )}
      </div>
   )
}
