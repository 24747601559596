import { Button } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { registerFormAtom } from 'Recoil/atoms'

export const SubmittedMessage = () => {
   const [state] = useRecoilState(registerFormAtom)
   const reset = useResetRecoilState(registerFormAtom)

   useEffect(() => {
      return () => {
         reset()
      }
   }, [])

   return (
      <div className='flex items-center justify-center w-full'>
         <div className='flex flex-col min-h-[50vh] justify-center items-center gap-5 w-96  '>
            <h3 className='text-xl font-semibold'>Iscrizione completata con successo!</h3>
            <p className='text-black font text-center'>
               Gentile {state.firstStepData.first_name} {state.firstStepData.last_name},
            </p>
            <p className='text-black font text-center'>
               Ancora un ultimo passo, dovresti aver ricevuto una mail di conferma del tuo account,
               segui le istruzioni per finalizzare la tua iscrizione
            </p>
            <p className='text-black font-semibold font text-center'>
               Se non la trovi cerca nella casella SPAM!
            </p>
            <Button type='submit' variant={'primary'} w='100%' height={'50px'}>
               <Link to='../'>Vai alla home</Link>
            </Button>
         </div>
      </div>
   )
}
