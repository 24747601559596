import { Box, Text } from '@chakra-ui/react'
// import error from 'Assets/new-window-error.svg'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const SecondTab = () => {
   useEffect(() => {
      document.body.style.overflow = 'hidden'
   }, [])

   const { t } = useTranslation()

   return (
      <>
         <Box className='w-full gap-3 h-screen flex flex-col items-center justify-center bg-[rgba(255,255,255,0.92)] select-none z-[100] fixed'>
            {/* <Image w='sm' src={error} /> */}
            <Text className='text-dark-gray'>{t('Oops!')}</Text>
            <Text className='text-dark-gray'>
               {t('You cannot open the session on the new tab')}
            </Text>
         </Box>
      </>
   )
}

export default SecondTab
