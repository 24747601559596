import React from 'react'
import { ModalWrapper } from './ModalWrapper'
import { userDataAtom } from 'Recoil/atoms'
import { useRecoilState } from 'recoil'
import { useApiRequests } from 'API/ApiRequests'
import { useMutation } from '@tanstack/react-query'
import { Button } from '@chakra-ui/react'

export const ConsentChannelModal = ({
   isOpen,
   onClose,
   data
}: {
   isOpen: any
   onClose: () => void
   data: any
}) => {
   const { consentChannel } = useApiRequests()
   const [userData] = useRecoilState(userDataAtom)
   const { mutate, isLoading } = useMutation({ mutationFn: consentChannel })

   return (
      <ModalWrapper size='xl' isOpen={isOpen} onClose={onClose}>
         <div className='flex flex-col justify-start items-center relative gap-[30px] p-5 rounded-[5px] bg-white'>
            <p className='self-stretch flex-grow-0 flex-shrink-0  uppercase text-base font-bold text-center text-[#496391]'>
               {data.channel.name}
            </p>
            <img
               className='h-32'
               src={`${process.env.REACT_APP_MEDIA_URL || ''}${data?.channel.logo}`}
            />
            <div className='flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5'>
               <p className='self-stretch flex-grow-0 capitalize flex-shrink-0 text-base font-bold text-center text-[#496391]'>
                  Gentile {userData?.user_data.first_name} {userData?.user_data.last_name},
               </p>
               <p className='self-stretch flex-grow-0 flex-shrink-0 text-[15px] text-center text-black'>
                  {data.channel.consent_text}
               </p>
               <a
                  href={data.channel.privacy_url}
                  target='_blank'
                  className='self-stretch flex-grow-0 flex-shrink-0 text-[15px] text-center text-black'
                  rel='noreferrer'>
                  {data.channel.privacy_label}
               </a>
            </div>
            <div className='flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-5'>
               <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  h='69px'
                  onClick={() => {
                     mutate(data.channel.id, { onSuccess: () => window.location.reload() })
                  }}
                  className='self-stretch disabled:opacity-60 rounded flex-grow-0 flex-shrink-0  bg-[#496492]'>
                  <p className=' text-base font-bold text-center text-white'>Confermo</p>
               </Button>
               <button
                  onClick={onClose}
                  className='self-stretch flex-grow-0 flex-shrink-0 text-[15px] text-center text-black'>
                  Non adesso
               </button>
            </div>
         </div>
      </ModalWrapper>
   )
}
