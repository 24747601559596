import React from 'react'
import { motion } from 'framer-motion'
import { Box } from '@chakra-ui/react'

export function FadeBox({
   children,
   className,
   onClick
}: {
   children: JSX.Element | JSX.Element[]
   className?: string
   onClick?: any
}) {
   return (
      <Box
         onClick={onClick}
         className={className}
         as={motion.div}
         exit={{ opacity: 0 }}
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}>
         {children}
      </Box>
   )
}
