import { useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { CourseCard } from 'Components/Cards/CourseCard/CourseCard'
import { Skeleton } from 'Components/Cards/CourseCard/Skeleton'
import SubscriptionStyles from 'Configs/SubscripationStyles'
import { NewTCourse, TPlan } from 'Types/Models'
import PlanTypes from 'Types/Subscripe'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useParams } from 'react-router-dom'
import Filters from 'Components/Filters/Filters'
import { Footer } from 'Layouts/Footer/Footer'
import Container from 'Components/shared/Container'
import { useRecoilState } from 'recoil'
import { defaultFilter, filterState } from 'Recoil/atoms'

const NewCategoryPage = () => {
  const { slug } = useParams()

  const [isLargerThan768] = useMediaQuery(['(min-width: 768px)'])

  const { getPlansCourses, getPlansNew } = useApiRequests()

  const [{ filter, keyword }, setFState] = useRecoilState(filterState)
  const [currentFilter, setCurrentFilter] = useState({
    filter,
    keyword,
  })

  const [page, setPage] = useState(1)
  const [allCourses, setAllCourses] = useState<any[]>([])
  const [selectedPlan, setSelectedPlan] = useState<TPlan | undefined>()
  const [planId] = useState(() => {
    const tempId =
      slug === 'free' ? 1 : slug === 'plus' ? 2 : slug === 'semi' ? 3 : slug === 'full' ? 4 : 1
    setFState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        plan: [tempId]
      },
      hidePlans: true
    }))
    return tempId
  })

  const { isLoading } = useQuery(['plan_data'], {
    queryFn: () => {
      return getPlansNew()
    },
    select: (res) => res.data.data.plans,
    onSuccess: (res: TPlan[]) => {
      const plan = res.filter((f) => f.id === planId)
      return setSelectedPlan(plan[0] as TPlan)
    }
  })

  const { data, isFetching } = useQuery(['planCourses', page, filter, keyword], {
    queryFn: ({ queryKey }) => {
      if (JSON.stringify({ filter, keyword }) !== JSON.stringify(currentFilter)) {
        setCurrentFilter({ filter, keyword })
        setPage(1)
        return getPlansCourses(
          { ...filter, keyword },
          1
        )
      }

      if (queryKey[1]) {
        return getPlansCourses(
          { ...filter, keyword },
          page
        )
      }

      return getPlansCourses(
        { ...filter, keyword },
        1
      )
    },
    select: (res) => res.data.data,
    onSuccess: (res) => {
      return setAllCourses([...allCourses, ...res.courses.data])
    }
  })

  useEffect(() => {
    setPage(1)
    setAllCourses([])
  }, [filter, keyword])

  useEffect(() => {
    return () => setFState(defaultFilter)
  }, [])

  const { ref, inView } = useInView()

  useEffect(() => {
    inView && setPage(page + 1)
  }, [inView])

  return (
    <>
      <Container
        extraClasses={`gap-0 pb-8 ${!isLargerThan768 &&
          selectedPlan &&
          SubscriptionStyles[(selectedPlan.name.toUpperCase() as PlanTypes) || 'FREE'].bg
          }`}>
        {!isLoading && selectedPlan && (
          <div
            className={
              'flex justify-end lg:px-0 gap-5 pt-[26px] px-[15px] md:px-0 pb-4 md:pt-0 md:container md:mx-auto text-white md:text-[#111827]'
            }>
            <Filters.Trigger stroke={isLargerThan768 ? '#111827' : '#fff'} />
          </div>
        )}
        <div
          className={
            'min-h-screen flex flex-col gap-5 px-[15px] md:px-0 md:container md:mx-auto md:pb-8'
          }>
          <div className=' md:px-0'>
            <div className='text-white md:hidden'>
              <h2 className='font-saira-normal text-[45px] font-bold leading-[50px]'>
                {!isLoading && selectedPlan && selectedPlan.name.toUpperCase()}
              </h2>
              <p className='mt-[11px] text-xl leading-[22px]'>
                {!isLoading && selectedPlan && selectedPlan.text}
              </p>
            </div>
            <div className='grid items-start gap-5 grid-cols-12 mt-[30px]'>
              {isLoading ? (
                <div className='rounded-[5px] h-full hidden md:block col-span-6 lg:col-span-4 text-white xl:col-span-3 px-[24px] py-[60px] bg-slate-300 animate-pulse transition-all duration-300'></div>
              ) : (
                <div
                  className={`rounded-[5px] h-full hidden md:block col-span-6 lg:col-span-4 text-white xl:col-span-3 px-[24px] py-[60px] ${SubscriptionStyles[
                    (!isLoading &&
                      selectedPlan &&
                      (selectedPlan.name.toUpperCase() as PlanTypes)) ||
                    'FREE'
                  ].bg
                    }`}>
                  <h2 className='font-saira-normal text-[45px] lg:text-[100px]  font-bold leading-[50px] mt-[23px] lg:leading-[110px]'>
                    {!isLoading && selectedPlan && selectedPlan.name.toUpperCase()}
                  </h2>
                  <p className='mt-[11px] text-xl lg:text-[30px] leading-[22px] lg:leading-[42px]'>
                    {!isLoading && selectedPlan && selectedPlan.text}
                  </p>
                </div>
              )}
              {allCourses.map((course: NewTCourse) => (
                <div
                  key={course.id}
                  className='col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 h-full'>
                  <CourseCard {...course} />
                </div>
              ))}
              {isFetching &&
                Array.from(Array(4).keys()).map((item) => (
                  <div
                    key={item}
                    className='col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3'>
                    <Skeleton />
                  </div>
                ))}
              {page < data?.courses.last_page && !isFetching && <div ref={ref}>{''}</div>}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default NewCategoryPage