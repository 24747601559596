import { ModalWrapper } from 'Components/Modals/ModalWrapper'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Package, TCourse } from 'Types/Models'

export const PackageCard = ({
   data,
   isSelected,
   setSelectedCard
}: {
   data: Package
   isSelected?: boolean
   setSelectedCard: Dispatch<SetStateAction<any>>
}) => {
   const [isOpen, setisOpen] = useState(false)

   return (
      <div
         onClick={() => setSelectedCard(data)}
         className='flex justify-start cursor-pointer hover:bg-slate-100 transition-all items-start gap-2.5 p-[15px] rounded-lg bg-white'
         style={{
            boxShadow: isSelected ? '0px 0px 10px 0 #496492' : '0px 0px 10px 0 rgba(0,0,0,0.25)'
         }}>
         <div className='flex justify-start items-center gap-2.5 p-2.5'>
            {isSelected ? (
               <svg
                  width={18}
                  height={18}
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='flex-grow-0 flex-shrink-0 w-[18px] h-[18px] relative'
                  preserveAspectRatio='none'>
                  <rect x={1} y={1} width={16} height={16} rx={8} fill='#496492' />
                  <path
                     d='M5.5 9.5L7.5 11.5L12.5 6.5'
                     stroke='white'
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
                  <rect
                     x={1}
                     y={1}
                     width={16}
                     height={16}
                     rx={8}
                     stroke='#496492'
                     strokeWidth={2}
                  />
               </svg>
            ) : (
               <div className='flex justify-start items-start w-[18px] h-[18px] overflow-hidden gap-2.5 p-[5px] rounded-full bg-white border-2 border-[#496492]' />
            )}
         </div>
         <div className='flex flex-col justify-start items-start gap-2.5 flex-1'>
            <div className='flex justify-start items-start gap-2.5'>
               <div className='flex justify-start items-start relative overflow-hidden gap-2.5 p-[5px] rounded-[5px] bg-[#a6aac4]'>
                  <p className='text-[9px] font-bold text-left text-white'>PACCHETTO</p>
               </div>
            </div>
            <div className='flex flex-col justify-start items-start relative gap-[7px] w-full'>
               <p
                  onClick={() => setSelectedCard(data)}
                  className='self-stretch font-bold text-left text-[#496492]'>
                  <br />
                  <span className='self-stretch text-xl font-bold text-left text-[#496492]'>
                     {data.title}{' '}
                  </span>
               </p>

               <p
                  className='text-xs text-left text-black'
                  dangerouslySetInnerHTML={{ __html: data.description }}
               />

               <div className='flex justify-between items-end self-stretch relative'>
                  <p className='text-left text-black'>
                     <span className='text-lg font-bold text-left text-[#496492]'>
                        €{data.price}
                     </span>
                     <br />
                     <span className='text-[10px] text-left text-[#496492]'>IVA INCLUSA</span>
                  </p>
                  <div
                     onClick={() => setisOpen(true)}
                     className='flex justify-start items-start relative overflow-hidden gap-2.5 p-[5px] rounded-[5px]'>
                     <p className='text-[9px] font-bold text-left uppercase text-[#4e638f]'>
                        Visualizza tutti i titoli dei corsi
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <ModalWrapper size='xl' isOpen={isOpen} onClose={() => setisOpen(false)}>
            <div className='flex flex-col gap-5 p-[30px] rounded-[5px]'>
               <div className='flex flex-col gap-1'>
                  <p className='self-stretch flex-grow-0 flex-shrink-0 w-[445px] font-bold text-left text-[#496492]'>
                     <span className='self-stretch flex-grow-0 flex-shrink-0 w-[445px] text-base font-bold text-left text-[#496492]'>
                        Questo corso fa parte del pacchetto
                     </span>
                     <br />
                     <span className='self-stretch flex-grow-0 flex-shrink-0 w-[445px] text-lg font-bold text-left text-[#496492]'>
                        {data?.title}
                     </span>
                  </p>
               </div>
               <b>
                  Pacchetto di {data.courses.length} corsi con un totale di{' '}
                  {data?.courses.reduce(
                     (prev: number, curr: Partial<TCourse>) =>
                        Number(prev) + Number(curr.completion_credits),
                     0
                  )}{' '}
                  crediti formativi!!
               </b>

               <ol className='self-stretch space-y-2 pl-4 list-decimal flex-grow-0 flex-shrink-0  text-sm text-left text-black'>
                  {data.courses.map((course) => (
                     <li
                        key={course.id}
                        className='self-stretch flex-grow-0 flex-shrink-0  text-sm text-left text-black'>
                        {course.title}
                     </li>
                  ))}
               </ol>

               <button
                  onClick={() => setisOpen(false)}
                  className=' h-[38.3px]  left-[29.5px] text-white w-full top-[301.5px] rounded-[5px] bg-[#496492]'>
                  CHIUDI
               </button>
            </div>
         </ModalWrapper>
      </div>
   )
}
