/* eslint-disable no-console */
import { Button, Select, Spinner } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { useApiRequests } from "API/ApiRequests"
import { userDataAtom } from "Recoil/atoms"
import { TOldCourseCertificate } from "Types/Models"
import { useState } from "react"
import { useRecoilState } from "recoil"

export const OldCert = () => {
   const [userCertificate, setUserCertificate] = useState<TOldCourseCertificate[]>([])
   const [toDownloadCertificate, setToDownloadCertificate] = useState("")
   const [success, setSuccess] = useState(false)
   const [userData] = useRecoilState(userDataAtom)
   const [loading, setLoading] = useState(false)

   const { getUsersCourses } = useApiRequests()

   const { isLoading } = useQuery(["get-user-certs"], {
      queryFn: getUsersCourses,
      onSuccess: (data) => {
         const completedCourses = data.data.filter(
            (c: TOldCourseCertificate) => c.completed && c.url.length > 0
         )
         if (completedCourses && completedCourses.length > 0) {
            setUserCertificate(completedCourses)
            setSuccess(true)
         }
      },
   })

   const handleSelectChange = (event: any) => {
      const temp = userCertificate.find((c) => c.participation_id === event.target.value)
      if (temp?.url) {
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
         const [first, ...rest] = temp.url.split("=")
         setToDownloadCertificate(rest.join("="))
      } else {
         setToDownloadCertificate("")
      }
   }

   const handleDownload = () => {
      setLoading(true)
      fetch(
         `${process.env.REACT_APP_API_BASE_URL}/v1/user/certificate/download?url_certificate=${toDownloadCertificate}`,
         {
            method: "GET",
            headers: {
               "Content-Type": "application/pdf",
               Authorization: `Bearer ${userData?.plainTextToken}`,
            },
         }
      )
         .then((response) => response.blob())
         .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "Certificati.pdf")
            document.body.appendChild(link)

            link.click()

            if (link.parentNode) {
               link.parentNode.removeChild(link)
            }
         })
         .finally(() => {
            setLoading(false)
         })
   }

   if (isLoading || !success) {
      return <></>
   }

   return (
      <div className='flex flex-col justify-start bg-[#F3A84D] mx-5 rounded-[5px] p-5 gap-2 my-8 sm:min-h-[226px]'>
         <div className=' text-white text-xs font-bold leading-none'>
            CERTIFICATI ECM TRIENNIO 2020-2022
         </div>

         <div className='text-white text-[11px] font-normal leading-[14.30px]'>
            Hai bisogno di scaricare di nuovo un certificato ECM di un corso fatto nel triennio
            2020-2022?
         </div>
         <Select
            onChange={handleSelectChange}
            bgColor={"white"}
            fontSize={"11px"}
            border={"none"}
            mt={2}>
            <option value={""}>Clicca qui e seleziona il titolo del corso</option>
            {userCertificate.length > 0 &&
               userCertificate.map((c) => {
                  return (
                     <option
                        key={c.participation_id}
                        value={c.participation_id}
                        className='border-b-2 border-black bg-gray'>
                        {c.title}
                     </option>
                  )
               })}
         </Select>
         <Button
            disabled={toDownloadCertificate.length < 1 || loading}
            isDisabled={toDownloadCertificate.length < 1 || loading}
            variant={"solid"}
            size={"sm"}
            onClick={() => {
               if (toDownloadCertificate.length > 0) {
                  handleDownload()
               }
            }}
            className='bg-blueDark rounded-lg text-[11px] text-white self-end mt-2 w-fit h-10 disabled:bg-gray disabled:cursor-not-allowed'>
            {isLoading || loading ? <Spinner className='m-2' /> : "Scarica il certificato"}
         </Button>
      </div>
   )
}
