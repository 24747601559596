/* eslint-disable react/no-unescaped-entities */
import { Footer } from 'Layouts/Footer/Footer'

/* eslint-disable @typescript-eslint/no-empty-interface */
interface TerminiProps {}

const Termini: React.FunctionComponent<TerminiProps> = () => {
   return (
      <>
         <div className='sm:container w-[96%] divide-x-2 divide-[#F5F5F5] flex flex-col flex-wrap gap-3 sm:flex-nowrap mx-auto border-2 p-4 my-6 rounded-md border-[#F5F5F5] lg:min-h-[85vh]'>
            <article className='px-4 lg:px-[10%] flex flex-col gap-5' role='article' id='post_879'>
               <div>
                  <h1 className='text-3xl font-bold py-6 text-black'>Termini e condizioni d’uso</h1>
                  <br />
                  <br />
                  Il presente documento disciplina i termini e le condizioni di utilizzo del
                  presente portale. Se l’utente non intende aderire alle condizioni d’uso qui
                  previste non può procedere alla registrazione e, quindi, accedere ai contenuti e
                  alle funzionalità del portale stesso. L’Utente è pregato di leggere attentamente
                  questo documento.
                  <br />
                  <br />
                  Il soggetto responsabile di questo portale è: Ecmclub srl, Via Gallarate 106,
                  20151 Milano, info@ecmclub.org
                  <br />
                  <br />
                  Ecmclub.org è un portale di formazione e aggiornamento scientifico riservata ai
                  professionisti della salute, nel cui contesto i visitatori possono accedere a
                  contenuti e servizi di natura meramente informativa e/o divulgativa.
                  <br />
                  <br />
                  Posto che l’accesso al sito è riservato ai professionisti regolarmente iscritti
                  all’albo professionale, l’utente è tenuto ad adempiere ad una procedura di
                  registrazione, alla quale segue l’attivazione di un account che consentirà di
                  usufruire dei contenuti e delle funzionalità del portale. La registrazione
                  dell’utente presuppone che egli abbia preventivamente visionato le condizioni per
                  la protezione e il trattamento dei dati personali, nonché l’informativa di cui
                  all’art. 13&nbsp;D. Lgs. 30.06.2003, n. 196, dell’art.    <br />    <br /> 13 Regolamento UE n.
                  2016/679. Le condizioni per la protezione e il trattamento dei dati personali, al
                  pari del presente documento, sono accessibili all’utente, in qualunque momento,
                  sulla home page del sito. L’utente è responsabile della correttezza e veridicità
                  dei dati forniti all’atto della registrazione, nonché di ogni eventuale violazione
                  di diritti e/o prerogative di terzi relativa, conseguente e/o connessa ai dati
                  forniti all’atto della registrazione. L’utente registrato è esclusivo responsabile
                  dell’utilizzo delle credenziali di accesso alla piattaforma – strettamente
                  personali e non cedibili a terzi – nonché della relativa conservazione.    <br />    <br /> Ecmclub
                  Srl non può garantire il costante aggiornamento dei contenuti del sito, né che
                  questi ultimi siano completi e privi di errori, trattandosi di contributi
                  meramente informativi e/o divulgativi, di cui è riportata la fonte. Ecmclub Srl
                  non risponde pertanto di eventuali oneri, spese, perdite e/o danni che dovessero
                  derivare all’utente dall’utilizzo e/o dall’impossibilità di utilizzare questo sito
                  web, ovvero dalla circostanza che l’utente abbia fatto affidamento, in tutto o in
                  parte, sui contenuti informativi/divulgativi del portale.Il presente portale può
                  contenere collegamenti e/o riferimenti a siti internet di terze parti, sui quali
                  Ecmclub Srl non ha alcun controllo.     <br />    <br />La sussistenza di eventuali collegamenti e/o
                  riferimenti a siti internet di terze parti non equivale ad approvazione dei
                  relativi contenuti da parte di Ecmclub Srl, che non risponde pertanto della
                  relativa attendibilità e correttezza, declinando ogni responsabilità rispetto a
                  quanto offerto all’interno dei predetti siti.
                  <br />
                  Ecmclub Srl si impegna ad adottare tutte le misure idonee e ragionevoli per
                  proteggere il sito da worms, trojans e/o altre minacce (c.d. materiali nocivi).
                  Pur adottando la massima diligenza, Ecmclub Srl non può comunque garantire che il
                  sito sia del tutto privo di materiali nocivi e declina, pertanto, ogni
                  responsabilità in ordine a qualsivoglia perdita, spesa, onere e/o danno occorso
                  all’utente a causa di materiali nocivi provenienti da questo portale, ovvero da
                  eventuali collegamenti e/o riferimenti a siti di terze parti presenti sul sito.
                  <br />
                  I contenuti pubblicati e/o resi disponibili sono tutelati dalla normativa sul
                  diritto d’autore e della proprietà intellettuale. I contenuti pubblicati sul
                  presente portale non possono pertanto essere copiati, salvo che per uso personale
                  e non commerciale, senza apportare alcuna modifica ai contenuti stessi e
                  mantenendo tutti gli avvisi e le indicazioni sulla titolarità del diritto d’autore
                  e/o dei diritti di proprietà intellettuale, ivi comprese le clausole di esclusione
                  da responsabilità in favore di Ecmclub Srl qui disciplinate.
                  <br />
                  Ecmclub Srl si impegna a garantire con continuità la funzionalità del sito, ma
                  declina ogni responsabilità rispetto ad eventuali inconvenienti tecnici e/o a
                  necessità di manutenzione tali da causare interruzioni alla funzionalità del sito.
                  In ogni caso, Ecmclub Srl si riserva il diritto di oscurare, modificare e/o
                  interrompere (temporaneamente o definitivamente) una o più funzionalità del
                  presente portale, in qualsiasi momento e senza preavviso agli utenti.
                  <br />
                  I termini e le condizioni di utilizzo del sito sono efficaci e vincolanti per
                  l’utente dal momento dell’attivazione dell’account per l’accesso ai contenuti del
                  portale. Per qualsiasi chiarimento e/o informazione circa i termini e le
                  condizioni di utilizzo del sito, l’utente può rivolgersi per iscritto, in
                  qualsiasi momento, al seguente indirizzo: info@ecmclub.org.
                  <br />
                  <br />
                  <h3>Da sapere a colpo d’occhio</h3>
                  <br />
                  Si fa presente che determinate disposizioni di questi Termini potrebbero essere
                  solo applicabili ad alcune categorie di Utenti. In particolare, alcune
                  disposizioni potrebbero applicarsi solo a Consumatori o solo a Utenti che non
                  agiscono come Consumatori. Tali limitazioni sono sempre menzionate esplicitamente
                  in ciascuna clausola interessata. In caso di mancata menzione, le clausole si
                  applicano a tutti gli Utenti. Il diritto di recesso su questa Applicazione è
                  applicabile a tutti gli Utenti, a prescindere dalla loro qualità di Consumatori.
                  <br />
                  <br />
                  <h3>CONDIZIONI D'USO</h3>
                  <br />
                  Salvo ove diversamente specificato, le condizioni d’uso di questa Applicazione
                  esposte in questa sezione hanno validità generale. Ulteriori condizioni d’uso o
                  d’accesso applicabili in particolari situazioni sono espressamente indicate in
                  questo documento. Utilizzando questa Applicazione l’Utente dichiara di soddisfare
                  i seguenti requisiti:
                  <br />
                  ⁃ Non ci sono restrizioni riferite agli Utenti rispetto al fatto che essi siano
                  Consumatori o Utenti Professionisti;
                  <br />
                  <br />
                  <h3>Registrazione</h3>
                  <br />
                  Per usufruire del Servizio l’Utente può aprire un account indicando tutti i dati e
                  le informazioni richieste in maniera completa e veritiera. Non è possibile
                  usufruire del Servizio senza aprire un account Utente. È responsabilità degli
                  Utenti conservare le proprie credenziali d’accesso in modo sicuro e preservarne la
                  confidenzialità. A tal fine, gli Utenti devono scegliere una password che
                  corrisponda al più alto livello di sicurezza disponibile su questa Applicazione.
                  <br />
                  Creando un account l’Utente accetta di essere pienamente responsabile di ogni
                  attività posta in atto con le sue credenziali d’accesso. Gli Utenti sono tenuti a
                  informare il Titolare immediatamente e univocamente tramite i recapiti indicati in
                  questo documento qualora ritengano che le proprie informazioni personali, quali ad
                  esempio l’account Utente, le credenziali d’accesso o dati personali, siano state
                  violate, illecitamente diffuse o sottratte.
                  <br />
                  <br />
                  <h3>Chiusura account</h3>
                  <br />
                  L’Utente è libero di chiudere il proprio account e cessare l’utilizzo del Servizio
                  in qualsiasi momento, seguendo questa procedura:
                  <br />
                  ⁃ Contattando il Titolare ai recapiti in questo documento.
                  <br />
                  <br />
                  Sospensione e cancellazione account
                  <br />
                  Il Titolare si riserva il diritto di sospendere o cancellare l’account di un
                  Utente in qualsiasi momento a propria discrezione e senza preavviso, qualora lo
                  ritenga inopportuno, offensivo o contrario a questi Termini. La sospensione o
                  cancellazione dell’account non da all’Utente alcun diritto di risarcimento,
                  rimborso o indennizzo.
                  <br />
                  La sospensione o cancellazione di un account per cause addebitabili all’Utente non
                  esonera l’Utente dal pagamento dei compensi o prezzi eventualmente applicabili.
                  <br />
                  <br />
                  <h3>Contenuti su questa Applicazione</h3>
                  <br />
                  Salvo ove diversamente specificato o chiaramente riconoscibile, tutti i contenuti
                  disponibili su questa Applicazione sono di proprietà di o forniti dal Titolare o
                  dei/dai suoi licenzianti.
                  <br />
                  Il Titolare adotta la massima cura affinché il contenuto disponibile su questa
                  Applicazione non violi la normativa applicabile o diritti di terze parti.
                  Tuttavia, non sempre è possibile raggiungere tale risultato.
                  <br />
                  In tali casi, senza alcun pregiudizio ai diritti ed alle pretese legalmente
                  esercitabili, gli Utenti sono pregati di indirizzare i relativi reclami ai
                  recapiti specificati in questo documento.
                  <br />
                  <br />
                  <h3>Diritti sui contenuti di questa Applicazione</h3>
                  <br />
                  Il Titolare detiene e si riserva espressamente ogni diritto di proprietà
                  intellettuale sui suddetti contenuti. Gli Utenti non sono autorizzati ad usare i
                  contenuti in alcun modo che non sia necessario od implicito nel corretto utilizzo
                  del Servizio.
                  <br />
                  In particolare, ma senza esclusioni, è fatto divieto agli Utenti di copiare,
                  scaricare, condividere oltre i limiti sotto specificati, modificare, tradurre,
                  elaborare, pubblicare, trasmettere, vendere, concedere sottolicenze, trasformare,
                  trasferire/alienare a terze parti o creare opere derivate a partire dal contenuto
                  disponibile su questa Applicazione, di permettere a terze parti di intraprendere
                  tali attività tramite il proprio account Utente o dispositivo, anche a propria
                  insaputa.
                  <br />
                  Ove espressamente indicato su questa Applicazione, l’Utente è autorizzato a
                  scaricare, copiare e/o condividere determinati contenuti disponibili su questa
                  Applicazione esclusivamente per scopi personali e non commerciali ed a condizione
                  che sia osservata l’attribuzione della paternità dell’opera nonché l’indicazione
                  di ogni altra circostanza rilevante richiesta dal Titolare.
                  <br />
                  Restano ferme le limitazioni ed esclusioni previste dalla normativa sul diritto
                  d’autore.
                  <br />
                  <br />
                  <h3>Accesso a risorse esterne</h3>
                  <br />
                  Tramite questa Applicazione gli Utenti potrebbero avere accesso a risorse fornite
                  da terzi. Gli Utenti riconoscono ed accettano che il Titolare non ha alcun
                  controllo su tali risorse e che pertanto non risponde del loro contenuto e della
                  loro disponibilità. Le condizioni applicabili alle risorse fornite da terzi, ivi
                  incluse quelle applicabili a eventuali concessioni di diritti su contenuti, sono
                  determinate dagli stessi terzi e regolate nei relativi termini e condizioni o, in
                  loro assenza, dalla legge.
                  <br />
                  <br />
                  <h3>Uso ammesso</h3>
                  <br />
                  Questa Applicazione ed il Servizio possono essere utilizzati solo per gli scopi
                  per i quali sono offerti, secondo questi Termini ed ai sensi della legge
                  applicabile. È responsabilità esclusiva dell’Utente di far sì che l’uso di questa
                  Applicazione e/o del Servizio non violi la legge, i regolamenti o i diritti di
                  terzi.
                  <br />
                  <b>
                     Pertanto, il Titolare si riserva il diritto di adottare ogni misura idonea a
                     proteggere i propri interessi legittimi, ed in particolare di negare all’Utente
                     l’accesso a questa Applicazione o al Servizio, risolvere contratti, denunciare
                     ogni attività censurabile svolta tramite questa Applicazione o il Servizio alle
                     autorità competenti – p. es. l’autorità giudiziaria o amministrativa – ogni
                     qualvolta l’Utente ponga in essere o vi sia il sospetto che ponga in essere:
                  </b>
                  <br />
                  ⁃ violazioni di legge, regolamenti e/o dei Termini;
                  <br />
                  ⁃ lesioni di diritti di terzi;
                  <br />
                  ⁃ atti che possono pregiudicare considerevolmente i legittimi interessi del
                  Titolare;
                  <br />
                  ⁃ offese al Titolare o a un terzo.
                  <br />
                  <br />
                  <h3>TERMINI E CONDIZIONI DI VENDITA</h3>
                  <br />
                  <h3>Prodotti a pagamento</h3>
                  <br />
                  Alcuni dei Prodotti offerti su questa Applicazione come parte del servizio sono a
                  pagamento. Le tariffe, la durata e le condizioni applicabili alla vendita di tali
                  Prodotti sono descritte di seguito e nelle rispettive sezioni di questa
                  Applicazione.
                  <br />
                  Per acquistare i Prodotti, l’Utente è tenuto a registrarsi o ad effettuare
                  l’accesso a questa Applicazione.
                  <br />
                  <h3>Descrizione del prodotto</h3>
                  <br />
                  Prezzi, descrizioni e disponibilità dei Servizi sono specificati nelle rispettive
                  sezioni di questa Applicazione e sono soggetti a modifica senza preavviso.
                  <br />
                  Sebbene i Servizi su questa Applicazione siano presentati con la massima
                  accuratezza tecnicamente possibile, la rappresentazione su questa Applicazione
                  tramite qualsiasi mezzo (inclusi, a seconda dei casi, materiali grafici, immagini,
                  colori, suoni) è da intendersi come mero riferimento e non implica alcuna garanzia
                  in merito alle caratteristiche del Prodotto acquistato.
                  <br />
                  Le caratteristiche del Prodotto selezionato saranno specificate durante la
                  procedura d’acquisto.
                  <br />
                  <h3>Procedura d’acquisto</h3>
                  <br />
                  Ogni fase, dalla scelta del prodotto fino all’inoltro dell’ordine, fa parte della
                  procedura d’acquisto. La procedura d’acquisto comprende i seguenti passaggi:
                  <br />
                  ⁃ Gli Utenti sono pregati di scegliere il Prodotto desiderato e di verificare la
                  loro scelta d’acquisto.
                  <br />
                  ⁃ Dopo aver controllato le informazioni visibili nella scelta d’acquisto, gli
                  Utenti possono effettuare l’ordine inoltrandolo.
                  <br />
                  <h3>Invio dell’ordine</h3>
                  <br />
                  L’invio dell’ordine comporta quanto segue:
                  <br />
                  ⁃ L’invio dell’ordine da parte dell’utente determina la conclusione del contratto
                  e fa sorgere in capo all’Utente l’obbligo di pagare prezzo, tasse ed eventuali
                  ulteriori oneri e spese, così come specificato nella pagina dell’ordine.
                  <br />
                  ⁃ Nel caso in cui il Prodotto acquistato richieda un contributo attivo da parte
                  dell’Utente, come la fornitura di informazioni o dati personali, specificazioni o
                  richieste particolari, l’inoltro dell’ordine costituisce in capo all’Utente anche
                  l’obbligo di collaborare di conseguenza.
                  <br />
                  ⁃ Una volta inoltrato l’ordine, agli Utenti sarà inviata una conferma di ricezione
                  dell’ordine.
                  <br />
                  <br />
                  Tutte le notifiche relative alla procedura d’acquisto sopra descritta saranno
                  inviate all’indirizzo di posta elettronica fornito dall’Utente a tal fine.
                  <br />
                  <br />
                  Prezzi
                  <br />
                  Durante la procedura d’acquisto e prima dell’inoltro dell’ordine, gli Utenti
                  vengono debitamente informati di tutte le commissioni, tasse e costi (comprese
                  eventuali spese di spedizione) che saranno loro addebitati.
                  <br />
                  I prezzi su questa Applicazione:
                  <br />
                  ⁃ includono tutte le commissioni, tasse e costi applicabili.
                  <br />
                  <h3>Promozioni e sconti</h3>
                  <br />
                  Il Titolare potrebbe offrire sconti o promozioni speciali per l’acquisto dei
                  Prodotti. Tali promozioni o sconti sono sempre soggetti ai requisiti ed a termini
                  e condizioni previste nella relativa sezione di questa Applicazione.
                  <br />
                  Promozioni e offerte sono sempre concesse a sola discrezione del Titolare.
                  <br />
                  Promozioni o sconti ripetuti o periodici non costituiscono alcuna pretesa o
                  diritto azionabili dagli Utenti in futuro.
                  <br />
                  A seconda dei casi, sconti e promozioni valgono per un determinato periodo di
                  tempo o fino ad esaurimento scorte. Salvo ove diversamente specificato, le
                  limitazioni di tempo di promozioni e sconti si intendono riferite al fuso orario
                  della sede del Titolare, come indicata nei recapiti in questo documento.
                  <br />
                  <br />
                  <h3>Buoni</h3>
                  <br />
                  Promozioni e sconti possono essere offerti sotto forma di Buoni.
                  <br />
                  In caso di violazione delle condizioni applicabili ai Buoni, il Titolare può
                  legittimamente rifiutare di adempiere ai propri obblighi contrattuali e si riserva
                  espressamente il diritto di agire nelle opportune sedi anche giudiziarie al fine
                  di tutelare i propri diritti e interessi.
                  <br />
                  Eventuali disposizioni aggiuntive o divergenti applicabili all’utilizzo dei Buoni
                  riportate nella relativa pagina informativa o sul Buono stesso prevalgono in ogni
                  caso, a prescindere dalle disposizioni che seguono.
                  <br />
                  Salvo ove diversamente specificato, le seguenti regole si applicano all’utilizzo
                  dei Buoni:
                  <br />
                  ⁃ Ciascun Buono è valido solo se utilizzato secondo le modalità e nel periodo di
                  tempo specificati sul sito web e/o sul Buono;
                  <br />
                  ⁃ Il Buono può essere riscattato solo integralmente al momento dell’acquisto – non
                  è consentito l’utilizzo parziale;
                  <br />
                  ⁃ Salvo ove diversamente specificato, i Buoni monouso possono essere riscattati
                  una sola volta per acquisto e possono pertanto essere riscattati una sola volta
                  anche nel caso di acquisti rateizzati;
                  <br />
                  ⁃ I Buoni non sono cumulabili;
                  <br />
                  ⁃ Il Buono deve essere utilizzato entro il termine di validità specificato.
                  Scaduto il termine, il Buono sarà automaticamente annullato. Resta esclusa
                  qualsiasi possibilità di rivendicare diritti, compreso al rimborso del valore del
                  Buono;
                  <br />
                  ⁃ L’Utente non ha diritto ad alcun accredito/rimborso/compensazione nel caso vi
                  sia differenza tra il valore del Buono e il valore riscattato;
                  <br />
                  ⁃ Il Buono è da intendersi esclusivamente ad uso non commerciale. La riproduzione,
                  contraffazione e commercializzazione del Buono sono severamente vietate, così come
                  qualsiasi attività illecita connessa all’acquisto e/o utilizzo del Buono.
                  <br />
                  <br />
                  <h3>Mezzi di pagamento</h3>
                  <br />
                  I dettagli relativi ai mezzi di pagamento accettati sono evidenziati durante la
                  procedura d’acquisto.
                  <br />
                  Alcuni mezzi di pagamento sono legati ad ulteriori condizioni o comportano costi
                  aggiuntivi. Le informazioni dettagliate sono riportate nella relativa sezione di
                  questa Applicazione.
                  <br />
                  I metodi di pagamento contrassegnati come tali sono gestiti direttamente dal
                  Titolare. Il Titolare raccoglie e memorizza i dati necessari per la gestione dei
                  pagamenti e per l’adempimento agli obblighi di legge correlati. Per ricevere
                  ulteriori informazioni sul trattamento dei dati personali e sui relativi diritti,
                  l’Utente può fare riferimento alla privacy policy di questa Applicazione.
                  <br />
                  Altri eventuali metodi di pagamento, se presenti, sono forniti in modo
                  indipendente da servizi terzi. In questi casi, questa Applicazione non raccoglie
                  alcuna informazione sul pagamento - come ad esempio i dati della carta di credito
                  - ma riceve una notifica dal fornitore terzo interessato nel momento in cui il
                  pagamento viene completato con successo.
                  <br />
                  Nel caso in cui il pagamento effettuato con uno dei mezzi disponibili fallisca o
                  venga rifiutato dal fornitore di servizi di pagamento, il Titolare non è obbligato
                  a eseguire l’ordine. Eventuali costi o commissioni derivanti dal pagamento fallito
                  o rifiutato sono a carico dell’Utente.
                  <br />
                  <br />
                  <h3>Pagamento rateale</h3>
                  <br />
                  Il prezzo d’acquisto può essere pagato in due o più rate, nei termini specificati
                  su questa Applicazione o altrimenti comunicati dal Titolare. Determinati Prodotti
                  potrebbero essere esclusi da questa modalità di pagamento.
                  <br />
                  Nel caso in cui l’Utente risulti inadempiente rispetto aduna sola delle rate del
                  pagamento, l’intero importo dovuto sarà immediatamente dovuto ed esigibile.
                  <br />
                  <h3>Autorizzazione per pagamenti futuri via PayPal</h3>
                  <br />
                  Nel caso in cui l’Utente autorizzi la funzione PayPal che consente acquisti
                  futuri, questa Applicazione memorizzerà un codice identificativo collegato
                  all’account PayPal dell’Utente. In tal modo questa Applicazione potrà elaborare
                  automaticamente i pagamenti per acquisti futuri o per il pagamento di rate
                  periodiche di un acquisto pregresso.
                  <br />
                  <br />
                  L’autorizzazione può essere revocata in ogni momento contattando il Titolare o
                  modificando le impostazioni personali di PayPal.
                  <br />
                  <br />
                  <h3>Riserva dei diritti d’uso</h3>
                  <br />
                  Fino alla ricezione del pagamento del prezzo integrale di acquisto da parte del
                  Titolare, l’Utente non acquista i diritti d’uso dei Prodotti ordinati.
                  <br />
                  <br />
                  <h3>Diritto contrattuale di recesso</h3>
                  <br />
                  Il Titolare concede agli Utenti il diritto contrattuale di recedere dal contratto
                  d’acquisto secondo i termini e le condizioni esplicitati nella relativa sezione di
                  questa Applicazione entro 30 giorni dalla conclusione del contratto.
                  <br />
                  <br />
                  <h3>Consegna</h3>
                  <br />
                  Prestazione di servizi
                  <br />
                  Il servizio acquistato sarà eseguito o reso disponibile nei tempi indicati su
                  questa Applicazione o secondo le modalità comunicate prima dell'inoltro
                  dell'ordine.
                  <br />
                  <br />
                  <h3>Durata del contratto</h3>
                  <br />
                  Abbonamenti
                  <br />
                  Grazie all’abbonamento, l’Utente riceve un Prodotto in via continuativa o
                  periodica. I dettagli riguardanti il tipo di abbonamento e la risoluzione sono
                  descritti di seguito.
                  <br />
                  <br />
                  <h3>Abbonamenti a tempo determinato</h3>
                  <br />
                  Gli abbonamenti a pagamento a tempo determinato decorrono dal giorno in cui il
                  Titolare riceve il pagamento e restano attivi per la durata di abbonamento scelta
                  dall’Utente o altrimenti indicata durante la procedura d’acquisto. Una volta
                  terminata la durata dell’abbonamento, il Prodotto non sarà più accessibile.
                  <br />
                  <br />
                  <h3>Rateizzazione</h3>
                  <br />
                  Se il Prezzo di acquisto è pagato in più rate, l’abbonamento decorre dal giorno in
                  cui il Titolare riceve il primo pagamento e rimane attivo per tutto il periodo
                  d’abbonamento, a condizione che tutte le altre rate siano ricevute
                  tempestivamente. Il mancato rispetto delle scadenze di pagamento potrebbe
                  comportare l’inaccessibilità del Prodotto.
                  <br />
                  <br />
                  <h3>Diritti dell’Utente</h3>
                  <br />
                  <h3>Diritto di recesso</h3>
                  <br />
                  A meno che non ricorra un’eccezione, l’Utente potrebbe godere del diritto di
                  recedere dal contratto entro il termine sotto specificato (di norma 14 giorni) per
                  qualsiasi motivo e senza giustificazione. L’Utente può trovare ulteriori
                  informazioni sul diritto di recesso in questa sezione.
                  <br />
                  <br />
                  <h3>Chi gode del diritto di recesso</h3>
                  <br />
                  Il diritto di recesso permette ai Consumatori Europei di recedere da contratti
                  conclusi a distanza (poiché l’Utente non è in grado di vedere o provare il
                  Prodotto prima di concludere il contratto). Il recesso dal contratto fa venir meno
                  l’obbligo dei contraenti di eseguirlo. Su questa Applicazione il diritto di
                  recesso è applicabile a tutti i gli Utenti.
                  <br />
                  Salvo ricorra una delle eccezioni sotto menzionate – se previste – l’Utente
                  Consumatore ha diritto di recedere dal contratto entro il periodo di tempo
                  specificato più avanti per qualsiasi motivo e senza bisogno di giustificazione.
                  <br />
                  <br />
                  <h3>Esercizio del diritto di recesso</h3>
                  <br />
                  Per esercitare il diritto di recesso l’Utente deve inviare al Titolare una
                  comunicazione inequivocabile della propria intenzione di recedere dal contratto.
                  <br />
                  A tal fine, l’Utente può utilizzare il modulo di recesso tipo reperibile nella
                  sezione delle definizioni di questo documento. L’Utente è tuttavia libero di
                  esprimere la propria intenzione di recedere dal contratto in ogni altra forma
                  idonea. Al fine di rispettare il periodo entro il quale dev’essere esercitato il
                  diritto, l’Utente deve inviare la dichiarazione di recesso prima che scada il
                  termine di recesso.
                  <br />
                  <br />
                  <h3>Quando scade il termine di recesso?</h3>
                  <br />
                  In caso di contratti relativi alla prestazione di un servizio il termine di
                  recesso scade dopo 14 giorni dal giorno di conclusione del contratto, a meno che
                  l’Utente abbia rinunciato al diritto di recesso. In caso di contratti d’acquisto
                  di contenuti digitali non forniti su supporto materiale il termine di recesso
                  scade dopo 14 giorni dal giorno di conclusione del contratto, a meno che l’Utente
                  abbia rinunciato al diritto di recesso.
                  <br />
                  <br />
                  <h3>Effetti del recesso</h3>
                  <br />
                  Se l’Utente esercita il diritto di recesso dopo aver richiesto che l’erogazione
                  del servizio inizi prima dello scadere del periodo di recesso, l’Utente sarà
                  tenuto a pagare al Titolare un compenso proporzionato alla parte di servizio
                  erogata sino al momento in cui l’Utente ha informato il Titolare della propria
                  intenzione di recedere rispetto all’intero servizio previsto dal contratto.
                  <br />
                  Il compenso sarà calcolato sulla base del prezzo contrattualmente pattuito e sarà
                  proporzionale alla parte di servizio erogata fino al momento in cui l’Utente
                  esercita il recesso rispetto all’intero servizio previsto dal contratto.
                  <br />
                  <br />
                  <h3>Limitazione di responsabilità e manleva</h3>
                  <br />
                  <h3>Disposizioni comuni</h3>
                  <br />
                  <h3>Nessuna rinuncia implicita</h3>
                  <br />
                  Il mancato esercizio di diritti di legge o pretese derivanti da questi Termini da
                  parte del Titolare non costituisce rinuncia agli stessi. Nessuna rinuncia può
                  essere considerata definitiva in relazione ad uno specifico diritto o a qualsiasi
                  altro diritto.
                  <br />
                  <br />
                  <h3>Interruzione del Servizio</h3>
                  <br />
                  Per garantire il miglior livello di servizio possibile, il Titolare si riserva di
                  interrompere il Servizio per finalità di manutenzione, aggiornamenti di sistema o
                  per qualsiasi altra modifica, dandone idonea notizia agli Utenti.
                  <br />
                  Nei limiti di legge, il Titolare si riserva di sospendere o terminare
                  completamente il Servizio. In caso di terminazione del Servizio, il Titolare si
                  adopererà affinché gli Utenti possano estrarre i propri Dati Personali e le
                  informazioni secondo le disposizioni di legge.
                  <br />
                  Inoltre, il Servizio potrebbe non essere disponibile per cause che si sottraggono
                  al ragionevole controllo del Titolare, quali cause di forza maggiore (p. es.
                  scioperi, malfunzionamenti infrastrutturali, blackout etc.).
                  <br />
                  <br />
                  <h3>Rivendita del Servizio</h3>
                  <br />
                  Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare, vendere,
                  rivendere o sfruttare questa Applicazione o il Servizio in toto o in parte senza
                  previo consenso scritto del Titolare, espresso direttamente o attraverso un
                  legittimo programma di rivendite.
                  <br />
                  <br />
                  <h3>Privacy policy</h3>
                  <br />
                  Le informazioni sul trattamento dei Dati Personali sono contenute nella privacy
                  policy di questa Applicazione.
                  <br />
                  <br />
                  <h3>Proprietà intellettuale</h3>
                  <br />
                  Senza pregiudizio ad alcuna previsione più specifica contenuta nei Termini, i
                  diritti di proprietà intellettuale ed industriale, quali ad esempio diritti
                  d’autore, marchi, brevetti e modelli relativi a questa Applicazione sono detenuti
                  in via esclusiva dal Titolare o dai suoi licenzianti e sono tutelati ai sensi
                  della normativa e dei trattati internazionali applicabili alla proprietà
                  intellettuale.
                  <br />
                  Tutti i marchi – denominativi o figurativi – ed ogni altro segno distintivo,
                  ditta, marchio di servizio, illustrazione, immagine o logo che appaiono in
                  collegamento con questa Applicazione sono e restano di esclusiva proprietà del
                  Titolare o dei suoi licenzianti e sono tutelati ai sensi della normativa e dei
                  trattati internazionali applicabili alla proprietà intellettuale.
                  <br />
                  <br />
                  <h3>Modifiche dei Termini</h3>
                  <br />
                  Il Titolare si riserva il diritto di modificare i Termini in ogni momento. In tal
                  caso, il Titolare darà opportuna notizia delle modifiche agli Utenti. Le modifiche
                  avranno effetti sul rapporto con l’Utente solo per il futuro.
                  <br />
                  L’utilizzo continuato del Servizio implica l’accettazione dell’Utente dei Termini
                  aggiornati. Se l’Utente non desidera accettare le modifiche, deve cessare
                  l’utilizzo del Servizio. La mancata accettazione dei Termini aggiornati potrebbe
                  comportare la facoltà di ciascuna parte di recedere dall’Accordo.
                  <br />
                  La versione precedente applicabile continua a disciplinare il rapporto fino
                  all’accettazione dell’Utente. Tale versione può essere richiesta al Titolare.
                  <br />
                  Se richiesto dalla legge applicabile, il Titolare specificherà la data entro cui
                  le modifiche ai Termini entreranno in vigore.
                  <br />
                  <br />
                  <h3>Cessione del contratto</h3>
                  <br />
                  Il Titolare si riserva il diritto di trasferire, cedere, disporre di, novare o
                  appaltare singoli o tutti i diritti e le obbligazioni secondo questi Termini,
                  avendo riguardo per gli interessi legittimi degli Utenti.
                  <br />
                  Si applicano le disposizioni relative alla modifica di questi Termini.
                  <br />
                  L’Utente non è autorizzato a cedere o trasferire i propri diritti e le proprie
                  obbligazioni secondo i Termini senza il consenso scritto del Titolare.
                  <br />
                  <br />
                  <h3>Contatti</h3>
                  <br />
                  Tutte le comunicazioni inerenti all’uso di questa Applicazione devono essere
                  inviate ai recapiti indicati in questo documento.
                  <br />
                  <br />
                  Clausola di salvaguardia
                  <br />
                  Qualora alcuna delle disposizioni di questi Termini dovesse essere o divenire
                  nulla o inefficace ai sensi della legge applicabile, la nullità o inefficacia di
                  tale disposizione non provoca inefficacia delle restanti previsioni, che
                  permangono pertanto valide ed efficaci.
                  <br />
                  <br />
                  <h3>Legge applicabile</h3>
                  <br />
                  I Termini sono disciplinati dalla legge del luogo in cui è stabilito il Titolare,
                  così come indicato nella relativa sezione di questo documento a prescindere dalle
                  norme di conflitto.
                  <br />
                  <br />
                  <h3>Foro competente</h3>
                  <br />
                  La competenza esclusiva a conoscere qualsiasi controversia derivante da o in
                  collegamento con i Termini spetta al giudice del luogo in cui il Titolare è
                  stabilito, così come indicato nella relativa sezione di questo documento.
                  <br />
                  <br />
                  <h3>Risoluzione delle controversie</h3>
                  <br />
                  Composizione amichevole delle controversie Gli Utenti possono segnalare eventuali
                  controversie al Titolare, che cercherà di risolvere in via amichevole. Per quanto
                  resti impregiudicato il diritto degli Utenti di promuovere un’azione in giudizio,
                  in caso di controversie inerenti all’uso di questa Applicazione o al Servizio, gli
                  Utenti sono pregati di contattare il Titolare ai recapiti indicati in questo
                  documento. L’Utente può indirizzare un reclamo all’indirizzo e-mail del Titolare
                  indicato in questo documento, includendo una breve descrizione e, se del caso, i
                  dettagli dell’ordine, acquisto o account interessato. Il Titolare provvederà ad
                  evadere la richiesta senza indebito ritardo ed entro 10 giorni dalla sua
                  ricezione.
                  <br />
                  <br />
                  <h3>Definizioni e riferimenti legali</h3>
                  <br />
                  Questa Applicazione (o questa Applicazione)
                  <br />
                  La struttura che consente la fornitura del Servizio.
                  <br />
                  <br />
                  <h3>Accordo</h3>
                  <br />
                  Qualsiasi rapporto legalmente vincolante o contrattuale tra il Titolare e l’Utente
                  disciplinato dai Termini.
                  <br />
                  <br />
                  <h3>Utente Commerciale</h3>
                  <br />
                  Qualsiasi Utente che non corrisponde alla definizione di Consumatore.
                  <br />
                  <br />
                  <h3>Buono</h3>
                  <br />
                  Qualsiasi codice o voucher digitale o cartaceo che consenta all’Utente di
                  acquistare il Prodotto ad un prezzo scontato o a titolo gratuito
                  <br />
                  <br />
                  <h3>Europeo (o Europa)</h3>
                  <br />
                  Definisce un Utente fisicamente presente o con sede legale nell’Unione Europea, a
                  prescindere dalla nazionalità.
                  <br />
                  <br />
                  <h3>Modulo di recesso tipo</h3>
                  <br />
                  Indirizzato a:
                  <br />
                  Ecmclub srl, Via Gallarate 106, 20151 Milano
                  <br />
                  info@ecmclub.org
                  <br />
                  <br />
                  Con la presente io/noi notifichiamo il recesso dal mio/nostro contratto di vendita
                  dei seguenti beni/servizi:
                  <br />
                  _____________________________________________ (inserire qui una descrizione dei
                  beni/servizi dal cui acquisto si intende recedere)
                  <br />
                  Ordinato il: _____________________________________________ (inserire la data)
                  <br />
                  Ricevuto il: _____________________________________________ (inserire la data)
                  <br />
                  Nome del/dei consumatore/i:_____________________________________________
                  <br />
                  Indirizzo del/dei consumatore/i:_____________________________________________
                  <br />
                  Data: _____________________________________________
                  <br />
                  (firmare solamente se il presente modulo è notificato in versione cartacea)
                  <br />
                  <br />
                  <h3>Titolare (o Noi)</h3>
                  <br />
                  Indica la persona fisica o giuridica che fornisce questa Applicazione e/o offre il
                  Servizio agli Utenti.
                  <br />
                  <h3>Prodotto</h3>
                  <br />
                  Un bene o servizio acquistabile attraverso questa Applicazione, come ad esempio un
                  bene materiale, file digitali, software, etc.
                  <br />
                  La vendita di un Prodotto può essere parte del Servizio, così come sopra definito.
                  <br />
                  <br />
                  <h3>Servizio</h3>
                  <br />
                  Il servizio offerto tramite questa Applicazione così come descritto nei Termini e
                  su questa Applicazione.
                  <br />
                  <br />
                  <h3>Termini</h3>
                  <br />
                  Tutte le condizioni applicabili all’utilizzo di questa Applicazione e/o alla
                  fornitura del Servizio così come descritti in questo documento nonché in qualsiasi
                  altro documento o accordo ad esso collegato, nella versione rispettivamente più
                  aggiornata.
                  <br />
                  <br />
                  <h3>Utente (o Tu)</h3>
                  <br />
                  Indica qualsiasi persona fisica che utilizzi questa Applicazione.
                  <br />
                  <br />
                  <h3>Consumatore</h3>
                  <br />
                  Qualsiasi persona fisica che, in qualità di Utente, utilizza beni o servizi per
                  scopi personali e, in generale, agisce per scopi estranei alla propria attività
                  imprenditoriale, commerciale, artigianale o professionale.
                  <br />
                  <br />
                  Ultima modifica: 27 dicembre 2022
               </div>
            </article>
         </div>
         <Footer />
      </>
   )
}

export default Termini
