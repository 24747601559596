import { useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { useRecoilState } from 'recoil'
import { selectedModuleAtom } from 'Recoil/atoms'

export const LessonOverviewMobile = () => {
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const [selectedModule] = useRecoilState(selectedModuleAtom)

   return (
      <>
         {smallScreen && (
            <div className='flex flex-col gap-5 p-6'>
               <span className='text-secondary font-bold text-xs'>{selectedModule?.type_text}</span>
               <p className='text-white font-semibold'>{selectedModule?.title}</p>
            </div>
         )}
      </>
   )
}
