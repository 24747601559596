import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import PlanCard from 'Components/Cards/PlanCard'
import { TPlan } from 'Types/Models'
import PlanTypes from 'Types/Subscripe'
import { useState } from 'react'

const PlansSection = () => {
  const { getPlansNew } = useApiRequests()

  const [plans, setPlans] = useState<any[]>([])

  const { isFetching } = useQuery(['plans'], {
    queryFn: () => getPlansNew(),
    select: (res) => res.data.data,
    onSuccess: (res) => setPlans([...res.plans])
  })

  return (
    <div className={'my-7 grid grid-cols-12 gap-[37px]'}>
      {plans.length > 0 &&
        plans.map((p: TPlan, i) => (
          <PlanCard.Card
            key={i}
            title={p.name}
            desc={p.text}
            type={p.name as PlanTypes}
            link={`/corsi/${p.name.toLocaleLowerCase()}`}
            className='col-span-12 md:col-span-6 2xl:col-span-3'
          />
        ))}

      {isFetching &&
        Array.from(Array(4).keys()).map((item) => (
          <PlanCard.Skeleton key={item} className='col-span-12 md:col-span-6 2xl:col-span-3' />
        ))}
    </div>
  )
}

export default PlansSection
