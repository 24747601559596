import { FormControl, Tooltip, FormLabel, Select } from '@chakra-ui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { TRegisterPersonalForm, TRegisterSecondStepForm } from 'Types/FormsTypes'

export const RegisterSelect = ({
   label,
   placeholder,
   register,
   name,
   errorMessage,
   options = [],
   required = false,
   selectId = false,
   autoSelectFirstOption = false,
   defaultValue = '',
   infoText = ''
}: {
   label: string
   infoText?: string
   defaultValue?: string
   errorMessage?: string
   name: keyof TRegisterPersonalForm | keyof TRegisterSecondStepForm
   placeholder: string
   register: any
   required?: boolean
   selectId?: boolean
   autoSelectFirstOption?: boolean
   options?: { name: string; id: number | string }[]
}) => {
   const { t } = useTranslation()

   return (
      <FormControl isRequired={required}>
         <div className='flex justify-between'>
            <FormLabel className='text-dark-gray flex justify-between text-sm' fontSize={'14px'}>
               {t(`${label}`)}
            </FormLabel>
            {infoText && (
               <Tooltip label={infoText} bg='white' textColor={'black'}>
                  <span className='cursor-pointer'>
                     <AiOutlineInfoCircle className='text-2xl' />
                  </span>
               </Tooltip>
            )}
         </div>
         <Select
            {...register(`${name}`)}
            defaultValue={autoSelectFirstOption ? options[0].name : defaultValue}
            disabled={options.length === 0}
            border={'3px solid'}
            borderColor={errorMessage ? 'red.400' : '#bbbbbb59'}
            shadow='md'
            height={'48px'}>
            <option value=''>{t`${placeholder}`}</option>
            {options?.map((option) => (
               <option key={option.id} value={selectId ? option.id : option.name}>
                  {option.name}
               </option>
            ))}
         </Select>
         {errorMessage && (
            <div className='py-2 flex flex-col items-start gap-2 rounded text-sm text-red-400 font-semibold'>
               {errorMessage}
            </div>
         )}
      </FormControl>
   )
}
