import {
   Accordion,
   AccordionButton,
   AccordionItem,
   AccordionPanel,
   Avatar,
   Box
} from '@chakra-ui/react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'

export const AuthorCard = ({
   title = '',
   secondTitle,
   defaultOpened = false,
   children,
   photo
}: {
   children: JSX.Element
   defaultOpened?: boolean
   title: string
   secondTitle?: string
   photo?: string
}) => {
   return (
      <Accordion allowMultiple defaultIndex={defaultOpened ? [0] : []}>
         <AccordionItem
            border={0}
            style={{ backgroundColor: 'white' }}
            className='min-w-[200px] rounded-md'>
            {({ isExpanded }) => (
               <>
                  <AccordionButton
                     _focus={{ boxShadow: 'none' }}
                     _hover={{ bg: 'none' }}
                     p={5}
                     style={{ color: 'black' }}
                     alignItems='start'
                     justifyContent={'center'}
                     className='w-full font-bold  p-5 flex flex-col h-fit items-start justify-center gap-3'>
                     <div className='flex items-center gap-3 w-full'>
                        <Box
                           flex='1'
                           className='flex w-full items-center justify-between'
                           textAlign='left'>
                           <div className='flex gap-4 items-center'>
                              <Avatar
                                 width={'56px'}
                                 height='56px'
                                 name={title}
                                 src={`${process.env.REACT_APP_MEDIA_URL || ''}${photo}`}
                              />
                              <div className='flex text-xs font-semibold flex-col'>
                                 {title}
                                 {secondTitle && (
                                    <span className='text-xs font-semibold'>{secondTitle}</span>
                                 )}
                              </div>
                           </div>
                        </Box>
                        {isExpanded ? (
                           <AiOutlineMinus className='text-2xl' />
                        ) : (
                           <AiOutlinePlus className='text-2xl' />
                        )}
                     </div>
                  </AccordionButton>
                  <AccordionPanel p={0}>{children}</AccordionPanel>
               </>
            )}
         </AccordionItem>
      </Accordion>
   )
}
