import { useRecoilState } from 'recoil'
import Icons from '../Icons'
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Radio,
  RadioGroup
} from '@chakra-ui/react'
import { filterState } from 'Recoil/atoms'

const CreditsFilter = ({ isOpen }: { isOpen: boolean }) => {
  const [state, setCState] = useRecoilState(filterState)

  return (
    <AccordionItem border={'none'}>
      <AccordionButton
        px={0}
        py={0}
        _hover={{
          bg: ''
        }}>
        <Icons.AnimatedDash isOpen={isOpen}>
          <Box as='span' flex='1' textAlign='left'>
            Numero dei crediti
          </Box>
        </Icons.AnimatedDash>
      </AccordionButton>
      <AccordionPanel pb={5} pl={0}>
        <RadioGroup
          className='grid grid-cols-2 pl-[20px] md:pl-[38px]'
          value={`${state.filter.credit}`}
          onChange={(e) => {
            setCState((prev) => ({
              ...prev,
              filter: {
                ...prev.filter,
                credit: +e
              },
              hasFilter: true,
              hasSearch: false,
              keyword: ''
            }))
          }}>
          <Radio value='10'>&lt;10</Radio>
          <Radio value='40'>&lt;40</Radio>
          <Radio value='20'>&lt;20</Radio>
          <Radio value='50'>&lt;50</Radio>
          <Radio value='30'>&lt;30</Radio>
        </RadioGroup>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default CreditsFilter
