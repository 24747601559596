import React from 'react'
import federlogo from 'Assets/logo-federcongressi.png'
import isoLogo from 'Assets/logo-iso-9001-ecmclub.png'
import xgateLogo from 'Assets/logo-xgate-color.svg'
import { Link } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'
import logo from 'Assets/logo.svg'
import freeLogo from 'Assets/freeUserLogo.svg'
import csqLogo from 'Assets/logo-csq-ecmclub.png'

export const Footer = () => {
   const [userData] = useRecoilState(userDataAtom)

   return (
      <>
         <div className='flex container mx-auto px-9 sm:justify-between border-y-[1px] border-y-secondary_root-dark flex-wrap  text-white'>
            <div className='flex text-sm pt-6 pb-2 border-white text-[#848484] w-full justify-around'>
               {userData ? (
                  <>
                     {userData?.user_data.subscription.type_text !== 'FREE' ? (
                        <img className='w-48 h-11' src={logo} />
                     ) : (
                        <img className='w-48 h-11' src={freeLogo} />
                     )}
                  </>
               ) : (
                  <>
                     <img className='w-full h-11' src={freeLogo} />
                  </>
               )}{' '}
            </div>
            <p className="w-full text-[25px] text-secondary_root-dark text-center mb-9">
               Community di formazione riservata ai professionisti della salute
            </p>
            <div className="grid grid-cols-12 max-w-6xl mx-auto mb-[68px]">
               <div className='col-span-12 md:col-span-6 lg:col-span-3 flex flex-col gap-3'>
                  <ul className=''>
                     <p className='font-semibold text-sm text-[#aa5260] py-3'>Ecmclub srl</p>
                     <li className='text-sm font-light text-black'>Via Gallarate 106</li>
                     <li className='text-sm font-light text-black'>20151 Milano</li>
                     <li className='text-sm font-light text-black'>Provider ECM: ID 4946</li>
                  </ul>
               </div>
               <div className='col-span-12 md:col-span-6 lg:col-span-3 flex flex-col gap-3'>
                  <ul className=''>
                     <p className='font-semibold text-sm text-[#aa5260] py-3'>Comitato Scientifico</p>
                     <li className='text-sm  font-light text-black pt-1'>Dr. Simone Celotti</li>
                     <li className='text-sm  font-light text-black pt-1'>Dr. Federico Durbano</li>
                     <li className='text-sm  font-light text-black pt-1'>Prof. Massimo Galli</li>
                     <li className='text-sm  font-light text-black pt-1'>Dr. Andrea Marinozzi</li>
                     <li className='text-sm  font-light text-black pt-1'>Prof. Carlo Ratto</li>
                     <li className='text-sm  font-light text-black pt-5'>Dr. Andrea Ravida</li>
                     <li className='text-sm  font-light text-black pt-1'>Dr. Claudio Robecchi</li>
                     <li className='text-sm  font-light text-black pt-1'>Dr. Andrea Terramocci</li>
                     <li className='text-sm  font-light text-black pt-1'>Dr.ssa Cristiana Vasino</li>
                  </ul>
               </div>
               <div className='col-span-12 md:col-span-6 lg:col-span-3 flex flex-col gap-3'>
                  <ul className=''>
                     <p className='font-semibold text-sm text-[#aa5260] py-3'>Policies</p>
                     <li className='text-sm  font-light text-black pt-1'>
                        <Link href='../termini-duso'>Termini e condizioni d’uso</Link>
                     </li>
                     <li className='text-sm  font-light text-black pt-1'>
                        <Link href='../privacy-policy'>Privacy policy</Link>
                     </li>
                     <li className='text-sm  font-light text-black pt-1'>
                        <Link target='_blank' href='https://axenso.s3.amazonaws.com/xgate/index.html'>
                           Privacy policy XGate
                        </Link>
                     </li>
                     <li className='text-sm  font-light text-black pt-1'>
                        <Link href='../politica-della-qualita'>Politica della qualità</Link>
                     </li>
                  </ul>
               </div>
               <div className='col-span-12 md:col-span-6 lg:col-span-3 flex flex-col gap-3'>
                  <ul className=''>
                     <p className='font-semibold text-sm text-[#aa5260] py-3'>Customercare</p>
                     <li className='text-sm  font-light text-black py-1'>
                        Customercare utenti attivo nei giorni feriali dalle 10:00 alle 13:00 e dalle
                        15:00 alle 17:00. <Link textDecoration='underline' href='dashboard'>Accedi alla tua dashboard</Link> e
                        contattaci tramite il form Contatti in basso a sinistra
                     </li>
                     <li className='text-sm  font-light text-black pt-1'>
                        <Link href='../faq'>FAQ</Link>
                     </li>
                     <li className='flex justify-center items-center gap-10 pt-8'>
                        <img src={isoLogo} className='w-14' />
                        <img src={csqLogo} className='w-16' />
                        <img src={federlogo} className='w-16' />
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div className='w-full  mt-2  justify-center lg:flex text-center'>
            <div className='py-4 border-t border-white flex flex-col justify-center items-center gap-2'>
               <img src={xgateLogo} className='w-14' />
               <p className='font-semibold text-sm text-[#848484] py-1'>
                  Ecmclub è un sito del network XGATE
               </p>
               <div className='text-[13px] font-light text-[#848484]'>
                  XGate è un sistema di registrazione centralizzato che permette di accedere con le
                  stesse credenziali (Email e password) a tutti i siti del network
               </div>
            </div>
         </div>
      </>
   )
}
