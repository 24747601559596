import PlansCheckout from 'Components/PlansCheckout'
import { Footer } from 'Layouts/Footer/Footer'

export const Offerta = () => {
   return (
      <div className='sm:container py-20 w-[96%] mx-auto flex flex-col gap-14 lg:min-h-[85vh]'>
         <PlansCheckout />
         <Footer />
      </div>
   )
}
