import Icons from "../Icons"
import ChakraCheckBox from "../ChakraCheckBox"
import { useRecoilState } from "recoil"
import { filterState } from "Recoil/atoms"
import { AccordionButton, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react"

const PricePlan = ({ isOpen }: { isOpen: boolean }) => {
  const [state, setCState] = useRecoilState(filterState)

  const handleCheckChange = (val: number) => {
    let plans: number[] = []
    if (state.filter["plan"] && state.filter["plan"]?.length > 0) {
      plans = [...state.filter["plan"]]
    }

    const exist = plans.includes(val)

    if (exist) {
      plans = plans.filter((p) => p !== val)
      setCState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          plan: plans,
        },
        hasFilter: true,
      }))
    } else {
      plans = [...plans, val]
      setCState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          plan: plans,
        },
        hasFilter: true,
      }))
    }
  }

  return (
    <AccordionItem border={"none"} style={{
      visibility: state.hidePlans ? "hidden" : "visible",
      height: state.hidePlans ? "0" : "auto",
    }}>
      <AccordionButton
        px={0}
        py={0}
        _hover={{
          bg: "",
        }}>
        <Icons.AnimatedDash isOpen={isOpen}>
          <Box as='span' flex='1' textAlign='left'>
            Piano
          </Box>
        </Icons.AnimatedDash>
      </AccordionButton>
      <AccordionPanel pb={5} pl={0}>
        <div className='pl-[20px] md:pl-[38px]'>
          <div className='grid grid-cols-2 gap-[15px]'>
            <ChakraCheckBox
              isChecked={state.filter["plan"]?.includes(1) || false}
              onChange={() => handleCheckChange(1)}>
              FREE
            </ChakraCheckBox>
            <ChakraCheckBox
              isChecked={state.filter["plan"]?.includes(3) || false}
              onChange={() => handleCheckChange(3)}>
              SEMI
            </ChakraCheckBox>
            <ChakraCheckBox
              isChecked={state.filter["plan"]?.includes(2) || false}
              onChange={() => handleCheckChange(2)}>
              PLUS
            </ChakraCheckBox>
            <ChakraCheckBox
              isChecked={state.filter["plan"]?.includes(4) || false}
              onChange={() => handleCheckChange(4)}>
              FULL
            </ChakraCheckBox>
          </div>
        </div>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default PricePlan
