/* eslint-disable no-dupe-keys */
export const italianStrings = {
   // dashboard
   Dashboard: 'Dashboard',
   Profile: 'Profilo',
   Subscription: 'Iscrizione',
   Billing: 'Fatture',
   'CME Credits': 'Crediti ECM',
   'Courses enrolled': 'I miei corsi',
   'On Going': 'In corso',
   Completed: 'Completato',
   Complete: 'Completa',
   'Course Title': 'Titolo del corso',
   Status: 'Stato',
   Go: 'Vai',
   'Completed on': 'Completato il',
   'Started on': 'Iniziato il',
   Resume: 'Riprendi',
   'Scrivi messaggio': 'Scrivi messaggio',
   Telefono: 'Telefono',
   Attendance: 'Partecipazione',
   'Invia Email': 'Invia Email',
   'Your membership expires on': 'Il tuo abbonamento scade il',
   'Your subscription expire on december': 'Il tuo abbonamento scade a dicembre',
   'You’re an Ecmclub Plus member since': 'Sei iscritto a Ecmclub Plus dal',
   'click here to renew it': 'clicca qui per rinnovare',
   'Change password': 'Cambia password',
   'Old password': 'Vecchia password',
   'New password': 'Nuova password',
   'Repeat new password': 'Ripeti nuova password',
   'Manage my subsicription': 'Gestisci la tua iscrizione',
   'Renew subscription': 'Rinnova la tua iscrizione',
   'There is no completed courses yet!': 'Non ci sono ancora corsi completati!',
   'There is no ongoing courses yet!':
      'Non ti sei ancora iscritto a un corso di ecmclub! Per vedere i corsi che hai concluso, clicca su "Completato" in alto a destra',

   // profile////////////////////////////////
   // personal
   'First name': 'Nome',
   'Last name': 'Cognome',
   'Fiscal code': 'Codice Fiscale',
   'Birth date': 'Data di nascita',
   'Birth place': 'Luogo di nascita',
   'Email address': 'Indirizzo email',
   Address: 'Indirizzo',
   CAP: 'CAP',
   Città: 'Città',
   Provincia: 'Provincia',
   Regione: 'Regione',
   Cellulare: 'Cellulare',
   'Salva impostazioni': 'Salva impostazioni',

   // profession
   Profession: 'Professione',
   Disciplin: 'Disciplina',
   'Area of interest': 'Area di interesse',
   Operation: 'Operatività',
   'Province activity work': 'Provincia attività lavorativa',
   'Professional association': 'Ordine professionale',
   'Registration number for the order': 'Numero di iscrizione all&apos;ordine',
   'Province of Registration to the Order': 'Provincia di iscrizione all&apos;ordine',

   // Billing
   'Below are all the invoices for the charges effected by Ecmclub.':
      'Di seguito sono riportate tutte le fatture per le spese effettuate da Ecmclub.',
   'Date issued': 'Data fatturazione',
   Invoice: 'Fattura',
   'Transaction ID': 'ID di transazione',
   'Payment Method': 'Metodo di pagamento',
   'Download Invoice': 'Scarica fattura',
   'Credit card': 'Carta di credito',
   Succeded: 'Completato',
   Download: 'Scarica',
   'Company name': 'Ragione sociale',
   'Edit details': 'Modifica dettagli',
   'Update email address': 'Aggiorna indirizzo email',
   'Billing notifications': 'Notifiche di fatturazione',

   // all courses
   'NO ECM': 'NO ECM',
   PRO: 'PRO',
   Disciplina: 'Disciplina',
   MEDICO: 'MEDICO',
   FARMACISTA: 'FARMACISTA',
   'Allergologia e immunonologia clinica': 'Allergologia e immunonologia clinica',
   Oncologia: 'Oncologia',

   // Header
   DASHBOARD: 'DASHBOARD',
   'ALL COURSES': 'CATALOGO CORSI',
   Logout: 'Esci',
   'FAVOURITE COURSES': 'PREFERITI',

   // Course details
   Credits: 'Crediti',
   'Online since': 'Online dal',
   'Valid until': 'Valido fino al',
   'Event ID': 'ID evento',
   Preview: 'Anteprima',
   Hours: 'Ore formative',
   'Inserire nome azienda': 'Inserire nome azienda',
   SUBSCRIBE: 'Accedi al corso',
   'UPGRADE YOUR ACCOUNT': 'PASSA ALLA VERSIONE PLUS',
   'SUBSCRIBE TO THE COURSE': 'ISCRIVITI AL CORSO',
   Channel: 'Canale',
   'Voglio procedere con l’upgrade': 'Voglio procedere con l’upgrade',
   'Non adesso': 'Channel',
   'Event description': 'Descrizione corso',
   'Board of Authors': 'Faculty',
   Continue: 'Continua',

   // Module page //////////
   Back: 'Indietro',
   'Course Guide': 'Guida al corso',
   'Course info': 'Informazioni del corso',
   Materials: 'Materiali',
   'Q&A': 'Q&A',
   Announcements: 'Comunicazioni',
   'Continue Session': 'Continua sessione',
   'You will be logged out after': 'Sarai disconesso dopo ',

   // quiz
   Module: 'Modulo',
   QUIZ: 'QUIZ',
   Tentativi: 'Tentativi',
   Previos: 'PRECEDENTE',
   'SUBMIT QUIZ': 'Invia QUIZ',
   Next: 'SUCCESSIVA',
   Question: 'Domanda',
   Questions: 'Domande',
   'Quiz Completed': 'Quiz completato',
   'You scored': 'Il tuo punteggio',
   'Status Qualified': 'Stato: superato',
   'Status Not Qualified': 'Stato: non superato',
   QUIZ_NOT_PASSED: 'QUIZ NON SUPERATO',
   'RETAKE QUIZ': 'RIPETI IL QUIZ',
   of: 'di',
   'START YOUR QUIZ': 'INIZIA IL QUIZ',
   'Here’s what you need to know': 'Qui trovi quello che devi sapere',
   'questions count': 'Ci sono {{count}} domande.',
   'The exam takes about': 'Il quiz dura',
   'to complete.': 'per completarlo',
   'You must answer': 'La risposta è obbligratoria',
   'questions correctly': 'Domande corrette',
   'to pass': 'per passare',
   'CONTINUE NEXT MODULE': 'VAI AL PROSSIMO MODULO',
   'submit instruction':
      'Controlla le tue risposte prima di inviare. Dopo l&apos;invio non potrai più effettuare modifiche.',
   'START YOUR SURVEY': 'INIZIA IL QUESTIONARIO',
   // AUTH Pages ///////////
   'Remember Me': 'Ricordami',
   'Forgot Password': 'Password dimenticata',
   Login: 'Accedi',
   Reset: 'Reimposta password',
   Register: 'Registrati',
   'Go back to': 'Torna al form di',
   'New User?': 'Nuovo utente?',
   'Welcome back! Please login to your account.':
      'Bentornato su ecmclub, inserisci di seguito le tue credenziali!',
   'Update Profile': 'Aggiorna il tuo profilo',
   'Reset you password': 'Reimposta la tua password',
   'Please enter the email address youd like your password reset information sent to.':
      'Inserisci l`indirizzo email a cui desideri inviare le informazioni per reimpostare la password',
   'Please accept website privacy policy to continue':
      'Per favore, accetta la privacy per continuare',
   'MAKE CONSENT': 'CONSENTI',
   'Privacy Policy Consent': 'Privacy Policy',
   // General //////////////////
   'You cannot open the session on the new tab':
      'Non puoi aprire la sessione in una nuova finestra',
   'You are not authorized': 'Non sei autorizzato',
   'you recived an email': 'Ti è stata mandata una mail!',
   'Email not exist': 'L&apos;indirizzo email non esiste',
   'No User Found': 'Utente non trovato :(',
   'Your password has been changed': 'La tua password è stata cambiata',
   'Password reset successfully': 'Reset della password effettuato con successo',
   'Something goes wrong': 'Ops, qualcosa è andato storto :(',
   'Account has not activated': 'L&apos;account non è stato attivato',
   'Account activated successfully': 'Account attivato correttamente',

   // FAQ
   'Curae hendrerit donec commodo hendrerit egestas tempus, turpis facilisis nostra nunc. Vestibulum dui eget ultrices.':
      'Hai bisogno di aiuto? Probabilmente la risposta di cui hai bisogno è qui! Controlla tra le domande e risposte più frequenti.',
   'Domande frequenti': 'Domande frequenti',

   // Quiz Information
   'Tentativi sostenuti': 'Tentativi sostenuti',
   domande: 'domande',
   'Tentativi a Disposizione': 'Tentativi a disposizione',
   'Il quiz contiene': 'Il quiz contiene',
   'percentage of correct answers to pass the quiz':
      'Percentuale di risposte corrette per il superamento del quiz',
   'In caso di test non superato sarai reindirizzato al modulo precedente':
      'In caso di questionario non superato sarai reindirizzato al modulo precedente',
   'Hai bisogno di aiuto? Probabilmente la risposta di cui hai bisogno è qui! Controlla tra le domande e risposte più frequenti':
      'Hai bisogno di aiuto? Probabilmente la risposta di cui hai bisogno è qui! Controlla tra le domande e risposte più frequenti',

   // Quiz Fail
   Attempts: 'Tentativi',
   'RETAKE THE LESSONS': 'GUARDA DI NUOVO IL MODULO',

   // SubscribeCourseButton
   'La Sua iscrizione nasce a seguito di un reclutamento diretto?':
      'La Sua iscrizione nasce a seguito di un reclutamento diretto?',
   'In caso affermativo indicare la ragione sociale dell Azienda':
      'In caso affermativo indicare la ragione sociale dell Azienda',

   // CourseOtherDetails
   'Scarica il programma del corso': 'Scarica il programma del corso',

   // CommentsDrawer
   'Lascia un commento': 'Lascia un commento',
   'Invia Commento': 'Invia Commento',

   // Profile
   'L’aggiornamento della password richiede un nuovo login':
      'L’aggiornamento della password richiede un nuovo login',

   // NotificationsSettings

   Notifiche: 'Notifiche',
   'Billing notifications are sent to': 'Billing notifications are sent to',
   'Vuoi disattivare le notifiche?': 'Vuoi disattivare le notifiche?',
   'Autorizzi la redazione ad accedere al tuo account per fornire assistenza?':
      'Autorizzi la redazione ad accedere al tuo account per fornire assistenza?',
   'Vuoi disattivare le notifiche': 'Vuoi disattivare le notifiche',

   // QUIZ

   'Riprendi quiz': 'Riprendi quiz',
   'Riprendi modulo': 'Riprendi modulo',

   // Modals
   'Hai concluso il corso!': 'Hai concluso il corso!',
   'These details will be included in all your future invoices.':
      'Questi dettagli saranno inclusi in tutte le tue prossime fatture.',
   'Confirm and close': 'Conferma e chiudy',
   'Invoice detail': 'Dettagli della fatturazione',
   'Si, desidero agggiungere il pacchetto PLUS al mio account al costo annuo di':
      'Si, desidero agggiungere il pacchetto PLUS al mio account al costo annuo di',
   Acquista: 'Acquista',
   'I accept the terms and conditions of this store': 'Accetto i termini e le condizioni d’uso',
   'Hai un codice sconto?': 'Hai un codice sconto?',
   'Aggiorna prezzo finale': 'Aggiorna prezzo finale',
   'Try again once you are ready': 'Riprova non appena sarai pronto',
   'Nome e cognome sulla carta': 'Nome e cognome sulla carta',
   'Hai Bisogno di fattura?': 'Hai Bisogno di fattura?',
   'Paga adesso': 'Paga adesso',
   Chiudi: 'Chiudi',
   'Reali con il noo conldettsx Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat, nulla est':
      'Reali con il noo conldettsx Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellat, nulla est',
   Attenzione: 'Attenzione',
   'Audio | Gli strumenti di counselling a supporto del processo terapeutico con gonadotropine':
      'Audio | Gli strumenti di counselling a supporto del processo terapeutico con gonadotropine',

   'Il corso non è accreditato per la tua professione e specializzazione.':
      'Il corso non è accreditato per la tua professione e specializzazione.',
   'L’iscrizione al corso è consentita ma non potrai ottenere i crediti ECM perché non appartieni alle professioni e specializzazioni alle quali il corso è rivolto':
      'L’iscrizione al corso è consentita ma non potrai ottenere i crediti ECM perché non appartieni alle professioni e specializzazioni alle quali il corso è rivolto',
   'L’iscrizione al corso è consentita ma non potrai ottenere i crediti ECM perché non appartieni alle professioni e specializzazioni alle quali il corso è rivolto':
      'L’iscrizione al corso è consentita ma non potrai ottenere i crediti ECM perché non appartieni alle professioni e specializzazioni alle quali il corso è rivolto',

   // PlansCheckout
   'Hai selezionato un corso riservato agli utenti PLUS':
      'Hai selezionato un corso riservato agli utenti PLUS',
   'Con Ecmclub PLUS potrai accedere all’intero catalogo di corsi ECM e eventi in live streaming per tutta la durata dell’abbonamento e avrai inoltre la possibilità di acquistare, con sconti speciali, l’accesso a iniziative dedicate':
      'Con Ecmclub PLUS potrai accedere all’intero catalogo di corsi ECM e eventi in live streaming per tutta la durata dell’abbonamento e avrai inoltre la possibilità di acquistare, con sconti speciali, l’accesso a iniziative dedicate',
   'Per diventare utente plus puoi scegliere la forma di adesione preferita, mensile o annuale.':
      'Per diventare utente plus puoi scegliere la forma di adesione preferita, mensile o annuale.',
   'Scopri le novità di Ecmclub PLUS': 'Scopri le novità di Ecmclub PLUS',
   'Per informazioni o chiarimenti contatta il nostro customercare allo 02 36692890':
      'Per informazioni o chiarimenti contatta il nostro customercare allo 02 36692890',
   Abbonati: 'Abbonati',

   // Second Tab
   'You cannot open the session on the new tab': 'Non puoi aprire la sessione in un nuovo tab!',
   'Oops!': 'Oops!',

   // Comments
   'Enter your comment': 'Scrivi qui il tuo commento',
   'Invia Commento': 'Invia Commento',
   'Lascia un commento': 'Lascia un commento',

   course_edition: 'Edizione del corso',
   'finished on': 'Completato il',
   user_plus_description:
      'Sei un utente PLUS; questo vuole dire che puoi accedere a tutti i corsi e servizi compresi nel piano FREE e nel piano PLUS :)',
   user_free_description:
      'Sei un utente FREE, questo vuol dire che puoi aver accesso solo ai corsi FREE :)',
   'UTENTE PLUS': 'UTENTE PLUS',
   'UTENTE FREE': 'UTENTE FREE',
   'My Courses': 'I miei corsi',

   'Your massage has been send': 'Il tuo messaggio è stato inviato!',
   'Oops, No Courses Found!': 'Oops, sembra che qui non ci siano ancora corsi!',
   'Update password': 'AGGIORNA PASSWORD',
   seconds: 'secondi',
   'this course will start on': 'this course will start on',
   'this course ended at': 'this course ended at',
   'CORSO CHIUSO IL': 'CORSO CHIUSO IL',
   'DISPONIBILE DAL': 'DISPONIBILE DAL'
}
