/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'
import { Button, Input, Select } from '@chakra-ui/react'
import { ModalWrapper } from 'Components/Modals/ModalWrapper'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'

export const SubscribeCourseButton = ({ courseId }: { courseId: string }) => {
   const [showUpgradeModal, setShowUpgradeModal] = useState(false)
   const { t } = useTranslation()
   const { enrollToCourse } = useApiRequests()
   const [selected, setselected] = useState('no')
   const [textValue, settextValue] = useState('')
   const navigate = useNavigate()
   const { mutate, isLoading } = useMutation({ mutationFn: enrollToCourse })

   const handleContinue = () => {
      mutate(
         { courseId, agency: textValue },
         {
            onSuccess: (data) => data && navigate(`/module/${courseId}`)
         }
      )
   }

   return (
      <>
         <Button
            onClick={() => setShowUpgradeModal(true)}
            height={'55px'}
            variant={'secondary'}
            w='full'>
            <span className='text-xs lg:text-base'>{t('SUBSCRIBE')}</span>
         </Button>

         <ModalWrapper
            size='sm'
            color='white'
            isOpen={showUpgradeModal}
            onClose={() => setShowUpgradeModal(false)}>
            <div className='p-4 flex flex-col gap-2 w-full text-dark-grey'>
               <span className='font-bold'>
                  {t('La Sua iscrizione nasce a seguito di un reclutamento diretto?')}
               </span>
               In caso affermativo indicare la ragione sociale dell'azienda
               <div className='flex flex-col justify-between gap-10 items-center py-4'>
                  <Select
                     variant='filled'
                     backgroundColor='white'
                     boxShadow='lg'
                     size='lg'
                     onChange={(e) => setselected(e.target.value)}
                     value={selected}
                     className='w-20 px-2 text-black border-grey rounded-lg border-2'>
                     <option value={'si'}>Si</option>
                     <option value={'no'}>No</option>
                  </Select>
                  {selected === 'si' && (
                     <div className='pb-6'>
                        <label className='text-left text-dark-gray text-sm self-start py-4'>
                           {t('Inserire nome azienda')}
                        </label>
                        <Input
                           variant='filled'
                           backgroundColor='white'
                           border={0}
                           boxShadow='xl'
                           required
                           onChange={(e) => settextValue(e.target.value)}
                           placeholder='Inserisci'
                           value={textValue}
                           _focus={{ boxShadow: 'xl' }}
                           className='w-2/3 bg-transparent border-2 border-gray rounded-lg px-3 text-sm text-slate-700'
                        />
                     </div>
                  )}
               </div>
               <Button
                  isLoading={isLoading}
                  onClick={handleContinue}
                  variant={'primary'}
                  bgColor='yellow.500'
                  disabled={selected === 'si' && textValue === ''}
                  py='8'
                  alignSelf='end'>
                  {t('Continue')}
               </Button>
            </div>
         </ModalWrapper>
      </>
   )
}
