import { TGlobalFilterSend } from "Types/filter.types";

function filterSearchParamBuilder(page: number, data?: TGlobalFilterSend): Record<any, any> {
  let params = Object()

  if (data) {
    if (data.keyword) {
      params = {
        ...data,
        page,
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { keyword, ...rest } = data;
      params = { ...rest, page };
    }

    for (const key in params) {
      // eslint-disable-next-line no-console
      if (params[key] === true) {
        params[key] = 1
      } else if (params[key] === false) {
        params[key] = 0
      }
    }

    return {
      ...params
    }
  }

  return {
    page
  }
}

export default filterSearchParamBuilder;