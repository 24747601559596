import React from 'react'

type Props = {
  title: string,
  description?: string
}

const SectionHeader = ({ title, description }: Props) => {
  return (
    <div className='flex flex-col gap-[11px] font-nuinto text-active'>
      <h2 className='font-bold text-[40px] lg:text-[45px]'>{title}</h2>
      {description && <p className='text-[23px] lg:text-[25px]'>{description}</p>}
    </div>
  )
}

export default SectionHeader