import { SpecialZoomLevel, Viewer, Worker, ScrollMode } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import type { ToolbarSlot } from '@react-pdf-viewer/toolbar'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode'
import '@react-pdf-viewer/zoom/lib/styles/index.css'
// eslint-disable-next-line no-duplicate-imports
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { RenderEnterFullScreenProps } from '@react-pdf-viewer/full-screen'
import { useState } from 'react'
import { useMediaQuery } from '@chakra-ui/react'

export const PdfPlayer = ({ url, handleFinishVideo }: { url: string; handleFinishVideo: any }) => {
   const [numberOfPages, setnumberOfPages] = useState(0)
   const [smallScreen] = useMediaQuery('(max-width: 1280px)')

   const handlePageChange = (e: any) => {
      // if (!smallScreen && e.currentPage === numberOfPages - 1) {
      //    handleFinishVideo()
      // }

      // if (smallScreen && e.currentPage === numberOfPages - 2) {
      //    handleFinishVideo()
      // }
   }

   const renderToolbar = (Toolbar: (props: any) => React.ReactElement) => (
      <Toolbar>
         {(slots: ToolbarSlot) => {
            const {
               CurrentPageInput,
               EnterFullScreen,
               GoToNextPage,
               GoToPreviousPage,
               NumberOfPages,
               ZoomIn,
               Zoom,
               ZoomOut
            } = slots
            return (
               <div
                  className='md:px-5 px-1'
                  style={{
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     display: 'flex',
                     flex: '1'
                  }}>
                  <div className='flex items-center w-2/5'>
                     <div style={{ padding: '0px 1px' }}>
                        <GoToPreviousPage>
                           {(props) => (
                              <button
                                 style={{
                                    borderRight: '1px solid #F8F8F8',
                                    borderLeft: '1px solid #F8F8F8',
                                    color: '#ffffff',
                                    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                                    padding: '8px'
                                 }}
                                 disabled={props.isDisabled}
                                 onClick={props.onClick}>
                                 <svg
                                    width='26'
                                    height='16'
                                    viewBox='0 0 26 16'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                       d='M24.7502 14.9983L13.0987 1.38487C12.9103 1.15743 12.5806 1.14119 12.3607 1.33613C12.345 1.33613 12.3293 1.36862 12.3136 1.38487L0.662109 14.9983'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                 </svg>
                              </button>
                           )}
                        </GoToPreviousPage>
                     </div>
                     <div style={{ padding: '0px 2px', width: '2.5rem' }}>
                        <CurrentPageInput />
                     </div>
                     <div
                        className='text-[#B1B1B1] '
                        style={{ padding: '0px 2px', margin: '0px 10px' }}>
                        <NumberOfPages />
                     </div>
                     <div style={{ padding: '0px 2px' }}>
                        <GoToNextPage>
                           {(props) => (
                              <button
                                 style={{
                                    borderRight: '1px solid #F8F8F8',
                                    borderLeft: '1px solid #F8F8F8',
                                    color: '#ffffff',
                                    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                                    padding: '8px'
                                 }}
                                 disabled={props.isDisabled}
                                 onClick={props.onClick}>
                                 <svg
                                    width='26'
                                    height='16'
                                    viewBox='0 0 26 16'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                       d='M1.16406 1.20117L12.8156 14.8146C13.004 15.042 13.3338 15.0582 13.5536 14.8633C13.5693 14.8633 13.585 14.8308 13.6007 14.8146L25.2522 1.20117'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                 </svg>
                              </button>
                           )}
                        </GoToNextPage>
                     </div>
                  </div>
                  <div className='flex items-center md:justify-center justify-end w-3/5'>
                     <div style={{ padding: '0px 2px' }}>
                        <ZoomOut>
                           {(props) => (
                              <button
                                 style={{
                                    border: 'none',
                                    borderRadius: '4px',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    padding: '8px'
                                 }}
                                 onClick={props.onClick}>
                                 <svg
                                    width='26'
                                    height='27'
                                    viewBox='0 0 26 27'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                       d='M6.55384 11.837H17.0343M25.4094 25.9306L19.2027 19.5096M11.8019 1.01074C17.5843 1.01074 22.2825 5.85491 22.2825 11.8532C22.2825 17.8516 17.6 22.6957 11.8019 22.6957C6.00383 22.6957 1.32129 17.8516 1.32129 11.8532C1.32129 5.85491 6.00383 1.01074 11.8019 1.01074Z'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                 </svg>
                              </button>
                           )}
                        </ZoomOut>
                     </div>
                     <div style={{ padding: '0px 2px' }}>
                        <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.4, 3.2]} />
                     </div>
                     <div style={{ padding: '0px 2px' }}>
                        <ZoomIn>
                           {(props) => (
                              <button
                                 style={{
                                    border: 'none',
                                    borderRadius: '4px',
                                    color: '#ffffff',
                                    cursor: 'pointer',
                                    padding: '8px'
                                 }}
                                 onClick={props.onClick}>
                                 <svg
                                    width='25'
                                    height='27'
                                    viewBox='0 0 25 27'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                       d='M10.8448 6.40761V17.2501M5.61243 11.837H16.0929M24.468 25.9306L18.2613 19.5096M10.8605 1.01074C16.6429 1.01074 21.3411 5.85492 21.3411 11.8532C21.3411 17.8516 16.6586 22.6957 10.8605 22.6957C5.06242 22.6957 0.379883 17.8516 0.379883 11.8532C0.379883 5.85492 5.06242 1.01074 10.8605 1.01074Z'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                 </svg>
                              </button>
                           )}
                        </ZoomIn>
                     </div>
                  </div>
                  <div className='flex items-center justify-end md:w-1/5 w-8'>
                     <div style={{ padding: '0px 2px' }}>
                        <EnterFullScreen>
                           {(props: RenderEnterFullScreenProps) => (
                              <button className='text-[#B1B1B1]' onClick={props.onClick}>
                                 <svg
                                    width='25'
                                    height='26'
                                    viewBox='0 0 25 26'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                       d='M0.381836 12.9541H24.4691'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                    <path
                                       d='M12.9111 1.04785V24.8625'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                    <path
                                       d='M9.76855 4.29678L12.909 1.04785L16.0495 4.29678'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                    <path
                                       d='M21.3281 9.70801L24.4686 12.9569L21.3281 16.2059'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                    <path
                                       d='M3.52231 16.2059L0.381836 12.9569L3.52231 9.70801'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                    <path
                                       d='M16.0495 21.6133L12.909 24.8622L9.76855 21.6133'
                                       stroke='#B1B1B1'
                                       strokeWidth='0.67'
                                       strokeLinecap='round'
                                       strokeLinejoin='round'
                                    />
                                 </svg>
                              </button>
                           )}
                        </EnterFullScreen>
                     </div>
                  </div>
               </div>
            )
         }}
      </Toolbar>
   )

   const defaultLayoutPluginInstance = defaultLayoutPlugin({
      toolbarPlugin: {
         fullScreenPlugin: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Unreachable code error
            getFullScreenTarget: (pagesContainer) =>
               pagesContainer.closest('[data-testid="default-layout__body"]'),
            renderExitFullScreenButton: () => <></>
         }
      },
      renderToolbar
   })

   const scrollModePluginInstance = scrollModePlugin()

   const onLoadModification = () => {
      const x = ((
         document.querySelector(
            '[data-testid="page-navigation__current-page-input"]'
         ) as HTMLButtonElement
      ).disabled = true)
   }

   if (!url.length) return <></>

   return (
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js'>
         <div
            className='rpv-core__viewer'
            style={{
               height: '100%',
               position: 'relative'
            }}>
            <Viewer
               onDocumentLoad={(e) => {
                  onLoadModification()
                  setnumberOfPages(e.doc.numPages)
               }}
               fileUrl={url}
               plugins={[defaultLayoutPluginInstance, scrollModePluginInstance]}
               // initialPage={currentPage}
               onPageChange={handlePageChange}
               scrollMode={ScrollMode.Vertical}
               defaultScale={SpecialZoomLevel.PageFit}
            />
         </div>
      </Worker>
   )
}
