import FaqAccordion from 'Components/Accordion/FaqAccordion'
import { Footer } from 'Layouts/Footer/Footer'
import { useTranslation } from 'react-i18next'

/* eslint-disable @typescript-eslint/no-empty-interface */
interface FAQProps {}

const FAQ: React.FunctionComponent<FAQProps> = () => {
   const { t } = useTranslation()

   return (
      <>
         <div className='sm:container w-[96%] divide-x-2 divide-[#F5F5F5] flex flex-col flex-wrap gap-3 sm:flex-nowrap mx-auto border-2 p-4 my-6 rounded-md border-[#F5F5F5] lg:min-h-[85vh]'>
            <article className='px-4 lg:px-[10%] flex flex-col gap-5' role='article' id='post_879'>
               <h1 className='text-3xl text-black font-bold pt-6'>{t('Domande frequenti')}</h1>
               <h2 className='text-black font-bold'>
                  {t(
                     'Hai bisogno di aiuto? Probabilmente la risposta di cui hai bisogno è qui! Controlla tra le domande e risposte più frequenti'
                  )}.
               </h2>
               <FaqAccordion />
            </article>
         </div>
         <Footer />
      </>
   )
}

export default FAQ
