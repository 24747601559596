import React from 'react'
import { useTranslation } from 'react-i18next'
import { TAuthor } from 'Types/Models'
import { AuthorCard } from './Cards/AuthorCard'

export const AuthorsList = ({ data, title }: { data: TAuthor[]; title?: string }) => {
   const { t } = useTranslation()

   return (
      <div className='rounded-md bg-[#F2F2F2] flex flex-col gap-2 p-4 '>
         <span className='text-primary font-bold'>{title ? title : t('Board of Authors')}</span>
         <div className='flex flex-col gap-2'>
            {data
               .sort((authorA, authorB) => authorA.pivot.sort_order - authorB.pivot.sort_order)
               .map((author) => (
                  <AuthorCard
                     key={author.id}
                     title={author.name}
                     photo={author.image}
                     secondTitle={''}
                     defaultOpened={false}>
                     <p className='px-5 pb-5'>{author.description}</p>
                  </AuthorCard>
               ))}
         </div>
      </div>
   )
}
