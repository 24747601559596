import { useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { CourseCard } from 'Components/Cards/CourseCard/CourseCard'
import { Skeleton } from 'Components/Cards/CourseCard/Skeleton'
import CardsCarousel from 'Components/CardsCarousel'
import DetailsText from 'Components/DetailsText'
import SectionTitle from 'Components/SectionTitle'
import SmallBtn from 'Components/shared/SmallBtn'
import { userDataAtom } from 'Recoil/atoms'
import { NewTCourse } from 'Types/Models'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

const AllCourses = () => {
  const [userData] = useRecoilState(userDataAtom)

  const navigate = useNavigate()

  const { getAllCourses } = useApiRequests()

  const [isLargerThan768, isLargerThan900, isLargerThan1400, isLargerThan1600] = useMediaQuery([
    '(min-width: 768px)',
    '(min-width: 900px)',
    '(min-width: 1400px)',
    '(min-width: 1600px)',
  ])

  const [allCourses, setAllCourses] = useState<any[]>([])
  const [page, setPage] = useState(1)

  const { ref, inView } = useInView()

  const { data, isFetching } = useQuery(['allCourses', page], {
    queryFn: ({ queryKey }) => getAllCourses(+queryKey[1]),
    select: (res) => res.data.data,
    onSuccess: (res) => setAllCourses((prev) => [...prev, ...res.courses.data])
  })

  useEffect(() => {
    inView && setPage((prev) => prev + 1)
  }, [inView])

  return (
    <div className='pt-6'>
      <div className='pb-5'>
        <SectionTitle>Tutti i corsi</SectionTitle>
        {userData?.accessToken && (
          <>
            <div className='md:hidden'>
              <DetailsText>
                Scorri con il dito verso sinistra per vedere altri corsi
              </DetailsText>
            </div>
            <div className='hidden md:block !text-[25px] !font-normal !not-italic text-active font-nuinto mt-[22px] mb-[18px]'>
              La nostra completa selezione di corsi ECM
            </div>
          </>
        )}
      </div>

      {isLargerThan768 || userData?.plainTextToken ? (
        <>
          <CardsCarousel
            slidesToShow={
              isLargerThan1600 ? 4.25 : isLargerThan1400 ? 3.25 : isLargerThan900 ? 2.25 : 1.25
            }
            wrap={page === data?.courses.last_page}
          >
            {allCourses.map((course: NewTCourse) => {
              return <CourseCard key={course.id} {...course} />
            })}

            {isFetching && Array.from(Array(4).keys()).map((item) => <Skeleton key={item} />)}

            {page < data?.courses.last_page && !isFetching && <div ref={ref}>{''}</div>}
          </CardsCarousel>
          {userData?.plainTextToken && <div className='flex justify-end relative -top-8 z-10'>
            <SmallBtn onClick={() => navigate('/tutti-i-corsi')}>Vedi tutto</SmallBtn>
          </div>}
        </>
      ) : (
        <div className='flex flex-col gap-5'>
          {allCourses.map((course: NewTCourse) => {
            return <CourseCard key={course.id} {...course} />
          })}

          {isFetching && Array.from(Array(4).keys()).map((item) => <Skeleton key={item} />)}

          {page < data?.courses.last_page && !isFetching && <div ref={ref}>{''}</div>}
        </div>
      )}
    </div>
  )
}

export default AllCourses
