import WaveSurfer from 'wavesurfer.js'
import moment from 'moment'
import 'moment-duration-format'

let wavesurfer: WaveSurfer
export const initPlayer = (mp3: string) => {
   wavesurfer = WaveSurfer.create({
      container: '#waveform',
      barWidth: 1,
      backend: 'MediaElement',
      barMinHeight: 1,
      barHeight: 100,
      height: 90,
      barGap: 4,
      scrollParent: true,
      barRadius: 1,
      maxCanvasWidth: 100,
      progressColor: '#F3A84D',
      normalize: true,
      hideScrollbar: true,
      responsive: true
   })
   wavesurfer.load(mp3)

   return wavesurfer
}

export const playingTime = () => {
   return moment.duration(wavesurfer?.getDuration(), 'seconds').format('hh:*mm:ss')
}

export const audioDuration = () => {
   return moment.duration(wavesurfer?.getCurrentTime(), 'seconds').format('hh:*mm:ss')
}
