import { useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { CourseCard } from 'Components/Cards/CourseCard/CourseCard'
import { Skeleton } from 'Components/Cards/CourseCard/Skeleton'
// import FilterNSort from 'Components/FilterNSortHolder/FilterNSort'
import Filters from 'Components/Filters/Filters'
import Container from 'Components/shared/Container'
import SectionHeader from 'Components/shared/SectionHeader'
import { Footer } from 'Layouts/Footer/Footer'
import { defaultFilter, filterState, userDataAtom } from 'Recoil/atoms'
import { NewTCourse } from 'Types/Models'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useRecoilState } from 'recoil'

const ForYou = () => {
  const [isLargerThan768] = useMediaQuery(['(min-width: 768px)'])

  const [{ filter, keyword }, setFState] = useRecoilState(filterState)
  const [currentFilter, setCurrentFilter] = useState({
    filter,
    keyword,
  })

  const [userData] = useRecoilState(userDataAtom)

  const { getRecommendedCourses } = useApiRequests()

  const [allCourses, setAllCourses] = useState<any[]>([])
  const [page, setPage] = useState(1)

  const { ref, inView } = useInView()

  const { data, isFetching } = useQuery(['forYou', page, filter, keyword], {
    queryFn: ({ queryKey }) => {
      if (JSON.stringify({ filter, keyword }) !== JSON.stringify(currentFilter)) {
        setCurrentFilter({ filter, keyword })
        setPage(1)
        return getRecommendedCourses(
          1,
          { ...filter, keyword },
        )
      }

      if (queryKey[1]) {
        return getRecommendedCourses(
          page,
          { ...filter, keyword }
        )
      }

      return getRecommendedCourses(
        1,
        { ...filter, keyword }
      )

    },
    select: (res) => res.data.data,
    onSuccess: (res) => setAllCourses((prev) => [...prev, ...res.courses.data])
  })

  useEffect(() => {
    setPage(1)
    setAllCourses([])
  }, [filter, keyword])

  useEffect(() => {
    inView && setPage((prev) => prev + 1)
  }, [inView])

  const getCapitalizedName = () => {
    if (userData) {
      return userData?.user_data.first_name?.charAt(0).toUpperCase() + userData?.user_data.first_name?.slice(1)
    }
    return ''
  }

  useEffect(() => {
    return () => setFState(defaultFilter)
  }, [])

  return (
    <>
      <Container extraClasses='pb-8'>

        <div className='mt-[26px] md:mt-0 flex justify-end px-[15px] lg:px-0'>
          <Filters.Trigger />
        </div>

        <div className='pb-3 px-[15px] lg:px-0'>
          <div className='lg:px-0'>
            <SectionHeader
              title={
                isLargerThan768
                  ? `Corsi scelti per ${getCapitalizedName()}:`
                  : `Ciao ${getCapitalizedName()}!`
              }
              description='Selezione di corsi accreditati per la tua professione scelti da ecmclub'
            />
          </div>
        </div>

        <div className='px-[15px] lg:px-0 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-9 2xl:grid-cols-4'>
          {allCourses.map((course: NewTCourse) => {
            return <CourseCard key={course.id} {...course} />
          })}

          {isFetching && Array.from(Array(4).keys()).map((item) => <Skeleton key={item} />)}

          {page < data?.courses.last_page && !isFetching && <div ref={ref}>{''}</div>}
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default ForYou
