import { yupResolver } from '@hookform/resolvers/yup'
import { loginSchema } from 'Schemas/LoginSchema'
import { TLoginInputs } from 'Types/FormsTypes'
import { useApiRequests } from 'API/ApiRequests'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useToast } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAxiosInstance } from 'API/AxiosInstance'
import { useTranslation } from 'react-i18next'

export const useLogin = () => {
   const { loginApi } = useApiRequests()

   const { setAuthToken } = useAxiosInstance()

   const [, setUserData] = useRecoilState(userDataAtom)

   const location = useLocation()

   const { t } = useTranslation()

   const { mutate, isLoading } = useMutation({
      mutationFn: loginApi,
      onSuccess: (res) => {
         if (res) {
            setUserData(null)
            setAuthToken(res?.data.plainTextToken)
            setUserData(res?.data)
            const prevOfferta = localStorage.getItem('wasInOfferta')
            const prevCheckout = localStorage.getItem('wasInCheckout')

            if (res.data.accessToken.abilities[0] === 'update_profile') {
               navigate('/update-profile', { replace: true })
            } else {
               if (prevOfferta) {
                  window.location.href = prevOfferta
                  localStorage.removeItem('wasInOfferta')
               }

               if (prevCheckout) {
                  window.location.href = prevCheckout
                  localStorage.removeItem('wasInCheckout')
               }
            }

            if (res.data.accessToken.abilities[0] === 'make_consent') navigate('/consent')
            else location.state?.redirectTo ? navigate(-1) : navigate('/')
         }
      },
      onError: (error) => {
         if ((error as any)?.response.status === 404) {
            toast({
               status: 'error',
               title: t('No User Found'),
               isClosable: true
            })
         }
         if ((error as any)?.response.status === 401 || (error as any)?.response.status === 422) {
            toast({
               status: 'error',
               title: 'Ops, le credenziali inserite non sono corrette! Se non ti ricordi la password, clicca su "Recupera password?"',
               isClosable: true
            })
         }
         if (
            (error as any)?.response.status === 403 &&
            (error as any)?.response.data.error === 'not-verified'
         ) {
            toast({
               status: 'error',
               title: 'Il tuo account non è attivo! Clicca sulla mail che ti abbiamo inviato in fase di registrazione',
               isClosable: true
            })
         }
         if (
            (error as any)?.response.status === 403 &&
            (error as any)?.response?.data?.error === 'not-active'
         ) {
            toast({
               status: 'error',
               title: 'Ops, sembra che il tuo account non sia verificato. Contattaci all`indirizzo di customercare',
               isClosable: true
            })
         }
      }
   })

   const toast = useToast()

   const navigate = useNavigate()

   const {
      register,
      handleSubmit,
      formState: { errors }
   } = useForm<TLoginInputs>({
      resolver: yupResolver(loginSchema)
   })

   const onSubmit: SubmitHandler<TLoginInputs> = (data: TLoginInputs) => {
      // eslint-disable-next-line no-console
      // console.log(data)
      const formData = new FormData;
      formData.append('email', data.email)
      formData.append('password', data.password)
      mutate(formData)
      // mutate(data)
   }

   return { onSubmit, register, handleSubmit, isLoading, errors }
}
