import { Box } from '@chakra-ui/react'
import { FadeBox } from 'Components/FadeBox'
import React from 'react'

export const Skeleton = () => {
   return (
      <FadeBox>
         <div className='animate-pulse min-w-[300px] h-[240px] bg-slate-100 flex-col flex justify-between p-5 rounded-md'>
            <div className='flex items-center gap-3 w-full'>
               <Box flex='1' className='flex w-full items-start justify-between' textAlign='left'>
                  <div className='flex text-xs mb-4 gap-2 flex-col'>
                     <div className='rounded-lg bg-slate-300 h-5 w-20'></div>
                     <div className='rounded-lg bg-slate-300 h-3 w-16'></div>
                  </div>
                  <div className='rounded-lg bg-slate-300 h-8 w-8'></div>
               </Box>
            </div>
            <div className='rounded-lg bg-slate-300 h-4 w-full'></div>

            <div className='rounded-lg bg-slate-300 h-4 w-full'></div>

            <div className='rounded-lg bg-slate-300 h-4 w-full'></div>
         </div>
      </FadeBox>
   )
}
