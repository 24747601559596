import { useRecoilState } from 'recoil'
import { registerFormAtom } from 'Recoil/atoms'
import { FirstStep } from 'Components/Forms/Register/FirstStep'
import { SecondStep } from 'Components/Forms/Register/SecondStep'
import { LastStep } from 'Components/Forms/Register/LastStep'
import { FaRegUser, FaShieldAlt, FaStethoscope } from 'react-icons/fa'
import { SubmittedMessage } from 'Components/Forms/Register/SubmittedMessage'
import { Footer } from 'Layouts/Footer/Footer'

export const Register = ({ ignorePrivacyStep }: { ignorePrivacyStep?: boolean }) => {
   const [state] = useRecoilState(registerFormAtom)

   return (
      <div className='max-w-[1200px] my-8 px-4 mx-auto'>
         {!state.submitted && (
            <div className='flex justify-between container mx-auto mb-9'>
               <span className='text-3xl'>
                  {state.step === 1 && 'Anagrafica'}
                  {state.step === 2 && 'Dati personali'}
                  {state.step === 3 && !ignorePrivacyStep && 'Privacy'}
               </span>
               <div className='text-2xl flex gap-4 text-primary/30'>
                  <FaRegUser className={`${state.step === 1 && 'text-primaryLighter '} `} />
                  <FaStethoscope className={`${state.step === 2 && 'text-primaryLighter'} `} />

                  {!ignorePrivacyStep && (
                     <FaShieldAlt className={`${state.step === 3 && 'text-primaryLighter '} `} />
                  )}
               </div>
            </div>
         )}
         {state.step === 1 && <FirstStep />}
         {state.step === 2 && <SecondStep />}
         {state.step === 3 && <LastStep />}
         {state.submitted && <SubmittedMessage />}
         <div className='mt-14'>
            <Footer />
         </div>
      </div>
   )
}
