import { Button, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { changePasswordSchema } from 'Schemas/LoginSchema'

interface ChangePasswordFormValues {
   old_password: string
   password: string
   password_confirmation: string
}

export const ChangePassword = () => {
   const { changePassword } = useApiRequests()
   const { mutate, isLoading } = useMutation({ mutationFn: changePassword })
   const {
      register,
      handleSubmit,
      setError,
      formState: { errors }
   } = useForm<ChangePasswordFormValues>({
      resolver: yupResolver(changePasswordSchema)
   })

   const { t } = useTranslation()

   const toast = useToast()

   const onSubmit = (data: ChangePasswordFormValues) => {
      mutate(data, {
         onSuccess: (res) => {
            if (res) {
               toast({
                  title: t('Your password has been changed'),
                  status: 'success'
               })
            }
         },
         onError: () => {
            setError('old_password', { message: 'old password incorrect' })
         }
      })
   }

   return (
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2 pb-5 text-xs'>
         <b> {t('Change password')}</b>
         <p>{t('L’aggiornamento della password richiede un nuovo login')}</p>
         <div className='mt-2 flex flex-col'>
            <label className='mt-3'>{t('Old password')}</label>
            <input
               type={'password'}
               className='border-[#F5F5F5] placeholder:text-xs p-3 border-2 h-[41px] rounded-md focus:outline-gray outline-transparent transition-all'
               {...register('old_password')}
               placeholder={t('Old password') || ''}
            />
            {errors.old_password && <p className='text-red-500'>{errors.old_password.message}</p>}

            <label className='mt-3'>{t('New password')}</label>
            <input
               type={'password'}
               className='border-[#F5F5F5] placeholder:text-xs p-3 border-2 h-[41px] rounded-md focus:outline-gray outline-transparent transition-all'
               {...register('password')}
               placeholder={t('New password') || ''}
            />
            {errors.password && <p className='text-red-500'>{errors.password.message}</p>}

            <label className='mt-3'>{t('Repeat new password')}</label>
            <input
               type={'password'}
               className='border-[#F5F5F5] placeholder:text-xs p-3 border-2 h-[41px] rounded-md focus:outline-gray outline-transparent transition-all'
               {...register('password_confirmation')}
               placeholder={t('Repeat new password') || ''}
            />
            {errors.password_confirmation && (
               <p className='text-red-500'>{errors.password_confirmation.message}</p>
            )}
         </div>

         <Button
            type='submit'
            isLoading={isLoading}
            size={'xs'}
            height='44px'
            className='bg-transparent font-semibold mt-2 border-2 border-black rounded text-primary text-xs h-11 hover:bg-black/10 transition-all'>
            <span className='xl:text-xs  text-[10px]'>{t('Update password')}</span>{' '}
         </Button>
      </form>
   )
}
