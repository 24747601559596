const SubscriptionStyles = {
  FREE: {
    gradient:
      'bg-gradient-to-r from-[#AA5260FC] via-[#AA5260f2] to-[#AA526087]',
    text: 'text-free',
    bg: 'bg-free',
    border: 'border-free',
    card: ''
  },
  SEMI: {
    gradient: 'bg-gradient-to-r from-[#48987D] via-[#48987DF0] to-[#48987DA8]',
    text: 'text-semi',
    bg: 'bg-semi',
    border: 'border-semi'
  },
  PLUS: {
    gradient:
      'bg-gradient-to-r from-[#496492f2] via-[#496492F2] to-[#496492B8]',
    text: 'text-plus',
    bg: 'bg-plus',
    border: 'border-plus'
  },
  FULL: {
    gradient: 'bg-gradient-to-r from-[#F3A84D] via-[#F3A84DE0] to-[#F3A84DA1]',
    text: 'text-full',
    bg: 'bg-full',
    border: 'border-full'
  },
} as const;

export default SubscriptionStyles;