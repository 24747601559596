import { Button, useMediaQuery } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { moduileQuizAtom, selectedModuleAtom } from 'Recoil/atoms'

export const QuizFooter = ({ refetch }: { refetch: () => void }) => {
   const { t } = useTranslation()
   const [quizState, setQuizState] = useRecoilState(moduileQuizAtom)
   const [selectedModule] = useRecoilState(selectedModuleAtom)
   const { submitSurvey } = useApiRequests()
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const { mutate, isLoading } = useMutation({
      mutationFn: submitSurvey
   })
   const { courseId } = useParams()

   const queryClient = useQueryClient()

   const nextStep = () => {
      setQuizState({ ...quizState, step: quizState.step + 1 })
   }

   const prevStep = () => {
      setQuizState({ ...quizState, step: quizState.step - 1 })
   }

   const handleStartQuiz = async () => {
      refetch()
      // selectedModule && (await startQuiz(selectedModule?.id))
      setQuizState({ ...quizState, started: true })
   }

   const handleSubmit = () => {
      mutate(
         {
            survey_id: selectedModule?.id,
            questions: quizState.answers,
            course_id: courseId
         },
         {
            onSuccess: (res) => {
               setQuizState({ ...quizState, finished: true, passed: res.data.data.passed })
               queryClient.invalidateQueries({ queryKey: ['modules'] })
            }
         }
      )
   }

   return (
      <>
         <div className='flex items-center lg:shadow-3xl lg:border rounded-md lg:border-[#F5F5F5] justify-between lg:p-2 p-0'>
            <div className='lg:flex hidden w-1/6 justify-between'>
               {/* <span> {t('Tentativi')}</span>
               <span>
                  {(selectedModule as TQuiz).quiz_user.status.attempts}/
                  {(selectedModule as TQuiz).max_attempts}
               </span> */}
               {/* <span className='cursor-pointer' onClick={() => setshowAttemptsDrawer(true)}>
                  <svg
                     width='20'
                     height='20'
                     viewBox='0 0 20 20'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <path
                        d='M7.866 9.28704C7.48632 9.74799 6.80737 9.74799 6.38749 9.28704L2.81541 5.67174C2.39687 5.28761 2.39687 4.60071 2.81541 4.17591C3.23395 3.75563 3.9129 3.75563 4.33144 4.17591L7.14685 7.02748L13.5343 0.561966C13.9542 0.138524 14.6332 0.138524 15.0128 0.561966C15.4685 0.985408 15.4685 1.67232 15.0128 2.09576L7.866 9.28704ZM7.866 19.4099C7.48632 19.8708 6.80737 19.8708 6.38749 19.4099L0.31397 13.2639C-0.104657 12.8797 -0.104657 12.1928 0.31397 11.768C0.732552 11.3478 1.4115 11.3478 1.83004 11.768L7.14685 17.1503L18.1798 5.98356C18.5997 5.56328 19.2786 5.56328 19.6583 5.98356C20.1139 6.40836 20.1139 7.09526 19.6583 7.47939L7.866 19.4099Z'
                        fill='#335F6B'
                     />
                  </svg>
               </span> */}
            </div>

            {quizState.started ? (
               <div className='flex lg:justify-end justify-between w-full gap-3'>
                  <Button
                     h={'40px'}
                     visibility={quizState.step === 0 ? 'hidden' : 'visible'}
                     onClick={prevStep}
                     w={smallScreen ? '50%' : '200px'}
                     variant={smallScreen ? 'secondary' : 'primary'}>
                     {t('Previos')}
                  </Button>
                  {quizState.step === quizState.questionsList.length - 1 ? (
                     <Button
                        h={'40px'}
                        onClick={handleSubmit}
                        w={smallScreen ? '50%' : '300px'}
                        isLoading={isLoading}
                        disabled={Object.keys(quizState.answers).length < quizState.step + 1}
                        variant='secondary'>
                        INVIA IL QUESTIONARIO
                     </Button>
                  ) : (
                     <Button
                        disabled={Object.keys(quizState.answers).length < quizState.step + 1}
                        h={'40px'}
                        onClick={nextStep}
                        w={smallScreen ? '50%' : '200px'}
                        variant={smallScreen ? 'secondary' : 'primary'}>
                        SUCCESSIVA
                     </Button>
                  )}
               </div>
            ) : (
               <>
                  <Button
                     h={'40px'}
                     onClick={handleStartQuiz}
                     w={smallScreen ? '100%' : '330px'}
                     alignSelf='end'
                     size={'sm'}
                     variant={'secondary'}>
                     {t('START YOUR SURVEY')}
                  </Button>
               </>
            )}
         </div>
      </>
   )
}
