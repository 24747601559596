import { Button, useMediaQuery } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { useRecoilState } from 'recoil'
import { selectedModuleAtom, userDataAtom } from 'Recoil/atoms'
import { TModule } from 'Types/Models'
import 'video.js/dist/video-js.css'
import { Player } from './Players/VideoPlayer'

const VideoPlayer = () => {
   const [selectedModule, setselectedModule] = useRecoilState(selectedModuleAtom)
   const { finishModule } = useApiRequests()
   const { mutate } = useMutation({ mutationFn: finishModule })
   const queryClient = useQueryClient()
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const [userData] = useRecoilState(userDataAtom)

   const handleFinishVideo = () => {
      selectedModule &&
         mutate(selectedModule?.id, {
            onSuccess: () => {
               document.getElementById('modulesList')?.scrollBy(400, 400)
               smallScreen && setselectedModule(null)
               queryClient.invalidateQueries({ queryKey: ['modules'] })
            }
         })
   }

   const showButton =
      userData?.user_data.email.includes('@axenso.com') ||
      userData?.user_data.email === 'margherita.foscari@me.com' ||
      userData?.user_data.email === 'antoghi@gmail.com'

   return (
      <div data-vjs-player>
         <Player
            handleFinishVideo={handleFinishVideo}
            url={(selectedModule as TModule).content_url}
         />
         {showButton && <Button onClick={handleFinishVideo}>Complete</Button>}
      </div>
   )
}

export default VideoPlayer
