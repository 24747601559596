import { Skeleton } from 'Components/Cards/CollapsableCard/Skeleton'
import React from 'react'

export const ModulesListSkeleton = () => {
   return (
      <div className='flex flex-col gap-3 lg:max-w-[400px] pt-4 lg:p-0 w-[96%] sm:container  mx-auto overflow-y-auto  '>
         {Array.from(Array(10).keys()).map((key) => (
            <Skeleton key={key} />
         ))}
      </div>
   )
}
