import React from 'react'
import { FormControl, Tooltip, FormLabel, Input } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { TRegisterPersonalForm, TRegisterSecondStepForm } from 'Types/FormsTypes'

export const RegisterInput = ({
   label,
   placeholder,
   disabled,
   errorMessage,
   register,
   name,
   isDate,
   isPassword,
   required = false,
   defaultValue = '',
   infoText = ''
}: {
   disabled?: boolean
   required?: boolean
   label: string
   infoText?: string
   errorMessage?: string
   name: keyof TRegisterPersonalForm | keyof TRegisterSecondStepForm
   placeholder: string
   isDate?: boolean
   isPassword?: boolean
   defaultValue?: string
   register: any
}) => {
   const { t } = useTranslation()
   return (
      <FormControl isRequired={required}>
         <div className='flex justify-between'>
            <FormLabel className='text-dark-gray flex justify-between text-sm' fontSize={'14px'}>
               {t(`${label}`)}
            </FormLabel>
            {infoText && (
               <Tooltip label={infoText} bg='white' textColor={'black'}>
                  <span className='cursor-pointer'>
                     <AiOutlineInfoCircle className='text-2xl' />
                  </span>
               </Tooltip>
            )}
         </div>

         <Input
            defaultValue={defaultValue}
            type={
               (isDate && 'date') || (isPassword && 'password') || (!isDate && !isPassword && '')
            }
            {...register(`${name}`)}
            border={'3px solid'}
            borderColor={errorMessage ? 'red.400' : '#bbbbbb59'}
            height={'48px'}
            shadow='md'
            disabled={disabled}
            placeholder={t`${placeholder}` || ''}
         />
         {errorMessage &&
            errorMessage !== 'Questo campo è obbligatorio' &&
            name === 'fiscal_code' && (
               <div className='p-3 flex flex-col items-start gap-2 mt-2 rounded text-sm text-white bg-primary'>
                  Il codice fiscale non corrisponde ai dati inseriti, per favore fai un controllo.
                  Il CF è un dato fondamentale ai fini dell‘accreditamento ECM. In caso di omocodia,
                  clicca sul quadratino di seguito
                  {name === 'fiscal_code' && (
                     <div className='flex gap-3'>
                        <input id='fiscal-checkbox' type='checkbox' />
                        <span>Omocodia</span>
                     </div>
                  )}{' '}
               </div>
            )}
         {errorMessage && name !== 'fiscal_code' && (
            <div className='py-2 flex flex-col items-start gap-2 mt- rounded text-sm text-red-400 font-semibold'>
               {errorMessage}
            </div>
         )}

         {!errorMessage?.includes('codice fiscale') && errorMessage && name === 'fiscal_code' && (
            <div className='py-2 flex flex-col items-start gap-2 mt- rounded text-sm text-red-400 font-semibold'>
               {errorMessage}
            </div>
         )}
      </FormControl>
   )
}
