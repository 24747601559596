import { motion } from 'framer-motion'
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from 'react-icons/md'

export const Love = ({
   isLiked,
   handleToggleLike
}: {
   isLiked: boolean
   handleToggleLike: () => void
}) => {
   const variants = {
      open: { scale: [1, 2, 1.2, 1.1, 1] },
      closed: {}
   }
   return (
      <motion.button
         className='outline-none'
         animate={isLiked ? 'open' : 'closed'}
         variants={variants}>
         {isLiked ? (
            <MdOutlineFavorite className='text-[35px] md:text-[40px] text-rose-600' onClick={handleToggleLike} />
         ) : (
            <MdOutlineFavoriteBorder
               className='text-white text-[35px] md:text-[40px] hover:text-rose-600 transition-all'
               onClick={handleToggleLike}
            />
         )}
      </motion.button>
   )
}
