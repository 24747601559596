import ChakraCheckBox from '../ChakraCheckBox'
import { useRecoilState } from 'recoil'
import { filterState, userDataAtom } from 'Recoil/atoms'

const CourseFor = () => {
  const [state, setCState] = useRecoilState(filterState)
  const [userData] = useRecoilState(userDataAtom)

  return (
    <div className='flex flex-col gap-5'>
      <ChakraCheckBox
        isChecked={state.filter.for_you || false}
        onChange={() => {
          setCState((prev) => ({
            ...prev,
            filter: {
              ...prev.filter,
              for_you: state.filter.for_you ? !state.filter.for_you : true
            },
            hasFilter: true
          }))
        }}>
        Corsi accreditati per me
      </ChakraCheckBox>
      {userData?.user_data.subscription.type !== 1 && <ChakraCheckBox
        isChecked={state.filter.for_plus_users || false}
        onChange={() => {
          setCState((prev) => ({
            ...prev,
            filter: {
              ...prev.filter,
              for_plus_users: state.filter.for_plus_users
                ? !state.filter.for_plus_users
                : true
            },
            hasFilter: true
          }))
        }}>
        Corsi disponibili per utenti PLUS
      </ChakraCheckBox>}
    </div>
  )
}

export default CourseFor
