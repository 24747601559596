import React from 'react'

export const CourseDescriptionSkeleton = () => {
   return (
      <div className='flex flex-col gap-5  animate-pulse'>
         <div className='rounded-lg bg-slate-300 h-4 w-1/2'></div>
         <div className='rounded-lg bg-slate-300 h-3 w-full'></div>

         <div className='rounded-lg bg-slate-300 h-3 w-full'></div>

         <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
         <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
         <div className='rounded-lg bg-slate-300 h-3 w-[90%]'></div>
         <br />
         <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
         <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
         <div className='rounded-lg bg-slate-300 h-3 w-[90%]'></div>

         <br />
         <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
         <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
         <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
      </div>
   )
}
