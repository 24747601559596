import { useMediaQuery } from '@chakra-ui/react'
import { CollapsableCard } from 'Components/Cards/CollapsableCard/CollapsableCard'
import { useTranslation } from 'react-i18next'
import { RiErrorWarningLine } from 'react-icons/ri'
import { FadeBox } from './FadeBox'

export const ModuleAttention = () => {
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const { t } = useTranslation()

   return (
      <FadeBox>
         {smallScreen ? (
            <div className=''>
               <CollapsableCard bgColor='#F51D51' textColor='white' title='ATTENZIONE'>
                  <div className='text-white p-5 flex flex-col'>
                     <b>
                        {t('Il corso non è accreditato per la tua professione e specializzazione.')}
                     </b>
                     <p className='text-sm'>
                        {t(
                           'L’iscrizione al corso è consentita ma non potrai ottenere i crediti ECM perché non appartieni alle professioni e specializzazioni alle quali il corso è rivolto'
                        )}
                     </p>
                  </div>
               </CollapsableCard>
            </div>
         ) : (
            <div className='bg-[#F51D51] text-white gap-3 p-5 rounded-md mt-4 flex items-center'>
               <RiErrorWarningLine className='text-[40px]' />
               <div className='text-white flex flex-1 flex-col'>
                  <b>
                     {t('Il corso non è accreditato per la tua professione e specializzazione.')}
                  </b>
                  <p className='text-sm'>
                     {t(
                        'L’iscrizione al corso è consentita ma non potrai ottenere i crediti ECM perché non appartieni alle professioni e specializzazioni alle quali il corso è rivolto'
                     )}
                  </p>
               </div>
               {/* <VscError className='text-[40px]' /> */}
            </div>
         )}
      </FadeBox>
   )
}
