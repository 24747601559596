import { Skeleton } from 'Components/Cards/ModuleCard/Skeleton'
import { FadeBox } from 'Components/FadeBox'
import React from 'react'

export const CourseDetailsHeaderSkeleton = () => {
   return (
      <FadeBox>
         <div className='relative bg-slate-200 animate-pulse md:block flex rounded-md min-h-[192px] w-full '>
            <div className='rounded-md md:justify-between w-full justify-end flex flex-col gap-9 '>
               <div className='flex flex-col gap-5'>
                  <p className='md:text-white text-primary md:px-10 md:pt-10 px-3 font-semibold lg:text-4xl text-xl lg:w-[55%] w-full'>
                     <div className='rounded-lg bg-slate-300 h-4 w-full'></div>
                  </p>
                  <p className='lg:w-[55%] w-full md:text-white text-black md:pl-10 px-3 lg:text-xl text-sm leading-7'>
                     <div className='rounded-lg bg-slate-300 h-4 w-full'></div>
                  </p>
                  <p className='lg:w-[55%] w-full md:text-white text-black md:pl-10 px-3 lg:text-xl text-sm leading-7'>
                     <div className='rounded-lg bg-slate-300 h-4 w-full'></div>
                  </p>
                  <p className='lg:w-[50%] w-full md:text-white text-black md:pl-10 px-3 lg:text-xl text-sm leading-7'>
                     <div className='rounded-lg bg-slate-300 h-4 w-full'></div>
                  </p>
                  <p className='lg:w-[53%] w-full md:text-white text-black md:pl-10 px-3 lg:text-xl text-sm leading-7'>
                     <div className='rounded-lg bg-slate-300 h-4 w-full'></div>
                  </p>
                  <p className='lg:w-[55%] w-full md:text-white text-black md:pl-10 px-3 lg:text-xl text-sm leading-7'>
                     <div className='rounded-lg bg-slate-300 h-4 w-full'></div>
                  </p>
                  <p className='lg:w-[51%] w-full md:text-white text-black md:pl-10 px-3 lg:text-xl text-sm leading-7'>
                     <div className='rounded-lg bg-slate-300 h-4 w-full'></div>
                  </p>
               </div>{' '}
               <div className='md:flex hidden no-scrollbar px-10  min-h-[250px] gap-8 overflow-x-scroll'>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
               </div>
               <div className='flex justify-between gap-5 md:border-t-4 px-4 border-white items-center'>
                  <div className='h-[80px] flex md:w-[65%] overflow-x-scroll no-scrollbar w-full justify-between items-center md:divide-x divide-zinc-300/50'>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                     </div>
                     <div className='flex px-2 min-w-[110px] text-white text-xs h-full flex-col md:gap-2 gap-0.5 items-center justify-center w-full'>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                        <div className='rounded-lg bg-slate-300 h-3 w-full'></div>
                     </div>
                  </div>
                  <div className='rounded-lg bg-slate-300 w-[30%] h-[55px]'></div>

                  {/* {!smallScreen && (
                     <Button
                        onClick={toggleShowUpgradeModal}
                        height={'55px'}
                        variant={'primary'}
                        w='30%'>
                        <span className='text-xs lg:text-base'>{t('UPGRADE YOUR ACCOUNT')}</span>
                     </Button>
                  )} */}
               </div>
            </div>
         </div>
      </FadeBox>
   )
}
