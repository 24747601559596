import { Contacts } from 'Components/HomeTabs/Dashboard/Contacts'
import { Dashboard } from 'Components/HomeTabs/Dashboard/Dashboard'
import { Profile } from 'Components/HomeTabs/Profile/Profile'
import { Subscription } from 'Components/HomeTabs/Subscription/Subscription'
import { OldCert } from 'Components/OldCert'
import { Footer } from 'Layouts/Footer/Footer'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// OldCert
export default function Home() {
   const [selected, setselected] = useState('Dashboard')

   const homeTabs = {
      Dashboard: Dashboard,
      Profile: Profile,
      Acquisti: Subscription
   }

   const TabComponent = homeTabs[selected as keyof typeof homeTabs]

   const { t } = useTranslation()

   return (
      <>
         <div className='sm:container w-[96%]  divide-x-2 divide-[#F5F5F5] flex flex-wrap sm:flex-nowrap mx-auto border-2 mt-6 rounded-md border-[#F5F5F5] '>
            <div className='flex sm:w-[400px] w-full h-full flex-col sm:h-[96vh]'>
               <div className='flex-wrap w-full flex sm:flex-col flex-row gap-5 sm:justify-start justify-between text-xs p-4 flex-grow'>
                  {Object.keys(homeTabs).map((tab, i) => (
                     <span
                        key={i}
                        onClick={() => setselected(tab)}
                        className={`${selected === tab && 'font-bold'} cursor-pointer`}>
                        {tab === 'Dashboard' ? 'I miei corsi' : t(`${tab}`)}
                     </span>
                  ))}
               </div>
               <OldCert />
               <Contacts />
            </div>
            <div className='w-full h-full'>{selected && <TabComponent />}</div>
         </div>
         <div className='mt-14'>
            <Footer />
         </div>
      </>
   )
}
