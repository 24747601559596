import { Box } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FiLock, FiUnlock } from 'react-icons/fi'

export const ModuleCard = ({
   bgColor = '#F5F5F5',
   textColor = '#848484',
   title = '',
   secondTitle,
   description,
   secondDescription,
   isLocked,
   status
}: {
   bgColor?: string
   textColor?: string
   status: ReactNode
   title: string
   secondTitle?: string
   description?: string
   secondDescription?: string
   isLocked?: boolean
}) => {


   return (
      <div
         style={{ backgroundColor: bgColor, color: textColor }}
         className='min-w-[300px] h-[240px] flex-col flex justify-start gap-1 p-5 rounded-md'>
         <div className='flex items-start gap-5 w-full justify-between'>
            <div className='flex text-xs mb-4 flex-col'>
               <span className='font-bold line'> {title}</span>
               {secondTitle && <span className='text-xs font-normal'>{secondTitle}</span>}
            </div>
            <>
               {isLocked ? (
                  <FiLock className='text-2xl shrink-0' />
               ) : (
                  <FiUnlock className='text-2xl shrink-0' />
               )}
            </>
         </div>
         {description && (
            <Box className='text-[14px] w-full mb-4 text-left font-bold'>
               <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </Box>
         )}
         {secondDescription && (
            <Box className='text-[12px] flex-1 font-normal w-full text-left'>
               {secondDescription}
            </Box>
         )}
         <span className='text-[14px] w-full text-left font-bold'>{status}</span>
      </div>
   )
}
