/* eslint-disable react/no-unescaped-entities */
import { Button, Checkbox, FormControl, FormLabel } from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { registerFormAtom } from 'Recoil/atoms'
import { TRegisterLastStepForm } from 'Types/FormsTypes'

export const LastStep = () => {
   const { getSSOconfig, registerApi } = useApiRequests()
   const [state, setState] = useRecoilState(registerFormAtom)
   const [acceptTerms, setacceptTerms] = useState(true)
   const [ps, setPs] = useState('')

   const ssoConfig = useQuery(['ssoConfig'], {
      queryFn: getSSOconfig,
      select: (res) => res.data.data
   })
   useEffect(() => {
      fetch('/ps.txt')
         .then((r) => r.text())
         .then((text) => {
            setPs(text)
         })
   }, [])

   const mutation = useMutation({
      mutationFn: registerApi,
      onSuccess: () => setState({ ...state, submitted: true, step: 0 })
   })

   const { handleSubmit, register } = useForm<TRegisterLastStepForm>({})

   const onSubmit: SubmitHandler<TRegisterLastStepForm> = (data: TRegisterLastStepForm) => {
      if (!mutation.isLoading) {
         mutation.mutate({ ...state.firstStepData, ...state.secondStepData, ...data })
      }
   }

   return (
      <div className='flex flex-col container mx-auto'>
         <div className='flex gap-6 md:flex-row flex-col '>
            <div className='flex-col flex flex-grow'>
               <div className='flex justify-between mb-2 items-center'>
                  <span className='text-gray'>Privacy policy Xgate</span>
                  <a
                     target={'_blank'}
                     href={ssoConfig.data?.privacy_policy_page_url.config_value}
                     className='cursor-pointer text-xs text-gray'
                     rel='noreferrer'>
                     Leggi linformativa
                  </a>
               </div>
               <textarea
                  rows={6}
                  defaultValue={ssoConfig?.data?.privacy_policy_text.config_value}
                  className=' w-full pl-3 text-base rounded-md border-2  shadow-md
                  focus:shadow-sm border-[#bbbbbb59] outline-none duration-500 transition-all'
               />
            </div>
            <div className='flex-col flex flex-grow'>
               <div className='flex justify-between mb-2 items-center'>
                  <span className='text-gray'>Privacy policy Sito</span>
                  <Link
                     target={'_blank'}
                     to={'/privacy-policy'}
                     className='cursor-pointer text-xs text-gray'
                     rel='noreferrer'>
                     Leggi linformativa
                  </Link>{' '}
               </div>
               <textarea
                  rows={6}
                  defaultValue={ps}
                  className=' w-full pl-3 text-base rounded-md border-2  shadow-md
                  focus:shadow-sm border-[#bbbbbb59] outline-none duration-500 transition-all'
               />
            </div>
         </div>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 mt-5'>
               <FormControl isRequired>
                  <Checkbox onChange={(e) => setacceptTerms(e.target.checked)} defaultChecked>
                     <FormLabel mb={0} className='md:text-sm text-xs'>
                        Accetto{'  '}
                        <a href='termini-duso' className='text-primary' target={'_blank'}>
                           termini e condizioni d'uso{' '}
                        </a>
                     </FormLabel>
                  </Checkbox>
               </FormControl>
               <Checkbox {...register('data_marketing_consent')} defaultChecked>
                  <FormLabel className='md:text-sm text-xs'>
                     Esprimo il mio consenso all’invio da parte di Ecmclub di comunicazioni aventi
                     contenuti informativi, medico-scientifici o divulgativi utilizzando l’indirizzo
                     di posta elettronica fornito
                  </FormLabel>
               </Checkbox>
               <Checkbox {...register('data_transfer_consent')}>
                  <FormLabel className='md:text-sm text-xs'>
                     Esprimo il mio consenso alla raccolta di informazioni relative all’attività
                     professionale e ai relativi interessi/aree di interesse tese alla creazione di
                     profili nell’ambito medico-scientifico da sviluppare, fornire e migliorare i
                     servizi informativi e medico-scientifici offerti da Ecmclub e renderli più
                     rispondenti alle esigenze e interessi
                  </FormLabel>
               </Checkbox>
            </div>
            <div className='w-full justify-between flex mt-10'>
               <Button
                  onClick={() => setState({ ...state, step: 2 })}
                  variant={'primary'}
                  w='150px'
                  height={'55px'}>
                  Indietro
               </Button>
               <Button
                  disabled={!acceptTerms}
                  type='submit'
                  variant={'primary'}
                  w='190px'
                  height={'55px'}>
                  Invia iscrizione
               </Button>
            </div>
         </form>
      </div>
   )
}
