import Carousel from 'nuka-carousel'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'

const CardsCarousel = ({
  children,
  slidesToShow,
  wrap = false
}: {
  children: React.ReactNode
  slidesToShow: number
  wrap?: boolean
}) => {
  return (
    <Carousel
      renderBottomCenterControls={null}
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
      renderTopRightControls={({ previousSlide, nextSlide, previousDisabled, nextDisabled }) => (
        <div className='flex items-center gap-5 relative -top-10'>
          <MdOutlineKeyboardArrowLeft
            aria-disabled={previousDisabled}
            size={30}
            className={`${previousDisabled
              ? 'text-primary_root-light cursor-not-allowed pointer-events-none'
              : 'text-primary_root-dark hover:cursor-pointer'
              } hidden md:block`}
            onClick={previousSlide}
          />
          <MdOutlineKeyboardArrowRight
            aria-disabled={nextDisabled}
            size={30}
            className={`${nextDisabled
              ? 'text-primary_root-light cursor-not-allowed pointer-events-none'
              : 'text-primary_root-dark hover:cursor-pointer'
              } hidden md:block`}
            onClick={nextSlide}
          />
        </div>
      )}
      className='pb-10'
      slidesToShow={slidesToShow}
      wrapAround={wrap}
    // cellSpacing={20}
    >
      {children}
    </Carousel>
  )
}

export default CardsCarousel
