import { Button } from '@chakra-ui/button'
import { ConsentChannelModal } from 'Components/Modals/ConsentChannel'
import React from 'react'

export const ConsentChanel = ({ data }: any) => {
   const [isOpen, setIsOpen] = React.useState(false)
   return (
      <>
         <Button
            onClick={() => setIsOpen(true)}
            height={'55px'}
            variant={'primary'}
            size='xs'
            w='full'>
            <span className='text-sm'>VAI AL CORSO</span>
         </Button>
         {isOpen && (
            <ConsentChannelModal data={data} isOpen={isOpen} onClose={() => setIsOpen(false)} />
         )}
      </>
   )
}
