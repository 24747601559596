import SubscriptionStyles from 'Configs/SubscripationStyles'
import PlanTypes from 'Types/Subscripe'
import { t } from 'i18next'

const DetailsLabel = ({
  plan_name,
  completion_credits,
  expired,
  showPlanName = true
}: {
  plan_name: PlanTypes
  completion_credits?: string
  expired: boolean
  showPlanName?: boolean
}) => {
  return (
    <div className={`h-8 flex drop-shadow-lg font-saira ${expired && 'opacity-50'} `}>
      <span
        className={`${SubscriptionStyles[plan_name].text} bg-white flex items-center font-bold px-4`}>
        {completion_credits + ' ECM' || t('NO ECM')}
      </span>
      {showPlanName && (
        <span
          className={`text-white ${SubscriptionStyles[plan_name].bg}  flex items-center font-bold px-3`}>
          {plan_name}
        </span>
      )}
    </div>
  )
}

export default DetailsLabel
