import React, { useState } from 'react'
import { ChangePassword } from './ChangePassword'
import { FaRegUser, FaStethoscope } from 'react-icons/fa'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { FirstStep } from './FirstStep'
import { Spinner, useToast } from '@chakra-ui/react'
import { SecondStep } from './SecondStep'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'
import { useAxiosInstance } from 'API/AxiosInstance'

export const Profile = () => {
   const [step, setstep] = useState(1)

   const { pathname } = useLocation()

   const { getProfile, updateProfile } = useApiRequests()

   const [profileData, setprofileData] = useState<any>()

   const { isLoading } = useQuery(['profile'], {
      queryFn: getProfile,
      select: (res) => res.data?.profile,
      onSuccess: (data) => setprofileData(data)
   })

   const { setAuthToken } = useAxiosInstance()

   const toast = useToast()

   const navigate = useNavigate()

   const [, setUserData] = useRecoilState(userDataAtom)

   const updateProfileMutation = useMutation({ mutationFn: updateProfile })

   const storeDateInState = (data: any) => setprofileData({ ...profileData, ...data })
   const nextStep = () => setstep(step + 1)
   const prevStep = () => setstep(step - 1)

   const update = (data: any) => {
      let dataToSubmit = data
      if (pathname === '/update-profile') {
         dataToSubmit = { profile: dataToSubmit, renew_token: true }
      } else {
         dataToSubmit = { profile: dataToSubmit }
      }
      updateProfileMutation.mutate(dataToSubmit, {
         onSuccess: (data) => {
            if (data) {
               toast({
                  status: 'success',
                  title: 'Il tuo account è stato aggiornato correttamente'
               })
            }
            if (pathname === '/update-profile') {
               setUserData(data.data)
               setAuthToken(data.data.plainTextToken)
               const prevOfferta = localStorage.getItem('wasInOfferta')
               if (prevOfferta) {
                  window.location.href = prevOfferta
                  localStorage.removeItem('wasInOfferta')
               } else {
                  navigate('/dashboard')
               }
            }
         }
      })
   }

   return (
      <div className='flex divide-x-2 lg:flex-row h-full flex-col divide-[#F5F5F5]'>
         <div className='my-8 px-4 mx-auto w-full lg:w-[75%] overflow-y-auto'>
            {pathname === '/update-profile' && (
               <p className='py-4'>
                  <span className='font-semibold py-1'>Conferma dati personali</span>
                  <br /> Abbiamo aggiornato il nostro sistema di registrazione per essere sempre
                  all’altezza delle tue aspettative. Ti invitiamo a completare i dati di seguito e
                  continuare a utilizzare le nostre piattaforme :)
               </p>
            )}
            {!isLoading && profileData ? (
               <>
                  <div className='flex justify-between container overflow-y-scroll mx-auto mb-9'>
                     <span className='text-3xl'>
                        {step === 1 && 'Anagrafica'}
                        {step === 2 && 'Dati personali'}
                     </span>
                     <div className='text-2xl flex gap-4 text-primary/30'>
                        <FaRegUser className={`${step === 1 && 'text-primaryLighter '} `} />
                        <FaStethoscope className={`${step === 2 && 'text-primaryLighter'} `} />
                     </div>
                  </div>
                  {step === 1 && (
                     <FirstStep
                        stateData={profileData}
                        storeDateInState={storeDateInState}
                        nextStep={nextStep}
                        profileData={profileData}
                     />
                  )}
                  {step === 2 && (
                     <SecondStep
                        isUpdating={updateProfileMutation.isLoading}
                        storeDateInState={storeDateInState}
                        prevStep={prevStep}
                        profileData={profileData}
                        update={update}
                     />
                  )}
               </>
            ) : (
               <div className='w-full flex justify-center'>
                  <Spinner />
               </div>
            )}
         </div>
         {pathname !== '/update-profile' && (
            <div className='p-5 text-xs h-full lg:w-[25%] w-full space-y-4 flex flex-col'>
               {/* <NotificationsSettings /> */}
               <ChangePassword />
            </div>
         )}
      </div>
   )
}
