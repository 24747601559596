import { TSponsor } from 'Types/Models'

export const CoursePartners = ({
   data,
   sponsor_text
}: {
   data: TSponsor[]
   sponsor_text: string
}) => {

   return (
      <div className='border-t container mx-auto mt-5 md:flex hidden flex-col justify-center items-center pt-6 border-gray/20'>
         <p className='text-black/70 my-16 '>{sponsor_text}</p>
         <div className='flex justify-center gap-20 items-center'>
            {data.map((sponsor) => (
               <a target={'_blank'} key={sponsor.id} href={sponsor.link} rel='noreferrer'>
                  <img
                     className='w-[250px] aspect-square object-contain'
                     src={process.env.REACT_APP_MEDIA_URL + sponsor.logo}
                  />
               </a>
            ))}
         </div>
      </div>
   )
}
