import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import SubscriptionStyles from 'Configs/SubscripationStyles'
import { Footer } from 'Layouts/Footer/Footer'
import { NewTChannel } from 'Types/Models'
import { useState } from 'react'
import { AiOutlineInfo } from 'react-icons/ai'
import { IoIosReturnRight } from 'react-icons/io'
import { Link } from 'react-router-dom'

const ChannelCard = (ch: NewTChannel) => {
  const [toggleVisibility, setToggleVisibility] = useState(false)

  return (
    <Link
      to={ch.courses_count > 0 ? `/canali/${ch.id}` : ''}
      className='group relative col-span-1 rounded-[5px] h-[345px] px-10 py-10 overflow-hidden fix-highlight'
      style={{ boxShadow: '1px 1px 6px 0px rgba(0, 0, 0, 0.25)' }}>
      <div
        className={`md:group-hover:opacity-100 opacity-0 transition-all duration-300 z-10 absolute w-full h-full inset-0 flex items-center justify-center ${toggleVisibility ? '!opacity-100' : 'opacity-0'
          } ${SubscriptionStyles.FREE.bg}`}
        style={{
          backgroundColor: ch.color
        }}>
        <p
          dangerouslySetInnerHTML={{ __html: ch.highlight_text || ch.name }}
          className='font-saira-normal text-[30px] leading-[33px] text-center text-white p-4 !text-base'
        />
      </div>
      <span
        className={'w-[45px] h-[45px] rounded-full absolute bottom-[10px] right-[10px] lg:hidden text-white flex items-center justify-center text-xl z-20'}
        style={{
          backgroundColor: ch.color ? ch.color : 'red'
        }}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setToggleVisibility((prev) => !prev)
        }}>
        {!toggleVisibility ? <AiOutlineInfo /> : <IoIosReturnRight />}
      </span>
      <div className='flex items-center justify-center w-full h-full'>
        <img
          src={`${process.env.REACT_APP_MEDIA_URL}${ch.logo}`}
          className='w-full object-contain h-full'
          alt=''
        />
      </div>
    </Link>
  )
}

const Skeleton = () => {
  return (
    <div className='h-[345px] w-full bg-slate-300 transition-all animate-pulse duration-300' />
  )
}

const Canali = () => {
  const { getChannels } = useApiRequests()

  const [channels, setChannels] = useState<any[]>([])

  const { isFetching } = useQuery(['channels'], {
    queryFn: () => getChannels(),
    select: (res) => res.data.data,
    onSuccess: (res) => setChannels(res.channels)
  })
  return (
    <>
      <div className='px-[15px] lg:px-0 container mx-auto pb-8'>
        <div className='font-saira-normal text-active lg:text-center mt-[22px] lg:mt-[71px]'>
          <h2 className='text-[45px] lg:text-[100px] font-bold leading-[45px] lg:leading-[110px]'>
            Canali
          </h2>
          <p className='text-[23px] lg:text-[38px]'>
            I nostri canali attivi collegati direttamente con i nostri partner
          </p>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-[38px] mt-[30px]'>
          {channels.length > 0
            ? channels.map((ch: NewTChannel, i) => {
              return <ChannelCard key={i} {...ch} />
            })
            : ''}
          {isFetching && Array.from(Array(4).keys()).map((i) => <Skeleton key={i} />)}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Canali
