import React from 'react'
import { useTranslation } from 'react-i18next'

export const CourseDescription = ({ description }: { description: string }) => {
   const { t } = useTranslation()

   return (
      <div className='flex flex-col gap-5 '>
         <span className='text-primary font-semibold'>{t('Event description')}</span>

         <p dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
   )
}
