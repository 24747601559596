import { useMediaQuery } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { Timer } from 'Components/Timer'
import { useRecoilState } from 'recoil'
import { selectedModuleAtom } from 'Recoil/atoms'
import { TModule } from 'Types/Models'
import { PdfPlayer } from '../../../Players/PdfPlayer'
export const LessonPdfWindow = () => {
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const { finishModule } = useApiRequests()
   const { mutate } = useMutation({ mutationFn: finishModule })
   const [selectedModule, setselectedModule] = useRecoilState(selectedModuleAtom)
   const queryClient = useQueryClient()
   const handleFinishVideo = () => {
      selectedModule &&
         mutate(selectedModule?.id, {
            onSuccess: () => {
               document.getElementById('modulesList')?.scrollBy(400, 400)
               smallScreen && setselectedModule(null)
            }
         })
      queryClient.invalidateQueries({ queryKey: ['modules'] })
   }

   return (
      <div className='w-full pt-6 lg:p-0 flex flex-col gap-4'>
         <div className='flex gap-5 items-start'>
            {smallScreen && <span className='text-xs font-bold mb-2 text-secondary pl-5'>PDF</span>}
            {smallScreen && <Timer />}
         </div>
         <PdfPlayer
            handleFinishVideo={handleFinishVideo}
            url={
               (selectedModule as TModule).content_url ||
               `${process.env.REACT_APP_MEDIA_URL + (selectedModule as TModule).content}`
            }
         />
         {/* {data?.user_data.email.includes('@axenso.com') && (
            <Button onClick={handleFinishVideo}>{t('Complete')}</Button>
         )}{' '} */}
      </div>
   )
}
