import { Spinner } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { useNavigate } from 'react-router-dom'

const PlansCheckout = ({ courseId }: { courseId?: string }) => {
   const { getPlans } = useApiRequests()

   const { data, isLoading } = useQuery(['planss', courseId], {
      queryFn: () => getPlans(courseId),
      select: (data) => data.data,
      onSuccess: (res) => {
         if (res.user_data?.subscription.type_text === 'PLUS') {
            navigate('/corsi-plus')
         }
      }
   })

   const navigate = useNavigate()

   if (isLoading)
      return (
         <div className='flex w-full justify-center h-[50vh]'>
            <Spinner alignSelf={'center'} />
         </div>
      )

   if (!data) return <></>

   return (
      <div className='max-w-2xl mx-auto font-nuinto px-2'>
         <div className='bg-white inline-flex flex-col items-center text-center w-full overflow-clip rounded-[5px]'>
            <div
               className={
                  'w-full gap-5 flex flex-col items-center self-stretch p-5 text-black font-normal font-[\'Montserrat\']'
               }>
               <div className='w-20 h-20'>
                  <svg
                     width='100%'
                     height='100%'
                     preserveAspectRatio='none'
                     viewBox='0 0 80 80'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <path
                        d='M 40.04 80 C 18 80 0.08 62.06 0.08 40 C 0.08 37.68 1.96 35.8 4.28 35.8 C 6.6 35.8 8.48 37.68 8.48 40 C 8.48 57.42 22.64 71.58 40.04 71.58 C 57.44 71.58 71.6 57.42 71.6 40 C 71.6 22.58 57.44 8.42 40.04 8.42 C 37.72 8.42 35.84 6.54 35.84 4.22 C 35.84 1.9 37.72 0 40.04 0 C 62.08 0 80 17.94 80 40 C 80 62.06 62.08 80 40.04 80 Z'
                        fill='#496492'
                     />
                     <path
                        d='M 26.88 11.38 H 19.74 V 4.22 C 19.74 1.9 17.86 0.02 15.54 0.02 C 13.22 0.02 11.34 1.9 11.34 4.22 V 11.38 H 4.2 C 1.88 11.38 0 13.26 0 15.58 C 0 17.9 1.88 19.78 4.2 19.78 H 11.34 V 26.94 C 11.34 29.26 13.22 31.14 15.54 31.14 C 17.86 31.14 19.74 29.26 19.74 26.94 V 19.78 H 26.88 C 29.2 19.78 31.08 17.9 31.08 15.58 C 31.08 13.26 29.2 11.38 26.88 11.38 Z'
                        fill='#AA5260'
                     />
                     <path
                        d='M 40.039 65.08 C 29.779 65.08 20.679 58.92 16.839 49.36 C 15.979 47.2 17.019 44.76 19.159 43.88 C 21.299 43.02 23.759 44.06 24.619 46.2 C 27.179 52.54 33.219 56.64 40.039 56.64 C 46.859 56.64 52.899 52.54 55.459 46.2 C 56.319 44.04 58.779 43 60.919 43.86 C 63.059 44.72 64.099 47.18 63.239 49.34 C 59.399 58.88 50.299 65.06 40.039 65.06 V 65.08 Z'
                        fill='#496492'
                     />
                  </svg>
               </div>
               <p className='w-full text-base leading-normal m-0'>
                  Diventando utente PLUS potrai accedere all’intero catalogo di corsi FAD, eventi in
                  live streaming e iniziative dedicate per tutta la durata dell’abbonamento
               </p>
            </div>
            <div
               className={
                  'w-full gap-5 flex flex-col items-center self-stretch p-5 bg-[rgba(248,248,248,1)] overflow-clip rounded-[5px] font-[\'Nunito\']'
               }>
               <div className='w-full leading-none relative text-[rgba(73,100,146,1)]'>
                  <p className='font-bold leading-normal inline m-0 text-[32px]'>
                     Diventa un utente PLUS
                     <br />
                  </p>
                  <p className='text-xs font-normal leading-normal inline m-0'>
                     {'L’abbonamento dà diritto ad accedere a tutti i corsi PLUS '}
                     <br />
                  </p>
                  <p className='text-xs font-normal leading-normal inline m-0'>
                     per 12 mesi dalla data di sottoscrizione
                  </p>
               </div>
               <div className='w-full flex flex-col items-center self-stretch gap-[5px]'>
                  <p className='font-bold m-0 h-[45px] md:w-[575px] font-nuinto text-[40px] leading-[1.2] text-[rgba(73,100,146,1)]'>
                     {data.data[0]?.price}€
                  </p>
                  <p className='w-full text-xs font-normal m-0 leading-[1.2] text-[rgba(132,132,132,1)]'>
                     IVA INCLUSA
                  </p>
                  <div
                     className='text-[#AA5260]'
                     dangerouslySetInnerHTML={{ __html: data.data[0]?.description }}
                  />
               </div>
               <a
                  href={'/checkout?mode=1'}
                  className='text-white font-bold flex justify-center items-center text-[15px] leading-[1.2] bg-[rgba(73,100,146,1)] rounded-[5px] md:w-[575px] w-[250px] mx-auto h-[51px]'>
                  PROCEDI CON L’ACQUISTO{' '}
               </a>
               <p className='w-full text-xs font-normal leading-normal text-black m-0'>
                  In caso di dubbi o chiarimenti contatta il nostro customercare allo 02 36692 890
               </p>
            </div>
         </div>
         {/* <div className='flex justify-center items-center gap-8 flex-col pb-4'>
            <img src={subscriptionImage} className='w-20' />
            <div className='text-center gap-4 flex text-black font-normal flex-col items-center justify-center'>
               <span className='text-xl text-blueDark font-semibold'>
                  {t('Hai selezionato un corso riservato agli utenti PLUS')}
               </span>
               <span>
                  {t(
                     'Con Ecmclub PLUS potrai accedere all’intero catalogo di corsi ECM e eventi in live streaming per tutta la durata dell’abbonamento e avrai inoltre la possibilità di acquistare, con sconti speciali, l’accesso a iniziative dedicate'
                  )}
               </span>
               <b className='text-base'>
                  {t(
                     'Per diventare utente plus puoi scegliere la forma di adesione preferita, mensile o annuale.'
                  )}
               </b>
            </div>
         </div>
         <div className='flex gap-1 w-full justify-center'>
            <Tabs variant='soft-rounded'>
               <TabList marginX={'auto'} w={'fit-content'} bgColor='#e5e5e5' rounded='5px'>
                  {data?.map(({ name, slug }: any) => (
                     <Tab
                        rounded={'5px'}
                        _selected={{
                           bgColor: slug === 'ecm-yearly' ? '#496492' : '#AA5260',
                           color: 'white'
                        }}
                        color='#AA5260'
                        bgColor='#e5e5e5'
                        paddingX='16'
                        fontWeight='bold'
                        key={name}>
                        {name}
                     </Tab>
                  ))}
               </TabList>
               <TabPanels>
                  {data?.map((plan: any) => (
                     <TabPanel key={plan.id}>
                        <div className='flex flex-col items-center gap-4 my-5'>
                           <h3
                              className={`text-[40px] font-bold ${
                                 plan.slug === 'ecm-yearly' ? 'text-[#496492]' : 'text-[#AA5260]'
                              }`}>
                              {plan?.price}€
                           </h3>
                           <p
                              className='text-sm'
                              dangerouslySetInnerHTML={{ __html: plan?.description }}
                           />
                           <a
                              className={`${
                                 plan.slug === 'ecm-yearly' ? 'bg-[#496492]' : 'bg-[#AA5260]'
                              } h-[51px] w-full flex justify-center items-center rounded-lg`}
                              href={plan?.checkout_url}>
                              <span className='text-[15px] font-bold text-white'>
                                 {t('Abbonati')}
                              </span>
                           </a>
                        </div>
                     </TabPanel>
                  ))}
               </TabPanels>
            </Tabs>
         </div>
         <div className='text-xs text-center text-black font-normal flex justify-center'>
            <p className='w-2/4'>
               {t(
                  'Per informazioni o chiarimenti contatta il nostro customercare allo 02 36692890'
               )}
            </p>
         </div> */}
      </div>
   )
}

export default PlansCheckout
