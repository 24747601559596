/* eslint-disable no-console */
import { useMediaQuery } from '@chakra-ui/react'
import { ModulesList } from 'Components/Lists/MoudulesList/ModulesList'
import { CourseCompletedModal } from 'Components/Modals/CourseCompletedModal'
import { ModuleTopBar } from 'Components/ModuleTopBar'
import OnlyOneTab from 'Components/OnlyOneTab'
import { LessonWindow } from 'Components/Windows/LessonWindow/LessonWindow'
import { useGetModules } from 'Hooks/useGetModules'
import { useEffect, useState } from 'react'

import { useRecoilState } from 'recoil'
import { selectedCourseAtom, selectedModuleAtom } from 'Recoil/atoms'
import { v4 as uuidv4 } from 'uuid'
import { ModalWrapper } from 'Components/Modals/ModalWrapper'
export const Module = () => {
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const [isMobile] = useMediaQuery('(max-width: 575px)')
   const [selectedLesson] = useRecoilState(selectedModuleAtom)

   const [selectedCourse, setSelectedCourse] = useRecoilState(selectedCourseAtom)

   const {
      modules,
      isLoading,
      totalHours,
      userCompletedHour,
      servey,
      isLiked,
      likesCount,
      courseProgres,
      canComment,
      commentsCount,
      courseCompleted,
      setCourseCompleted,
      moudleInfo,
      isNotAvailable
   } = useGetModules()

   const newKey = uuidv4()
   const [isLocked, setisLocked] = useState(selectedCourse?.insights?.is_locked)

   useEffect(() => {
      return () => {
         setSelectedCourse(null)
      }
   }, [])

   useEffect(() => {
      setisLocked(selectedCourse?.insights?.is_locked)
   }, [selectedCourse?.insights?.is_locked])

   if (isNotAvailable) {
      return (
         <div className='p-[30px] bg-white rounded shadow w-full flex items-center border border-neutral-100 flex-col justify-start gap-5 max-w-xl mx-auto mt-32'>
            <div className='flex-col justify-start items-start gap-[30px] flex'>
               <div className='flex-col justify-start items-start gap-[30px] flex'>
                  <div className='text-center text-slate-500 text-[28px] font-bold'>
                     Il corso si è concluso
                  </div>
               </div>
            </div>
            <div className='text-center text-black text-[15px] font-normal'>
               Il corso si è chiuso in data {isNotAvailable}.
            </div>
            <a
               href='/dashboard'
               className='flex-grow-0 h-11 flex items-center justify-center hover:font-semibold transition-all cursor-pointer flex-shrink-0 w-full text-[15px] text-center bg-[#496492] text-white rounded'>
               Vai ai tuoi corsi
            </a>
         </div>
      )
   }

   return (
      <OnlyOneTab id={newKey}>
         <ModalWrapper
            size={isMobile ? 'sm' : 'md'}
            color='white'
            isOpen={isLocked}
            onClose={() => setisLocked(false)}>
            <div className='flex flex-col justify-start items-start relative gap-[30px] p-[30px] rounded-[5px] bg-white border border-neutral-100'>
               <div className='flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 gap-[30px]'>
                  <div className='flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-[30px]'>
                     <p className='flex-grow-0 flex-shrink-0 sm:w-[400px] w-[300px] text-[28px] font-bold text-center text-[#f51d51]'>
                        Hai raggiunto il limite di tentativi disponibili
                     </p>
                  </div>
               </div>
               <p className='flex-grow-0 flex-shrink-0 sm:w-[400px] w-[300px] text-[15px] text-center text-black'>
                  <span className='flex-grow-0 flex-shrink-0 sm:w-[400px] w-[300px] text-[15px] text-center text-black'>
                     In accordo con le regole AGENAS ogni questionario prevede al massimo{' '}
                  </span>

                  <span className='flex-grow-0 font-bold flex-shrink-0 sm:w-[400px] w-[300px] text-[15px] text-center text-black'>
                     {selectedCourse?.insights?.user_maxed_attempts}{' '}
                  </span>

                  <span className='flex-grow-0 flex-shrink-0 sm:w-[400px] w-[300px] text-[15px] text-center text-black'>
                     tentativi, oltre ai quali non è piu possibile procedere con il corso
                  </span>
               </p>
               <a
                  href='/'
                  className='flex-grow-0 hover:font-semibold transition-all cursor-pointer flex-shrink-0 sm:w-[400px] w-[300px] text-[15px] text-center text-[#496492]'>
                  TORNA ALL’ELENCO CORSI
               </a>
            </div>
         </ModalWrapper>

         <div className='lg:container mx-auto w-full h-screen lg:h-[80vh]'>
            {!smallScreen && (
               <ModuleTopBar
                  moudleInfo={moudleInfo}
                  canComment={canComment}
                  commentsCount={commentsCount}
                  progress={courseProgres}
                  likesCount={likesCount}
                  isLiked={isLiked}
                  totalHours={totalHours}
                  userCompletedHour={userCompletedHour}
               />
            )}
            {/* {smallScreen ? (
               <div className='flex sm:gap-4 flex-col lg:flex-row gap-0'>
                  <>
                     {selectedLesson ? (
                        <LessonWindow />
                     ) : (
                        <ModulesList servey={servey} modules={modules} isLoading={isLoading} />
                     )}
                  </>
               </div>
            ) : (
               <div className='flex sm:gap-4 pb-3 flex-col lg:h-[80vh] lg:flex-row gap-0'>
                  <ModulesList servey={servey} modules={modules} isLoading={isLoading} />
                  <LessonWindow />
               </div>
            )} */}
            <div
               className={
                  smallScreen
                     ? 'flex sm:gap-4 flex-col lg:flex-row gap-0 '
                     : 'flex sm:gap-4 pb-3 flex-col lg:h-[80vh] lg:flex-row-reverse gap-0'
               }>
               <div className={`${smallScreen && !selectedLesson && 'hidden'} w-full`}>
                  <LessonWindow />
               </div>

               <div className={`${smallScreen && selectedLesson && 'hidden'}`}>
                  <ModulesList servey={servey} modules={modules} isLoading={isLoading} />
               </div>
            </div>
         </div>
         <CourseCompletedModal isOpen={courseCompleted} onClose={() => setCourseCompleted(false)} />
      </OnlyOneTab>
   )
}
