/* eslint-disable react/no-unescaped-entities */
import { Footer } from 'Layouts/Footer/Footer'

/* eslint-disable @typescript-eslint/no-empty-interface */
interface PrivacyPolicyProps {}

const PrivacyPolicy: React.FunctionComponent<PrivacyPolicyProps> = () => {
   return (
      <>
         <div className='sm:container w-[96%] divide-x-2 divide-[#F5F5F5] flex flex-col flex-wrap gap-3 sm:flex-nowrap mx-auto border-2 p-4 my-6 rounded-md border-[#F5F5F5] lg:min-h-[85vh]'>
            <article className='px-4 lg:px-[10%] flex flex-col gap-5' role='article' id='post_879'>
               <h1 className='text-3xl text-black font-bold py-6'>Privacy Policy</h1>
               <br />
               Ai sensi del regolamento UE 679/2016
               <br />
               e del D.LGS. 196/2003 così come modificato dal D.LGS. 101/2018
               <br />
               <br />
               TITOLARE DEL TRATTAMENTO
               <br />
               A seguito della consultazione di questo sito possono essere trattati dati relativi a
               persone identificate o identificabili. Il Titolare del loro trattamento è Ecmclub
               S.r.l., con sede in via Gallarate 106, 20151 Milano (MI), p. iva 12066410965.
               <br />
               <br />
               FINALITÀ DEL TRATTAMENTO
               <br />
               Le finalità per le quali i dati vengono raccolti sono le seguenti:
               <br />
               a) Adesione agli specifici servizi offerti dal Titolare del trattamento
               <br />
               b) Invio di informazioni medico-scientifiche o attinenti (es. congressi, seminari,
               convegni);
               <br />
               c) Invio di pubblicazioni medico-scientifiche;
               <br />
               d) Ricerche di mercato e analisi statistiche, con dati anonimi ed aggregati;
               <br />
               e) Adempiere agli obblighi previsti dalla legge, da un regolamento, dalla normativa
               comunitaria o da un ordine dell’Autorità (come ad esempio obblighi contabili e
               dichiarativi fiscali);
               <br />
               f) Esercitare i diritti del Titolare, ad esempio il diritto di difesa in giudizio.
               <br />
               Il Titolare tratterà i dati personali per il tempo necessario per adempiere a tale
               finalità e comunque per non oltre 10 anni dalla cessazione del servizio.
               <br />
               <br />
               BASE GIURIDICA DEL TRATTAMENTO
               <br />
               Il trattamento è lecito in base alle seguenti condizioni:
               <br />- Ai sensi dell’art. 6, comma 1, lett. a) Regolamento UE 679/2016, il
               trattamento è lecito se l’interessato ha espresso il consenso al trattamento dei
               propri dati personali per una o più specifiche finalità.
               <br />- Ai sensi dell’art. 6, comma 1, lett. b) Regolamento UE 679/2016, il
               trattamento è necessario all’esecuzione di un contratto di cui l’interessato è parte
               o all’esecuzione di misure precontrattuali adottate su richiesta dello stesso;
               <br />- Ai sensi dell’art. 6, comma 1, lett. c) Regolamento UE 679/2016, il
               trattamento è necessario per adempiere un obbligo legale al quale è soggetto il
               titolare del trattamento;
               <br />- Ai sensi dell’art. 6, comma 1, lett. f) Regolamento UE 679/2016, il
               trattamento è necessario per il perseguimento del legittimo interesse del titolare
               del trattamento o di terzi, a condizione che non prevalgano gli interessi o i diritti
               e le libertà fondamentali dell’interessato che richiedono la protezione dei dati
               personali, in particolare se l’interessato è un minore. Il legittimo interesse del
               Titolare è connesso alla gestione dei dati personali per scopi promozionali e di
               marketing, nonché per fornire riscontro alle richieste a lui inoltrate.
               <br />
               <br />
               LUOGO DI TRATTAMENTO DEI DATI
               <br />
               I trattamenti connessi ai servizi web di questo sito hanno luogo presso la predetta
               sede di via Gallarate 106, 20151 Milano (MI) e sono curati solo da personale
               autorizzato al trattamento.
               <br />
               <br />
               TIPI DI DATI TRATTATI
               <br />
               Vengono trattate le seguenti tipologie di dati:
               <br />• Dati di navigazione.
               <br />
               I sistemi informatici e le procedure software preposte al funzionamento di questo
               sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la
               cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si
               tratta di informazioni che non sono raccolte per essere associate a interessati
               identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed
               associazioni con dati detenuti da terzi, permettere di identificare gli utenti. In
               questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer
               utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI
               (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il
               metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file
               ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal
               server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e
               all'ambiente informatico dell'utente. Questi dati vengono utilizzati al solo fine di
               ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il
               corretto funzionamento e vengono cancellati immediatamente dopo l'elaborazione. I
               dati potrebbero essere utilizzati per l'accertamento di responsabilità in caso di
               ipotetici reati informatici ai danni del sito: salva questa eventualità, allo stato i
               dati sui contatti web non persistono per più di sette giorni (salvo eventuali
               necessità di accertamento di reati da parte dell’Autorità giudiziaria). <br />
               • Dati forniti volontariamente dall’utente.
               <br />
               L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi
               indicati su questo sito comporta la successiva acquisizione dell'indirizzo del
               mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati
               personali inseriti nella missiva. Specifiche informative di sintesi verranno
               progressivamente riportate o visualizzate nelle pagine del sito predisposte per
               particolari servizi a richiesta. <br />
               • Cookies.
               <br />
               Nessun dato personale degli utenti viene in proposito acquisito dal sito. Non viene
               fatto uso di cookies per la trasmissione di informazioni di carattere personale, né
               vengono utilizzati c.d. cookies persistenti di alcun tipo, ovvero sistemi per il
               tracciamento degli utenti. L'uso di c.d. cookies di sessione (che non vengono
               memorizzati in modo persistente sul computer dell'utente e svaniscono con la chiusura
               del browser) è strettamente limitato alla trasmissione di identificativi di sessione
               (costituiti da numeri casuali generati dal server) necessari per consentire
               l'esplorazione sicura ed efficiente del sito. I c.d. cookies di sessione utilizzati
               in questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente
               pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono
               l'acquisizione di dati personali identificativi dell'utente. <br />
               <br />
               MODALITÀ DEL TRATTAMENTO
               <br />
               I dati personali sono trattati con strumenti automatizzati per il tempo strettamente
               necessario a conseguire gli scopi per cui sono stati raccolti. Specifiche misure di
               sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non
               corretti e accessi non autorizzati.
               <br />
               <br />
               DIRITTI DEGLI INTERESSATI
               <br />
               Ai sensi nella normativa vigente, l’interessato potrà far valere i propri diritti
               verso il Titolare del trattamento, come espressi dall’art. 15 e ss. del Regolamento
               UE 679/2016.
               <br />
               <br />
               TITOLARE, EVENTUALE DPO E COMUNICAZIONI PRIVACY
               <br />
               Il Titolare è Ecmclub S.r.l., p. iva 12066410965.
               <br />
               Per ogni comunicazione ai sensi degli articoli sopra riportati del Regolamento UE
               679/2016, il Titolare mette a disposizione l’indirizzo Via Gallarate 106, 20151
               Milano (MI); telefono 02 36692890; email privacy@ecmclub.org
            </article>
         </div>
         <Footer />
      </>
   )
}

export default PrivacyPolicy
