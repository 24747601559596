import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { selectedModuleAtom } from 'Recoil/atoms'

export const QuizInormation = () => {
   const { t } = useTranslation()

   const [selectedModule] = useRecoilState(selectedModuleAtom)

   return (
      <div className='py-5 w-full flex-col lg:bg-[#F8F8F8] h-[80%] flex gap-5'>
         <h3 className='lg:text-primary text-white sm:text-[20px] font-semibold'>
            {selectedModule?.title}
         </h3>
         <p className='lg:text-base text-xs leading-5 text-white lg:text-black'>
            {selectedModule?.description}
         </p>
         <div className='sm:w-2/3 w-full'>
            <ul className='pl-4 flex flex-col gap-3'>
               <li className='list-disc text-white lg:text-black  lg:text-sm text-xs'>
                  {t('Tentativi sostenuti')} {(selectedModule as any).user_attempts_count}
               </li>
               <li className='list-disc text-white lg:text-black  lg:text-sm text-xs'>
                  {t('Tentativi a Disposizione')}{' '}
                  {(selectedModule as any).max_attempts -
                     (selectedModule as any).user_attempts_count}
               </li>
               <li className='list-disc text-white lg:text-black  lg:text-sm text-xs'>
                  {t('Il quiz contiene')} {(selectedModule as any).question_count} {t('domande')}
               </li>
               <li className='list-disc text-white lg:text-black  lg:text-sm text-xs'>
                  {t('percentage of correct answers to pass the quiz')}{' '}
                  {(selectedModule as any).min_score}
               </li>
               <li className='list-disc text-white lg:text-black  lg:text-sm text-xs'>
                  {t('In caso di test non superato sarai reindirizzato al modulo precedente')}{' '}
               </li>
               <li className='list-disc text-white lg:text-black  lg:text-sm text-xs'>
                  {t(
                     'Una volta inviato il test non è possibile modificare le risposte, è richiesto un nuovo tentativo. Ricorda pertanto di controllare le risposte prima di inviarle'
                  )}{' '}
               </li>
            </ul>
         </div>
      </div>
   )
}
