import { FadeBox } from "Components/FadeBox"
import { useState } from "react"
// import { useTranslation } from 'react-i18next'
import moment from "moment"
import { Link } from "react-router-dom"
import { NewTCourse, TUserCourses } from "Types/Models"
import { ClapsSVG } from "./ClapsSVG"
import { EndDateSVG } from "./EndDateSVG"
import { MembersSVG } from "./MembersSVG"
import { StartDateSvg } from "./StartDateSvg"
import { Love } from "Components/Buttons/Love"
import { useApiRequests } from "API/ApiRequests"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GoToDetailsButton } from "./GoToDetailsButton"
import DetailsLabel from "../../DetailsLabel"
import PlanTypes from "Types/Subscripe"
import { useRecoilState } from "recoil"
import { userDataAtom } from "Recoil/atoms"

export function CourseCard({
   cover,
   abstract_description,
   title,
   likes_count,
   id,
   channel_name,
   is_favourite,
   // is_liked,
   subscribers_count,
   slug,
   // expired,
   // coming_soon,
   plan_name,
   plan_id,
   start_date,
   end_date,
   completion_credits,
   max_subscribers,
   full_plan_price,
   plus_user_price,
   discount,
   discount_label,
}: NewTCourse | TUserCourses) {
   // const { t } = useTranslation()
   const [userData] = useRecoilState(userDataAtom)

   // ! Temporary
   const expired = moment(Date.now()).isAfter(end_date)

   const { favouriteCourse } = useApiRequests()
   const { mutate } = useMutation({ mutationFn: favouriteCourse })
   const [isLiked, setisLiked] = useState(is_favourite)
   const queryClient = useQueryClient()
   const handleToggleLike = () => {
      setisLiked((prev) => !prev)
      mutate(id, { onSuccess: () => queryClient.invalidateQueries(["favouriteCourses"]) })
   }

   return (
      <div className='h-full'>
         <FadeBox className='h-full'>
            <div className='rounded-[5px] flex flex-col overflow-hidden h-full cShadow hover:shadow-2xl transition-all duration-500 min-h-[400px] max-h-[700px]'>
               <div className='relative overflow-hidden'>
                  {cover.match("https") ? (
                     <div className='object-cover transition-all cursor-pointer bg-slate-300 duration-500 hover:scale-105 min-h-[298px] w-full rounded-t-md' />
                  ) : (
                     <Link to={`/courses/${slug}`} draggable={false}>
                        <img
                           src={process.env.REACT_APP_MEDIA_URL + cover}
                           className={`object-cover object-center transition-all ${
                              expired && "opacity-50"
                           } cursor-pointer duration-500
                       aspect-square max-h-[200px] sm:max-h-[300px] w-full rounded-t-md`}
                           draggable={false}
                        />
                     </Link>
                  )}

                  <div className='top-0 left-0 px-[11px] pt-[14px] md:pt-5 md:px-[17px] absolute flex w-full justify-between'>
                     <DetailsLabel
                        expired={expired}
                        plan_name={plan_name as PlanTypes}
                        completion_credits={completion_credits}
                     />
                     {userData?.plainTextToken && (
                        <Love isLiked={isLiked} handleToggleLike={handleToggleLike} />
                     )}
                  </div>

                  <GoToDetailsButton
                     started_at={moment(start_date).format("DD/MM/YYYY")}
                     finished_at={moment(end_date).format("DD/MM/YYYY")}
                     slug={slug}
                     coming_soon={moment(start_date).isAfter(Date.now())}
                     expired={expired}
                     plan_name={plan_name as PlanTypes}
                     discount={discount}
                     discount_label={discount_label}
                  />
               </div>

               <div
                  className={`flex-1 h-full flex justify-between flex-col gap-5 pt-[11px] md:pt-[23px] pb-[15px] md:pb-[30px] px-[11px] md:px-[17px] bg-white ${
                     expired && "opacity-50"
                  }`}>
                  <div className='flex flex-wrap justify-between gap-1'>
                     <div className='flex gap-1'>
                        <EndDateSVG />
                        <span className='text-[13px] md:text-[14px] text-[#848484] font-saira'>
                           {moment(start_date).format("DD-MM-YYYY")}
                        </span>
                     </div>
                     <div className='flex gap-1'>
                        <StartDateSvg />
                        <span className='text-[13px] md:text-[14px] text-[#848484] font-saira'>
                           {moment(end_date).format("DD-MM-YYYY")}
                        </span>
                     </div>
                     <div className='flex items-center gap-1'>
                        <ClapsSVG />
                        <span className='text-[13px] md:text-[14px] text-[#848484] font-saira'>
                           {likes_count}
                        </span>
                     </div>
                     <div className='flex items-center gap-1'>
                        <MembersSVG />
                        <span className='text-[13px] md:text-[14px] text-[#848484] font-saira'>
                           {subscribers_count}
                        </span>
                     </div>
                  </div>
                  <Link
                     draggable={false}
                     to={`/courses/${slug}`}
                     className='text-active md:h-[80px] transition-all font-bold text-xl line-clamp-3 cursor-pointer font-nuinto'>
                     {title}
                  </Link>

                  <div
                     dangerouslySetInnerHTML={{ __html: abstract_description || "" }}
                     className='text-[13px] line-clamp-4 md:h-20'
                  />

                  <div className='flex flex-col gap-[3px] md:gap-[10px] font-saira-normal'>
                     <p className='self-stretch flex-grow-0 flex-shrink-0 text-[15px] text-left'>
                        <span className='self-stretch flex-grow-0 flex-shrink-0 text-left text-[#848484] font-saira mr-0.5'>
                           CANALE:{" "}
                        </span>
                        <span className='self-stretch uppercase flex-grow-0 flex-shrink-0 font-bold text-left text-black font-saira'>
                           {channel_name}
                        </span>
                     </p>
                     <p className='self-stretch flex-grow-0 flex-shrink-0 text-[15px] flex text-left items-baseline '>
                        <span className='self-stretch flex-grow-0 flex-shrink-0 text-left text-[#848484] font-saira mr-0.5'>
                           POSTI DISPONIBILI:{" "}
                        </span>
                        <span className='uppercase self-center flex-grow-0 flex-shrink-0 font-bold text-left text-black font-saira'>
                           {max_subscribers >= 5000 ? (
                              <span className=''>
                                 <svg
                                    className='w-5'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 640 512'>
                                    <path d='M0 233.5C0 157.5 61.5 96 137.5 96c39.3 0 76.7 16.8 102.7 46.1L320 231.9l79.8-89.8C425.9 112.8 463.3 96 502.5 96C578.5 96 640 157.5 640 233.5v45.1C640 354.5 578.5 416 502.5 416c-39.3 0-76.7-16.8-102.7-46.1L320 280.1l-79.8 89.8C214.1 399.2 176.7 416 137.5 416C61.5 416 0 354.5 0 278.5V233.5zM298.6 256l-82.3-92.6c-20-22.5-48.7-35.4-78.8-35.4C79.2 128 32 175.2 32 233.5v45.1C32 336.8 79.2 384 137.5 384c30.1 0 58.8-12.9 78.8-35.4L298.6 256zm42.8 0l82.3 92.6c20 22.5 48.7 35.4 78.8 35.4C560.8 384 608 336.8 608 278.5V233.5C608 175.2 560.8 128 502.5 128c-30.1 0-58.8 12.9-78.8 35.4L341.4 256z' />
                                 </svg>
                              </span>
                           ) : (
                              max_subscribers
                           )}
                        </span>
                     </p>
                     {plan_id === 1 ? (
                        <>
                           <span className='h-[24px] md:h-[22px]'></span>
                           <span className='text-secondary_root-dark self-stretch uppercase flex-grow-0 flex-shrink-0 font-bold text-left font-saira'>
                              CORSO GRATUITO PER TUTTI GLI UTENTI
                           </span>
                        </>
                     ) : plan_id === 2 ? (
                        <>
                           <span className='h-[24px] md:h-[22px]'></span>
                           <span className='text-primary_root-dark self-stretch uppercase flex-grow-0 flex-shrink-0 font-bold text-left font-saira'>
                              CORSO IN ESCLUSIVA PER UTENTI PLUS
                           </span>
                        </>
                     ) : (
                        <>
                           <p className='self-stretch flex-grow-0 flex-shrink-0 text-[15px] flex text-left items-baseline '>
                              <span className='self-stretch flex-grow-0 flex-shrink-0 text-left text-[#848484] font-saira mr-0.5'>
                                 PREZZO UTENTE FREE:{" "}
                              </span>
                              <span className='text-secondary_root-dark self-stretch uppercase flex-grow-0 flex-shrink-0 font-bold text-left font-saira'>
                                 {full_plan_price && Number.isFinite(full_plan_price)
                                    ? `${full_plan_price}€`
                                    : full_plan_price}
                              </span>
                           </p>
                           <p className='self-stretch flex-grow-0 flex-shrink-0 text-[15px] flex text-left items-baseline '>
                              <span className='self-stretch flex-grow-0 flex-shrink-0 text-left text-[#848484] font-saira mr-0.5'>
                                 PREZZO UTENTE PLUS:{" "}
                              </span>
                              <span className='text-primary_root-dark self-stretch uppercase flex-grow-0 flex-shrink-0 font-bold text-left font-saira'>
                                 {plus_user_price && Number.isFinite(plus_user_price)
                                    ? `${plus_user_price}€`
                                    : plus_user_price}
                              </span>
                           </p>
                        </>
                     )}
                  </div>
               </div>
            </div>
         </FadeBox>
      </div>
   )
}
