import { Checkbox, CheckboxProps } from '@chakra-ui/react'

const ChakraCheckBox = (props: CheckboxProps) => {
  return (
    <Checkbox
      _checked={{
        '& .chakra-checkbox__control': { background: '#496492' }
      }}
      {...props}>
      {props.children}
    </Checkbox>
  )
}

export default ChakraCheckBox