import { Spinner } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { FadeBox } from 'Components/FadeBox'
import { UserStatistecs } from 'Components/UserStatistecs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { dashboardCoursesAtom, userDataAtom } from 'Recoil/atoms'
import { CompletedCourses } from './CompletedCourses'
import { OnGoingCourses } from './OnGoingCourses'

export const Dashboard = () => {
   const [selected, setselected] = useState('On Going')
   const [, setCourses] = useRecoilState(dashboardCoursesAtom)
   const { getDashboardCourses } = useApiRequests()
   const [userData] = useRecoilState(userDataAtom)

   const { isLoading } = useQuery(['Dashboard'], {
      queryFn: getDashboardCourses,
      select: (data) => data.data,
      onSuccess: (data) => setCourses(data)
   })

   const coursesTabs = {
      'On Going': OnGoingCourses,
      Completed: CompletedCourses
   }

   const TabComponent = coursesTabs[selected as keyof typeof coursesTabs]

   const { t } = useTranslation()

   return (
      <FadeBox>
         {isLoading ? (
            <div className='w-full flex justify-center my-3'>
               <Spinner />
            </div>
         ) : (
            <div className='flex divide-x-2 lg:flex-nowrap flex-wrap w-full divide-[#F5F5F5]'>
               <div className='flex flex-col lg:w-[75%] w-full'>
                  <div className='p-5 flex items-center mb-7 justify-between'>
                     <h3 className='font-bold md:text-base text-sm'>{t('My Courses')}</h3>
                     <div className='flex md:gap-7 gap-2 md:text-xs text-[10px]'>
                        {Object.keys(coursesTabs).map((tab, i) => (
                           <span
                              key={i}
                              onClick={() => setselected(tab)}
                              className={`${selected === tab && 'font-bold'} cursor-pointer`}>
                              {t(`${tab}`)}
                           </span>
                        ))}
                     </div>
                  </div>
                  <TabComponent />
               </div>
               <div className='flex flex-col lg:w-[25%] gap-3 h-full w-full p-3'>
                  {userData?.user_data.subscription.type_text === 'FREE' ? (
                     <div className=" inline-flex flex-col rounded-[5px] items-start gap-3 p-5 text-white text-left w-full bg-[rgba(170,82,96,1)] rounded-tr-[5px] font-['Montserrat']">
                        <div className=' flex flex-col '>
                           <p className='inset-x-0 top-0 text-xs font-bold inline m-0 bottom-[76.12%] leading-[1.3]'>
                              {t('UTENTE FREE')}
                           </p>
                           <p className='inset-x-0 bottom-0  font-normal inline m-0 top-[35.82%] text-[11px] leading-[1.3]'>
                              {t('user_free_description')}
                           </p>
                        </div>
                     </div>
                  ) : (
                     <div className=" inline-flex flex-col rounded-[5px] items-start gap-3 p-5 text-white text-left w-full bg-primary rounded-tr-[5px] font-['Montserrat']">
                        <div className=' flex flex-col '>
                           <p className='inset-x-0 top-0 text-xs font-bold inline m-0 bottom-[76.12%] leading-[1.3]'>
                              {t('UTENTE PLUS')}
                           </p>
                           <p className='inset-x-0 bottom-0  font-normal inline m-0 top-[35.82%] text-[11px] leading-[1.3]'>
                              {t('user_plus_description')}
                           </p>
                        </div>
                     </div>
                  )}

                  <UserStatistecs />
               </div>
            </div>
         )}
      </FadeBox>
   )
}
