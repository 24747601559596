import logo from 'Assets/logo.svg'
import freeLogo from 'Assets/freeUserLogo.svg'
import { NavbarLink } from 'Components/NavbarLink/NavbarLink'
import { NavbarUserMenu } from 'Components/Menus/NavbarUserMenu/NavbarUserMenu'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'
import {
   Link,
} from '@chakra-ui/react'
import { AiOutlineHeart } from 'react-icons/ai'
import { BsQuestionLg } from 'react-icons/bs'
import Label from 'Components/Label'

const WithoutUser = () => {

   return (
      <div className='flex items-center gap-[30px]'>
         <NavbarLink destension='/' title='HOME' />
         <NavbarLink destension='/tutti-i-corsi' title='CORSI ECM' />
         <NavbarLink destension='/canali' title='CANALI' />
         <NavbarLink destension='/offerta' title='DIVENTA UTENTE PLUS' />
         <NavbarLink destension='/login' title='ACCEDI' />
         <NavbarLink destension='/register' title='ISCRIVITI' />
      </div>
   )
}

const UserSignedIn = () => {
   const [userData] = useRecoilState(userDataAtom)

   return (
      <div className='flex items-center gap-[20px]'>
         <NavbarLink destension='/corsi-ecm' title='CORSI ECM' />
         <NavbarLink destension='/canali' title='CANALI' />
         {userData?.user_data.subscription.type_text !== 'PLUS' && (
            <NavbarLink destension='/offerta' title='DIVENTA UTENTE PLUS' />
         )}
         <NavbarLink destension='/preferiti' title={<AiOutlineHeart />} />
         <NavbarLink destension='../faq' title={<BsQuestionLg />} />
         {userData?.user_data?.subscription?.type_text && (
            <Label child={userData?.user_data?.subscription?.type_text} />
         )}
         <NavbarUserMenu />
      </div>
   )
}

export function DesktopNavbar() {
   const [userData] = useRecoilState(userDataAtom)
   // const { t } = useTranslation()
   return (
      <div className='flex py-4 border-b-[1px] border-b-secondary_root-dark backdrop-blur-sm container mx-auto bg-white items-center z-50  sticky top-0 justify-between px-4 md:px-4'>
         <Link href='/'>
            {userData ? (
               <>
                  {userData?.user_data?.subscription?.type_text !== 'FREE' ? (
                     <img className='h-11' src={logo} />
                  ) : (
                     <img className='h-11' src={freeLogo} />
                  )}
               </>
            ) : (
               <>
                  <img className='h-11' src={freeLogo} />
               </>
            )}
         </Link>
         <div className='flex gap-10 lg:gap-5 items-center'>
            {userData?.accessToken ? <UserSignedIn /> : <WithoutUser />}
         </div>
      </div>
   )
}

{
   /* <div className='flex gap-10 lg:gap-5 items-baseline'>
   <NavbarLink destension='/' title='HOME' />
   <Menu isLazy variant='unstyled'>
      <MenuButton fontSize={'12px'}>{t('ALL COURSES')}</MenuButton>
      <NavLink
         to='/packages'
         className={({ isActive }) => (isActive ? 'font-bold text-xs ' : 'text-xs ')}>
         PACCHETTI
      </NavLink> 
      <MenuList border={0} boxShadow='lg'>
         <MenuItem>
            <NavLink
               to='/'
               className={({ isActive }) =>
                  isActive ? 'font-bold text-xs' : 'text-xs'
               }>
               TUTTI I CORSI
            </NavLink>
         </MenuItem>
         <MenuItem>
            <NavLink
               to='/corsi-free'
               className={({ isActive }) =>
                  isActive ? 'font-bold text-xs' : 'text-xs'
               }>
               CORSI FREE
            </NavLink>
         </MenuItem>
         <MenuItem>
            <NavLink
               to='/corsi-plus'
               className={({ isActive }) =>
                  isActive ? 'font-bold text-xs' : 'text-xs'
               }>
               CORSI PLUS
            </NavLink>
         </MenuItem>
      </MenuList>
   </Menu>
   <ChannelsList />
   <Link
      color={'#4F638E'}
      target='_blank'
      className='text-xs font-semibold'
      href='https://ecmclub.org/offerta'>
      SCOPRI L’OFFERTA PLUS
   </Link>
</div>
<div className='flex gap-10 lg:gap-5 items-center'>
   {userData && (
      <NavLink
         to='/favourite-courses'
         className={({ isActive }) =>
            isActive
               ? 'font-bold text-xs flex justify-center items-center gap-2 mb-[1px]'
               : 'text-xs flex justify-center items-center gap-2'
         }>
         <MdOutlineFavoriteBorder className='text-xl  transition-all' />
      </NavLink>
   )}{' '}
   <NavLink
      to='../faq'
      className={({ isActive }) =>
         isActive
            ? 'font-bold text-xs flex justify-center items-center gap-2 mb-[1px]'
            : 'text-xs flex justify-center items-center gap-2'
      }>
      <img src={FaqIcon} className='w-4 h-4' />
   </NavLink>
   <NavbarUserMenu />
</div> */
}
