import CreditsFilter from "./CreditsFilter"
import CourseState from "./CourseState"
import PriceRange from "./PriceRange"
import PricePlan from "./PricePlan"
import Icons from "../Icons"
import CourseFor from "./CourseFor"
import { Accordion } from "@chakra-ui/react"
import { useRecoilState, useRecoilValue } from "recoil"
import { accordionOpenSelector, userDataAtom } from "Recoil/atoms"

const Filter = () => {
  const di = useRecoilValue(accordionOpenSelector)
  const [userData] = useRecoilState(userDataAtom)

  return (
    <div className='flex flex-col gap-5'>
      <h3 className='text-[20px] font-saira-normal text-active flex items-center gap-2 font-bold'>
        <Icons.Filter />
        FILTRA
      </h3>
      <div className='flex flex-col gap-5 pl-[25px] md:pl-[50px]'>
        <Accordion defaultIndex={di} allowMultiple border={"none"}>
          {userData?.accessToken && <CourseFor />}
          <div className='pt-5'>
            <CreditsFilter isOpen={di.includes(0)} />
            <PriceRange isOpen={di.includes(1)} />
            <CourseState isOpen={di.includes(2)} />
            <PricePlan isOpen={di.includes(3)} />
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default Filter
