import { Button, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useValidationSchemas } from 'Schemas/RegisterSchemas'
import { TResetPasswordInputs } from 'Types/FormsTypes'

export const ResetPasswordForm = () => {
   const { resetPasswordSchema } = useValidationSchemas()

   const {
      register,
      handleSubmit,
      formState: { errors }
   } = useForm<TResetPasswordInputs>({
      resolver: yupResolver(resetPasswordSchema)
   })

   const { passwordResetRequest } = useApiRequests()

   const { mutate, isLoading } = useMutation({ mutationFn: passwordResetRequest })

   const toast = useToast()

   const onSubmit: SubmitHandler<TResetPasswordInputs> = async (data: TResetPasswordInputs) => {
      mutate(data, {
         onSuccess: (data) => {
            if (data) {
               toast({
                  status: 'success',
                  title: t('you recived an email')
               })
            }
         },
         onError: () => {
            toast({
               status: 'error',
               title: t('Email not exist')
            })
         }
      })
   }

   const { t } = useTranslation()

   return (
      <form
         className='container max-w-[600px] mx-auto shadow-3xl rounded-lg p-9 flex flex-col gap-7'
         onSubmit={handleSubmit(onSubmit)}>
         <div className='flex flex-col gap-2'>
            <p className='font-normal text-xs text-dark-gray'>Email</p>
            <input
               {...register('email')}
               className={`${
                  errors.email ? 'border-red-500' : 'border-[#bbbbbb59]'
               } w-full pl-3 text-base rounded-md border-2  h-12 shadow-md
                  focus:shadow-sm outline-none duration-500 transition-all`}
               placeholder='Inserisci indirizzo email'
            />
            <p className='text-red-500 text-sm'>{errors.email?.message}</p>
         </div>
         <div className='flex justify-between'></div>
         <Button isLoading={isLoading} height={'60px'} type='submit' variant={'primary'}>
            {t('Reset')}{' '}
         </Button>
      </form>
   )
}
