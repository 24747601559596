import { Button, useMediaQuery } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { AttemptsDrawer } from 'Components/Drawers/AttemptsDrawer'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { moduileQuizAtom, selectedModuleAtom, showAttemptsDrawerAtom } from 'Recoil/atoms'
import { TQuiz } from 'Types/Models'

export const QuizFooter = ({ refetch }: { refetch: () => void }) => {
   const { t } = useTranslation()
   const [quizState, setQuizState] = useRecoilState(moduileQuizAtom)
   const [selectedModule] = useRecoilState(selectedModuleAtom)
   const { startQuiz, submitQuiz } = useApiRequests()
   const [showAttemptsDrawer, setshowAttemptsDrawer] = useRecoilState(showAttemptsDrawerAtom)
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const { mutate, isLoading } = useMutation({
      mutationFn: submitQuiz
   })
   const queryClient = useQueryClient()

   const nextStep = () => {
      setQuizState({ ...quizState, step: quizState.step + 1 })
   }

   const prevStep = () => {
      setQuizState({ ...quizState, step: quizState.step - 1 })
   }

   const handleStartQuiz = async () => {
      refetch()
      selectedModule && (await startQuiz(selectedModule?.id))
      setQuizState({ ...quizState, started: true })
   }

   const handleSubmit = () => {
      if (!isLoading) {
         mutate(
            { quiz_id: selectedModule?.id, questions: quizState.answers },
            {
               onSuccess: (res) => {
                  setQuizState({
                     ...quizState,
                     finished: true,
                     passed: res.data.data.passed,
                     score: res.data.data.score,
                     user_attempts: res.data.data.user_attempts
                  })
                  res.data.data.last_attempt_reminder === true &&
                     queryClient.invalidateQueries({ queryKey: ['modules'] })

                  queryClient.invalidateQueries({ queryKey: ['Attempts'] })
               }
            }
         )
      }
   }

   return (
      <>
         <div className='flex items-center lg:shadow-3xl lg:border rounded-md lg:border-[#F5F5F5] justify-between lg:p-2 p-0'>
            <div className='lg:flex hidden gap-2 justify-between'>
               <span> {t('Tentativi')}</span>
               <span>
                  {(selectedModule as TQuiz).quiz_user.status.attempts}/
                  {(selectedModule as TQuiz).max_attempts}
               </span>
               <span
                  className='cursor-pointer text-[#496492]'
                  onClick={() => setshowAttemptsDrawer(true)}>
                  Vedi i tentativi precedenti
               </span>
            </div>

            {(selectedModule as TQuiz).max_attempts
               !== (selectedModule as TQuiz).quiz_user.status.attempts && (quizState.started ? (
                  <div className='flex lg:justify-end justify-between gap-3'>
                     <Button
                        h={'40px'}
                        visibility={quizState.step === 0 ? 'hidden' : 'visible'}
                        onClick={prevStep}
                        w={smallScreen ? '50%' : '200px'}
                        variant={smallScreen ? 'secondary' : 'primary'}>
                        {t('Previos')}
                     </Button>
                     {quizState.step === quizState.questionsList.length - 1 ? (
                        <Button
                           h={'40px'}
                           onClick={handleSubmit}
                           w={smallScreen ? '50%' : '200px'}
                           isLoading={isLoading}
                           disabled={Object.keys(quizState.answers).length < quizState.step + 1}
                           variant='secondary'>
                           {t('SUBMIT QUIZ')}
                        </Button>
                     ) : (
                        <Button
                           disabled={Object.keys(quizState.answers).length < quizState.step + 1}
                           h={'40px'}
                           onClick={nextStep}
                           w={smallScreen ? '50%' : '200px'}
                           variant={smallScreen ? 'secondary' : 'primary'}>
                           {t('Next')}
                        </Button>
                     )}
                  </div>
               ) : (
                  <>
                     {(selectedModule as TQuiz).attempts !==
                        (selectedModule as TQuiz).max_attempts && (
                           <Button
                              h={'40px'}
                              disabled={
                                 (selectedModule as TQuiz).quiz_user.status.last_success_attempt !== null
                              }
                              onClick={handleStartQuiz}
                              w={smallScreen ? '100%' : '200px'}
                              alignSelf='end'
                              variant={'secondary'}>
                              {(selectedModule as TQuiz).quiz_user.status.last_success_attempt !== null
                                 ? 'Quiz superato'
                                 : t('START YOUR QUIZ')}
                           </Button>
                        )}
                  </>
               ))}
         </div>
         <AttemptsDrawer
            id={selectedModule?.id}
            onClose={() => setshowAttemptsDrawer(false)}
            isOpen={showAttemptsDrawer}
         />
      </>
   )
}
