import {
   Accordion,
   AccordionButton,
   AccordionIcon,
   AccordionItem,
   AccordionPanel,
   Box,
   Button,
   Spinner
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { useState } from 'react'

const FaqAccordion = () => {
   const { getAllFaqs } = useApiRequests()

   const [search, setsearch] = useState({
      searchInput: '',
      category: '',
      submittedSearch: ''
   })

   const faqs = useQuery(['faqs', search.category, search.submittedSearch], {
      queryFn: ({ queryKey }) => getAllFaqs(queryKey[1], queryKey[2])
   })

   return (
      <div className='flex flex-col gap-6'>
         <div className='flex items-center flex-col gap-5'>
            <form
               onSubmit={(e) => {
                  e.preventDefault()
                  setsearch({ ...search, submittedSearch: search.searchInput })
               }}
               className='flex w-full gap-5'>
               <input
                  onChange={(e) => setsearch({ ...search, searchInput: e.target.value })}
                  value={search.searchInput}
                  placeholder='Cerca'
                  className='h-[55px] outline-zinc-400 border-zinc-400 border w-full rounded px-4 transition-all focus:border-primary focus:outline-primary'
               />
               <Button
                  type='submit'
                  height={'55px'}
                  width={'fit-content'}
                  px='20px'
                  variant={'primary'}>
                  Cerca
               </Button>
            </form>
         </div>
         <Accordion>
            {faqs.isLoading ? (
               <div className='flex h-[60vh] justify-center items-center'>
                  <Spinner />
               </div>
            ) : (
               <div className='flex flex-col gap-10'>
                  {faqs.data?.data.data.map((cat: any) => (
                     <div key={cat.categoryName} className='flex flex-col gap-2'>
                        <h1 className='text-xl font-semibold'>{cat.categoryName}</h1>
                        <div className='flex flex-col'>
                           {cat.FAQs.map((faq: any) => (
                              <AccordionItem key={faq.id} border={0}>
                                 <AccordionButton
                                    bg='#edf1f4'
                                    py={4}
                                    gap='12px'
                                    border='1px solid #dee2e6'>
                                    <Box
                                       className='text-primary font-semibold text-sm'
                                       as='span'
                                       flex='1'
                                       textAlign='left'>
                                       {faq.question}
                                    </Box>
                                    <AccordionIcon />
                                 </AccordionButton>
                                 <AccordionPanel className='text-sm' pb={4}>
                                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                 </AccordionPanel>
                              </AccordionItem>
                           ))}
                        </div>
                     </div>
                  ))}
               </div>
            )}
         </Accordion>
      </div>
   )
}

export default FaqAccordion
