import React from 'react'

export const ModuleOverview = ({ title, description }: { title: string; description: string }) => {
   return (
      <div className='flex flex-col gap-5'>
         <p className='md:text-white text-primary md:px-10 px-3 font-semibold lg:text-4xl text-xl lg:w-[55%] w-full'>
            {title}
         </p>
         <div
            dangerouslySetInnerHTML={{ __html: description }}
            className='lg:w-[55%] w-full md:text-white text-black md:pl-10 px-3 lg:text-xl text-sm leading-7'
         />
      </div>
   )
}
