import SecondTab from 'Components/SecondTab'
import { useEffect, useState } from 'react'

interface OnlyOneTabProps {
   id: string
   children: any
}

const OnlyOneTab: React.FunctionComponent<OnlyOneTabProps> = ({ children, id }) => {
   const localStorageKey = localStorage.getItem('tab')
   const [isCurrentTab, setisCurrentTab] = useState(true)

   useEffect(() => {
      localStorage.setItem('tab', id)
   }, [])

   window.addEventListener('storage', () => {
      if (localStorageKey === id) {
         setisCurrentTab(true)
      } else {
         setisCurrentTab(false)
      }
   })

   return (
      <>
         {isCurrentTab ? (
            children
         ) : (
            <>
               <SecondTab />
               {children}
            </>
         )}
      </>
   )
}

export default OnlyOneTab
