import { ModalWrapper } from 'Components/Modals/ModalWrapper'
import React, { Dispatch, SetStateAction, useState } from 'react'

export const SubscriptionCard = ({
   data,
   isSelected,
   setSelectedCard
}: {
   data: any
   isSelected?: boolean
   setSelectedCard: Dispatch<SetStateAction<any>>
}) => {
   const [isOpen, setisOpen] = useState(false)

   return (
      <div
         className='flex justify-start transition-all items-start gap-2.5 p-[15px] rounded-lg bg-white'
         style={{
            boxShadow: isSelected ? '0px 0px 10px 0 #496492' : '0px 0px 10px 0 rgba(0,0,0,0.25)'
         }}>
         <div
            onClick={() => setSelectedCard(data)}
            className='flex justify-start items-center gap-2.5 p-2.5'>
            {isSelected ? (
               <svg
                  width={18}
                  height={18}
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='flex-grow-0 flex-shrink-0 w-[18px] h-[18px] relative'
                  preserveAspectRatio='none'>
                  <rect x={1} y={1} width={16} height={16} rx={8} fill='#496492' />
                  <path
                     d='M5.5 9.5L7.5 11.5L12.5 6.5'
                     stroke='white'
                     strokeWidth='1.5'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
                  <rect
                     x={1}
                     y={1}
                     width={16}
                     height={16}
                     rx={8}
                     stroke='#496492'
                     strokeWidth={2}
                  />
               </svg>
            ) : (
               <div className='flex justify-start items-start w-[18px] h-[18px] overflow-hidden gap-2.5 p-[5px] rounded-full bg-white border-2 border-[#496492]' />
            )}
         </div>
         <div className='flex flex-col justify-start items-start gap-2.5 flex-1'>
            <div className='flex justify-start items-start flex-grow-0 flex-shrink-0 gap-2.5'>
               <div className='flex justify-start items-start flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 p-[5px] rounded-[5px] bg-[#a6aac4]'>
                  <p className='flex-grow-0 flex-shrink-0 text-[9px] font-bold text-left uppercase text-white'>
                     abbonamento
                  </p>
               </div>
               <div className='flex justify-start items-start flex-grow-0 flex-shrink-0 relative overflow-hidden gap-2.5 p-[5px] rounded-[5px] bg-[#aa5260]'>
                  <p className='flex-grow-0 flex-shrink-0 text-[9px] font-bold text-left text-white'>
                     BEST VALUE
                  </p>
               </div>
            </div>

            <div className='flex flex-col justify-start items-start relative gap-[7px] w-full'>
               <p
                  onClick={() => setSelectedCard(data)}
                  className='self-stretch font-bold text-left text-[#496492]'>
                  <br />
                  <span className='self-stretch text-xl font-bold text-left text-[#496492]'>
                     {data.name}{' '}
                  </span>
               </p>

               <p
                  className='text-xs text-left text-black'
                  dangerouslySetInnerHTML={{ __html: data.description }}
               />

               <div className='flex justify-between items-end self-stretch relative'>
                  <p className='text-left text-black'>
                     <span className='text-lg font-bold text-left text-[#496492]'>
                        €{data.price}
                     </span>
                     <br />
                     <span className='text-[10px] text-left text-[#496492]'>IVA INCLUSA</span>
                  </p>
                  <div className='flex justify-start items-start relative overflow-hidden gap-2.5 p-[5px] rounded-[5px]'>
                     <p
                        onClick={() => setisOpen(true)}
                        className='text-[9px] cursor-pointer font-bold text-left uppercase text-[#4e638f]'>
                        SCOPRI DI PIÙ{' '}
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <ModalWrapper size='2xl' isOpen={isOpen} onClose={() => setisOpen(false)}>
            <div
               className='flex flex-col justify-center items-start relative gap-5 p-[30px] rounded-[5px] bg-white border border-neutral-100'
               style={{ boxShadow: '0px 4px 4px 0 rgba(0,0,0,0.25)' }}>
               <p className='self-stretch flex-grow-0 flex-shrink-0  text-base font-bold text-left text-[#496492]'>
                  I VANTAGGI DELL’UTENTE PLUS
               </p>
               <div className='grid grid-cols-2 justify-center w-full gap-3.5'>
                  <div className='flex flex-col justify-start items-start  gap-2'>
                     <div className='flex justify-center items-start  relative gap-1.5'>
                        <svg
                           width={15}
                           height={16}
                           viewBox='0 0 15 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='w-[15px] shrink-0  h-[15px] mt-1 relative'
                           preserveAspectRatio='none'>
                           <path
                              d='M5.625 8L6.875 9.25L9.375 6.75M13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8Z'
                              stroke='#496492'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                        <p className=' text-sm text-left text-black'>
                           Accesso a tutti i corsi FREE
                        </p>
                     </div>
                     <div className='flex justify-center items-start  relative gap-1.5'>
                        <svg
                           width={15}
                           height={16}
                           viewBox='0 0 15 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='w-[15px] shrink-0  h-[15px] mt-1 relative'
                           preserveAspectRatio='none'>
                           <path
                              d='M5.625 8L6.875 9.25L9.375 6.75M13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8Z'
                              stroke='#496492'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                        <p className=' text-sm text-left text-black'>Accesso a tutti corsi PLUS</p>
                     </div>
                     <div className='flex justify-center items-start  relative gap-1.5'>
                        <svg
                           width={15}
                           height={16}
                           viewBox='0 0 15 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='w-[15px] shrink-0  h-[15px] mt-1 relative'
                           preserveAspectRatio='none'>
                           <path
                              d='M5.625 8L6.875 9.25L9.375 6.75M13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8Z'
                              stroke='#496492'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                        <p className=' text-sm text-left text-black'>
                           Accesso agli eventi live inclusi nei corsi
                        </p>
                     </div>
                     <div className='flex justify-center items-start  relative gap-1.5'>
                        <svg
                           width={15}
                           height={16}
                           viewBox='0 0 15 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='w-[15px] shrink-0  h-[15px] mt-1 relative'
                           preserveAspectRatio='none'>
                           <path
                              d='M5.625 8L6.875 9.25L9.375 6.75M13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8Z'
                              stroke='#496492'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                        <p className=' text-sm text-left text-black'>
                           Sconti sui corsi SEMI
                        </p>
                     </div>
                  </div>
                  <div className='flex flex-col justify-start items-start  gap-2'>
                     <div className='flex justify-center items-start  relative gap-1.5'>
                        <svg
                           width={15}
                           height={16}
                           viewBox='0 0 15 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='w-[15px] shrink-0  h-[15px] mt-1 relative'
                           preserveAspectRatio='none'>
                           <path
                              d='M5.625 8L6.875 9.25L9.375 6.75M13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8Z'
                              stroke='#496492'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                        <p className=' text-sm text-left text-black'>
                           Contatto diretto con gli autori
                        </p>
                     </div>
                     <div className='flex justify-center items-start  relative gap-1.5'>
                        <svg
                           width={15}
                           height={16}
                           viewBox='0 0 15 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='w-[15px] shrink-0  h-[15px] mt-1 relative'
                           preserveAspectRatio='none'>
                           <path
                              d='M5.625 8L6.875 9.25L9.375 6.75M13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8Z'
                              stroke='#496492'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                        <p className=' text-sm text-left text-black'>
                           Sconti su iniziative speciali
                        </p>
                     </div>
                     <div className='flex justify-center items-start  relative gap-1.5'>
                        <svg
                           width={15}
                           height={16}
                           viewBox='0 0 15 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='w-[15px] shrink-0  h-[15px] mt-1 relative'
                           preserveAspectRatio='none'>
                           <path
                              d='M5.625 8L6.875 9.25L9.375 6.75M13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8Z'
                              stroke='#496492'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                        <p className=' text-sm text-left text-black'>Customer care dedicato</p>
                     </div>
                     <div className='flex justify-center items-start  relative gap-1.5'>
                        <svg
                           width={15}
                           height={16}
                           viewBox='0 0 15 16'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='w-[15px] shrink-0  h-[15px] mt-1 relative'
                           preserveAspectRatio='none'>
                           <path
                              d='M5.625 8L6.875 9.25L9.375 6.75M13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8Z'
                              stroke='#496492'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                        <p className='text-sm text-left text-black'>
                           Durata della sottoscrizione: 12 mesi della
                        </p>
                     </div>
                  </div>
               </div>
               <svg
                  height={2}
                  viewBox='0 0 446 2'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='self-stretch '
                  preserveAspectRatio='none'>
                  <path d='M0 1H446' stroke='#496492' />
               </svg>
               <div className='flex flex-col  justify-start items-start  gap-3 w-full'>
                  <div className='grid grid-cols-12 pr-2 gap-1 w-full'>
                     <p className=' text-xs col-span-6 font-bold text-left text-[#496492]'>
                        Titolo
                     </p>
                     <p className=' text-xs col-span-2 font-bold text-center text-[#496492]'>ECM</p>
                     <p className=' text-xs col-span-2 font-bold text-center text-[#aa5260]'>
                        Utente FREE
                     </p>
                     <p className=' text-xs col-span-2 font-bold text-center text-[#496492]'>
                        Utente PLUS
                     </p>
                  </div>
                  <div className='flex flex-col gap-4 max-h-72 overflow-y-auto'>
                     {data?.popup.map((item: any) => (
                        <div key={item.id} className='grid grid-cols-12 gap-1 w-full'>
                           <p className='col-span-6 text-[11px] text-left text-black'>
                              {item.title}
                           </p>
                           <p className='col-span-2  text-[11px] font-bold text-center text-black'>
                              {item.completion_credits}
                           </p>
                           <p className='col-span-2 top-0 text-[11px] text-center text-gray-900'>
                              {item.free_user === 'ABBONAMENTO' ? '-' : item.free_user}
                           </p>
                           <p className='col-span-2  text-[11px] text-center text-gray-900'>
                              {item.plus_user === 'INCLUSO' ? (
                                 <span className='text-[#496492] font-semibold'>INCLUSO</span>
                              ) : (
                                 item.plus_user
                              )}
                           </p>
                        </div>
                     ))}
                  </div>
               </div>
               <div className='self-stretch  h-[38.3px]'>
                  <button
                     onClick={() => setisOpen(false)}
                     className=' h-[38.3px]  left-[29.5px] text-white w-full top-[301.5px] rounded-[5px] bg-[#496492]'>
                     CHIUDI
                  </button>
               </div>
            </div>
         </ModalWrapper>
      </div>
   )
}
