import moment from 'moment'
import { useEffect, useState } from 'react'
import { ModalWrapper } from './Modals/ModalWrapper'
import { Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const IdleTimeOutHandler = (props) => {
   const [showModal, setShowModal] = useState(false)
   const [isLogout, setLogout] = useState(false)
   let timer = undefined
   const events = ['click', 'load', 'keydown', 'mousemove']
   const IDLE_TIMEOUT_VALUE = 10000
   const [logoutTimeOut, setlogoutTimeOut] = useState(60)
   const navigate = useNavigate()

   const eventHandler = () => {
      if (!isLogout) {
         localStorage.setItem('lastInteractionTime', moment())
         if (timer) {
            props.onActive()
            startTimer()
         }
      }
   }

   useEffect(() => {
      addEvents()
      return () => {
         removeEvents()
         clearTimeout(timer)
      }
   }, [])

   const startTimer = () => {
      if (timer) {
         clearTimeout(timer)
      }
      timer = setTimeout(() => {
         const lastInteractionTime = localStorage.getItem('lastInteractionTime')
         const diff = moment.duration(moment().diff(moment(lastInteractionTime)))
         const timeOutInterval = IDLE_TIMEOUT_VALUE

         if (isLogout) {
            clearTimeout(timer)
         } else {
            if (diff._milliseconds < timeOutInterval) {
               startTimer()
               props.onActive()
            } else {
               props.onIdle()
               setShowModal(true)
            }
         }
      }, IDLE_TIMEOUT_VALUE)
   }

   const addEvents = () => {
      events.forEach((eventName) => {
         window.addEventListener(eventName, eventHandler)
      })

      startTimer()
   }

   const removeEvents = () => {
      events.forEach((eventName) => {
         window.removeEventListener(eventName, eventHandler)
      })
   }

   const handleContinueSession = () => {
      setShowModal(false)
      setLogout(false)
      props.tabIsBack()
   }

   const { t } = useTranslation()

   useEffect(() => {
      if (logoutTimeOut === 0) {
         navigate('/login')
      }
      if (showModal) {
         const timer =
            logoutTimeOut > 0 && setInterval(() => setlogoutTimeOut(logoutTimeOut - 1), 1000)

         return () => clearInterval(timer)
      } else {
         setlogoutTimeOut(60)
      }
   }, [logoutTimeOut, showModal])

   return (
      <div>
         <ModalWrapper size='sm' isOpen={showModal} onClose={handleContinueSession}>
            <div className='p-5 flex flex-col gap-3'>
               <b>ops, sembra che tu non stia seguendo il corso</b>
               <p>Per procedere non devi abbandonare la pagina!</p>
               <p>
                  {t('You will be logged out after')} <b>{logoutTimeOut}</b> {t('seconds')}
               </p>
               <Button height={'60px'} variant='primary' onClick={handleContinueSession}>
                  {t('Continue Session')}
               </Button>
            </div>
         </ModalWrapper>
      </div>
   )
}

export default IdleTimeOutHandler
