import { Button } from '@chakra-ui/react'
import { ContinueCourseButton } from 'Components/Buttons/ContinueCourseButton'
import { LoginAndSubscribe } from 'Components/Buttons/LoginAndSubscribe'
import { SubscribeCourseButton } from 'Components/Buttons/SubscribeCourseButton'
import { UpgradeAccountButton } from 'Components/Buttons/UpgradeAccountButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TCourseDetails } from 'Types/Models'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ConsentChanel } from 'Components/Buttons/ConsentChanel'

export const CourseEnrollButton = ({ data }: { data: TCourseDetails }) => {
   const { t } = useTranslation()
   const navigate = useNavigate()

   if (data.coming_soon) {
      return (
         <Button
            height={'55px'}
            variant={'primary'}
            bg='#A95260'
            overflow='hidden'
            size='xs'
            _hover={{ bg: '#A95260' }}
            w='full'>
            <span className='text-sm'>
               {t('DISPONIBILE DAL')} <br />
               {moment(data.info.start_date).format('DD/MM/YYYY')}
            </span>
         </Button>
      )
   }

   if (data.expired) {
      return (
         <Button height={'55px'} variant={'primary'} size='xs' w='full'>
            <span className='text-sm'>
               {t('CORSO CHIUSO IL')} <br />
               {moment(data.info.end_date).format('DD/MM/YYYY')}
            </span>
         </Button>
      )
   }

   if (data?.course_user.reason_code === 'MUST-CONSENT') return <ConsentChanel data={data} />

   if (
      data.course_user.reason_code === 'FREE-USER-WITH-CODE' &&
      data.course_user.can_enroll &&
      !data.course_user.status.enrolled
   ) {
      return <SubscribeCourseButton courseId={data.id} />
   }

   if (
      data.course_user.reason_code === 'FREE-USER-WITH-CODE' &&
      data.course_user.can_enroll &&
      data.course_user.status.enrolled
   ) {
      return <ContinueCourseButton courseId={data.id} />
   }

   return (
      <>
         {data.course_user.reason_code === 'GUEST' ? (
            <LoginAndSubscribe />
         ) : (
            <>
               {(data.course_user.reason_code === 'FREE-USER' ||
                  data.course_user.reason_code === 'MUST-PAY') &&
               !data.course_user.can_enroll ? (
                  <UpgradeAccountButton type={data.plan_name} courseId={data.id} />
               ) : (
                  <>
                     {(data.course_user.can_enroll && !data.course_user.status.enrolled) ||
                     data.course_user.reason_code === 'FREE-USER-WITH-CODE' ? (
                        <SubscribeCourseButton courseId={data.id} />
                     ) : (
                        <>
                           {data.course_user.status.finished ? (
                              <Button
                                 onClick={() => navigate(`/module/${data.id}`)}
                                 height={'55px'}
                                 variant={'primary'}
                                 w='full'>
                                 <span className='text-xs lg:text-base'>RIVEDI IL CORSO</span>
                              </Button>
                           ) : (
                              <ContinueCourseButton courseId={data.id} />
                           )}
                        </>
                     )}
                  </>
               )}
            </>
         )}
      </>
   )
}
