/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "@chakra-ui/react"
import React from "react"

interface Props {
  children?: React.ReactNode
  fallback?: React.ReactNode
}

interface State {
  hasError: boolean
  isButtonContentError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    isButtonContentError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // console.log('Name', _.name)

    return { hasError: true, isButtonContentError: _.name === "NotFoundError" }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // ! e.g. For Logging Error Info

    // .componentStack.includes('ButtonContent')
    // eslint-disable-next-line no-console
    console.error("Uncaught error:", errorInfo)
  }

  public render() {
    if (this.props.fallback && this.state.hasError) {
      return this.props.fallback
    }

    if (this.state.isButtonContentError) {
      return (
        <div className='flex justify-center items-center h-screen'>
          <div className='bg-white container flex flex-col'>
            <div className='w-full text-center'>
              <h3 className='text-primary text-3xl pb-10 font-semibold'>
                Abbiamo rilevato un errore
              </h3>
              <p className='text-base font-semibold pb-9'>
                Disattiva il plug-in di traduzione automatica e ricarica la pagina. Per
                qualsiasi dubbio contattaci all’indirizzo customercare@ecmclub.org.
              </p>
            </div>
            <div className='w-full justify-center mt-10 flex gap-1'>
              <a href='/'>
                <Button variant={"primary"}>Ricarica la pagina</Button>
              </a>
            </div>
          </div>
        </div>
      )
    }

    if (this.state.hasError) {
      return (
        <div className='flex justify-center items-center h-screen'>
          <div className='bg-white container flex flex-col'>
            <div className='w-full text-center'>
              <h3 className='text-primary text-3xl pb-10 font-semibold'>
                Abbiamo rilevato un errore
              </h3>
              <p className='text-base font-semibold pb-9'>
                Disattiva il plug-in di traduzione automatica e ricarica la pagina. Per
                qualsiasi dubbio contattaci all’indirizzo customercare@ecmclub.org
              </p>
            </div>
            <div className='w-full justify-center mt-10 flex gap-1'>
              <a href='/'>
                <Button variant={"primary"}>Ricarica la pagina</Button>
              </a>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
