import axios, { AxiosResponse } from 'axios'
import { prepareAllCoursesURL, prepareAllFaqsURL } from 'Helpers'
import { TRegister } from 'Types/FormsTypes'
import { TCourseDetails, TCourseSecondaryDetails } from 'Types/Models'
import { useAxiosInstance } from './AxiosInstance'
import { TGlobalFilterSend } from 'Types/filter.types'
import filterSearchParamBuilder from 'Utils/filterSearchParamBuilder'

export const useApiRequests = () => {
   const { axiosClient } = useAxiosInstance()

   return {
      registerApi: (data: TRegister) => axiosClient.post('/v1/user/register', data),

      validateEmail: (data: { email: string }) =>
         axiosClient.post('/v1/user/validate-email', { email: data.email }),

      validateFiscalCode: (data: any) => axiosClient.post('/v1/user/validate-fiscal', data),

      loginApi: (data: FormData) => axiosClient.post('/v1/user/login', data),

      passwordResetRequest: (data: { email: string }) =>
         axiosClient.post('/v1/user/password-reset-request', { email: data.email }),

      passwordReset: (data: any) => axiosClient.post('/v1/user/password-reset', data),

      activateUser: (data: { code: string }) => axiosClient.post('/v1/user/verify-account', data),

      consentApi: () => axiosClient.post('/v1/user/consent'),

      enrollToCourse: (data: { courseId: string; agency: string }) =>
         axiosClient.post(`/v1/user/course/${data.courseId}/enroll`, { agency: data.agency }),

      getProfession: async () => axiosClient.get('/v1/data/professions'),

      getCountries: async () => axiosClient.get('/v1/data/countries'),

      getBillingCountries: async () => axiosClient.get('/v1/data/billing/countries'),

      getProvinces: async () => axiosClient.get('/v1/data/provinces'),

      getCerteficatiom: async (courseId: string, type: number) =>
         axiosClient.get(`/v1/user/course/${courseId}/certification?certification_type=${type}`, {
            responseType: 'blob'
         }),

      getAllCourses: async (
         page: number,
         professionId?: number | string,
         specializationId?: number | string,
         chanelSlug?: string
      ) => axiosClient.get(prepareAllCoursesURL(page, professionId, specializationId, chanelSlug)),

      getAllCoursesV2: async (
         page: number,
         data: TGlobalFilterSend
      ) => {
         const params = filterSearchParamBuilder(page, data);

         return await axiosClient.get('/v2/courses/all', {
            params
         })
      },

      getCourseDetails: async (
         courseSlug: string
      ): Promise<AxiosResponse<{ data: TCourseDetails }>> =>
         axiosClient.get(`/v1/courses/${courseSlug}/details`),

      getCourseSecondaryDetails: async (
         courseSlug: string
      ): Promise<AxiosResponse<{ data: TCourseSecondaryDetails }>> =>
         axiosClient.get(`/v1/courses/${courseSlug}/sedondary-details`),

      getSSOconfig: async () => axiosClient.get('/v1/data/sso-config'),

      getAllFaqs: async (category: string, search: string) =>
         axiosClient.get(prepareAllFaqsURL(search)),

      getAllFaqsCategories: async () => axiosClient.get('/v1/faqs/categories'),

      getProvinceCities: async (prov: string) => await axiosClient.get(`/v1/data/cities/${prov}`),

      getProvinceRegion: async (prov: string) => axiosClient.get(`/v1/data/region/${prov}`),

      getPlans: async (courseId?: string) =>
         await axiosClient.get(courseId ? `/v1/plans?course_id=${courseId}` : '/v1/plans'),

      getPlansV2: async ({ id, mode }: { id?: string; mode: number }) =>
         await axiosClient.post('/v1/plans/all', { product_id: id, mode }),

      getProfile: async () => await axiosClient.get('/v1/user/profile'),

      changePassword: async (data: any) => await axiosClient.post('/v1/user/change-password', data),

      updateProfile: async (data: any) => await axiosClient.post('/v1/user/profile', data),

      getStripePortal: async () => await axiosClient.get('/v1/user/get-stripe-portal'),

      getCourseModules: async (courseId: string) =>
         await axiosClient.get(`/v1/courses/${courseId}/modules`),

      startModule: async (moduleId: number) =>
         await axiosClient.post(`/v1/modules/${moduleId}/start`),

      updateProgress: async (moduleId: number) =>
         await axiosClient.post(`/v1/modules/${moduleId}/update-progress`, { time: 1 }),

      finishModule: async (moduleId: number) =>
         await axiosClient.post(`/v1/modules/${moduleId}/finish`),

      getQuizData: async (quizId: number) => await axiosClient.get(`/v1/quizzes/${quizId}`),

      getSurveyData: async (surveyId: number) => await axiosClient.get(`/v1/surveys/${surveyId}`),

      startQuiz: async (quizId: number) => await axiosClient.post(`/v1/quizzes/${quizId}/start`),

      consentChannel: async (channel_id: number) =>
         await axiosClient.post('/v1/channels/consent', { channel_id }),

      submitQuiz: async (data: any) =>
         await axiosClient.post('/v1/quizzes/submit', {
            questions: data.questions,
            quiz_id: data.quiz_id
         }),

      submitSurvey: async (data: any) =>
         await axiosClient.post('/v1/surveys/submit', {
            questions: data.questions,
            survey_id: data.survey_id,
            course_id: data.course_id
         }),

      getCourseComments: async (courseId: string) =>
         await axiosClient.get(`/v1/courses/${courseId}/comments`),

      addComment: async (data: any) =>
         await axiosClient.post(`/v1/courses/${data.courseId}/comment/add`, { body: data.comment }),

      getDashboardCourses: async () => await axiosClient.get('/v1/user/dashboard'),

      getAttempts: async (quizId: string) =>
         await axiosClient.get(`/v1/quizzes/${quizId}/attempts`),

      getBanners: async () => await axiosClient.get('/v1/banners'),

      // getChannels: async () => await axiosClient.get('/v1/channels/get'),

      likeCourse: async (course_id: string) =>
         await axiosClient.post('/v1/user/course/like-dislike', { course_id }),

      favouriteCourse: async (course_id: string) =>
         await axiosClient.post('/v1/user/course/add-to-favourites', { course_id }),

      getFavouriteCoursesV2: async (page: number) =>
         await axiosClient.get(`/v2/courses/favourite?page=${page}`),
      getFavouriteCourses: async () => await axiosClient.get('/v2/user/course/favourites'),

      getUserPurchases: async () => await axiosClient.get('/v1/user/purchases'),

      confirmSubscription: async ({
         session_id,
         payment_method,
         product_id,
         mode
      }: {
         session_id: string
         payment_method: string
         product_id: string
         mode: string
      }) =>
         await axiosClient.post('/v1/user/confirm-subscription', {
            session_id,
            payment_method,
            product_id,
            mode
         }),

      send_email: async (body: string) =>
         await axiosClient.post('/v1/contact/send-email', { body: body }),

      configurations: async () => axiosClient.get('/v1/general/configurations'),

      validate_coupon: async (data: { code: string; course: string }) =>
         await axiosClient.post('/v1/courses/validate-code', data),

      saveBilling: async (data: any) => await axiosClient.post('/v1/user/billing/save', data),

      update_pointer: async (moduleId: number, pointer: number) =>
         await axiosClient.post(`/v1/modules/${moduleId}/update-pointer`, { pointer }),

      getMainBanner: async () => await axiosClient.get('/v1/main-banner'),
      getPackages: async () => await axiosClient.get('/v1/packages/all'),
      getOldCert: async ({ course, year }: { course: any; year: any }) =>
         await axiosClient.post('/v1/user/course/old-certificate', { course, year }),

      getFeaturedCourses: async (page: number) =>
         await axiosClient.get(`/v2/courses/featured?page=${page}`),
      getUserCourses: async (ongoing: boolean, completed: boolean, expired: boolean) =>
         await axiosClient.get(
            `/v2/courses/my-courses?ongoining=${ongoing}&completed=${completed}&expired=${expired}`
         ),
      getRecommendedCourses: async (page: number, data?: TGlobalFilterSend) => {
         let params = Object()

         if (!data) {
            return await axiosClient.get(`/v2/courses/recommended?page=${page}`)
         }
         if (data.keyword) {
            params = {
               ...data,
               page,
            }
         } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { keyword, ...rest } = data;
            params = rest;
         }

         for (const key in params) {
            // eslint-disable-next-line no-console
            if (params[key] === true) {
               params[key] = 1
            } else if (params[key] === false) {
               params[key] = 0
            }
         }

         return await axiosClient.get(`/v2/courses/recommended?page=${page}`, {
            params: {
               ...params
            }
         })

      },
      getChannels: async () => await axiosClient.get('/v2/channels/all'),
      channelDetails: async (channel_id: number) =>
         await axiosClient.get(`/v2/channels/${channel_id}/details`),

      getPlansNew: async () => await axiosClient.get('/v2/courses/plans'),

      getPlansCourses: async (data: TGlobalFilterSend, page: number) => {
         let params = Object()

         if (data.keyword) {
            params = {
               ...data,
               // 'plan': data['plan'],
               // price: data.price
            }
         } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { keyword, ...rest } = data;
            params = {
               ...rest,
            };
         }

         for (const key in params) {
            // eslint-disable-next-line no-console
            if (params[key] === true) {
               params[key] = 1
            } else if (params[key] === false) {
               params[key] = 0
            }
         }

         return await axiosClient.get('/v2/courses/all-plan', {
            params: {
               ...params,
               page
            }
         })
      },

      postPlansCourses: async (page: number) =>
         await axiosClient.get(`/v2/courses/all-plan?page=${page}`),

      getFilteredData: async (data: Record<string, unknown>, page: number) =>
         await axiosClient.post(`/v2/courses/filter?page=${page}`, data),

      getSearchedData: async (data: string, page: number) => {
         const formData = new FormData()
         formData.append('keyword', data)
         return await axiosClient.post(`/v2/courses/search?page=${page}`, formData)
      },
      getUsersCourses: async () => await axiosClient.get('/v1/user/certificate/get-courses'),
      downloadUserCertificate: async (url: string) => await axiosClient.get(`/v1/user/certificate/download?url_certificate=${url}`, {
         headers: {
            'Content-Type': 'application/pdf',
         },
         responseType: 'blob',
      })
   }
}
