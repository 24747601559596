import { Button } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import AddCoupon from 'Components/AddCoupon'
import { ModalWrapper } from 'Components/Modals/ModalWrapper'
import PlansCheckout from 'Components/PlansCheckout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const UpgradeAccountButton = ({ courseId, type }: { courseId: string; type: string }) => {
   const [showUpgradeModal, setShowUpgradeModal] = useState(false)
   const [showCouponModal, setShowCouponModal] = useState(false)
   const { t } = useTranslation()
   const queryClient = useQueryClient()
   const navigate = useNavigate()

   return (
      <>
         <div className='flex flex-col w-full  justify-center items-center gap-[10px] py-[6px]'>
            <Button
               onClick={() => navigate(`/checkout?id=${courseId}&mode=2`)}
               height={'55px'}
               bgColor='#496492'
               variant={'primary'}
               w='full'>
               {type === 'FULL' || type === 'SEMI' || type === 'PLUS' ? (
                  <span className='text-xs lg:text-base'>ACQUISTA IL CORSO</span>
               ) : (
                  <span className='text-xs lg:text-base'>PASSA ALLA VERSIONE {type}</span>
               )}
            </Button>
            <span className='md:text-white pb-[5px] text-xs font-bold text-center'>
               Hai ricevuto un codice di accesso a questo corso?{' '}
               <span
                  onClick={() => setShowCouponModal(true)}
                  className='underline cursor-pointer select-none'>
                  {' '}
                  Clicca qui
               </span>
            </span>
         </div>

         <ModalWrapper
            size='xl'
            isOpen={showCouponModal}
            onClose={() => {
               setShowCouponModal(false)
               queryClient.invalidateQueries(['courseDetails'])
            }}>
            <div className='bg-white'>
               <AddCoupon onClose={setShowCouponModal} courseId={courseId} />
            </div>
         </ModalWrapper>

         <ModalWrapper
            size='3xl'
            isOpen={showUpgradeModal}
            onClose={() => setShowUpgradeModal(false)}>
            <div className='bg-white py-4'>
               <PlansCheckout courseId={courseId} />
            </div>
         </ModalWrapper>
      </>
   )
}
