import React, { useEffect } from 'react'
import { RegisterInput } from 'Components/Inputs/RegisterInput'
import { RegisterSelect } from 'Components/Inputs/RegisterSelect'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TRegisterSecondStepForm } from 'Types/FormsTypes'
import { Button } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { brithCitiesAtom, provinceCitiesAtom, registerFormAtom } from 'Recoil/atoms'
import { genderOptions, nascitaOptions, tipoOptions } from 'Configs/Constants'
import { useApiRequests } from 'API/ApiRequests'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useValidationSchemas } from 'Schemas/RegisterSchemas'

export const SecondStep = () => {
   const [state, setState] = useRecoilState(registerFormAtom)
   const { RegisterSecondStepFormSchema } = useValidationSchemas()

   const {
      handleSubmit,
      register,
      control,
      setValue,
      setError,
      formState: { errors }
   } = useForm<TRegisterSecondStepForm>({
      resolver: yupResolver(RegisterSecondStepFormSchema),
      defaultValues: state.secondStepData
   })

   const [provinceCities, setProvinceCities] = useRecoilState(provinceCitiesAtom)
   const [brithCities, setbrithCities] = useRecoilState(brithCitiesAtom)
   const watchProvience = useWatch({ control, name: 'address_province' })
   const watchBirth = useWatch({ control, name: 'birth_in_italy' })
   const watchBirthProvience = useWatch({ control, name: 'birth_province' })
   const { getCountries, getProvinces, getProvinceCities, getProvinceRegion, validateFiscalCode } =
      useApiRequests()
   const vaildateFiscalMutation = useMutation({ mutationFn: validateFiscalCode })
   const provincesQuery = useQuery({
      queryKey: ['provinces'],
      queryFn: getProvinces,
      select: (data) => {
         return Object.keys(data.data.data).map((prov: any) => {
            return { id: prov, name: data.data.data[prov] }
         })
      }
   })

   const countriesQuery = useQuery({
      queryKey: ['countries'],
      queryFn: getCountries
   })

   const onSubmit: SubmitHandler<TRegisterSecondStepForm> = (data: TRegisterSecondStepForm) => {
      const inputElement = document.getElementById('fiscal-checkbox')
      const inputValue = (inputElement as any)?.checked

      vaildateFiscalMutation.mutate(
         {
            date_of_birth: data.date_of_birth,
            first_name: state.firstStepData.first_name,
            last_name: state.firstStepData.last_name,
            ...(data.birth_in_italy === 'Estero' && {
               birth_in_italy: data.birth_in_italy,
               place_of_birth: data.place_of_birth
            }),
            birth_city: data.birth_city,
            gender: data.gender,
            fiscal_code: data.fiscal_code,
            custom_fiscal: inputValue ? 1 : 0
         },
         {
            onSuccess: (res) => {
               if (res) {
                  const updated = { ...data }
                  if (data.birth_in_italy === 'Italia') {
                     delete updated.place_of_birth
                  }

                  setState({
                     ...state,
                     secondStepData: { ...updated, custom_fiscal: inputValue ? 1 : 0 },
                     step: 3
                  })
               }
            },
            onError: () => {
               setError('fiscal_code', {
                  type: 'custom',
                  message: 'Il codice fiscale  non corrisponde ai dati inseriti'
               })
            }
         }
      )
   }

   useEffect(() => {
      if (watchProvience) {
         getProvinceCities(watchProvience).then((res) => {
            setProvinceCities(res.data.data)
         })
         getProvinceRegion(watchProvience).then((res) => {
            setValue('address_regione', res.data.data)
         })
      }
   }, [watchProvience])

   useEffect(() => {
      if (watchBirthProvience) {
         getProvinceCities(watchBirthProvience).then((res) => {
            setbrithCities(res.data.data)
         })
      }
   }, [watchBirthProvience])

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <div className='flex flex-col gap-8 my-4 container mx-auto'>
            <div
               className={`grid ${
                  watchBirth !== 'Italia' ? 'lg:grid-cols-[20%_78%]' : 'lg:grid-cols-3'
               } justify-between gap-4 grid-cols-1`}>
               <RegisterSelect
                  name='birth_in_italy'
                  infoText='Se non nati in Italia, selezionare Estero'
                  register={register}
                  options={nascitaOptions}
                  errorMessage={errors.birth_in_italy?.message}
                  label='Luogo di nascita*'
                  placeholder='Seleziona'
               />
               {watchBirth !== 'Italia' ? (
                  <RegisterSelect
                     name='place_of_birth'
                     register={register}
                     options={countriesQuery.data?.data.data || []}
                     errorMessage={errors.place_of_birth?.message}
                     label='Nazionalità*'
                     placeholder='Seleziona nazione di nascita '
                  />
               ) : (
                  <>
                     <RegisterSelect
                        name='birth_province'
                        register={register}
                        selectId
                        infoText='Se non nati in italia, selezionare la nazione di nascita'
                        options={provincesQuery.data || []}
                        errorMessage={errors.birth_province?.message}
                        label='Provincia di nascita*'
                        placeholder=''
                     />
                     <RegisterSelect
                        name='birth_city'
                        register={register}
                        options={brithCities || []}
                        errorMessage={errors.birth_city?.message}
                        label='Città di nascita*'
                        placeholder=''
                     />
                  </>
               )}
            </div>
            <div className='grid md:grid-cols-[20%_20%_57%] grid-cols-1 md:gap-0 gap-4 justify-between'>
               <RegisterInput
                  errorMessage={errors.date_of_birth?.message}
                  name='date_of_birth'
                  isDate
                  infoText="Inserire la data di nascita con il formato gg/mm/aaaa, prestanto attenzione ad inserire l'anno di nascita completo (es: 1980)"
                  register={register}
                  label='Data di nascita*'
                  placeholder=''
               />
               <RegisterSelect
                  name='gender'
                  infoText='Selezionare il sesso, così come riportato nel codice fiscale'
                  selectId
                  register={register}
                  options={genderOptions}
                  errorMessage={errors.gender?.message}
                  label='Genere*'
                  placeholder='Seleziona genere'
               />
               <RegisterInput
                  errorMessage={errors.fiscal_code?.message}
                  name='fiscal_code'
                  infoText='Inserire il codice fiscale, prestando attenzione che sia formato da 16 caratteri (9 lettere e 7 numeri)'
                  register={register}
                  label='Codice fiscale*'
                  placeholder='Inserisci codice fiscale'
               />
            </div>

            <div className='grid md:grid-cols-[20%_55%_20%] grid-cols-1 justify-between gap-4'>
               <RegisterSelect
                  errorMessage={errors.address_type?.message}
                  name='address_type'
                  options={tipoOptions}
                  register={register}
                  label='Tipo di indirizzo*'
                  placeholder='Seleziona'
               />
               <RegisterInput
                  errorMessage={errors.address_line?.message}
                  name='address_line'
                  register={register}
                  infoText="Inserire l'indirizzo di residenza, domicilo o lavorativo, specificando se via/piazza/viale, ecc"
                  label='Indirizzo*'
                  placeholder='Via, viale, piazza'
               />
               <RegisterInput
                  errorMessage={errors.address_number?.message}
                  name='address_number'
                  register={register}
                  infoText='Inserire il numero civico'
                  label='Civico*'
                  placeholder='Numero'
               />
            </div>
            <div className='grid md:grid-cols-[20%_78%] grid-cols-1 justify-between gap-4'>
               <RegisterInput
                  errorMessage={errors.zip_code?.message}
                  name='zip_code'
                  infoText="Inserire il cap dell'indirizzo indicato. Se non si è in possesso del cap di zona è possibile inserire anche quello della città (es: Milano 20100)"
                  register={register}
                  label='CAP*'
                  placeholder='Inserisci CAP'
               />

               <RegisterSelect
                  errorMessage={errors.address_province?.message}
                  name='address_province'
                  options={provincesQuery.data}
                  register={register}
                  selectId
                  label='Provincia*'
                  placeholder=''
               />
            </div>
            <div className='grid md:grid-cols-3 grid-cols-1 justify-between gap-4'>
               <RegisterSelect
                  errorMessage={errors.address_city?.message}
                  name='address_city'
                  register={register}
                  label='Città*'
                  options={(provinceCities as any) || []}
                  placeholder=''
               />
               <RegisterInput
                  errorMessage={errors.address_regione?.message}
                  name='address_regione'
                  disabled
                  register={register}
                  label='Regione*'
                  placeholder='Regione'
               />
               <RegisterInput
                  errorMessage={errors.phone_number?.message}
                  name='phone_number'
                  register={register}
                  infoText='Inserire un numero di cellulare valido. Il numero può essere privato o aziendale. Se fosse un numero estero si prega di specificare il prefisso.'
                  label='Cellulare*'
                  placeholder='Cellulare'
               />
            </div>
            <div className='w-full justify-between flex'>
               <Button
                  onClick={() => setState({ ...state, step: 1 })}
                  variant={'primary'}
                  w='150px'
                  height={'55px'}>
                  Indietro
               </Button>
               <Button type='submit' variant={'primary'} w='150px' height={'55px'}>
                  Avanti
               </Button>
            </div>
         </div>
      </form>
   )
}
