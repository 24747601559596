import Container from 'Components/shared/Container'
import AllCourses from 'Pages/home/AllCourses'
import PlansSection from 'Pages/home/PlansSection'

const CorsiEcmPc = () => {
  return (
    <Container>
      <AllCourses />
      <PlansSection />
    </Container>
  )
}

export default CorsiEcmPc