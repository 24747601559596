import { useRecoilState } from 'recoil'
import Icons from '../Icons'
import { AccordionButton, AccordionItem, AccordionPanel, Box, Radio, RadioGroup } from '@chakra-ui/react'
import { filterState, userDataAtom } from 'Recoil/atoms'

const CourseState = ({ isOpen }: { isOpen: boolean }) => {
  const [state, setCState] = useRecoilState(filterState)
  const [userData] = useRecoilState(userDataAtom)

  return (
    <AccordionItem border={'none'} style={{
      visibility: userData?.accessToken ? "visible" : "hidden",
      height: userData?.accessToken ? "auto" : 0,
    }}>
      <AccordionButton
        px={0}
        py={0}
        _hover={{
          bg: ''
        }}>
        <Icons.AnimatedDash isOpen={isOpen}>
          <Box as='span' flex='1' textAlign='left'>
            Stato del corso
          </Box>
        </Icons.AnimatedDash>
      </AccordionButton>
      <AccordionPanel pb={5} pl={0}>
        <div className='pl-[20px] md:pl-[38px]'>
          <RadioGroup className='flex flex-col gap-[15px]' value={`${state.filter.status}`}
            onChange={(e) => {
              setCState(prev => ({
                ...prev,
                filter: {
                  ...prev.filter,
                  status: +e as 1 | 2 | 3 | 4
                },
                hasFilter: true,
                hasSearch: false,
              }))
            }}>
            <Radio value='1'>Corsi aperti</Radio>
            <Radio value='3'>Corsi iniziati</Radio>
            <Radio value='2'>Corsi in scadenza</Radio>
            <Radio value='4'>Corsi completati</Radio>
          </RadioGroup>
        </div>
      </AccordionPanel>
    </AccordionItem>)
}

export default CourseState