import { Spinner } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { moduileQuizAtom } from 'Recoil/atoms'
import { QuestionAndAnswers } from './QuestionAndAnswers'

export const QuizInProgress = ({ questionsList }: any) => {
   const [{ step }] = useRecoilState(moduileQuizAtom)

   return (
      <div className='flex flex-col gap-4 h-full '>
         <div className='flex flex-col'>
            {questionsList.length > 0 ? (
               <QuestionAndAnswers
                  totalQuestions={questionsList?.length}
                  key={questionsList[step]?.id}
                  question={questionsList[step]}
               />
            ) : (
               <>
                  <div className='flex justify-center'>
                     <Spinner />
                  </div>
               </>
            )}
         </div>
      </div>
   )
}
