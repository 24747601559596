import { useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import OngoingCard from 'Components/Cards/OngoingCard'
import Container from 'Components/shared/Container'
import { Footer } from 'Layouts/Footer/Footer'
import { userDataAtom } from 'Recoil/atoms'
import { TUserCourses } from 'Types/Models'
import { useState } from 'react'
import { useRecoilState } from 'recoil'

const OnGoing = () => {
  const { getUserCourses } = useApiRequests()
  const [userData] = useRecoilState(userDataAtom)

  // const [, setCState] = useRecoilState(filterState);

  const [isLargerThan768] = useMediaQuery(['(min-width: 768px)'])

  const [continueCourses, setContinueCourses] = useState<any[]>([])
  const [finishedCourses, setFinishedCourses] = useState<any[]>([])
  const [failedCourses, setFailedCourses] = useState<any[]>([])

  const { isFetching } = useQuery(['favourated'], {
    queryFn: () => getUserCourses(true, true, true),
    select: (res) => res.data.data,
    onSuccess: (res) => {
      setFailedCourses(res.expired)
      setContinueCourses(res.ongoing)
      setFinishedCourses(res.completed)
      return { ...res }
    }
  })

  const getCapitalizedName = () => {
    if (userData) {
      return (
        userData?.user_data.first_name?.charAt(0).toUpperCase() +
        userData?.user_data.first_name?.slice(1)
      )
    }
    return ''
  }

  return (
    <>
      <Container extraClasses='px-[15px] mb-10 gap-0 md:px-0 md:pt-0'>
        <div className='lg:px-0 mb-[30px] md:mb-[50px] mt-[25px] md:mt-[50px]'>
          <h2 className='text-[45px] font-bold leading-[50px] text-active font-nuinto mb-[11px]'>
            {isLargerThan768
              ? `${getCapitalizedName()}, continua con la tua formazione:`
              : `Ciao ${getCapitalizedName()}!`}
          </h2>
          <div>
            {!isLargerThan768 && (
              <h4 className='font-saira-normal text-[23px] text-active'>
                Continua con la tua formazione
              </h4>
            )}
          </div>
        </div>
        {/* // ! continua */}
        {(isFetching || continueCourses.length > 0) && (
          <div>
            <div className='lg:px-0 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-9 2xl:grid-cols-4'>
              {continueCourses.map((course: TUserCourses) => {
                return <OngoingCard.Card key={course.id} {...course} enrolled={true} />
              })}

              {isFetching &&
                Array.from(Array(4).keys()).map((item) => <OngoingCard.Skeleton key={item} />)}
            </div>
          </div>
        )}

        {/* // ! success */}
        {(isFetching || finishedCourses.length > 0) && (
          <div>
            <div>
              <div className='mb-5 mt-[30px] md:mt-[35px] md:mb-[42px]'>
                <h3 className='text-[20px] text-active font-nuinto leading-[22px] font-bold md:text-[40px] md:leading-[44px] '>
                  Corsi completati :)
                </h3>
              </div>
            </div>
            <div className='lg:px-0 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-9 2xl:grid-cols-4'>
              {finishedCourses.map((course: TUserCourses) => {
                return <OngoingCard.Card key={course.id} {...course} />
              })}

              {isFetching &&
                Array.from(Array(4).keys()).map((item) => <OngoingCard.Skeleton key={item} />)}
            </div>
          </div>
        )}

        {/* // ! failed */}
        {(isFetching || failedCourses.length > 0) && (
          <div>
            <div>
              <div className='mb-5 mt-[30px] md:mt-[35px] md:mb-[42px]'>
                <h3 className='text-[20px] text-active font-nuinto leading-[22px] font-bold md:text-[40px] md:leading-[44px] '>
                  Corsi chiusi e non completati :(
                </h3>
              </div>
            </div>
            <div className='lg:px-0 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-9 2xl:grid-cols-4'>
              {failedCourses.map((course: TUserCourses) => {
                return <OngoingCard.Card key={course.id} {...course} />
              })}

              {isFetching &&
                Array.from(Array(4).keys()).map((item) => <OngoingCard.Skeleton key={item} />)}
            </div>
          </div>
        )}
      </Container>
      <Footer />
    </>
  )
}

export default OnGoing
