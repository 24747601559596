import { Button, useDisclosure } from '@chakra-ui/react'
import { ModalWrapper } from 'Components/Modals/ModalWrapper'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const CourseWelcomingCard = ({
   description,
   introductionDetails
}: {
   title: string
   description: string
   introductionDetails?: string
}) => {
   const { isOpen, onClose, onOpen } = useDisclosure()
   const { t } = useTranslation()

   return (
      <div className='bg-white h-[240px] justify-between rounded-md min-w-[410px] text-[#848484]  p-5 flex flex-col gap-5'>
         <span className='font-bold text-xs line-clamp-2'>Panoramica del corso</span>
         <p className='text-base line-clamp-4'>{description}</p>
         <Button onClick={onOpen} variant={'secondary'} w='50%' alignSelf={'end'}>
            {t('Preview')}
         </Button>
         <ModalWrapper size='3xl' isOpen={isOpen} onClose={onClose}>
            <div className='relative flex justify-center'>
               <p className='p-4 w-full' dangerouslySetInnerHTML={{ __html: introductionDetails || '' }} />
            </div>
         </ModalWrapper>
      </div>
   )
}
