import React from 'react'
import { Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { dashboardCoursesAtom } from 'Recoil/atoms'
import { useRecoilState } from 'recoil'
import { useApiRequests } from 'API/ApiRequests'
import moment from 'moment'

export const CompletedCourses = () => {
   const [courses] = useRecoilState(dashboardCoursesAtom)
   const { t } = useTranslation()
   const { getCerteficatiom } = useApiRequests()

   const fetchCertificate = async (courseId: string, type: number) => {
      try {
         const res = await getCerteficatiom(courseId || '', type)
         const blob = new Blob([res.data], { type: 'application/pdf' })
         const link = document.createElement('a')
         link.href = URL.createObjectURL(blob)
         link.download = 'certification.pdf'
         link.click()
      } catch (error) {
         // console.log(error)
      }
   }

   return (
      <div
         className='overflow-y-scroll
      lg:h-[85vh]'>
         {courses.completed.length === 0 ? (
            <div className='flex w-full my-3 justify-center text-primary font-semibold'>
               {t('There is no completed courses yet!')}
            </div>
         ) : (
            <>
               <div className='p-5 pb-3 flex items-center text-xs  gap-2 border-b-2 border-[#F5F5F5] justify-between'>
                  <span className='w-[75%] '>{t('Course Title')}</span>
                  <span className='w-[15%] text-center '>{t('Attendance')}</span>
                  <span className='w-[92px] text-center'>ECM</span>
               </div>
               <div className='flex flex-col h-[75vh] overflow-scroll'>
                  {courses.completed.map((item: any) => (
                     <div
                        className='p-5 flex items-center md:text-sm text-xs  gap-2 border-b-2 border-[#F5F5F5] justify-between'
                        key={item.id}>
                        <span className='w-[75%] flex flex-col gap-2 font-bold'>
                           {item.title}
                           <div className='flex gap-3'>
                              <div
                                 className='bg-blueDark rounded-[5px] w-fit flex flex-row gap-2.5 items-start justify-start shrink-0 
                                 relative pt-[5px] pr-2.5 pb-[5px] pl-2.5'>
                                 <div className='text-white text-[10px] font-bold text-left relative flex items-center justify-start'>
                                    ECMCLUB{' '}
                                 </div>
                              </div>
                              <div className='bg-[#f5f5f5] rounded-[5px] w-fit flex flex-row gap-2.5 items-start justify-start shrink-0 relative pt-[5px] pr-2.5 pb-[5px] pl-2.5'>
                                 <div className='text-[#000000] text-[10px] font-normal text-left relative flex items-center justify-start'>
                                    {t('finished on')}{' '}
                                    {moment(item?.finished_at).locale('it').format('DD MMM YYYY')}
                                 </div>
                              </div>
                           </div>
                        </span>

                        <Button
                           onClick={() => fetchCertificate(item.id, 2)}
                           border='#848484 solid 2px'
                           bg='#F5F5F5'
                           width={'99px'}
                           height='44px'>
                           <span className='text-xs font-normal'>{t('Download')}</span>
                        </Button>
                        <Button
                           disabled={!item.can_dounload_both}
                           onClick={() => fetchCertificate(item.id, 1)}
                           border='#848484 solid 2px'
                           bg='#F5F5F5'
                           width={'99px'}
                           height='44px'>
                           <span className='text-xs font-normal'>{t('Download')}</span>
                        </Button>
                     </div>
                  ))}
               </div>
            </>
         )}
      </div>
   )
}
