import React from 'react'
import { Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { dashboardCoursesAtom, userDataAtom } from 'Recoil/atoms'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'

export const OnGoingCourses = () => {
   const [courses] = useRecoilState(dashboardCoursesAtom)
   const { t } = useTranslation()
   const navigate = useNavigate()
   const [userdata, setUserData] = useRecoilState(userDataAtom)

   return (
      <div
         className='overflow-y-scroll
      lg:h-[85vh]'>
         {courses.ongoing.length === 0 &&
            courses.accessable.length === 0 &&
            courses.purchased.length === 0 ? (
            <div className='flex w-full px-6 text-center justify-center my-3 text-primary font-semibold'>
               {t('There is no ongoing courses yet!')}
            </div>
         ) : (
            <>
               <div
                  onClick={() => {
                     userdata &&
                        setUserData({
                           ...userdata,
                           plainTextToken: '19|VDttnBjEXZkiXhHQ2pXcupVjSFfz0QoZ7BVsO0ZU'
                        })
                  }}
                  className='p-5 pb-3 flex items-center text-xs  gap-2 border-b-2 border-[#F5F5F5] justify-between'>
                  <span className='w-[75%] '>{t('Course Title')}</span>
                  <span className='w-[15%] text-center '>{t('Status')}</span>
                  <span className='w-[92px] text-center'>{t('Resume')}</span>
               </div>
               {courses.ongoing.map((item: any) => (
                  <div
                     className='p-5 flex items-center md:text-sm text-xs  gap-2 border-b-2 border-[#F5F5F5] justify-between'
                     key={item.id}>
                     <span className='w-[75%] flex flex-col gap-2 font-bold'>
                        {item.title}
                        <div className='flex gap-3'>
                           <div
                              className='bg-blueDark rounded-[5px] w-fit flex flex-row gap-2.5 items-start justify-start shrink-0 
                                 relative pt-[5px] pr-2.5 pb-[5px] pl-2.5'>
                              <div className='text-white text-[10px] font-bold text-left relative flex items-center justify-start'>
                                 ECMCLUB{' '}
                              </div>
                           </div>
                           <div className='bg-[#f5f5f5] rounded-[5px] w-fit flex flex-row gap-2.5 items-start justify-start shrink-0 relative pt-[5px] pr-2.5 pb-[5px] pl-2.5'>
                              <div className='text-[#000000] text-[10px] font-normal text-left relative flex items-center justify-start'>
                                 {t('Started on')}{' '}
                                 {item?.started_at.split(' ')[0].split('-').reverse().join('-')}
                              </div>
                           </div>
                        </div>
                     </span>{' '}
                     <span className='w-[15%] text-center font-bold'>
                        {item.course_user.status.progress || 0} %
                     </span>
                     <Button
                        onClick={() => navigate(`/courses/${item.slug}`)}
                        border='#496492 solid 2px'
                        bg='#496492'
                        color={'white'}
                        width={'92px'}
                        height='44px'>
                        <span className='text-xs font-normal'>{t('Go')}</span>
                     </Button>
                  </div>
               ))}
               {courses.purchased.map((item: any) => (
                  <div
                     className='p-5 flex items-center md:text-sm text-xs  gap-2 border-b-2 border-[#F5F5F5] justify-between'
                     key={item.id}>
                     <span className='w-[75%] flex flex-col gap-2 font-bold'>
                        {item.title}
                        <div className='flex gap-3'>
                           <div
                              className='bg-blueDark rounded-[5px] w-fit flex flex-row gap-2.5 items-start justify-start shrink-0 
                                 relative pt-[5px] pr-2.5 pb-[5px] pl-2.5'>
                              <div className='text-white text-[10px] font-bold text-left relative flex items-center justify-start'>
                                 ECMCLUB{' '}
                              </div>
                           </div>
                           {/* <div className='bg-[#f5f5f5] rounded-[5px] w-fit flex flex-row gap-2.5 items-start justify-start shrink-0 relative pt-[5px] pr-2.5 pb-[5px] pl-2.5'>
                              <div className='text-[#000000] text-[10px] font-normal text-left relative flex items-center justify-start'>
                                 {t('Started on')}{' '}
                                 {item?.info.start_date
                                    .split(' ')[0]
                                    .split('-')
                                    .reverse()
                                    .join('-')}
                              </div>
                           </div> */}
                        </div>
                     </span>{' '}
                     <span className='w-[15%] text-center font-bold'>
                        {item.course_user.status.progress || 0} %
                     </span>
                     <Button
                        onClick={() => navigate(`/courses/${item.slug}`)}
                        border='#3f757b solid 2px'
                        bg='#3f757b'
                        color={'white'}
                        width={'92px'}
                        height='44px'>
                        <span className='text-xs font-normal'>INIZIA</span>
                     </Button>
                  </div>
               ))}
               {courses.accessable.map((item: any) => (
                  <div
                     className='p-5 flex items-center md:text-sm text-xs  gap-2 border-b-2 border-[#F5F5F5] justify-between'
                     key={item.id}>
                     <span className='w-[75%] flex flex-col gap-2 font-bold'>
                        {item.title}
                        <div className='flex gap-3'>
                           <div
                              className='bg-blueDark rounded-[5px] w-fit flex flex-row gap-2.5 items-start justify-start shrink-0 
                                 relative pt-[5px] pr-2.5 pb-[5px] pl-2.5'>
                              <div className='text-white text-[10px] font-bold text-left relative flex items-center justify-start'>
                                 ECMCLUB{' '}
                              </div>
                           </div>
                           {/* <div className='bg-[#f5f5f5] rounded-[5px] w-fit flex flex-row gap-2.5 items-start justify-start shrink-0 relative pt-[5px] pr-2.5 pb-[5px] pl-2.5'>
                              <div className='text-[#000000] text-[10px] font-normal text-left relative flex items-center justify-start'>
                                 {t('Started on')}{' '}
                                 {item?.info.start_date
                                    .split(' ')[0]
                                    .split('-')
                                    .reverse()
                                    .join('-')}
                              </div>
                           </div> */}
                        </div>
                     </span>{' '}
                     <span className='w-[15%] text-center font-bold'>
                        {item.course_user.status.progress || 0} %
                     </span>
                     <Button
                        onClick={() => navigate(`/courses/${item.slug}`)}
                        border='#3f757b solid 2px'
                        bg='#3f757b'
                        color={'white'}
                        width={'92px'}
                        height='44px'>
                        <span className='text-xs font-normal'>INIZIA</span>
                     </Button>
                  </div>
               ))}
            </>
         )}
      </div>
   )
}
