import { Input, Button } from '@chakra-ui/react'
import { Checkout } from 'Components/Checkout'
import { PaymentCard } from 'Components/PaymentCard'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const PaymentModal = () => {
   const [checkout] = useState(false)

   const { handleCheckout } = PaymentCard()
   const { t } = useTranslation()

   return (
      <>
         {checkout ? (
            <Checkout />
         ) : (
            <div className='flex rounded-lg flex-col bg-white items-center gap-10'>
               <div className='pt-7 px-7 flex flex-col gap-10 items-center'>
                  <h3 className='text-lg font-bold text-center'>
                     {t(
                        'Si, desidero agggiungere il pacchetto PLUS al mio account al costo annuo di'
                     )}
                  </h3>
                  <span className='text-3xl font-bold'>130,00€</span>
                  <Button
                     w='315px'
                     alignSelf={'center'}
                     height={'70px'}
                     bg='#3C8287'
                     onClick={handleCheckout}
                     variant={'primary'}>
                     Acquista
                  </Button>
                  <div className='flex justify-between gap-2'>
                     <input type={'checkbox'} />
                     <span className='text-xs'>
                        {t('I accept the terms and conditions of this store')}
                     </span>
                  </div>
               </div>
               <div className='px-4 py-7 rounded-b-lg bg-[#F8F8F8] w-full items-center flex flex-col gap-5'>
                  <span className='text-sm'>{t('Hai un codice sconto?')}</span>
                  <Input
                     width='full'
                     height={'55px'}
                     placeholder='Inserisci codice sconto...'
                     fontSize={'15px'}
                     bg='white'
                     borderColor='#D9D9D9'
                  />
                  <span className='font-bold text-sm'>{t('Aggiorna prezzo finale')}</span>
               </div>
            </div>
         )}
      </>
   )
}
