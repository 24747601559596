import { ReactNode } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react'

type Props = {
   isOpen: boolean
   onClose: () => void
   children: ReactNode
   size?: string
   color?: string
}

export const ModalWrapper = ({ isOpen, onClose, children, size, color }: Props) => {
   return (
      <Modal size={size} isOpen={isOpen} onClose={onClose}>
         <ModalOverlay />
         <ModalContent rounded={'6px'} p={0} mt={150} borderRadius={0}>
            <ModalCloseButton
               _focus={{ boxShadow: 'none' }}
               top={'-35px'}
               right={0}
               color='white'
            />
            <ModalBody
               width={'max-content'}
               rounded={'6px'}
               w='full'
               p='0'
               bgColor={color ? color : 'light-gray'}>
               {children}
            </ModalBody>
         </ModalContent>
      </Modal>
   )
}
