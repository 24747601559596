import { useApiRequests } from 'API/ApiRequests'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Skeleton } from '@chakra-ui/react'
import { TPlan } from 'Types/Models'
import PlanSection from './PlanSection'
const CorsiEcmMobile = () => {
  const { getPlansNew } = useApiRequests()

  const [allPlans, setAllPlans] = useState([])

  const { isFetching } = useQuery(['allPlans'], {
    queryFn: () => getPlansNew(),
    select: (res) => res.data.data,
    onSuccess: (res) => setAllPlans(res.plans)
  })

  return (
    <div>
      {isFetching ? (
        <Skeleton />
      ) : (
        allPlans.map((plan: TPlan) => {
          return <PlanSection key={plan.id} {...plan} />
        })
      )}
    </div>
  )
}

export default CorsiEcmMobile