import { TModule, TQuiz } from 'Types/Models'

export const getColor = (module: TModule | TQuiz): string => {
   if (module.type_text === 'QUIZ') {
      if ((module as TQuiz).quiz_user.status.attempts === (module as TQuiz).max_attempts) return 'rgb(245, 29, 81)'
      if ((module as TQuiz).quiz_user.status.last_success_attempt) return '#159600'
      if (!(module as TQuiz).quiz_user.can_start) return '#B1B1B1'
      return '#335F6B'
   } else {
      if (!(module as TModule).module_user.can_start) return '#B1B1B1'
      if ((module as TModule).module_user.finished_at && (module as TModule).module_user.started_at)
         return '#159600'
      return '#335F6B'
   }
}

export function prepareAllCoursesURL(
   page: number,
   professionId?: number | string,
   specializationId?: number | string,
   chanelSlug?: string
) {
   // & update endpoint
   // let url = `/courses/get?page=${page}`
   let url = `/v2/courses/all?page=${page}`

   if (specializationId) {
      url += `&profession=${professionId}&specialization=${specializationId}`
   }

   if (chanelSlug) {
      url += `&channel_slug=${chanelSlug}`
   }

   return url
}

export function prepareAllFaqsURL(search?: string) {
   let url = '/v1/faqs?'

   if (search) {
      url += `search=${search}`
   }

   return url
}
