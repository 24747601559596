import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Package, TCourse } from 'Types/Models'

export const Packages = () => {
   const { getPackages } = useApiRequests()

   const { data } = useQuery(['Packages'], {
      queryFn: getPackages,
      select: (res) => res.data
   })

   const [collapsed, setcollapsed] = useState(false)

   return (
      <div className='sm:container py-20 w-[96%] mx-auto flex flex-col gap-14 lg:min-h-[85vh]'>
         {data?.map((pack: Package) => (
            <div
               key={pack.id}
               className='flex flex-col gap-5 rounded-[5px]  p-5 shadow-lg bg-white'>
               <div className='flex md:items-start items-center justify-center flex-wrap gap-7'>
                  <div className='w-[190px] flex-shrink-0 min-w-[150px] h-[120px] relative rounded'>
                     <img
                        src={`${process.env.REACT_APP_MEDIA_URL}${pack.image}`}
                        alt=' '
                        className='absolute w-full h-full rounded object-cover shadow-primary shadow-sm'
                     />
                     <div className='flex justify-start items-center  absolute z-10 left-3 top-3'>
                        <div className='flex justify-start items-start  relative overflow-hidden gap-2.5 px-2 py-[7px] rounded-tl-[5px] rounded-bl-[5px] bg-white'>
                           <p className=' flex gap-1 text-xs font-bold text-left text-[#496492]'>
                              {pack?.courses.reduce(
                                 (prev: number, curr: Partial<TCourse>) =>
                                    Number(prev) + Number(curr.completion_credits),
                                 0
                              )}
                              <span>ECM</span>
                           </p>
                        </div>
                        <div className='flex justify-start items-start  relative overflow-hidden gap-2.5 px-2 py-[7px] rounded-tr-[5px] rounded-br-[5px] bg-[#496492]'>
                           <p className=' text-xs font-bold flex gap-1 items-center text-left text-white'>
                              <span>{pack?.courses.length} </span> <span> Corsi</span>
                           </p>
                        </div>
                     </div>
                  </div>

                  <div className='flex flex-col md:flex-1'>
                     <p className=' text-2xl font-bold text-left mb-1 text-[#496492]'>
                        {pack.title}{' '}
                     </p>
                     <p
                        className='text-sm text-left text-black'
                        dangerouslySetInnerHTML={{ __html: pack.description }}
                     />
                  </div>

                  {pack.purchased ? (
                     <button
                        disabled
                        className='flex flex-col gap-3 opacity-60 cursor-not-allowed items-center'>
                        <p className='top-0 text-center text-black'>
                           <span className='text-[22.295591354370117px] font-bold text-center text-green-600'>
                              €{pack.price}
                           </span>
                           <br />
                           <span className='text-[12.38644027709961px] text-center text-green-600'>
                              IVA INCLUSA
                           </span>
                        </p>
                        <button className='rounded-[5px] bg-green-600 w-[170px] h-[38px] cursor-not-allowed text-white'>
                           ACQUISTATO
                        </button>
                     </button>
                  ) : (
                     <Link
                        to={`/checkout?id=${pack.id}&mode=${3}`}
                        className='flex flex-col gap-3 items-center'>
                        <p className='top-0 text-center text-black'>
                           <span className='text-[22.295591354370117px] font-bold text-center text-[#496492]'>
                              €{pack.price}
                           </span>
                           <br />
                           <span className='text-[12.38644027709961px] text-center text-[#496492]'>
                              IVA INCLUSA
                           </span>
                        </p>
                        <button className='rounded-[5px] bg-[#496492] w-[170px] h-[38px] text-white'>
                           ACQUISTA ORA
                        </button>
                     </Link>
                  )}
               </div>

               <div className='w-full flex flex-col group gap-7 p-2.5 rounded-[5px] bg-[#f8f8f8]'>
                  <div
                     onClick={() => setcollapsed(!collapsed)}
                     className='flex hover:opacity-50 transition-all cursor-pointer justify-between items-center flex-grow  px-3 relative'>
                     <p className=' text-base font-bold text-left text-black'>
                        Visualizza tutti i titoli dei corsi
                     </p>
                     {collapsed ? (
                        <svg
                           width='14'
                           height='3'
                           viewBox='0 0 14 3'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'>
                           <path
                              d='M13 1.12769H1'
                              stroke='#111827'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     ) : (
                        <svg
                           width={20}
                           height={21}
                           viewBox='0 0 20 21'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className=' w-5 h-5 relative'
                           preserveAspectRatio='none'>
                           <path
                              d='M9.99967 3.46094V16.7943M16.6663 10.1276L3.33301 10.1276'
                              stroke='#111827'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                           />
                        </svg>
                     )}
                  </div>
                  {collapsed && (
                     <div className='flex flex-col md:gap-5 gap-7'>
                        {pack?.courses?.map((course, i) => (
                           <div
                              key={course.id}
                              className='flex justify-start  items-start self-stretch md:flex-nowrap flex-wrap relative gap-3'>
                              <p className=' text-base font-bold text-left text-[#496492] w-4'>
                                 {i + 1}.
                              </p>
                              <img
                                 className='self-stretch object-cover shrink-0 md:w-[115px] md:h-[75px] w-full h-36 rounded-[5px]'
                                 src={`${process.env.REACT_APP_MEDIA_URL}${course.cover}`}
                              />
                              <div className='flex flex-col justify-start items-start  relative gap-0.5'>
                                 <p className=' text-base max-md:line-clamp-2 font-bold text-left text-[#496492]'>
                                    {course.title}
                                 </p>

                                 {course?.description && (
                                    <p
                                       className=' text-sm text-left text-[#496492]'
                                       dangerouslySetInnerHTML={{ __html: course?.description }}
                                    />
                                 )}
                              </div>

                              <Link className='bg-[#496492] text-white rounded-md flex-shrink-0 md:ml-auto px-3 py-2 md:w-fit w-full justify-center items-center text-center' to={`/courses/${course.slug}`}>Vai al corso</Link>
                           </div>
                        ))}
                     </div>
                  )}
               </div>
            </div>
         ))}
      </div>
   )
}
