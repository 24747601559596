import {
   Drawer,
   DrawerBody,
   DrawerHeader,
   DrawerContent,
   useMediaQuery,
   DrawerCloseButton,
   Spinner
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { AttemptsCard } from 'Components/Cards/AttemptsCard'
import { useTranslation } from 'react-i18next'

export type TAttemptQuestion = {
   text: string
   options: {
      attempts: Record<number, { is_selected: boolean; is_correct: boolean }>
      text: string
   }[]
}

export const AttemptsDrawer = ({
   isOpen,
   onClose,
   id
}: {
   onClose: () => void
   isOpen: boolean
   id: any
}) => {
   const [smallScreen] = useMediaQuery('(max-width: 1024px)')
   const { getAttempts } = useApiRequests()
   const { data, isLoading } = useQuery(['Attempts', id], {
      queryFn: () => getAttempts(id),
      select: (res) => res?.data || []
   })

   const { t } = useTranslation()

   return (
      <Drawer
         size={smallScreen ? 'full' : 'md'}
         isOpen={isOpen}
         placement='right'
         onClose={onClose}>
         <DrawerContent>
            <div className='flex justify-between items-center lg:bg-secondary lg:text-white bg-primary text-secondary'>
               <DrawerCloseButton top={3.5} />
               <DrawerHeader>{t('Attempts')}</DrawerHeader>
            </div>
            <DrawerBody p={0}>
               {isLoading ? (
                  <div className='flex w-full my-7 justify-center h-full'>
                     <Spinner />
                  </div>
               ) : (
                  <>
                     {data?.map((question: TAttemptQuestion, i: number) => (
                        <AttemptsCard
                           question={question}
                           key={Math.random()}
                           totalQuestions={data?.length}
                           questionIndex={i + 1}
                        />
                     ))}
                  </>
               )}
            </DrawerBody>
         </DrawerContent>
      </Drawer>
   )
}
