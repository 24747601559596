import { Button } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { useTranslation } from 'react-i18next'
import { FiExternalLink } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'

type Purches = {
   title: string
   mode: number
   mode_text: string
   date: string
   price: string
   receipt_url: string | undefined
   invoice_url: string | undefined
}

export const Subscription = () => {
   const { t } = useTranslation()
   const { getStripePortal, getUserPurchases } = useApiRequests()
   const stripePortal = useQuery({
      queryFn: getStripePortal,
      select: (res) => res.data.data
   })

   const navigate = useNavigate()

   const { data, isLoading } = useQuery(['purcheses'], {
      queryFn: getUserPurchases,
      select: (res) => res.data.data
   })

   return (
      <div className='flex divide-x-2 lg:flex-row h-full overflow-y-auto flex-wrap flex-col divide-[#F5F5F5]'>
         <div className='lg:p-6 p-1 w-full divide-[#F5F5F5] divide-y-2'>
            <div className='flex justify-between items-center gap-1 py-10'>
               <p className='text-base font-bold text-left text-black'>Acquisti</p>
               <div className='flex flex-wrap gap-9'>
                  <a href={stripePortal.data} className='w-full'>
                     <Button width={'full'} isLoading={stripePortal.isLoading} variant={'primary'}>
                        {t('Manage my subsicription')} <FiExternalLink className='ml-2 text-xl' />
                     </Button>
                  </a>
               </div>
            </div>
            {data?.length === 0 && !isLoading && (
               <div className='flex bg-[#F8F8F8] py-16 justify-center items-center flex-col gap-7'>
                  <p className='text-lg text-center text-[#496391] '>
                     <span className=' text-lg text-center text-[#496391]'>
                        Non hai ancora effettuato degli acquisti!
                     </span>
                     <br />
                     <span className=' text-lg font-bold text-center text-[#496391]'>
                        Scopri il nostro catalogo corsi
                     </span>
                  </p>
                  <Button
                     onClick={() => {
                        navigate('/')
                     }}
                     isLoading={isLoading}
                     fontSize=''
                     className='bg-blueDark rounded text-white text-xs h-11 hover:bg-blueDark/90 transition-all'>
                     VAI AL CATALOGO{' '}
                  </Button>
               </div>
            )}

            {data?.length > 0 && !isLoading && (
               <div className='flex flex-col'>
                  <div className='grid grid-cols-12 py-10  justify-between gap-5'>
                     <span className='col-span-4 text-xs'>Dettaglio</span>
                     <span className='col-span-2 justify-self-center text-center text-xs'>
                        Data di pagamento
                     </span>
                     <span className='col-span-2 justify-self-center text-center text-xs'>
                        Importo
                     </span>
                     <span className='col-span-2 justify-self-center text-center text-xs'>
                        Ricevuta
                     </span>
                     <span className='col-span-2 justify-self-center text-center text-xs'>
                        Fattura
                     </span>
                  </div>
                  <div className='flex flex-col gap-3'>
                     {data
                        .sort(
                           (a: Purches, b: Purches) =>
                              new Date(b.date).getTime() - new Date(a.date).getTime()
                        )
                        ?.map((purchase: Purches, i: number) => (
                           <div
                              key={i}
                              className='flex flex-col bg-[#F8F8F8] rounded-md lg:px-5 px-2 py-5 gap-3'>
                              <div className='flex justify-start items-start relative overflow-hidden w-fit gap-2.5 p-[5px] rounded-[5px] bg-[#a6aac4]'>
                                 <p className='text-[9px] font-bold text-left uppercase text-white'>
                                    {purchase.mode_text}
                                 </p>
                              </div>
                              <div className='grid grid-cols-12 overflow-x-auto items-center justify-between gap-5'>
                                 <div className='col-span-4  text-xs flex-col flex gap-1'>
                                    <p className='text-sm font-bold text-left text-black line-clamp-3'>
                                       {purchase.title}
                                    </p>
                                 </div>
                                 <span className='col-span-2 justify-self-center text-center text-xs'>
                                    <p className='text-sm text-center text-black'>
                                       {purchase.date.split('T')[0].split('-').reverse().join('-')}
                                    </p>
                                 </span>
                                 <span className='col-span-2 justify-self-center text-center text-xs'>
                                    <p className='text-sm text-center text-black'>
                                       {purchase.price}
                                    </p>
                                 </span>
                                 <a
                                    style={{
                                       opacity: !purchase?.receipt_url ? 0.5 : 1,
                                       cursor: !purchase?.receipt_url ? 'not-allowed' : 'pointer'
                                    }}
                                    target='_blank'
                                    href={`${process.env.REACT_APP_MEDIA_URL}${purchase?.receipt_url}`}
                                    className='col-span-2 py-5 border-gray border-2 rounded-md text-dark-grey flex items-center justify-center justify-self-stretch text-center text-xs'
                                    rel='noreferrer'>
                                    Scarica
                                 </a>
                                 <a
                                    style={{
                                       opacity: !purchase?.invoice_url ? 0.5 : 1,
                                       cursor: !purchase?.invoice_url ? 'not-allowed' : 'pointer'
                                    }}
                                    target='_blank'
                                    href={`${process.env.REACT_APP_MEDIA_URL}${purchase?.invoice_url}`}
                                    className='col-span-2 py-5 border-gray border-2 rounded-md text-dark-grey flex items-center justify-center justify-self-stretch text-center text-xs'
                                    rel='noreferrer'>
                                    Scarica
                                 </a>
                              </div>
                           </div>
                        ))}
                  </div>
               </div>
            )}
         </div>
         {/* <RightBar /> */}
      </div>
   )
}
