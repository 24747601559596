import React from 'react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box } from '@chakra-ui/react'
import { TAttemptQuestion } from 'Components/Drawers/AttemptsDrawer'
import { BsCircle, BsRecordCircle } from 'react-icons/bs'
import { selectedModuleAtom } from 'Recoil/atoms'
import { useRecoilState } from 'recoil'
import { TQuiz } from 'Types/Models'
import { useTranslation } from 'react-i18next'
import { FaCheck, FaTimes } from 'react-icons/fa'

export const AttemptsCard = ({
   question,
   totalQuestions,
   questionIndex
}: {
   question: TAttemptQuestion
   totalQuestions: number
   questionIndex: number
}) => {
   const [selectedModule] = useRecoilState(selectedModuleAtom)
   const attempts = (selectedModule as TQuiz).quiz_user.status.attempts
   const map = new Map<number, string>([
      [1, 'grid-cols-1'],
      [2, 'grid-cols-2'],
      [3, 'grid-cols-3'],
      [4, 'grid-cols-4'],
      [5, 'grid-cols-5']
   ])
   const { t } = useTranslation()

   function attemptsHistory(question: TAttemptQuestion): Record<number, boolean> {
      const arrayOfBooleans: Record<number, boolean> = {}

      Object.values(question.options[0].attempts).forEach(
         (v, i) => (arrayOfBooleans[i + 1] = false)
      )

      question.options.map((option) => {
         return Object.values(option.attempts).forEach((value, i) => {
            if (value.is_correct) {
               arrayOfBooleans[i + 1] = true
            }
         })
      })

      return arrayOfBooleans
   }

   return (
      <Accordion defaultIndex={[]} allowMultiple>
         <AccordionItem borderBottom={'1px #e7e7e7 solid'} borderTop={0}>
            <h2>
               <AccordionButton
                  _focus={{ boxShadow: 'none' }}
                  _hover={{ bg: 'none' }}
                  p={5}
                  alignItems='start'
                  justifyContent={'center'}>
                  <Box flex='1' textAlign='left'>
                     <div>
                        <span className='text-xs font-bold'>
                           {t('Questions')}: {questionIndex} {t('of')} {totalQuestions}
                        </span>
                        <p className='text-xs leading-[18px]'>{question.text}</p>
                     </div>{' '}
                  </Box>
               </AccordionButton>
            </h2>
            <AccordionPanel p={4} pt={0} bg='#F8F8F8'>
               <div className='flex gap-3'>
                  <div className='w-2/3 flex flex-col gap-3'>
                     <span className='font-bold text-xs'>Risposte</span>
                  </div>
                  <div className='flex flex-col gap-3 w-[40%] h-auto justify-around'>
                     <div className={`grid ${map.get(attempts)} justify-items-end`}>
                        {Object.values(attemptsHistory(question)).map((result) => {
                           if (result) {
                              return <FaCheck key={Math.random()} className='text-green-600' />
                           } else {
                              return <FaTimes key={Math.random()} className='text-red-600' />
                           }
                        })}
                     </div>
                  </div>
               </div>
               <div className='flex mt-2 gap-3'>
                  <div className='w-2/3 flex flex-col gap-3'>
                     {question.options.map((option) => (
                        <span key={Math.random()} className='text-xs '>
                           {option.text}
                        </span>
                     ))}
                  </div>

                  <div className='flex flex-col gap-3 w-[40%] h-auto justify-around'>
                     {question.options.map((option) => {
                        return (
                           <div
                              key={Math.random()}
                              className={`grid ${map.get(attempts)} justify-items-end`}>
                              {Object.values(option.attempts).map((value) => {
                                 if (value.is_selected) {
                                    return (
                                       <BsRecordCircle key={Math.random()} className='text-base' />
                                    )
                                 } else {
                                    return <BsCircle key={Math.random()} className='text-base' />
                                 }
                              })}
                           </div>
                        )
                     })}
                  </div>
               </div>
            </AccordionPanel>
         </AccordionItem>
      </Accordion>
   )
}
