import { userDataAtom } from "Recoil/atoms"
// import { useMediaQuery } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"

import { Footer } from "Layouts/Footer/Footer"
import AdBanner from "Components/AdBanner"

import FeaturedCourses from "./FeaturedCourses"
import PlansSection from "./PlansSection"
import UserCourses from "./UserCourses"
import ChosenForYou from "./ChosenForYou"
import AllCourses from "./AllCourses"
import AdBannerMain from "Components/AdBannerMain"
// import BabyYoda from "yoda/BabyYoda"

export const HomePage = () => {
   const [userData] = useRecoilState(userDataAtom)

   // const [isLargerThan768] = useMediaQuery(['(min-width: 768px)'])

   const navigate = useNavigate()

   const [linkHasCopied, setLinkHasCopied] = useState(false)
   useEffect(() => {
      let timer: NodeJS.Timeout
      if (linkHasCopied) {
         timer = setTimeout(() => {
            setLinkHasCopied(false)
            navigate("/offerta")
         }, 1000)
      }
      return () => clearTimeout(timer)
   }, [linkHasCopied])

   return (
      <>
         <div className='w-[96%] min-h-[80vh] flex flex-col gap-0 sm:container mx-auto pb-8 pt-[25px] md:pt-[50px]'>
            {/* <BabyYoda /> */}

            <AdBannerMain />

            <FeaturedCourses />

            {userData?.accessToken && (
               <>
                  <UserCourses />
               </>
            )}

            <AdBanner top={true} />

            {userData?.accessToken && (
               <>
                  <ChosenForYou />
               </>
            )}
            {userData?.accessToken && <AdBanner top={false} />}

            <AllCourses />

            <PlansSection />
         </div>

         <Footer />
      </>
   )
}
