import { useMediaQuery } from '@chakra-ui/react'
import CorsiEcmMobile from './CorsiEcmMobile'
import CorsiEcmPc from './CorsiEcmPc'
import { Footer } from 'Layouts/Footer/Footer'

// !h-fit

const CorsiEcm = () => {
  const [isLargerThan768] = useMediaQuery(['(min-width: 768px)'])

  return (
    <>
      <div>
        <div className='text-active font-saira-normal md:text-center mt-5 md:mt-10 md:container md:w-[96%] md:mx-auto md:pt-8 mb-5 md:mb-0 px-[15px]'>
          <h2 className='text-[45px] leading-[50px] md:text-[100px] md:leading-[110px] font-bold'>
            Corsi ECM
          </h2>
          <p className='mt-[5px] md:text-[38px] md:leading-[40px]'>
            Scopri la nostra selezione di corsi ECM
          </p>
        </div>
        {isLargerThan768 ? <CorsiEcmPc /> : <CorsiEcmMobile />}
      </div>
      <Footer />
    </>
  )
}

export default CorsiEcm
