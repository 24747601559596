import { useRecoilState } from "recoil"
import { filterState } from "Recoil/atoms"
import Icons from "./Icons"
import { Radio, RadioGroup } from "@chakra-ui/react"

const Sort = () => {
  const [state, setCState] = useRecoilState(filterState)

  return (
    <div className='flex flex-col gap-5'>
      <h3 className='text-[20px] font-saira-normal text-active flex items-center gap-2 font-bold'>
        <Icons.Sort />
        ORDINA
      </h3>
      <RadioGroup
        className='flex flex-col gap-5 pl-[25px] md:pl-[50px]'
        value={`${state.filter.sort}`}
        onChange={(e) => {
          setCState((prev) => ({
            ...prev,
            filter: {
              ...prev.filter,
              sort: +e as 1 | 2 | 3 | 4,
            },
            hasFilter: true,
            hasSearch: false,
          }))
        }}>
        <Radio value='1'>I più seguiti</Radio>
        <Radio value='2'>Ordine Alfabetico (A-Z)</Radio>
        <Radio value='3'>Crediti (ordine decrescente)</Radio>
        {(state.filter.plan &&
          state.hidePlans &&
          state.filter.plan.length === 1 &&
          (state.filter.plan.includes(1) || state.filter.plan.includes(2))) || (
            <Radio value='4'>Prezzo (ordine crescente)</Radio>
          )}
      </RadioGroup>
    </div>
  )
}

export default Sort
