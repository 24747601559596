import { useState } from 'react'

const Icons = () => {
  return <div>Icons</div>
}

const Dash = () => {
  return (
    <svg width='20' height='3' viewBox='0 0 20 3' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 1.18408H19' stroke='#496492' strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}

const AnimatedDash = ({ children, isOpen = false }: { children: React.ReactNode, isOpen?: boolean }) => {
  const [open, setOpen] = useState<boolean>(isOpen)

  return (
    <button onClick={() => setOpen((prev) => !prev)} className='flex items-center gap-[13px] flex-1 w-full py-2'>
      <div className='relative w-5 h-5 z-50'>
        <svg
          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all duration-200 rotate-0 ${open && 'rotate-45'
            }`}
          width='20'
          height='3'
          viewBox='0 0 20 3'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path d='M1 1.18408H19' stroke='#496492' strokeWidth='2' strokeLinecap='round' />
        </svg>
        <svg
          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all duration-200 rotate-0 ${open && 'rotate-[135deg]'
            }`}
          width='20'
          height='3'
          viewBox='0 0 20 3'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path d='M1 1.18408H19' stroke='#496492' strokeWidth='2' strokeLinecap='round' />
        </svg>
      </div>
      {children}
    </button>
  )
}

const Search = ({ stroke = '#111827' }: { stroke?: string }) => {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.5 14.5L10 10M11.5 6.25C11.5 9.14949 9.14949 11.5 6.25 11.5C3.35051 11.5 1 9.14949 1 6.25C1 3.35051 3.35051 1 6.25 1C9.14949 1 11.5 3.35051 11.5 6.25Z'
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const Sort = () => {
  return (
    <svg
      className='text-active'
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.36162 18.27V1.86865M6.36162 1.86865L1.50195 7.33578M6.36162 1.86865L11.2213 7.33578M18.5108 7.33578V23.7372M18.5108 23.7372L23.3705 18.27M18.5108 23.7372L13.6511 18.27'
        stroke='#496492'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SmallFilter = ({ stroke = '#111827' }: { stroke?: string }) => {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 2C1 1.44772 1.44772 1 2 1H13.5C14.0523 1 14.5 1.44772 14.5 2V3.58579C14.5 3.851 14.3946 4.10536 14.2071 4.29289L9.54289 8.95711C9.35536 9.14464 9.25 9.399 9.25 9.66421V11.5L6.25 14.5V9.66421C6.25 9.399 6.14464 9.14464 5.95711 8.95711L1.29289 4.29289C1.10536 4.10536 1 3.851 1 3.58579V2Z'
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const Filter = () => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.86914 2.34522C1.86914 1.79293 2.31686 1.34521 2.86914 1.34521H22.7377C23.2899 1.34521 23.7377 1.79293 23.7377 2.34521V5.79067C23.7377 6.05589 23.6323 6.31024 23.4448 6.49778L15.5261 14.4164C15.3386 14.6039 15.2332 14.8583 15.2332 15.1235V18.3541L10.3736 23.2137V15.1235C10.3736 14.8583 10.2682 14.6039 10.0807 14.4164L2.16203 6.49778C1.9745 6.31024 1.86914 6.05589 1.86914 5.79067V2.34522Z'
        stroke='#496492'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default { Icons, Dash, Search, Sort, SmallFilter, Filter, AnimatedDash }
