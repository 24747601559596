import { Button } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const ContinueCourseButton = ({ courseId }: { courseId: string }) => {
   const navigate = useNavigate()
   const { t } = useTranslation()

   return (
      <Button
         onClick={() => navigate(`/module/${courseId}`)}
         height={'55px'}
         variant={'secondary'}
         w='full'>
         <span className='text-xs lg:text-base'>{t('Continue')}</span>
      </Button>
   )
}
