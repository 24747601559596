import React from 'react'

type Props = {
  children: React.ReactNode
  extraClasses?: string
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Container = ({ children, extraClasses, ...rest }: Props) => {
  return (
    <div
      className={`min-h-screen flex flex-col gap-5 md:container md:w-[96%] md:mx-auto md:py-8 ${extraClasses}`}
      {...rest}>
      {children}
    </div>
  )
}

export default Container
