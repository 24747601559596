import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useValidationSchemas = () => {
   const { t } = useTranslation()

   const RegisterPersonalFormSchema = yup
      .object()
      .shape({
         title: yup.string().required('Questo campo è obbligatorio'),
         first_name: yup.string().required('Questo campo è obbligatorio'),
         last_name: yup.string().required('Questo campo è obbligatorio'),
         email: yup.string().email().required('Questo campo è obbligatorio'),
         email_confirmation: yup
            .string()
            .oneOf([yup.ref('email'), null], 'Indirizzi email non corrispondenti'),
         password: yup
            .string()
            .min(8, 'Il campo password deve avere almeno 8')
            .required('Questo campo è obbligatorio'),
         password_confirmation: yup
            .string()
            .min(8, 'Il campo password deve avere almeno 8')
            .oneOf([yup.ref('password'), null], 'Password non corrispondenti'),
         profession: yup.string().required('Questo campo è obbligatorio'),
         territory: yup.string().required('Questo campo è obbligatorio'),
         specialization: yup.string().required('Questo campo è obbligatorio'),
         sub_specialization: yup.string(),
         Specialization_2: yup.string(),
         Specialization_3: yup.string(),
         province_enployment: yup.string().required('Questo campo è obbligatorio'),
         employment: yup.string().required('Questo campo è obbligatorio'),
         regione_enployment: yup.string().required('Questo campo è obbligatorio'),
         board_member: yup.string().required('Questo campo è obbligatorio'),
         board_number: yup.string().required('Questo campo è obbligatorio'),
         province_board: yup.string().required('Questo campo è obbligatorio')
      })
      .required()

   const UpdateProfileFirstStepFormSchema = yup
      .object()
      .shape({
         title: yup.string().required(),
         first_name: yup.string().required(),
         last_name: yup.string().required(),
         profession: yup.string().required(),
         territory: yup.string().required(),
         specialization: yup.string().required(),
         Specialization_2: yup.string(),
         Specialization_3: yup.string(),
         province_enployment: yup.string().required(),
         employment: yup.string().required(),
         regione_enployment: yup.string().required(),
         board_member: yup.string().required(),
         board_number: yup.string().required(),
         province_board: yup.string().required()
      })
      .required()

   const RegisterSecondStepFormSchema = yup
      .object()
      .shape({
         birth_in_italy: yup.string().required('Questo campo è obbligatorio'),
         birth_province: yup.string().when('birth_in_italy', {
            is: (birth_in_italy: string) => birth_in_italy === 'Italia',
            then: yup.string().required('Questo campo è obbligatorio')
         }),
         birth_city: yup.string().when('birth_in_italy', {
            is: (birth_in_italy: string) => birth_in_italy === 'Italia',
            then: yup.string().required('Questo campo è obbligatorio')
         }),
         date_of_birth: yup.string().required('Questo campo è obbligatorio'),
         gender: yup.string().required('Questo campo è obbligatorio'),
         fiscal_code: yup.string().required('Questo campo è obbligatorio'),
         address_type: yup.string().required('Questo campo è obbligatorio'),
         address_line: yup.string().required('Questo campo è obbligatorio'),
         address_number: yup.string().required('Questo campo è obbligatorio'),
         zip_code: yup.string().required('Questo campo è obbligatorio'),
         address_province: yup.string().required('Questo campo è obbligatorio'),
         address_city: yup.string().required('Questo campo è obbligatorio'),
         address_regione: yup.string().required('Questo campo è obbligatorio'),
         phone_number: yup.string().required('Questo campo è obbligatorio')
      })
      .required()

   const resetPasswordSchema = yup
      .object()
      .shape({
         email: yup.string().email().required()
      })
      .required()

   return {
      RegisterPersonalFormSchema,
      UpdateProfileFirstStepFormSchema,
      RegisterSecondStepFormSchema,
      resetPasswordSchema
   }
}
