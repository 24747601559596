import { useMediaQuery } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import CardsCarousel from 'Components/CardsCarousel';
import DetailsText from 'Components/DetailsText';
import SectionTitle from 'Components/SectionTitle';
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import FeaturedCourse from '../../Components/Cards/FeaturedCourses/FeaturedCourseCard'
import { NewTCourse } from 'Types/Models';

const FeaturedCourses = () => {
  const { getFeaturedCourses } = useApiRequests();
  const [isLargerThan900, isLargerThan1400] = useMediaQuery([
    '(min-width: 900px)',
    '(min-width: 1400px)'
  ])

  const [page, setPage] = useState(1);
  const [featuredCourses, setFeaturedCourses] = useState<any[]>([]);

  const { ref: ref1, inView: inView2 } = useInView()

  useEffect(() => {
    inView2 && setPage(page + 1)
  }, [inView2])

  const { data, isFetching } = useQuery(
    [
      'featuredCourses',
      page
    ],
    {
      queryFn: () =>
        getFeaturedCourses(page),
      select: (res) => res.data.data,
      onSuccess: (res) => setFeaturedCourses([...featuredCourses, ...res.courses.data])
    }
  )

  if (!isFetching && featuredCourses.length === 0) {
    return <></>
  }

  return (
    <div className='mt-5'>
      <div className='pb-5'>
        <SectionTitle>Corsi in evidenza</SectionTitle>
        <div className='md:hidden'>
          <DetailsText>
            Scorri con il dito verso sinistra per vedere altri corsi
          </DetailsText>
        </div>
      </div>

      <CardsCarousel
        slidesToShow={isLargerThan1400 ? 3.25 : isLargerThan900 ? 2.25 : 1.25}
        wrap={page === data?.courses.last_page}
      >
        {featuredCourses.map((course: NewTCourse) => {
          return <FeaturedCourse.Card key={course.id} {...course} />
        })}
        {isFetching &&
          Array.from(Array(4).keys()).map((item) => (
            <FeaturedCourse.Skeleton key={item} />
          ))}

        {page < data?.courses.last_page && !isFetching && <div ref={ref1}>{''}</div>}
      </CardsCarousel>
    </div>
  )
}

export default FeaturedCourses