import { Checkbox, Link, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { CourseCard } from 'Components/Cards/Billing/CourseCard'
import { PackageCard } from 'Components/Cards/Billing/PackageCard'
import { SubscriptionCard } from 'Components/Cards/Billing/SubscriptionCard'
import AziendaForm from 'Components/Forms/Billing/AziendaForm'
import PrvitoForm from 'Components/Forms/Billing/PrvitoForm'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'
import { Package } from 'Types/Models'

const Billing: React.FunctionComponent = () => {
   const [isChecked, setisChecked] = useState(false)
   const { getPlansV2 } = useApiRequests()
   const [userData] = useRecoilState(userDataAtom)
   const { mutate, isLoading } = useMutation({
      mutationFn: getPlansV2
   })
   const [selectedGatway, setSelectedGetway] = useState<string>('stripe')
   const [data, setdata] = useState<Record<string, any>>({})
   const [selectedCard, setSelectedCard] = useState<any>()
   const [searchParams] = useSearchParams()
   const id = searchParams.get('id')
   const mode = Number(searchParams.get('mode'))

   useEffect(() => {
      if (mode) {
         mutate(
            { mode, ...(id && { id }) },
            {
               onSuccess: (res) => {
                  setdata(res.data.data)
               }
            }
         )
      }
   }, [mode, id])

   if (isLoading)
      return (
         <div className='h-[80vh] flex justify-center items-center'>
            <Spinner />
         </div>
      )

   return (
      <div className='grid grid-cols-2 w-[100%] px-2 min-h-[80vh] sm:container mx-auto py-8'>
         <div className='flex flex-col col-span-2 lg:col-span-1 gap-5 md:p-12 p-4 lg:p-0'>
            {data?.subscription?.[0] && (
               <>
                  {data.subscription[0].map((sub: any) => (
                     <SubscriptionCard
                        key={sub.id}
                        isSelected={selectedCard?.id === sub.id}
                        setSelectedCard={setSelectedCard}
                        data={sub}
                     />
                  ))}
               </>
            )}

            {mode === 1 && data?.[0] && (
               <>
                  <SubscriptionCard
                     key={data[0]?.id}
                     isSelected={selectedCard?.id === data[0]?.id}
                     setSelectedCard={setSelectedCard}
                     data={data[0]}
                  />
               </>
            )}

            {mode === 3 && !Array.isArray(data?.packages) && data?.package && (
               <PackageCard
                  isSelected={selectedCard?.id === data?.package.id}
                  setSelectedCard={setSelectedCard}
                  data={data.package}
               />
            )}

            {mode === 2 && data?.course && (
               <>
                  <CourseCard
                     key={data?.course[0].id}
                     isSelected={selectedCard?.id === data?.course[0].id}
                     setSelectedCard={setSelectedCard}
                     data={data?.course[0]}
                  />
               </>
            )}

            {mode === 2 &&
               Array.isArray(data?.packages) &&
               data?.packages[0] &&
               data?.packages[0].map((pac: Package) => (
                  <PackageCard
                     key={pac.id}
                     isSelected={selectedCard?.id === pac.id}
                     setSelectedCard={setSelectedCard}
                     data={pac}
                  />
               ))}
         </div>
         <div className='flex flex-col col-span-2 lg:col-span-1 gap-5  md:p-12 p-4'>
            <p>
               Riepilogo dei tuoi dati: <br />
               Nome: {userData?.user_data.first_name}
               <br />
               Cognome: {userData?.user_data.last_name}
               <br />
               Email: {userData?.user_data.email}
            </p>
            {selectedCard && (
               <div className='flex flex-col mt-2'>
                  <div className='flex'>
                     <b className='mr-2'>Titolo: </b>
                     <div /> {selectedCard?.title || selectedCard?.name}
                  </div>
                  <div className='flex'>
                     <b className='mr-2'>Tipologia: </b>
                     <div /> {selectedCard?.type}
                  </div>
                  <div className='flex'>
                     <b className='mr-2'>Costo: </b> <div />
                     {userData?.user_data.subscription.type_text === 'PLUS' ? (
                        <>{selectedCard?.price_with_discount || selectedCard?.price} euro</>
                     ) : (
                        <>{selectedCard?.price} euro</>
                     )}
                  </div>
               </div>
            )}
            <Checkbox onChange={(e) => setisChecked(e.target.checked)}>Voglio la fattura</Checkbox>
            {!isChecked && (
               <div
                  style={{ opacity: selectedCard ? 1 : 0.5 }}
                  className={`grid ${
                     selectedCard && !selectedCard?.paypal_checkout_url
                        ? 'xl:grid-cols-1'
                        : 'xl:grid-cols-2'
                  }  grid-cols-1 xl:h-[49px] gap-2 my-5`}>
                  {selectedCard && !selectedCard?.paypal_checkout_url ? (
                     <></>
                  ) : (
                     <div
                        onClick={() => {
                           if (!selectedCard) return

                           setSelectedGetway('paypal')
                        }}
                        className={`${
                           selectedGatway === 'paypal' && 'border-primary border-4'
                        } flex flex-col justify-center items-center  ${
                           selectedCard && 'cursor-pointer'
                        } self-stretch w-full relative p-5 rounded-[5px] bg-[#e8e8e8]`}>
                        <svg
                           width={111}
                           height={31}
                           viewBox='0 0 111 31'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='flex-grow-0 flex-shrink-0 w-[110px] h-[29.27px] relative'
                           preserveAspectRatio='none'>
                           <g clipPath='url(#clip0_2273_13004)'>
                              <path
                                 d='M41.4937 6.84991H35.4269C35.0117 6.84991 34.6586 7.15153 34.5939 7.56137L32.1402 23.1184C32.0914 23.4253 32.3291 23.7021 32.6405 23.7021H35.5369C35.952 23.7021 36.3051 23.4005 36.3698 22.9898L37.0316 18.7938C37.0955 18.3831 37.4494 18.0814 37.8637 18.0814H39.7843C43.7806 18.0814 46.0871 16.1476 46.6894 12.3153C46.9609 10.6387 46.701 9.32137 45.9159 8.39879C45.0536 7.38572 43.5243 6.84991 41.4937 6.84991ZM42.1936 12.5318C41.8619 14.7087 40.1985 14.7087 38.5902 14.7087H37.6748L38.317 10.6431C38.3552 10.3974 38.5681 10.2164 38.8164 10.2164H39.236C40.3316 10.2164 41.3651 10.2164 41.8991 10.841C42.2176 11.2135 42.3152 11.7671 42.1936 12.5318Z'
                                 fill='#253B80'
                              />
                              <path
                                 d='M59.6286 12.4616H56.7233C56.4758 12.4616 56.2621 12.6426 56.2239 12.8883L56.0953 13.7009L55.8921 13.4064C55.2632 12.4936 53.8607 12.1884 52.4608 12.1884C49.2504 12.1884 46.5084 14.6199 45.9744 18.0308C45.6967 19.7323 46.0915 21.3592 47.0566 22.4938C47.942 23.537 49.2087 23.9717 50.7159 23.9717C53.3027 23.9717 54.7371 22.3084 54.7371 22.3084L54.6076 23.1157C54.5588 23.4244 54.7966 23.7012 55.1062 23.7012H57.7231C58.1391 23.7012 58.4904 23.3995 58.5561 22.9888L60.1262 13.0454C60.1759 12.7393 59.9391 12.4616 59.6286 12.4616ZM55.579 18.116C55.2987 19.7758 53.9813 20.8899 52.3012 20.8899C51.4575 20.8899 50.7833 20.6194 50.3504 20.1066C49.9211 19.5974 49.7579 18.8727 49.8945 18.0654C50.1562 16.4199 51.4957 15.2693 53.1501 15.2693C53.9751 15.2693 54.6458 15.5434 55.0875 16.0606C55.5302 16.5831 55.7058 17.3123 55.579 18.116Z'
                                 fill='#253B80'
                              />
                              <path
                                 d='M75.1013 12.4616H72.1819C71.9033 12.4616 71.6416 12.6 71.4837 12.8315L67.4572 18.7627L65.7504 13.0631C65.6431 12.7064 65.314 12.4616 64.9414 12.4616H62.0725C61.7239 12.4616 61.4817 12.8023 61.5926 13.1305L64.8083 22.5674L61.7851 26.8352C61.5474 27.1714 61.7869 27.6336 62.1976 27.6336H65.1135C65.3903 27.6336 65.6493 27.4988 65.8063 27.2717L75.5165 13.2556C75.7489 12.9202 75.5103 12.4616 75.1013 12.4616Z'
                                 fill='#253B80'
                              />
                              <path
                                 d='M84.7672 6.84991H78.6994C78.2852 6.84991 77.9321 7.15153 77.8673 7.56137L75.4136 23.1184C75.3648 23.4253 75.6026 23.7021 75.9122 23.7021H79.0259C79.3151 23.7021 79.5626 23.491 79.6078 23.2035L80.3042 18.7938C80.3681 18.3831 80.722 18.0814 81.1363 18.0814H83.056C87.0532 18.0814 89.3588 16.1476 89.962 12.3153C90.2344 10.6387 89.9727 9.32137 89.1876 8.39879C88.3262 7.38572 86.7977 6.84991 84.7672 6.84991ZM85.4671 12.5318C85.1362 14.7087 83.4729 14.7087 81.8637 14.7087H80.9491L81.5923 10.6431C81.6304 10.3974 81.8415 10.2164 82.0908 10.2164H82.5104C83.6051 10.2164 84.6394 10.2164 85.1735 10.841C85.4919 11.2135 85.5886 11.7671 85.4671 12.5318Z'
                                 fill='#179BD7'
                              />
                              <path
                                 d='M102.901 12.4616H99.9976C99.7483 12.4616 99.5363 12.6426 99.4991 12.8883L99.3704 13.7009L99.1664 13.4064C98.5374 12.4936 97.1358 12.1884 95.736 12.1884C92.5256 12.1884 89.7845 14.6199 89.2504 18.0308C88.9736 19.7323 89.3666 21.3592 90.3318 22.4938C91.2189 23.537 92.4839 23.9717 93.9911 23.9717C96.5778 23.9717 98.0123 22.3084 98.0123 22.3084L97.8828 23.1157C97.834 23.4244 98.0717 23.7012 98.3831 23.7012H100.999C101.413 23.7012 101.766 23.3995 101.831 22.9888L103.402 13.0454C103.45 12.7393 103.212 12.4616 102.901 12.4616ZM98.8515 18.116C98.5729 19.7758 97.2538 20.8899 95.5737 20.8899C94.7318 20.8899 94.0558 20.6194 93.6229 20.1066C93.1936 19.5974 93.0321 18.8727 93.167 18.0654C93.4304 16.4199 94.7682 15.2693 96.4226 15.2693C97.2476 15.2693 97.9182 15.5434 98.36 16.0606C98.8045 16.5831 98.9801 17.3123 98.8515 18.116Z'
                                 fill='#179BD7'
                              />
                              <path
                                 d='M106.326 7.27664L103.836 23.1184C103.787 23.4254 104.025 23.7021 104.335 23.7021H106.838C107.254 23.7021 107.607 23.4005 107.671 22.9898L110.126 7.43366C110.175 7.12672 109.937 6.84906 109.628 6.84906H106.825C106.577 6.84995 106.364 7.03091 106.326 7.27664Z'
                                 fill='#179BD7'
                              />
                              <path
                                 d='M6.94567 26.7253L7.40963 23.7784L6.37616 23.7544H1.44124L4.87075 2.00904C4.8814 1.9434 4.916 1.88219 4.96656 1.83872C5.01713 1.79525 5.08188 1.7713 5.1493 1.7713H13.4703C16.2327 1.7713 18.1391 2.34614 19.1344 3.48074C19.601 4.01299 19.8982 4.5692 20.0419 5.1813C20.1927 5.82356 20.1954 6.5909 20.0481 7.52678L20.0374 7.59509V8.19477L20.5041 8.45912C20.897 8.66759 21.2093 8.90622 21.4488 9.17945C21.848 9.63453 22.1062 10.2129 22.2153 10.8986C22.3279 11.6039 22.2907 12.4431 22.1062 13.3932C21.8933 14.4861 21.5491 15.4379 21.0842 16.2168C20.6566 16.9344 20.112 17.5297 19.4653 17.991C18.8479 18.4292 18.1142 18.7619 17.2848 18.9748C16.4811 19.1841 15.5647 19.2897 14.5596 19.2897H13.912C13.449 19.2897 12.9992 19.4565 12.6462 19.7554C12.2922 20.0606 12.058 20.4775 11.9862 20.9335L11.9374 21.1987L11.1177 26.3927L11.0804 26.5834C11.0707 26.6437 11.0538 26.6739 11.029 26.6943C11.0068 26.7129 10.9749 26.7253 10.9438 26.7253H6.94567Z'
                                 fill='#253B80'
                              />
                              <path
                                 d='M20.9457 7.66425C20.9209 7.82304 20.8925 7.98537 20.8606 8.15215C19.7632 13.7861 16.009 15.7324 11.2143 15.7324H8.77298C8.18661 15.7324 7.69249 16.1582 7.60112 16.7366L6.3512 24.6637L5.99725 26.9107C5.93782 27.2904 6.23056 27.6328 6.61378 27.6328H10.9437C11.4564 27.6328 11.892 27.2602 11.9727 26.7546L12.0153 26.5346L12.8306 21.361L12.8829 21.0771C12.9627 20.5697 13.3992 20.1971 13.9119 20.1971H14.5595C18.7546 20.1971 22.0386 18.4939 22.9985 13.5652C23.3994 11.5063 23.1918 9.78707 22.1309 8.57796C21.8098 8.21336 21.4114 7.91086 20.9457 7.66425Z'
                                 fill='#179BD7'
                              />
                              <path
                                 d='M19.7978 7.20655C19.6301 7.15776 19.4572 7.11341 19.2797 7.07349C19.1014 7.03446 18.9187 6.99986 18.7306 6.9697C18.0724 6.86325 17.3512 6.81268 16.5785 6.81268H10.0566C9.89602 6.81268 9.74344 6.84905 9.60683 6.9147C9.3061 7.0593 9.08256 7.34405 9.02844 7.69268L7.64102 16.4803L7.6011 16.7366C7.69248 16.1582 8.18659 15.7324 8.77296 15.7324H11.2142C16.009 15.7324 19.7632 13.7853 20.8605 8.1522C20.8934 7.98543 20.9209 7.82309 20.9457 7.6643C20.668 7.51704 20.3673 7.39107 20.0435 7.28373C19.9637 7.25712 19.8812 7.23139 19.7978 7.20655Z'
                                 fill='#222D65'
                              />
                              <path
                                 d='M9.02855 7.69267C9.08267 7.34404  9.30622 7.05929 9.60694 6.91558C9.74444 6.84993 9.89613 6.81356 10.0567 6.81356H16.5786C17.3513 6.81356 18.0725 6.86412 18.7307 6.97058C18.9188 7.00074 19.1015 7.03533 19.2798 7.07437C19.4573 7.11429 19.6302 7.15864 19.7979 7.20743C19.8813 7.23227 19.9638 7.258 20.0445 7.28372C20.3683 7.39106 20.669 7.51791 20.9467 7.66429C21.2732 5.58227 20.944 4.16469 19.8183 2.88106C18.5773 1.46792 16.3373 0.862915 13.4711 0.862915H5.15017C4.56468 0.862915 4.06525 1.28872 3.97476 1.868L0.508876 23.8369C0.44057 24.2716 0.775892 24.6637 1.21412 24.6637H6.3513L7.64113 16.4803L9.02855 7.69267Z'
                                 fill='#253B80'
                              />
                           </g>
                        </svg>
                     </div>
                  )}
                  <div
                     onClick={() => {
                        if (!selectedCard) return
                        setSelectedGetway('stripe')
                     }}
                     className={`${
                        selectedGatway === 'stripe' && 'border-primary border-4'
                     }  flex flex-col justify-center items-center ${
                        selectedCard && 'cursor-pointer'
                     } self-stretch flex-grow py-5 rounded-[5px] bg-[#e8e8e8]`}>
                     <div className='flex justify-center items-start flex-grow-0 flex-shrink-0 relative gap-2'>
                        <p className='flex-grow-0 flex-shrink-0 text-lg font-bold text-center uppercase text-[#4e638f]'>
                           Carta di credito{' '}
                        </p>
                     </div>
                  </div>
               </div>
            )}

            {isChecked && (
               <Tabs isFitted variant='soft-rounded'>
                  <TabList gap='20px'>
                     <Tab
                        borderRadius={5}
                        boxShadow='md'
                        h='63px'
                        _selected={{ bgColor: 'white', border: '2px', borderColor: '#496492' }}
                        bgColor='white'>
                        PRIVATO
                     </Tab>
                     <Tab
                        borderRadius={5}
                        boxShadow='md'
                        h='63px'
                        _selected={{ bgColor: 'white', border: '2px', borderColor: '#496492' }}>
                        AZIENDA
                     </Tab>
                  </TabList>
                  <TabPanels>
                     <TabPanel>
                        <PrvitoForm
                           selectedCard={selectedCard}
                           setSelectedGetway={setSelectedGetway}
                           selectedGatway={selectedGatway}
                           mode={mode}
                           product_id={id}
                        />
                     </TabPanel>
                     <TabPanel>
                        <AziendaForm
                           selectedCard={selectedCard}
                           setSelectedGetway={setSelectedGetway}
                           selectedGatway={selectedGatway}
                           mode={mode}
                           product_id={id}
                        />
                     </TabPanel>
                  </TabPanels>
               </Tabs>
            )}
            {!isChecked && (
               <Link
                  height='69px'
                  bg='#496492'
                  variant='primary'
                  display={'flex'}
                  textDecoration='none'
                  fontWeight={'600'}
                  _hover={{ textDecoration: 'none' }}
                  textColor='white'
                  href={
                     selectedGatway === 'stripe'
                        ? selectedCard?.checkout_url
                        : selectedCard?.paypal_checkout_url
                  }
                  opacity={selectedCard ? 1 : 0.5}
                  cursor={selectedCard ? 'pointer' : 'not-allowed'}
                  justifyContent='center'
                  alignItems={'center'}>
                  {selectedCard ? selectedCard.checkout_text : 'VAI AL PAGAMENTO'}
               </Link>
            )}
         </div>
      </div>
   )
}

export default Billing
