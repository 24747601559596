import * as yup from 'yup'

export const loginSchema = yup
   .object()
   .shape({
      email: yup
         .string()
         .email('Il campo email deve essere un\'email valida')
         .required('Il campo email è obbligatorio'),
      password: yup
         .string()
         .min(8, 'Il campo password deve avere almeno 8')
         .required('Il campo password è obbligatorio')
   })
   .required()

export const changePasswordSchema = yup.object().shape({
   old_password: yup
      .string()
      .required('La vecchia password è obbligatoria')
      .min(8, 'La vecchia password deve essere lunga almeno 8 caratteri'),
   password: yup
      .string()
      .required('La nuova password è obbligatoria')
      .min(8, 'La nuova password deve essere lunga almeno 8 caratteri')
      .notOneOf(
         [yup.ref('old_password')],
         'La nuova password deve essere diversa dalla vecchia password'
      ),
   password_confirmation: yup
      .string()
      .required('Conferma la nuova password')
      .oneOf([yup.ref('password')], 'Le nuove password devono corrispondere')
})

export const resetPasswordSchema = yup.object().shape({
   password: yup
      .string()
      .required('La nuova password è obbligatoria')
      .min(8, 'La nuova password deve contenere almeno 8 caratteri'),
   password_confirmation: yup
      .string()
      .required('La conferma della nuova password è obbligatoria')
      .oneOf([yup.ref('password')], 'Le nuove password devono corrispondere')
})
