import Filters from "Components/Filters/Filters"
import SectionHeader from "Components/shared/SectionHeader"
import { NewTCourse } from "Types/Models"
import { CourseCard } from "Components/Cards/CourseCard/CourseCard"
import { Skeleton } from "Components/Cards/CourseCard/Skeleton"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useApiRequests } from "API/ApiRequests"
import { useInView } from "react-intersection-observer"
// import FilterNSort from 'Components/FilterNSortHolder/FilterNSort'
import { Footer } from "Layouts/Footer/Footer"
import Container from "Components/shared/Container"
import { useRecoilState } from "recoil"
import { defaultFilter, filterState } from "Recoil/atoms"

const AllCourses = () => {
  const { getAllCoursesV2 } = useApiRequests()

  const [{ filter, keyword }, setFState] = useRecoilState(filterState)
  const [currentFilter, setCurrentFilter] = useState({
    filter,
    keyword,
  })

  const [allCourses, setAllCourses] = useState<any[]>([])
  const [page, setPage] = useState(1)

  const { ref, inView } = useInView()

  const { data, isFetching } = useQuery(["all-Courses", page, filter, keyword], {
    queryFn: ({ queryKey }) => {
      if (JSON.stringify({ filter, keyword }) !== JSON.stringify(currentFilter)) {
        // eslint-disable-next-line no-console
        // console.log("Change Detected");
        setCurrentFilter({ filter, keyword })
        setPage(1)
        return getAllCoursesV2(1, { ...filter, keyword })
      }

      if (queryKey[1]) {
        return getAllCoursesV2(page, { ...filter, keyword })
      }

      return getAllCoursesV2(1, { ...filter, keyword })
    },
    select: (res) => res.data.data,
    onSuccess: (res) => setAllCourses((prev) => [...prev, ...res.courses.data]),
  })

  useEffect(() => {
    inView && setPage((prev) => prev + 1)
  }, [inView])

  useEffect(() => {
    setPage(1)
    setAllCourses([])
  }, [filter, keyword])

  useEffect(() => {
    return () => setFState(defaultFilter)
  }, [])

  return (
    <>
      <Container extraClasses='pb-8'>
        <div className='mt-[26px] md:mt-0 flex justify-end px-[15px] lg:px-0'>
          <Filters.Trigger />
        </div>
        <div className='pb-3 px-[15px] lg:px-0 mt-[26px] md:mt-0 '>
          <div className='lg:px-0'>
            <SectionHeader
              title={"Tutti i corsi"}
              description='La nostra completa selezione di corsi ECM'
            />
          </div>
        </div>

        <div className='px-[15px] lg:px-0 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-9 2xl:grid-cols-4 pb-8'>
          {allCourses.map((course: NewTCourse) => {
            return <CourseCard key={course.id} {...course} />
          })}

          {isFetching && Array.from(Array(4).keys()).map((item) => <Skeleton key={item} />)}

          {page < data?.courses.last_page && !isFetching && <div ref={ref}>{""}</div>}
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default AllCourses
