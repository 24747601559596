/* eslint-disable react/no-unescaped-entities */
import { Button } from '@chakra-ui/react'
import subscriptionImage from 'Assets/subscrption.svg'
import { Link } from 'react-router-dom'

const PaymentCancelled: React.FunctionComponent = () => {
   return (
      <div className='sm:container w-[96%] divide-x-2 divide-[#F5F5F5] flex flex-col items-center flex-wrap gap-3 sm:flex-nowrap mx-auto border-2 p-4 my-6 rounded-md border-[#F5F5F5] lg:min-h-[85vh]'>
         <div className='flex items-center justify-center flex-col py-10 text-center md:w-[500px] w-full'>
            <img src={subscriptionImage} className='w-20' />
            <h4 className='font-semibold text-xl py-3'>C'è stato un problema sulla transazione!</h4>
            <Button bgColor='#496391' py={8} my={3} fontSize={13} variant='primary'>
               <Link to={'../'}>
                  Torna alla homapage
               </Link>
            </Button>
         </div>
      </div>
   )
}

export default PaymentCancelled
