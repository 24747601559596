import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { FiUnlock, FiLock } from 'react-icons/fi'
import { Accordion, AccordionItem, Box, AccordionButton, AccordionPanel } from '@chakra-ui/react'
import { ReactNode } from 'react'

export const CollapsableCard = ({
   bgColor = '#F5F5F5',
   textColor = '#848484',
   title = '',
   secondTitle,
   description,
   secondDescription,
   isLocked,
   defaultOpened = false,
   children,
   id
}: {
   bgColor?: string
   children: JSX.Element
   textColor?: string
   defaultOpened?: boolean
   title: string
   id?: string | number
   secondTitle?: string
   description?: string
   secondDescription?: ReactNode
   isLocked?: boolean
}) => {
   return (
      <Accordion id={String(id)} allowMultiple defaultIndex={defaultOpened ? [0] : []}>
         <AccordionItem
            border={0}
            transition='all 1000ms'
            style={{ backgroundColor: bgColor }}
            className='min-w-[350px] rounded-md'>
            {({ isExpanded }) => (
               <>
                  <AccordionButton
                     _focus={{ boxShadow: 'none' }}
                     _hover={{ bg: 'none' }}
                     p={5}
                     style={{ color: textColor }}
                     alignItems='start'
                     // opacity={isLocked ? 0.7 : 1}
                     justifyContent={'center'}
                     className={`w-full font-bold ${
                        isLocked && 'cursor-not-allowed'
                     } p-5 flex flex-col h-fit items-start justify-center gap-3`}>
                     <div className='flex items-center gap-3 w-full'>
                        {isExpanded && !isLocked ? (
                           <AiOutlineMinus className='text-2xl' />
                        ) : (
                           <AiOutlinePlus className='text-2xl' />
                        )}
                        <Box
                           flex='1'
                           className='flex w-full items-center justify-between'
                           textAlign='left'>
                           <div className='flex text-xs w-[90%] flex-col'>
                              {title}
                              {secondTitle && (
                                 <span className='text-xs font-normal'>{secondTitle}</span>
                              )}
                           </div>
                           <div className='shrink-0'>
                              {isLocked && (
                                 <>
                                    {isLocked ? (
                                       <FiLock className='text-2xl' />
                                    ) : (
                                       <FiUnlock className='text-2xl' />
                                    )}
                                 </>
                              )}
                           </div>
                        </Box>
                     </div>
                     {description && (
                        <Box className='text-[14px] w-full font-normal text-left'>
                           <p dangerouslySetInnerHTML={{ __html: description }}></p>
                        </Box>
                     )}
                     {secondDescription && (
                        <Box className='text-[12px] font-normal w-full text-left'>
                           {secondDescription}{' '}
                        </Box>
                     )}
                  </AccordionButton>
                  {!isLocked && <AccordionPanel p={0}>{children}</AccordionPanel>}
               </>
            )}
         </AccordionItem>
      </Accordion>
   )
}
