import { Select } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { dashboardCoursesAtom } from 'Recoil/atoms'
import { OldCert } from './OldCert'

export const UserStatistecs = () => {
   const { t } = useTranslation()

   const [data] = useRecoilState(dashboardCoursesAtom)

   return (
      <div className='flex flex-col gap-3 w-full sm:h-[85vh]'>
         <div className='flex flex-col items-center justify-center bg-[#3c8287] rounded-[5px] h-20 gap-2'>
            <div className='text-[#ffffff] text-[26px] font-bold text-center relative self-stretch flex items-center justify-center'>
               {data?.user_kpi?.enroled_courses_count}
            </div>
            <div className='text-[#ded7ff] text-[11px] text-center  self-stretch flex items-end justify-center'>
               {t('Courses enrolled')}
            </div>
         </div>
         <div className='flex flex-col items-center justify-center bg-[#7B61FF] rounded-[5px] h-20 gap-2'>
            <div className='text-[#ffffff] text-[26px] font-bold text-center relative self-stretch flex items-center justify-center'>
               {data?.user_kpi?.credits_number}
            </div>
            <div className='text-[#ded7ff] text-[11px] text-center  self-stretch flex items-end justify-center'>
               {t('CME Credits')} 2023
            </div>
         </div>
      </div>
   )
}
