/* eslint-disable no-console */
import { Button, Spinner } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import subscriptionImage from 'Assets/subscrption.svg'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'
import { TUserData } from 'Types/Models'
import 'moment/locale/it'
import { Widget } from '@typeform/embed-react'
import lodash from 'lodash' // Make sure lodash is installed

const PaymentOk: React.FunctionComponent = () => {
   const [searchParams] = useSearchParams()
   const { confirmSubscription } = useApiRequests()
   const [data, setData] = useState<any>()
   const [userData, setUserData] = useRecoilState(userDataAtom)
   const navigate = useNavigate()
   const [isSuccess, setSuccess] = useState(false)
   const { mutate, isLoading } = useMutation({ mutationFn: confirmSubscription })
   const session_id = String(searchParams.get('session_id'))
   const payment_method = String(searchParams.get('payment_method'))
   const product_id = String(searchParams.get('product_id'))
   const mode = String(searchParams.get('mode'))

   useEffect(() => {
      if (session_id) {
         mutate(
            { session_id, payment_method, product_id, mode },
            {
               onSuccess: (data) => {
                  if (data) {
                     setSuccess(true)
                     setData(data.data)
                     const userDataObject = { ...userData }

                     if (userDataObject.user_data && mode === '1') {
                        const newUserDataObject = lodash.cloneDeep(userDataObject)
                        if ((newUserDataObject.user_data as any).subscription) {
                           ;(newUserDataObject.user_data as any).subscription.type_text = 'PLUS'
                        }
                        setUserData(newUserDataObject as TUserData)
                     }
                  }
               },
               onError: () => {
                  navigate('/')
               }
            }
         )
      }
   }, [])

   return (
      <>
         {isLoading ? (
            <div className='sm:container w-[96%] divide-x-2 divide-[#F5F5F5] flex flex-col items-center flex-wrap gap-3 sm:flex-nowrap mx-auto border-2 p-4 my-6 rounded-md border-[#F5F5F5] lg:min-h-[85vh]'>
               <div className='flex items-center justify-center flex-col py-10 text-center md:w-[500px]'>
                  <Spinner colorScheme='blue' />
               </div>
            </div>
         ) : (
            isSuccess && (
               <div className='sm:container w-[96%] divide-x-2 divide-[#F5F5F5] flex flex-col items-center flex-wrap gap-3 sm:flex-nowrap mx-auto border-2 p-4 my-6 rounded-md border-[#F5F5F5] lg:min-h-[85vh]'>
                  {mode === '1' ? (
                     <div className='flex items-center justify-center flex-col py-10 text-center md:w-[500px]'>
                        <img src={subscriptionImage} className='w-20' />
                        <h4 className='font-semibold text-xl py-3'>Benvenuto!</h4>
                        <p className='py-3'>Gentile {data.customer},</p>
                        <p className='py-3'>
                           le confermiamo che la sottoscrizione al piano{' '}
                           <span className='font-bold uppercase'>
                              {data.subscription.name} {data.subscription.meta}
                           </span>{' '}
                           del sito ECMCLUB è stata effettuata correttamente al costo di{' '}
                           <span className='uppercase'>
                              {data.amount_paid} {data.currency}
                           </span>
                           .
                        </p>

                        <Link
                           className='w-full'
                           to={data.course_slug ? `/courses/${data.course_slug}` : '/corsi-plus'}>
                           <Button bgColor='#496391' py={8} my={3} fontSize={13} variant='primary'>
                              {data.course_slug ? (
                                 <Link to={`/courses/${data.course_slug}`}>Vai ai corsi</Link>
                              ) : (
                                 <Link to={'/corsi-plus'}>Vai al catalogo corsi</Link>
                              )}
                           </Button>
                        </Link>

                        <Link className='text-blueDark ' to={'/'}>
                           Torna alla Homepage
                        </Link>
                     </div>
                  ) : (
                     <div className='flex items-center justify-center flex-col py-10 text-center md:w-[500px]'>
                        <img src={subscriptionImage} className='w-20' />
                        <h4 className='font-semibold text-xl py-3'>Benvenuto!</h4>
                        <p className='py-3'>Gentile {data.customer},</p>
                        <p className='py-3'>
                           Grazie per il tuo ordine! A breve riceverai la conferma di pagamento e
                           relativa ricevuta
                        </p>

                        {mode === '3' ? (
                           <Link to={'/packages'} className='w-full'>
                              <Button
                                 bgColor='#496391'
                                 py={8}
                                 my={3}
                                 fontSize={13}
                                 variant='primary'>
                                 <span>VAI AL PACCHETTO</span>
                              </Button>
                           </Link>
                        ) : (
                           <Link
                              className='w-full'
                              to={
                                 data.course_slug ? `/courses/${data.course_slug}` : '/corsi-plus'
                              }>
                              <Button
                                 bgColor='#496391'
                                 py={8}
                                 my={3}
                                 fontSize={13}
                                 variant='primary'>
                                 {data.course_slug ? (
                                    <span>{mode === '2' ? 'VAI AL CORSO' : 'VAI AI CORSI'}</span>
                                 ) : (
                                    <span>Vai al catalogo corsi</span>
                                 )}
                              </Button>
                           </Link>
                        )}

                        <Link className='text-blueDark' to={'/'}>
                           Torna alla Homepage
                        </Link>
                     </div>
                  )}

                  <Widget
                     id='WJdZEVx6'
                     style={{ margin: '0 auto', height: '670px' }}
                     className='md:w-2/3 w-full my-form'
                  />
               </div>
            )
         )}
      </>
   )
}

export default PaymentOk
