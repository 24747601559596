import { Button, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react'
import { useLogin } from 'Hooks/useLogin'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsEyeSlash, BsEye } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export const LoginForm = () => {
   const [showPassword, setShowPassword] = useState(false)

   const { onSubmit, register, handleSubmit, isLoading, errors } = useLogin()

   const { t } = useTranslation()

   return (
      <form
         className='container max-w-[600px] mx-auto shadow-3xl rounded-lg p-9 flex flex-col gap-7'
         onSubmit={handleSubmit(onSubmit)}>
         <div className='flex flex-col gap-2'>
            <p className='font-normal text-xs text-dark-gray'>Email</p>
            <input
               {...register('email')}
               className={`${errors.email ? 'border-red-500' : 'border-[#bbbbbb59]'
                  } w-full pl-3 text-base rounded-md border-2  h-12 shadow-md
                  focus:shadow-sm outline-none duration-500 transition-all`}
               placeholder='Inserisci il tuo indirizzo email'
            />
            <p className='text-red-500 text-sm'>{errors.email?.message}</p>
         </div>
         <div className='flex flex-col gap-2'>
            <p className='font-normal text-xs text-dark-gray'>Password</p>
            <InputGroup>
               <input
                  {...register('password')}
                  type={showPassword ? 'text' : 'password'}
                  className={`${errors.password ? 'border-red-500' : 'border-[#bbbbbb59]'
                     } w-full pl-3 text-base rounded-md border-2  h-12 shadow-md
                  focus:shadow-sm outline-none duration-500 transition-all`}
                  placeholder='Inserisci la tua password'
               />
               <InputRightElement h='full'>
                  <Button
                     size={'sm'}
                     onClick={() => setShowPassword((showPassword) => !showPassword)}>
                     {showPassword ? (
                        <BsEye className='text-3xl text-gray' />
                     ) : (
                        <BsEyeSlash className='text-3xl text-gray' />
                     )}
                  </Button>
               </InputRightElement>
            </InputGroup>
            <p className='text-red-500 text-sm'>{errors.password?.message}</p>
         </div>
         <div className='flex justify-between'>
            <div className='flex items-center gap-1'></div>
            <Link to='/forgot-password' className='font-bold'>
               {t('Forgot Password')}?
            </Link>
         </div>
         <Button
            spinner={<Spinner />}
            height={'60px'}
            type='submit'
            isLoading={isLoading}
            variant={'primary'}>
            {t('Login')}
         </Button>
      </form>
   )
}
