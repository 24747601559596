import { Button, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { resetPasswordSchema } from 'Schemas/LoginSchema'

interface ResetPasswordFormValues {
   password: string
   password_confirmation: string
}
export const ResetPassword = () => {
   const [searchParams] = useSearchParams()

   const code = searchParams.get('code')

   const { passwordReset } = useApiRequests()

   const { mutate, isLoading } = useMutation({ mutationFn: passwordReset })

   const {
      register,
      handleSubmit,
      formState: { errors }
   } = useForm<ResetPasswordFormValues>({
      resolver: yupResolver(resetPasswordSchema)
   })

   const { t } = useTranslation()

   const navigat = useNavigate()

   const toast = useToast()

   const onSubmit: SubmitHandler<ResetPasswordFormValues> = async (
      data: ResetPasswordFormValues
   ) => {
      mutate(
         { ...data, token: code },
         {
            onSuccess: (data) => {
               if (data) {
                  toast({
                     status: 'success',
                     title: t('Password reset successfully')
                  })
                  navigat('/login')
               }
            },
            onError: () => {
               toast({
                  status: 'error',
                  title: t('Something goes wrong')
               })
            }
         }
      )
   }

   return (
      <div className='flex justify-center items-center'>
         <div className='bg-white my-14 pb-10 container flex flex-col'>
            <div className='w-full text-center'>
               <h3 className='text-primary text-3xl py-10 font-semibold'>
                  {t('Reset you password')}
               </h3>
               <p className='text-base font-semibold pb-9'>
                  Inserisci di seguito la nuova password
               </p>
            </div>
            <form
               className='container max-w-[600px] mx-auto shadow-3xl rounded-lg p-9 flex flex-col gap-7'
               onSubmit={handleSubmit(onSubmit)}>
               <div className='flex flex-col gap-2'>
                  <p className='font-normal text-xs text-dark-gray'>{t('New password')}</p>
                  <input
                     type={'password'}
                     {...register('password')}
                     className={`${
                        errors.password ? 'border-red-500' : 'border-[#bbbbbb59]'
                     } w-full pl-3 text-base rounded-md border-2  h-12 shadow-md
                  focus:shadow-sm outline-none duration-500 transition-all`}
                     placeholder={`${t('New password')}`}
                  />
                  <p className='text-red-500 text-sm'>{errors.password?.message}</p>
               </div>
               <div className='flex flex-col gap-2'>
                  <p className='font-normal text-xs text-dark-gray'>{t('Repeat new password')}</p>
                  <input
                     type={'password'}
                     {...register('password_confirmation')}
                     className={`${
                        errors.password_confirmation ? 'border-red-500' : 'border-[#bbbbbb59]'
                     } w-full pl-3 text-base rounded-md border-2  h-12 shadow-md
                  focus:shadow-sm outline-none duration-500 transition-all`}
                     placeholder={`${t('Repeat new password')}`}
                  />
                  <p className='text-red-500 text-sm'>{errors.password_confirmation?.message}</p>
               </div>
               <div className='flex justify-between'></div>
               <Button isLoading={isLoading} height={'60px'} type='submit' variant={'primary'}>
                  {t('Reset')}{' '}
               </Button>
            </form>{' '}
            <div className='w-full justify-center mt-10 flex gap-1'>
               <span>{t('Go back to')}</span>
               <Link to='/login' className='text-primary font-semibold'>
                  {t('Login')}
               </Link>
            </div>
         </div>
      </div>
   )
}
