import { atom, selector } from 'recoil'
import { LAYOUT_CARDS_VIEW } from 'Types/enums'
import { TRegisterPersonalForm, TRegisterSecondStepForm } from 'Types/FormsTypes'
import { TCourseFilters, TModule, TQuiz, TSpecialization, TUserData } from 'Types/Models'
import { TQuestion } from 'Types/ModuleQuiz.type'
import { recoilPersist } from 'recoil-persist'
import { TGlobalFilterForm } from 'Types/filter.types'
const { persistAtom } = recoilPersist()

export const selectedModuleAtom = atom<TModule | TQuiz | null>({
   key: 'selectedLesson',
   default: null
})

export const moduileQuizAtom = atom<{
   started: boolean
   finished: boolean
   passed: boolean
   step: number
   score: number
   user_attempts: number
   questionsList: TQuestion[]
   answers: {
      [key: string]: string
   }
}>({
   key: 'moduileQuiz',
   default: {
      finished: false,
      passed: false,
      started: false,
      step: 0,
      score: 0,
      user_attempts: 0,
      questionsList: [],
      answers: {}
   }
})

export const selectedCourseAtom = atom<any>({
   key: 'courseInfo',
   default: null
})

export const selectedLayoutCardsView = atom<LAYOUT_CARDS_VIEW>({
   key: 'layout',
   default: LAYOUT_CARDS_VIEW.Horz
})

export const isSessionActive = atom<boolean>({
   key: 'isSessionActive',
   default: true
})

export const registerFormAtom = atom<{
   step: number
   submitted: boolean
   firstStepData: TRegisterPersonalForm
   secondStepData: TRegisterSecondStepForm
}>({
   key: 'registerFormAtom',
   default: {
      secondStepData: {
         birth_in_italy: 'Italia',
         place_of_birth: 'CANADA',
         birth_province: '',
         birth_city: '',
         date_of_birth: '',
         gender: '',
         fiscal_code: '',
         address_type: '',
         address_line: '',
         address_number: '',
         zip_code: '',
         address_province: '',
         address_city: '',
         address_regione: '',
         phone_number: ''
      },
      firstStepData: {
         title: '',
         first_name: '',
         last_name: '',
         email: '',
         email_confirmation: '',
         password: '',
         password_confirmation: '',
         profession: '',
         territory: '',
         specialization: '',
         sub_specialization: '',
         specialization_2: '',
         specialization_3: '',
         province_enployment: '',
         employment: '',
         regione_enployment: '',
         board_member: '',
         board_number: '',
         province_board: ''
      },
      step: 1,
      submitted: false
   }
})

export const otherSpecializationsAtom = atom<any[]>({
   key: 'OtherSpecializations',
   default: []
})
export const specializationsAtom = atom<any[]>({
   key: 'specializations',
   default: []
})
export const brithCitiesAtom = atom<any[]>({
   key: 'brithCitiesAtom',
   default: []
})
export const provinceCitiesAtom = atom<any[]>({
   key: 'provinceCitiesAtom',
   default: []
})

export const professionsFilterAtom = atom<TCourseFilters | null>({
   key: 'professionsFilterAtom',
   default: null
})

export const selectedSpecializationAtom = atom<TSpecialization | null>({
   key: 'selectedSpecializationAtom',
   default: null
})

export const selectedChannelAtom = atom<any | null>({
   key: 'selectedChannelAtom',
   default: null
})

export const userDataAtom = atom<TUserData | null>({
   key: 'userDataAtom',
   default: null,
   effects_UNSTABLE: [persistAtom]
})

export const dashboardCoursesAtom = atom({
   key: 'dashboardCoursesAtom',
   default: {
      completed: [],
      ongoing: [],
      configurations: {
         contact_email: '',
         help_desk_phone: '',
         contact_form_message: '',
         help_desk_availability: ''
      },
      purchased: [],
      accessable: [],
      user_kpi: {
         enroled_courses_count: 0,
         credits_number: 0
      }
   }
})

export const updateProfileAtom = atom({
   key: 'updateProfileAtom',
   default: {
      birth_in_italy: 'Italia',
      place_of_birth: 'CANADA',
      birth_province: '',
      birth_city: '',
      date_of_birth: '',
      gender: '',
      fiscal_code: '',
      address_type: '',
      address_line: '',
      address_number: '',
      zip_code: '',
      address_province: '',
      address_city: '',
      address_regione: '',
      phone_number: '',
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      email_confirmation: '',
      password: '',
      password_confirmation: '',
      profession: '',
      territory: '',
      specialization: '',
      sub_specialization: '',
      specialization_2: '',
      specialization_3: '',
      province_enployment: '',
      employment: '',
      regione_enployment: '',
      board_member: '',
      board_number: '',
      province_board: ''
   }
})

export const modulesListAtom = atom<(TModule | TQuiz)[]>({
   key: 'modulesListAtom',
   default: []
})

export const showAttemptsDrawerAtom = atom<boolean>({
   key: 'showAttemptsDrawer',
   default: false
})

export const defaultFilter: TGlobalFilterForm = {
   filter: {
      'plan': null,
      'price[end]': 500,
      'price[start]': 0,
      for_plus_users: null,
      for_you: null,
      status: null,
      sort: null,
      credit: null,
   },
   open: false,
   keyword: '',
   hasFilter: false,
   hasSearch: false,
   openSearch: false,
   hidePlans: false,
}

export const filterState = atom<TGlobalFilterForm>({
   key: 'filterState',
   default: defaultFilter
})

// export const filterStateSelector = selector<TGlobalCourseFiltersSend>({
//    key: 'filterStateSelector',
//    get: ({ get }) => {
//       const filters = get(filterState).filter
//       const jsonPayload: TGlobalCourseFiltersSend = {}
//       if (filters.credit) {
//          jsonPayload['credit'] = filters.credit
//       }
//       if (filters.for_plus_users) {
//          jsonPayload['for_plus_users'] = filters.for_plus_users
//       }
//       if (filters.for_you) {
//          jsonPayload['for_you'] = filters.for_you
//       }
//       if (filters['plan']) {
//          jsonPayload['plan[]'] = filters['plan']
//       }
//       if (filters['price[start]'] || filters['price[end]']) {
//          jsonPayload.price = {
//             start: filters['price[start]'],
//             end: filters['price[end]']
//          };
//       }
//       if (filters.sort) {
//          jsonPayload['sort'] = filters.sort
//       }
//       if (filters.status) {
//          jsonPayload['status'] = filters.status
//       }

//       return jsonPayload
//    }
// })

export const accordionOpenSelector = selector<number[]>({
   key: 'accordionOpenSelector',
   get: ({ get }) => {
      const filters = get(filterState).filter
      const tempArr = []
      if (filters.credit) {
         tempArr.push(0)
      }
      if (filters['price[start]']) {
         tempArr.push(1)
      }
      if (filters.status) {
         tempArr.push(2)
      }
      if (filters['plan'] && filters['plan']?.length > 0) {
         tempArr.push(3)
      }

      return tempArr
   }
})
