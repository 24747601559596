import { useRecoilState } from 'recoil'
import { selectedModuleAtom } from 'Recoil/atoms'

export const QuizInormation = () => {
   const [selectedModule] = useRecoilState(selectedModuleAtom)

   return (
      <div className='py-5 w-full flex-col lg:bg-[#F8F8F8] h-[80%] flex gap-5'>
         <h3 className='lg:text-primary text-white sm:text-[20px] font-semibold'>
            {selectedModule?.title}
         </h3>
         <p className='lg:text-base text-xs leading-5 text-white lg:text-black'>
            {selectedModule?.description}
         </p>
         {/* <div className='sm:w-2/3 w-full'>
            <h6 className='text-sm lg:text-primary text-white mb-2 font-semibold'>
               {t('survey_text')}
            </h6>
         </div> */}
      </div>
   )
}
