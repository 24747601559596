// import { useMatomo } from '@datapunt/matomo-tracker-react'
import ErrorBoundary from 'Components/ErrorBoundary'
import Filters from 'Components/Filters/Filters'
import UnderMaintenance from 'Pages/UnderMaintenance'
// import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from 'Routes/AppRoutes'

function App() {
   // const { trackPageView } = useMatomo()

   // useEffect(() => {
   //    trackPageView()
   // }, [])

   if (process.env.REACT_APP_UNDER_MAINTENANCE === 'true') {
      return (
         <BrowserRouter>
            <UnderMaintenance />
         </BrowserRouter>
      )
   }

   return (
      <ErrorBoundary>
         <Filters.Drawer />
         <AppRoutes />
      </ErrorBoundary>
   )
}

export default App
