import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import pdf from 'Assets/Appendice A Politica della qualita 9001_2015 .pdf'
import { Footer } from 'Layouts/Footer/Footer'

/* eslint-disable @typescript-eslint/no-empty-interface */
interface PoliticaProps {}

const Politica: React.FunctionComponent<PoliticaProps> = () => {
   return (
      <>
         <div className='sm:container w-[96%] divide-x-2 divide-[#F5F5F5] flex flex-col flex-wrap gap-3 sm:flex-nowrap mx-auto border-2 p-4 my-6 rounded-md border-[#F5F5F5] lg:min-h-[85vh]'>
            <article className='px-4 lg:px-[10%] flex flex-col gap-5' role='article' id='post_879'>
               <h1 className='text-3xl text-black font-bold py-6'>Politica della qualità</h1>
               <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js'>
                  <div
                     className='rpv-core__viewer'
                     style={{
                        height: '100%',
                        position: 'relative'
                     }}>
                     <Viewer fileUrl={pdf} />
                  </div>
               </Worker>
            </article>
         </div>
         <Footer />
      </>
   )
}

export default Politica
