import React, { useEffect, useState } from 'react'
import { RegisterInput } from 'Components/Inputs/RegisterInput'
import { RegisterSelect } from 'Components/Inputs/RegisterSelect'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { TRegisterSecondStepForm } from 'Types/FormsTypes'
import { Button, Checkbox, FormControl, FormLabel } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { brithCitiesAtom, provinceCitiesAtom } from 'Recoil/atoms'
import { genderOptions, nascitaOptions, tipoOptions } from 'Configs/Constants'
import { useApiRequests } from 'API/ApiRequests'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'

export const SecondStep = ({
   profileData,
   prevStep,
   update,
   isUpdating
}: {
   profileData: any
   isUpdating: boolean
   prevStep: () => void
   update: (data: any) => void
   storeDateInState: (data: any) => void
}) => {
   const {
      handleSubmit,
      register,
      control,
      setValue,
      setError,
      formState: { errors }
   } = useForm<TRegisterSecondStepForm>({
      defaultValues: profileData
   })
   const [provinceCities, setProvinceCities] = useRecoilState(provinceCitiesAtom)
   const [brithCities, setbrithCities] = useRecoilState(brithCitiesAtom)
   const watchProvience = useWatch({ control, name: 'address_province' })
   const watchBirth = useWatch({ control, name: 'birth_in_italy' })
   const watchBirthProvience = useWatch({ control, name: 'birth_province' })

   const {
      getCountries,
      getSSOconfig,
      getProvinces,
      getProvinceCities,
      getProvinceRegion,
      validateFiscalCode
   } = useApiRequests()
   const countriesQuery = useQuery({
      queryKey: ['countries'],
      queryFn: getCountries
   })
   const [ps, setPs] = useState('')

   const vaildateFiscalMutation = useMutation({ mutationFn: validateFiscalCode })
   const provincesQuery = useQuery({
      queryKey: ['provinces'],
      queryFn: getProvinces,
      select: (data) => {
         return Object.keys(data.data.data).map((prov: any) => {
            return { id: prov, name: data.data.data[prov] }
         })
      }
   })

   const onSubmit: SubmitHandler<TRegisterSecondStepForm> = (data: TRegisterSecondStepForm) => {
      const inputElement = document.getElementById('fiscal-checkbox')
      const inputValue = (inputElement as any)?.checked

      vaildateFiscalMutation.mutate(
         {
            date_of_birth: data.date_of_birth,
            first_name: profileData.first_name,
            last_name: profileData.last_name,
            ...(data.birth_in_italy === 'Estero'
               ? {
                    birth_in_italy: data.birth_in_italy,
                    place_of_birth: data.place_of_birth
                 }
               : {
                    birth_city: data.birth_city
                 }),
            gender: data.gender,
            fiscal_code: data.fiscal_code,
            custom_fiscal: inputValue ? 1 : 0
         },
         {
            onSuccess: (res) => {
               const dataToSubmit = { ...profileData, ...data, custom_fiscal: inputValue ? 1 : 0 }
               if (data.birth_in_italy !== 'Italia') {
                  delete dataToSubmit.birth_city
                  delete dataToSubmit.birth_province
               } else {
                  delete dataToSubmit.place_of_birth
               }
               if (res) {
                  update(dataToSubmit)
               }
            },
            onError: () => {
               setError('fiscal_code', {
                  type: 'custom',
                  message: 'Il codice fiscale  non corrisponde ai dati inseriti'
               })
            }
         }
      )
   }

   useEffect(() => {
      if (watchProvience) {
         getProvinceCities(watchProvience).then((res) => {
            setProvinceCities(res.data.data)
         })
         getProvinceRegion(watchProvience).then((res) => {
            setValue('address_regione', res.data.data)
         })
      }
   }, [watchProvience])

   useEffect(() => {
      if (watchBirthProvience) {
         getProvinceCities(watchBirthProvience).then((res) => {
            setbrithCities(res.data.data)
         })
      }
   }, [watchBirthProvience])

   const isPlaceOfBirthInCountries = () => {
      return countriesQuery.data?.data.data.some(
         (country: any) => country.name === profileData.place_of_birth
      )
   }

   useEffect(() => {
      if (profileData.birth_province || !isPlaceOfBirthInCountries()) {
         setValue('birth_in_italy', 'Italia')
         return
      }
      if (!profileData.birth_province || isPlaceOfBirthInCountries())
         setValue('birth_in_italy', 'Estero')
   }, [])

   useEffect(() => {
      setValue('birth_province', profileData.birth_province)
   }, [provincesQuery.data?.length])

   useEffect(() => {
      setValue('birth_city', profileData.birth_city)
   }, [brithCities.length])

   useEffect(() => {
      setValue('address_city', profileData.address_city)
   }, [provinceCities.length])

   useEffect(() => {
      countriesQuery.data?.data.data && setValue('place_of_birth', profileData.place_of_birth)
   }, [countriesQuery.data?.data.data])

   const ssoConfig = useQuery(['ssoConfig'], {
      queryFn: getSSOconfig,
      select: (res) => res.data.data
   })
   useEffect(() => {
      fetch('/ps.txt')
         .then((r) => r.text())
         .then((text) => {
            setPs(text)
         })
   }, [])

   return (
      <>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-8 my-4 w-full mx-auto'>
               <div
                  className={`grid ${
                     watchBirth !== 'Italia' ? 'lg:grid-cols-[20%_78%]' : 'lg:grid-cols-3'
                  } justify-between gap-4 grid-cols-1`}>
                  <RegisterSelect
                     required
                     name='birth_in_italy'
                     infoText='Se non nati in Italia, selezionare Estero'
                     register={register}
                     options={nascitaOptions}
                     errorMessage={errors.birth_in_italy?.message}
                     label='Luogo di nascita'
                     placeholder='Seleziona'
                  />
                  {watchBirth !== 'Italia' ? (
                     <RegisterSelect
                        required
                        name='place_of_birth'
                        register={register}
                        options={countriesQuery.data?.data.data || []}
                        errorMessage={errors.place_of_birth?.message}
                        label='Nazionalità'
                        placeholder='Seleziona nazione di nascita '
                     />
                  ) : (
                     <>
                        <RegisterSelect
                           required
                           name='birth_province'
                           register={register}
                           selectId
                           infoText='Se non nati in italia, selezionare la nazione di nascita'
                           options={provincesQuery.data || []}
                           errorMessage={errors.birth_province?.message}
                           label='Provincia di nascita'
                           placeholder=''
                        />
                        <RegisterSelect
                           required
                           name='birth_city'
                           register={register}
                           options={brithCities || []}
                           errorMessage={errors.birth_city?.message}
                           label='Città di nascita'
                           placeholder=''
                        />
                     </>
                  )}
               </div>
               <div className='grid md:grid-cols-[30%_25%_42%] items-end grid-cols-1 md:gap-0 gap-4 justify-between'>
                  <RegisterInput
                     required
                     errorMessage={errors.date_of_birth?.message}
                     name='date_of_birth'
                     isDate
                     infoText="Inserire la data di nascita con il formato gg/mm/aaaa, prestanto attenzione ad inserire l'anno di nascita completo (es: 1980)"
                     register={register}
                     label='Data di nascita'
                     placeholder=''
                  />
                  <RegisterSelect
                     required
                     name='gender'
                     infoText='Selezionare il sesso, così come riportato nel codice fiscale'
                     selectId
                     register={register}
                     options={genderOptions}
                     errorMessage={errors.gender?.message}
                     label='Genere'
                     placeholder='Seleziona genere'
                  />
                  <RegisterInput
                     required
                     errorMessage={errors.fiscal_code?.message}
                     name='fiscal_code'
                     infoText='Inserire il codice fiscale, prestando attenzione che sia formato da 16 caratteri (9 lettere e 7 numeri)'
                     register={register}
                     label='Codice fiscale'
                     placeholder='Inserisci codice fiscale'
                  />
               </div>

               <div className='grid md:grid-cols-[30%_47%_18%] grid-cols-1 justify-between gap-4'>
                  <RegisterSelect
                     required
                     errorMessage={errors.address_type?.message}
                     name='address_type'
                     options={tipoOptions}
                     register={register}
                     label='Tipo di indirizzo'
                     placeholder='Seleziona'
                  />
                  <RegisterInput
                     required
                     errorMessage={errors.address_line?.message}
                     name='address_line'
                     register={register}
                     infoText="Inserire l'indirizzo di residenza, domicilo o lavorativo, specificando se via/piazza/viale, ecc"
                     label='Indirizzo'
                     placeholder='Via, viale, piazza'
                  />
                  <RegisterInput
                     required
                     errorMessage={errors.address_number?.message}
                     name='address_number'
                     register={register}
                     infoText='Inserire il numero civico'
                     label='Civico'
                     placeholder='Numero'
                  />
               </div>
               <div className='grid md:grid-cols-[20%_78%] grid-cols-1 justify-between gap-4'>
                  <RegisterInput
                     required
                     errorMessage={errors.zip_code?.message}
                     name='zip_code'
                     infoText="Inserire il cap dell'indirizzo indicato. Se non si è in possesso del cap di zona è possibile inserire anche quello della città (es: Milano 20100)"
                     register={register}
                     label='CAP'
                     placeholder='Inserisci CAP'
                  />

                  <RegisterSelect
                     required
                     errorMessage={errors.address_province?.message}
                     name='address_province'
                     options={provincesQuery.data}
                     register={register}
                     selectId
                     label='Provincia'
                     placeholder=''
                  />
               </div>
               <div className='grid md:grid-cols-3 grid-cols-1 justify-between gap-4'>
                  <RegisterSelect
                     required
                     errorMessage={errors.address_city?.message}
                     name='address_city'
                     register={register}
                     label='Città'
                     options={(provinceCities as any) || []}
                     placeholder=''
                  />
                  <RegisterInput
                     required
                     errorMessage={errors.address_regione?.message}
                     name='address_regione'
                     disabled
                     register={register}
                     label='Regione'
                     placeholder='Regione'
                  />
                  <RegisterInput
                     required
                     errorMessage={errors.phone_number?.message}
                     name='phone_number'
                     register={register}
                     infoText='Inserire un numero di cellulare valido. Il numero può essere privato o aziendale. Se fosse un numero estero si prega di specificare il prefisso.'
                     label='Cellulare'
                     placeholder='Cellulare'
                  />
               </div>
            </div>
            <div className='flex flex-col container mx-auto'>
               <div className='flex gap-6 md:flex-row flex-col '>
                  <div className='flex-col flex flex-grow'>
                     <div className='flex justify-between mb-2 items-center'>
                        <span className='text-gray'>Privacy policy Xgate</span>
                        <a
                           target={'_blank'}
                           href={ssoConfig.data?.privacy_policy_page_url.config_value}
                           className='cursor-pointer text-xs text-gray'
                           rel='noreferrer'>
                           Leggi linformativa
                        </a>
                     </div>
                     <textarea
                        rows={6}
                        defaultValue={ssoConfig?.data?.privacy_policy_text.config_value}
                        className=' w-full pl-3 text-base rounded-md border-2  shadow-md
                 focus:shadow-sm border-[#bbbbbb59] outline-none duration-500 transition-all'
                     />
                  </div>
                  <div className='flex-col flex flex-grow'>
                     <div className='flex justify-between mb-2 items-center'>
                        <span className='text-gray'>Privacy policy Sito</span>
                        <Link
                           target={'_blank'}
                           to={'/privacy-policy'}
                           className='cursor-pointer text-xs text-gray'
                           rel='noreferrer'>
                           Leggi linformativa
                        </Link>
                     </div>
                     <textarea
                        rows={6}
                        defaultValue={ps}
                        className=' w-full pl-3 text-base rounded-md border-2  shadow-md
                 focus:shadow-sm border-[#bbbbbb59] outline-none duration-500 transition-all'
                     />
                  </div>
               </div>
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='flex flex-col gap-4 mt-5'>
                     <FormControl isRequired>
                        <Checkbox disabled defaultChecked>
                           <FormLabel mb={0} className='md:text-sm text-xs'>
                              Accetto{'  '}
                              <a href='termini-duso' className='text-primary' target={'_blank'}>
                                 termini e condizioni d`uso{' '}
                              </a>
                           </FormLabel>
                        </Checkbox>
                     </FormControl>
                     <Checkbox {...register('data_marketing_consent')}>
                        <FormLabel className='md:text-sm text-xs'>
                           Esprimo il mio consenso all’invio da parte di Ecmclub di comunicazioni
                           aventi contenuti informativi, medico-scientifici o divulgativi
                           utilizzando l’indirizzo di posta elettronica fornito
                        </FormLabel>
                     </Checkbox>
                     <Checkbox {...register('data_transfer_consent')}>
                        <FormLabel className='md:text-sm text-xs'>
                           Esprimo il mio consenso alla raccolta di informazioni relative
                           all’attività professionale e ai relativi interessi/aree di interesse tese
                           alla creazione di profili nell’ambito medico-scientifico da sviluppare,
                           fornire e migliorare i servizi informativi e medico-scientifici offerti
                           da Ecmclub e renderli più rispondenti alle esigenze e interessi
                        </FormLabel>
                     </Checkbox>
                  </div>
               </form>
            </div>
            <div className='w-full justify-between flex mt-5 pb-5'>
               <Button onClick={() => prevStep()} variant={'primary'} w='150px' height={'55px'}>
                  Indietro
               </Button>
               <Button
                  isLoading={isUpdating}
                  type='submit'
                  variant={'primary'}
                  w='150px'
                  height={'55px'}>
                  Salva
               </Button>
            </div>
         </form>
      </>
   )
}
