import { useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import OngoingCard from 'Components/Cards/OngoingCard'
import CardsCarousel from 'Components/CardsCarousel'
import DetailsText from 'Components/DetailsText'
import SectionTitle from 'Components/SectionTitle'
import SmallBtn from 'Components/shared/SmallBtn'
import { userDataAtom } from 'Recoil/atoms'
import { TUserCourses } from 'Types/Models'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

const UserCourses = () => {
  const {
    getUserCourses
    // getMainBanner
  } = useApiRequests()

  const navigate = useNavigate()

  const [userData] = useRecoilState(userDataAtom)

  const [isLargerThan900, isLargerThan1026, isLargerThan1400] = useMediaQuery([
    '(min-width: 900px)',
    '(min-width: 1026px)',
    '(min-width: 1400px)'
  ])

  const [userCourses, setUserCourses] = useState<any[]>([]);

  const { isFetching } = useQuery(
    [
      'userCourses',
    ],
    {
      queryFn: () =>
        getUserCourses(true, false, false),
      select: (res) => {
        return res.data.data.ongoing
      },
      onSuccess: (res) => setUserCourses(res)
    }
  )

  const getCapitalizedName = () => {
    if (userData) {
      return userData?.user_data.first_name?.charAt(0).toUpperCase() + userData?.user_data.first_name?.slice(1)
    }
    return ''
  }

  return (
    <div className='mt-4'>
      <div className='pb-5'>
        <div className='block md:hidden'>
          <SectionTitle>{getCapitalizedName()}, continua:</SectionTitle>
        </div>
        <div className='hidden md:block'>
          <SectionTitle>{getCapitalizedName()}, continua con la tua formazione:</SectionTitle>
        </div>

        <div className='md:hidden'>
          <DetailsText>
            Scorri con il dito verso sinistra per vedere altri corsi
          </DetailsText>
        </div>
      </div>
      <CardsCarousel
        slidesToShow={
          isLargerThan1400
            ? 3.25
            : isLargerThan1026
              ? 2.25
              : isLargerThan900
                ? 2.25
                : 1.25
        }
        wrap={true}
      >
        {userCourses.map((course: TUserCourses) => {
          return <OngoingCard.Card {...course} enrolled={true} key={course.id} expired={false} />
        })}
        {isFetching && (
          <div className='flex gap-5'>
            {Array.from(Array(4).keys()).map((item) => (
              <OngoingCard.Skeleton key={item} />
            ))}
          </div>
        )}
      </CardsCarousel>
      <div className="flex justify-end relative -top-8 z-10">
        <SmallBtn onClick={() => navigate('/corsi-inziati')}>
          Vedi tutto
        </SmallBtn>
      </div>
    </div>
  )
}

export default UserCourses