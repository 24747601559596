import { CollapsableCard } from 'Components/Cards/CollapsableCard/CollapsableCard'
import { Button } from '@chakra-ui/react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { TModule, TQuiz } from 'Types/Models'
import { ModulesListSkeleton } from './ModulesListSkeleton'
import { getColor } from 'Helpers'
import { selectedModuleAtom, moduileQuizAtom } from 'Recoil/atoms'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FaRegClock } from 'react-icons/fa'

export const ModulesList = ({
   modules,
   isLoading,
   servey
}: {
   isLoading: boolean
   modules: any
   servey: any
}) => {
   const [, setselectedModule] = useRecoilState(selectedModuleAtom)

   const resetSelectedModule = useResetRecoilState(selectedModuleAtom)

   const resetQuizProgress = useResetRecoilState(moduileQuizAtom)

   const handleStartModule = (module: TModule | TQuiz) => {
      resetQuizProgress()
      resetSelectedModule()
      setselectedModule(module)
   }
   const { t } = useTranslation()

   return (
      <div
         id='modulesList'
         style={{ scrollBehavior: 'smooth' }}
         className='flex flex-col pb-3 gap-3 lg:max-w-[400px] pt-4 lg:p-0 w-[96%] sm:container mx-auto overflow-y-auto h-full'>
         {isLoading ? (
            <ModulesListSkeleton />
         ) : (
            <>
               {modules.map((module: TModule | TQuiz) => {
                  if (module.type_text === 'QUIZ') {
                     return (
                        <CollapsableCard
                           key={Math.random()}
                           id={module.id}
                           bgColor={getColor(module as TQuiz)}
                           textColor='white'
                           isLocked={
                              (
                                 (module as TQuiz).quiz_user.status.locked
                                 &&
                                 !(module as TQuiz).quiz_user.status.last_success_attempt
                              )
                              &&
                              (
                                 (module as TQuiz).max_attempts !== (module as TQuiz).quiz_user.status.attempts
                              )
                           }
                           title={module.title}
                           secondTitle={module.type_text}
                           // secondDescription='CONSULTATO PER IL 33%'
                           description={module.description}>
                           <div className='text-black p-5 pt-0 flex flex-col'>
                              <Button
                                 fontSize={'14px'}
                                 bg='white'
                                 onClick={() => handleStartModule(module)}
                                 h='51px'
                                 _hover={{
                                    bg: 'transparent',
                                    color: 'white',
                                    border: 'white 2px solid'
                                 }}
                                 textColor={getColor(module)}>
                                 {t('Riprendi quiz')}
                              </Button>
                           </div>
                        </CollapsableCard>
                     )
                  }
                  if (module.type_text === 'SURVEY') {
                     return (
                        <CollapsableCard
                           id={servey.id}
                           key={Math.random()}
                           bgColor={servey?.can_survey_start?.is_passed ? '#159600' : '#335F6B'}
                           textColor='white'
                           isLocked={!servey?.can_survey_start?.can_start}
                           title={servey.servey.title}
                           secondTitle={servey.servey.type_text}
                           // secondDescription={`CONSULTATO PER IL ${}%`}
                           description={servey.servey.description}>
                           <div className='text-black p-5 pt-0 flex flex-col'>
                              <Button
                                 fontSize={'14px'}
                                 bg='white'
                                 onClick={() => handleStartModule(servey.servey)}
                                 h='51px'
                                 _hover={{
                                    bg: 'transparent',
                                    color: 'white',
                                    border: 'white 2px solid'
                                 }}
                                 textColor={
                                    servey?.can_survey_start?.is_passed ? '#159600' : '#335F6B'
                                 }>
                                 {t('Riprendi modulo')}
                              </Button>
                           </div>
                        </CollapsableCard>
                     )
                  }
                  return (
                     <CollapsableCard
                        id={module.id}
                        key={Math.random()}
                        bgColor={getColor(module as TModule)}
                        textColor='white'
                        isLocked={(module as TModule).module_user.status.locked}
                        title={module.title}
                        secondTitle={module.type_text === 'TEXT' ? 'TESTO' : module.type_text}
                        secondDescription={
                           (module as TModule).start_date ? (
                              <div className='text-[#AA5260] uppercase font-bold flex gap-1 items-start'>
                                 <FaRegClock className='text-base' />
                                 DISPONIBILE DAL{' '}
                                 {moment((module as TModule).start_date)
                                    .locale('it')
                                    .format('DD MMMM')}
                              </div>
                           ) : (
                              ((module as TModule).module_user.status.progress &&
                                 `CONSULTATO PER IL ${(module as TModule).module_user.status.progress
                                 }%`) ||
                              ''
                           )
                        }
                        description={module.description}>
                        <div className='text-black p-5 pt-0 flex flex-col'>
                           <Button
                              fontSize={'14px'}
                              bg='white'
                              onClick={() => handleStartModule(module)}
                              h='51px'
                              _hover={{
                                 bg: 'transparent',
                                 color: 'white',
                                 border: 'white 2px solid'
                              }}
                              textColor={getColor(module)}>
                              {t('Riprendi modulo')}
                           </Button>
                        </div>
                     </CollapsableCard>
                  )
               })}
               {/* {servey.servey && (
         
               )} */}
            </>
         )}
      </div>
   )
}
