/* eslint-disable no-console */
import {
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalOverlay,
   color,
   useMediaQuery
} from '@chakra-ui/react'
import { ModuleAttention } from 'Components/ModuleAttention'
import { ModuleOverview } from 'Components/ModuleOverview'
import { Link, useParams } from 'react-router-dom'
import { useApiRequests } from 'API/ApiRequests'
import { useQuery } from '@tanstack/react-query'
import { CourseDetailsHeader } from 'Components/CourseDetailsComponents/CourseDetailsHeader/CourseHeader'
import { CourseDetailsHeaderSkeleton } from 'Components/CourseDetailsComponents/CourseDetailsHeader/CourseHeaderSkeleton'
import { CourseOtherDetails } from 'Components/CourseDetailsComponents/CourseOtherDetails/CourseOtherDetails'
import { CourseEnrollButton } from 'Components/CourseDetailsComponents/CourseDetailsHeader/CourseEnrollButton'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { ModalWrapper } from 'Components/Modals/ModalWrapper'
import { size } from 'lodash'

export const CourseDetails = () => {
   const [smallScreen] = useMediaQuery('(max-width: 768px)')
   const { getCourseDetails } = useApiRequests()
   const params = useParams()

   const { data, isFetching, isLoading } = useQuery(['courseDetails', params?.slug], {
      queryFn: ({ queryKey }) => getCourseDetails(queryKey[1] || ''),
      select: (data) => data.data.data
   })

   return (
      <div>
         <div className='w-[96%] sm:container md:h-auto h-[75vh] overflow-y-auto md:py-7 py-3 flex flex-col md:gap-8 gap-5 mx-auto'>
            {(data as any)?.users_maxed_out && (
               <Modal isOpen={true} onClose={() => ''}>
                  <ModalOverlay />
                  <ModalContent rounded={'6px'} p={0} mt={150} borderRadius={0}>
                     <ModalBody
                        width={'max-content'}
                        rounded={'6px'}
                        w='full'
                        p='0'
                        bgColor={'light-gray'}>
                        <div className='p-8 flex flex-col gap-5'>
                           <div
                              style={{
                                 textAlign: 'center',
                                 color: '#AA5260',
                                 fontSize: 28,
                                 fontWeight: '700',
                                 wordWrap: 'break-word'
                              }}>
                              Oh no, limite raggiunto :(
                           </div>

                           <div
                              style={{
                                 textAlign: 'center',
                                 color: 'black',
                                 fontSize: 15,
                                 fontWeight: '400',
                                 wordWrap: 'break-word'
                              }}>
                              Abbiamo raggiunto il numero massimo di utenti iscritti a questo corso
                              con diritto ai crediti ECM!
                              <br />
                              Torna alla homepage per accedere ad altri corsi
                           </div>

                           <Link
                              to={'/'}
                              className='text-center text-white bg-[#AA5260] rounded w-full py-3'>
                              Torna ai corsi
                           </Link>
                        </div>
                     </ModalBody>
                  </ModalContent>
               </Modal>
            )}

            {data?.get_warning && (
               <motion.div
                  transition={{ duration: 0.6 }}
                  initial={{ opacity: 0.2, y: '-40%' }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}>
                  <ModuleAttention />
               </motion.div>
            )}

            {!isLoading && data ? (
               <CourseDetailsHeader data={data} />
            ) : (
               <CourseDetailsHeaderSkeleton />
            )}

            {smallScreen && data && (
               <ModuleOverview description={data.abstract_description} title={data.title} />
            )}
         </div>

         {data && smallScreen && (
            <div className='w-[97%] mx-auto'>
               <CourseEnrollButton data={data} />
            </div>
         )}

         <CourseOtherDetails
            course_professions={data?.course_professions || []}
            course_specializations={data?.course_specializations || []}
            course_program_file={data?.info.course_program_file || ''}
            description={data?.description || ''}
         />
      </div>
   )
}
