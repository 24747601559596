import SubscriptionStyles from 'Configs/SubscripationStyles';
import PlanTypes from 'Types/Subscripe';

const Label = ({ child }: { child: string }) => {
  return (
    <span
      className={`rounded-[3px] px-[10px] py-[2px] text-xs font-nuinto font-bold text-white ${SubscriptionStyles[child as PlanTypes].bg}`}
    >
      {child}
    </span>
  );
};

export default Label;
