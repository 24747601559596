/* eslint-disable no-console */
import { Button, Input, Select } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface AddCouponProps {
   courseId: string
   onClose: Dispatch<SetStateAction<boolean>>
}

const AddCoupon: React.FunctionComponent<AddCouponProps> = ({ courseId, onClose }) => {
   const { validate_coupon, enrollToCourse } = useApiRequests()
   const { mutate, isLoading, isError, isSuccess } = useMutation({ mutationFn: validate_coupon })
   const enrollMutation = useMutation({ mutationFn: enrollToCourse })
   const navigate = useNavigate()
   const codeInput = useRef<HTMLInputElement>(null)
   const [error, seterror] = useState('')
   const [selected, setselected] = useState('no')
   const [textValue, settextValue] = useState('')
   const { t } = useTranslation()
   const queryClient = useQueryClient()
   const handleContinueEnroll = () => {
      enrollMutation.mutate(
         { courseId, agency: textValue },
         {
            onSuccess: (data) => data && navigate(`/module/${courseId}`)
         }
      )
   }

   const handleContinue = (code: string | undefined) => {
      if (!code) return
      mutate(
         { course: courseId, code: code },
         {
            onError: (error: any) => {
               if (
                  error.response.data?.error?.code?.[0] === 'The selected code is invalid.' ||
                  error.response.data?.error?.code === 'invalid-code'
               ) {
                  seterror('Ops, il codice inserito non è corretto, ti preghiamo di verificare.')
               } else if (error.response.data?.error?.code === 'code-expired') {
                  seterror('Oh no, sembra questo codice sia già stato utilizzato!')
               } else {
                  seterror('Hai già utilizzato un codice di accesso a questo corso')
               }
            }
         }
      )
   }
   return (
      <div className='max-w-2xl mx-auto font-nuinto px-2'>
         {isSuccess ? (
            <div className='p-4 flex flex-col gap-2 w-full text-dark-grey'>
               <span className='font-bold'>
                  <span className='font-bold'>
                     {t('La Sua iscrizione nasce a seguito di un reclutamento diretto?')}
                  </span>{' '}
                  In caso affermativo indicare la ragione sociale dell`azienda
               </span>
               <div className='flex flex-col justify-between gap-10 items-center py-4'>
                  <Select
                     variant='filled'
                     backgroundColor='white'
                     boxShadow='lg'
                     size='lg'
                     onChange={(e) => setselected(e.target.value)}
                     value={selected}
                     className='w-20 px-2 text-black border-grey rounded-lg border-2'>
                     <option value={'si'}>Si</option>
                     <option value={'no'}>No</option>
                  </Select>
                  {selected === 'si' && (
                     <div className='pb-6 w-full'>
                        <label className='text-left text-dark-gray text-sm self-start py-4'>
                           {t('Inserire nome azienda')}
                        </label>
                        <Input
                           variant='filled'
                           backgroundColor='white'
                           border={0}
                           boxShadow='xl'
                           required
                           onChange={(e) => settextValue(e.target.value)}
                           placeholder='Enter ..'
                           value={textValue}
                           _focus={{ boxShadow: 'xl' }}
                           className='w-full bg-transparent border-2 border-gray rounded-lg px-3 text-sm text-slate-700'
                        />
                     </div>
                  )}
               </div>
               <Button
                  isLoading={isLoading}
                  onClick={handleContinueEnroll}
                  variant={'primary'}
                  bgColor='yellow.500'
                  disabled={selected === 'si' && textValue === ''}
                  py='8'
                  alignSelf='end'>
                  {t('Continue')}
               </Button>
            </div>
         ) : (
            <div className='flex flex-col p-8 gap-5 justify-center items-center'>
               <span className='font-bold text-3xl text-[#496492]'>
                  Inserisci qui il tuo codice di accesso e iscriviti al corso!
               </span>
               <Input
                  ref={codeInput}
                  className='placeholder:text-black'
                  fontSize='12px'
                  textColor='black'
                  borderColor='#F5F5F5'
                  variant='filled'
                  border='1px solid'
                  placeholder='Inserisci il tuo codice'
                  _focus={{ borderColor: '#F5F5F5' }}
                  isInvalid={isError}
               />
               {isError && <span className='text-red-600 text-xs'>{error}</span>}
               <Button
                  onClick={() => {
                     if (codeInput.current?.value !== '') {
                        handleContinue(codeInput.current?.value)
                     }
                  }}
                  isLoading={isLoading}
                  fontSize='12px'
                  bgColor='#496492'
                  variant='primary'>
                  Applica
               </Button>

               <span
                  onClick={() => onClose(false)}
                  className='text-xs text-[#496492] cursor-pointer select-none'>
                  TORNA AL CORSO
               </span>
            </div>
         )}
      </div>
   )
}

export default AddCoupon
