import { useMediaQuery } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { useApiRequests } from "API/ApiRequests"
import Carousel from "nuka-carousel"

const AdBanner = ({ top }: { top: boolean }) => {
  const { getBanners } = useApiRequests()
  const { data, isLoading } = useQuery(["banners"], {
    queryFn: getBanners,
    select: (data) => {
      if (top) {
        return data.data.data.filter((banner: any) => banner.position === 1)
      } else {
        return data.data.data.filter((banner: any) => banner.position === 2)
      }
    },
  })

  const [isLargerThan800, isLargerThan1024] = useMediaQuery([
    "(min-width: 799px)",
    "(min-width: 1024px)",
  ])

  return (
    <>
      {isLoading ? (
        <div className='flex justify-between items-center  overflow-x-scroll no-scrollbar'>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
          <div className='h-[135px] min-w-[415px] bg-[#D9D9D9]'></div>
        </div>
      ) : (
        <Carousel
          autoplay
          wrapAround
          withoutControls
          slidesToShow={isLargerThan1024 ? 3 : isLargerThan800 ? 2 : 1}
          className='fix-spacing '>
          {data.map((item: any, index: number) => {
            return (
              <div key={item.image}>
                {item.type === 1 && (
                  <a target={"_blank"} href={item.link} rel='noreferrer'>
                    <img
                      key={index}
                      src={`${process.env.REACT_APP_MEDIA_URL + item.image}`}
                    />
                  </a>
                )}
                {item.type === 2 && (
                  <>
                    <div dangerouslySetInnerHTML={{ __html: item.html_content }} />
                  </>
                )}
              </div>
            )

          })}
        </Carousel>
      )}
    </>
  )
}

export default AdBanner
