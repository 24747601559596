/* eslint-disable react/no-unescaped-entities */
import {
   Drawer,
   DrawerBody,
   DrawerContent,
   DrawerCloseButton,
   DrawerOverlay,
   Link,
   Button
} from '@chakra-ui/react'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { CommentsDrawer } from './Drawers/CommentsDrawer'

import { Claps } from './Buttons/Claps'
import moment from 'moment'
import 'moment/locale/it'
import { selectedModuleAtom } from 'Recoil/atoms'
import { useRecoilState } from 'recoil'
import { Timer } from './Timer'

export const ModuleTopBar = ({
   isLiked,
   canComment,
   likesCount,
   progress,
   commentsCount,
   moudleInfo
}: {
   totalHours: string
   likesCount: any
   isLiked: boolean
   canComment: boolean
   progress: any
   commentsCount: any
   userCompletedHour: string
   moudleInfo: any
}) => {
   const navigate = useNavigate()

   const [showDescriptionDrawer, setShowDescriptionDrawer] = useState(false)
   const [showGuideDrawer, setShowGuideDrawer] = useState(false)
   const [showCommentsDrawer, setShowCommentsDrawer] = useState(false)
   const [showMaterialDrawer, setShowMaterialDrawer] = useState(false)
   const [selectedModule] = useRecoilState(selectedModuleAtom)
   const hanldeOpenDescription = () => setShowDescriptionDrawer(true)
   const hanldeCloseDescription = () => setShowDescriptionDrawer(false)

   const hanldeOpenComments = () => setShowCommentsDrawer(true)
   const hanldeCloseComments = () => setShowCommentsDrawer(false)

   const hanldeOpenGuide = () => setShowGuideDrawer(true)
   const hanldeCloseGuide = () => setShowGuideDrawer(false)

   // const hanldeOpenMaterial = () => setShowMaterialDrawer(true)
   const hanldeCloseMaterial = () => setShowMaterialDrawer(false)

   const { t } = useTranslation()

   return (
      <div className='flex px-0.5 gap-12 text-primary text-xs items-center'>
         <span
            onClick={() => navigate(-1)}
            className='flex cursor-pointer font-bold py-6 text-black  items-center gap-1'>
            <IoIosArrowBack />
            {t('Back')}
         </span>
         <span className='cursor-pointer' onClick={hanldeOpenGuide}>
            GUIDA AL CORSO
         </span>
         <span className='cursor-pointer' onClick={hanldeOpenDescription}>
            INFORMAZIONI CORSO
         </span>
         {/* <span className='cursor-pointer' onClick={hanldeOpenMaterial}>
            MATERIALI DI APPROFONDIMENTO
         </span> */}

         <div className='flex flex-1  justify-end gap-5 items-center bg-white py-3'>
            {selectedModule?.attachment && (
               <Link
                  target={'_blank'}
                  w='150px'
                  href={process.env.REACT_APP_MEDIA_URL + selectedModule?.attachment}
                  className='py-4 w-1/5 self-center'
                  _hover={{ textDecoration: 'nome' }}>
                  <Button fontSize='sm' variant='primary'>
                     SCARICA IL PDF
                  </Button>
               </Link>
            )}
            <Claps likesCount={likesCount} isLiked={isLiked} />
            {canComment && (
               <div className='flex text-primary items-end gap-2'>
                  <svg
                     className='cursor-pointer'
                     onClick={hanldeOpenComments}
                     width='26'
                     height='20'
                     viewBox='0 0 26 20'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                     <path
                        d='M16.9228 6.875C16.9228 3.07812 13.2773 0 8.7782 0C4.2791 0 0.633618 3.07812 0.633618 6.875C0.633618 8.4207 1.24524 9.84219 2.26527 10.9922C1.6235 12.2719 0.72916 13.2312 0.715063 13.2453C0.632834 13.3314 0.610515 13.4592 0.658639 13.5716C0.708015 13.6836 0.813816 13.75 0.933557 13.75C2.43521 13.75 3.70428 13.3156 4.68241 12.8109C5.88844 13.4023 7.28241 13.75 8.7782 13.75C13.2773 13.75 16.9228 10.6719 16.9228 6.875ZM8.7782 13.125C7.44139 13.125 6.12103 12.8226 4.96003 12.2504L4.67458 12.1094L4.39461 12.2539C3.50811 12.7113 2.59223 12.9898 1.66344 13.0855C2.00148 12.6531 2.45127 12.0152 2.826 11.2684L3.01544 10.8905L2.73437 10.5742C1.76994 9.45703 1.26012 8.21094 1.26012 6.875C1.26012 3.42891 4.63268 0.625 8.7782 0.625C12.9237 0.625 16.2963 3.42891 16.2963 6.875C16.2963 10.3211 12.9249 13.125 8.7782 13.125ZM24.1119 17.332C25.0987 16.168 25.6939 14.7109 25.6939 13.125C25.6939 9.62734 22.8707 6.74609 19.139 6.3125C18.9467 6.28999 18.7813 6.44875 18.7933 6.64156C18.8025 6.79125 18.9178 6.91293 19.0671 6.93055C22.4478 7.32812 25.0674 9.95312 25.0674 13.125C25.0674 14.5094 24.5716 15.825 23.6334 16.9293L23.3625 17.2484L23.5548 17.6199C23.917 18.3199 24.3438 18.9234 24.6661 19.3371C23.7933 19.2466 22.9287 18.9931 22.0876 18.5801L21.8067 18.4422L21.5275 18.5837C20.5056 19.0664 19.3466 19.375 18.1758 19.375C15.2946 19.375 12.827 17.7605 11.8011 15.4789C11.7409 15.3453 11.5955 15.2721 11.4523 15.3043L11.4382 15.3075C11.2479 15.3503 11.1474 15.5563 11.2272 15.7336C12.3493 18.2344 15.0354 20 18.1758 20C19.495 20 20.7335 19.6872 21.8115 19.1414C22.7649 19.6094 23.9749 20 25.3924 20C25.5121 20 25.6179 19.9326 25.6672 19.8201C25.7154 19.7077 25.693 19.58 25.6108 19.4938C25.5999 19.4805 24.7502 18.5664 24.1119 17.332Z'
                        fill='#496492'
                     />
                  </svg>
                  <span>{commentsCount}</span>
               </div>
            )}
            <div className='flex rounded-lg p-3 text-blueDark text-sm font-bold gap-3 items-center '>
               <svg
                  width='20'
                  height='11'
                  viewBox='0 0 20 11'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M11.3174 0.5H19.3174M19.3174 0.5V8.5M19.3174 0.5L11.3174 8.5L7.31738 4.5L1.31738 10.5'
                     stroke='#4E638F'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                  />
               </svg>

               <span className=''>{Number(progress)}%</span>
            </div>

            {selectedModule?.type_text !== 'VIDEO' &&
               selectedModule?.type_text !== 'QUIZ' &&
               selectedModule?.type_text !== 'SURVEY' &&
               (selectedModule as any)?.module_user.total_time && (
                  <>
                     |
                     <Timer />
                  </>
               )}
         </div>
         <Drawer isOpen={showDescriptionDrawer} placement='right' onClose={hanldeCloseDescription}>
            <DrawerOverlay />
            <DrawerContent>
               <DrawerBody px={0} pt={0} pb={10}>
                  <div className='flex text-white justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative p-5 bg-[#f18535]'>
                     <p className='flex-grow-0 flex-shrink-0 w-[259.6px] text-xs font-bold text-left text-white uppercase'>
                        Informazioni corso
                     </p>
                     <DrawerCloseButton />
                  </div>
                  <div
                     className={
                        'pl-5 pr-4 gap-5 inline-flex flex-col items-start text-left text-black mt-7 font-[\'Montserrat\']'
                     }>
                     <div className='leading-none relative '>
                        <p className='font-normal inline m-0 text-[13px] leading-[1.3]'>
                           {'Crediti ECM: '}
                        </p>
                        <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                           {moudleInfo?.completion_credits}
                        </p>
                     </div>
                     <div className='leading-none relative '>
                        <p className='font-normal inline m-0 text-[13px] leading-[1.3]'>
                           {'Online da: '}
                        </p>
                        <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                           {moment(moudleInfo?.start_date).locale('it').format('DD MMMM YYYY')}
                        </p>
                     </div>
                     <div className='leading-none relative '>
                        <p className='font-normal inline m-0 text-[13px] leading-[1.3]'>
                           {'Valido fino a: '}
                        </p>
                        <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                           {moment(moudleInfo?.end_date).locale('it').format('DD MMMM YYYY')}
                        </p>
                     </div>
                     <div className='leading-none relative '>
                        <p className='font-normal inline m-0 text-[13px] leading-[1.3]'>
                           {'Ore formative: '}
                        </p>
                        <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                           {moudleInfo?.completion_hours}
                        </p>
                     </div>
                     {moudleInfo?.event_id && (
                        <div className='leading-none relative '>
                           <p className='font-normal inline m-0 text-[13px] leading-[1.3]'>
                              {'ID EVENTO: '}
                           </p>
                           <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                              {moudleInfo?.ag_id}
                           </p>
                        </div>
                     )}

                     <div className='leading-none relative '>
                        <p className='font-normal inline m-0 text-[13px] leading-[1.3]'>
                           {'Professioni: '}
                        </p>
                        <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                           {moudleInfo?.professions?.map((item: string, index: number) => (
                              <>
                                 {item}
                                 {index + 1 !== moudleInfo?.professions?.length && ','}{' '}
                              </>
                           ))}
                        </p>
                     </div>
                     <div className='leading-none relative '>
                        <p className='font-normal inline m-0 text-[13px] leading-[1.3]'>
                           {'Discipline: '}
                        </p>
                        <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                           {moudleInfo?.specializations?.map((item: string, index: number) => (
                              <>
                                 {item}
                                 {index + 1 !== moudleInfo?.specializations?.length && ','}{' '}
                              </>
                           ))}
                        </p>
                     </div>
                     <div className='leading-none relative '>
                        <p className='font-normal inline m-0 text-[13px] leading-[1.3]'>
                           {'Obbiettivo formativo: '}
                        </p>
                        <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                           {moudleInfo?.education}
                        </p>
                     </div>
                     {moudleInfo?.edition_number && (
                        <div className='leading-none relative '>
                           <p className='font-normal inline m-0 text-[13px] leading-[1.3]'>
                              {t('course_edition')}:{' '}
                           </p>
                           <p className='font-bold inline m-0 text-[13px] leading-[1.3]'>
                              {moudleInfo?.edition_number}
                           </p>
                        </div>
                     )}
                  </div>
               </DrawerBody>
            </DrawerContent>
         </Drawer>
         <Drawer isOpen={showGuideDrawer} placement='right' onClose={hanldeCloseGuide}>
            <DrawerOverlay />
            <DrawerContent>
               <DrawerBody px={0} pt={0} pb={10}>
                  <div className='flex text-white justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative p-5 bg-[#f18535]'>
                     <p className='flex-grow-0 flex-shrink-0 w-[259.6px] text-xs font-bold text-left text-white'>
                        GUIDA AL CORSO
                     </p>
                     <DrawerCloseButton />
                  </div>

                  <div className='flex flex-col mt-7 justify-start items-start self-stretch flex-grow-0 flex-shrink-0  relative overflow-hidden gap-5 px-5'>
                     <p className='self-stretch flex-grow-0 flex-shrink-0  text-[13px] text-left text-black'>
                        <span className='self-stretch flex-grow-0 flex-shrink-0  text-[13px] font-bold text-left text-black'>
                           1. Come accedere al corso
                        </span>
                        <br />
                        <span className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] text-left text-black'>
                           Dopo aver effettuato la registrazione o il login, per poter accedere ai
                           moduli formativi è necessario cliccare sul pulsante “accedi al corso”.
                           L'utente ora può prendere visione dei contenuti scientifici (file pdf e
                           video) messi a disposizione dal docente.
                        </span>
                     </p>
                     <p className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] text-left text-black'>
                        <span className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] font-bold text-left text-black'>
                           2. Moduli formativi e questionari
                        </span>
                        <br />
                        <span className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] text-left text-black'>
                           Per facilitare l'utente durante l'apprendimento, all’interno di ciascun
                           corso, il sistema permette l'apertura di un modulo formativo alla volta.
                           Al termine di ciascun modulo, una volta presa visione di tutti i
                           contenuti obbligatori, sarà possibile effettuare online il test di
                           valutazione. Per il superamento del test, è necessario rispondere
                           correttamente al 75% delle domande. Il test di valutazione può essere
                           ripetuto per un massimo di 5 volte. Superato il test di valutazione, è
                           necessario compilare anche il questionario di gradimento, al termine del
                           quale sarà possibile scaricare il certificato ECM o l’attestato di
                           partecipazione.
                        </span>
                     </p>
                     <p className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] text-left text-black'>
                        <span className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] font-bold text-left text-black'>
                           3. Certificato ECM
                        </span>
                        <br />
                        <span className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] text-left text-black'>
                           Una volta seguito il corso, superato il test di valutazione e compilato
                           il questionario di gradimento, il sistema permetterà di visualizzare
                           automaticamente il certificato ECM in formato PDF, pronto per la stampa.
                           Successivamente sarà possibile scaricare il certificato in qualunque
                           momento dalla sezione 'Dashboard'.
                        </span>
                     </p>
                     <p className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] text-left text-black'>
                        <span className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] font-bold text-left text-black'>
                           4. Attestato di partecipazione
                        </span>
                        <br />
                        <span className='self-stretch flex-grow-0 flex-shrink-0 text-[13px] text-left text-black'>
                           Una volta terminato il corso, superato il test di valutazione e compilato
                           il questionario di gradimento, il sistema visualizzerà automaticamente
                           l’attestato di partecipazione in formato PDF, pronto per la stampa.
                           Successivamente sarà possibile scaricare l’attestato in qualunque momento
                           dalla sezione 'Dashboard'.
                        </span>
                     </p>
                  </div>
               </DrawerBody>
            </DrawerContent>
         </Drawer>
         <Drawer isOpen={showMaterialDrawer} placement='right' onClose={hanldeCloseMaterial}>
            <DrawerOverlay />
            <DrawerContent>
               <DrawerBody px={0} pt={0} pb={10}>
                  <div className='flex text-white justify-between items-center self-stretch flex-grow-0 flex-shrink-0 relative p-5 bg-[#f18535]'>
                     <p className='flex-grow-0 flex-shrink-0 w-[259.6px] text-xs font-bold text-left text-white'>
                        MATERIALI DI APPROFONDIMENTO{' '}
                     </p>
                     <DrawerCloseButton />
                  </div>

                  <div
                     className={
                        'px-5 gap-5 inline-flex flex-col items-center text-left font-normal w-[375px] h-[395px] font-[\'Montserrat\'] text-[rgba(78,99,143,1)]'
                     }>
                     <div>
                        <div className='[background:url(https://uortjlczjmucmpaqqhqm.supabase.co/storage/v1/object/public/firejet-converted-images/images/43edd22060696b332eaa8179bbcf13037bde3649:0.webp)_no-repeat_center_/_contain] w-[627px] h-[214px]' />
                     </div>
                     <div className='w-full'>
                        <div className='w-full gap-5 flex items-center self-stretch rounded-[5px]'>
                           <div className='h-5 w-[29px]'>
                              <svg
                                 width='100%'
                                 height='100%'
                                 preserveAspectRatio='none'
                                 viewBox='0 0 29 20'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M 10.21 11.933 C 9.929 11.656 9.929 11.201 10.21 10.924 C 10.487 10.643 10.942 10.643 11.219 10.924 L 13.571 13.277 V 6.429 C 13.571 6.036 13.893 5.714 14.286 5.714 C 14.679 5.714 15 6.036 15 6.429 V 13.277 L 17.353 10.924 C 17.63 10.643 18.085 10.643 18.362 10.924 C 18.643 11.201 18.643 11.656 18.362 11.933 L 14.79 15.505 C 14.513 15.786 14.058 15.786 13.781 15.505 L 10.21 11.933 Z M 12.143 0 C 14.799 0 17.147 1.319 18.571 3.339 C 19.219 3.03 19.946 2.857 20.714 2.857 C 23.478 2.857 25.714 5.094 25.714 7.857 C 25.714 8.335 25.647 8.754 25.522 9.232 C 27.335 10.188 28.571 12.094 28.571 14.286 C 28.571 17.442 26.013 20 22.857 20 H 6.429 C 2.878 20 0 17.121 0 13.571 C 0 10.768 1.792 8.388 4.293 7.504 C 4.478 3.33 7.92 0 12.143 0 Z M 12.143 1.429 C 8.688 1.429 5.871 4.152 5.719 7.567 C 5.696 8.152 5.317 8.621 4.768 8.853 C 2.821 9.54 1.429 11.393 1.429 13.571 C 1.429 16.335 3.667 18.571 6.429 18.571 H 22.857 C 25.223 18.571 27.143 16.652 27.143 14.286 C 27.143 12.643 26.219 11.214 24.857 10.496 C 24.259 10.138 23.964 9.487 24.147 8.799 C 24.237 8.527 24.286 8.201 24.286 7.857 C 24.286 5.884 22.688 4.286 20.714 4.286 C 20.165 4.286 19.647 4.411 19.183 4.629 C 18.558 4.929 17.804 4.728 17.402 4.161 C 16.237 2.505 14.317 1.429 12.143 1.429 Z'
                                    fill='black'
                                 />
                              </svg>
                           </div>{' '}
                           <p className='h-8 text-sm leading-normal m-0 w-[161px]'>
                              Programma del Corso
                           </p>
                        </div>
                     </div>
                     <div className='w-full'>
                        <div className='py-px w-full gap-5 flex items-center self-stretch rounded-[5px]'>
                           <div className='h-5 w-[29px]'>
                              <svg
                                 width='100%'
                                 height='100%'
                                 preserveAspectRatio='none'
                                 viewBox='0 0 29 20'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M 10.21 11.933 C 9.929 11.656 9.929 11.201 10.21 10.924 C 10.487 10.643 10.942 10.643 11.219 10.924 L 13.571 13.277 V 6.429 C 13.571 6.036 13.893 5.714 14.286 5.714 C 14.679 5.714 15 6.036 15 6.429 V 13.277 L 17.353 10.924 C 17.63 10.643 18.085 10.643 18.362 10.924 C 18.643 11.201 18.643 11.656 18.362 11.933 L 14.79 15.505 C 14.513 15.786 14.058 15.786 13.781 15.505 L 10.21 11.933 Z M 12.143 0 C 14.799 0 17.147 1.319 18.571 3.339 C 19.219 3.03 19.946 2.857 20.714 2.857 C 23.478 2.857 25.714 5.094 25.714 7.857 C 25.714 8.335 25.647 8.754 25.522 9.232 C 27.335 10.188 28.571 12.094 28.571 14.286 C 28.571 17.442 26.013 20 22.857 20 H 6.429 C 2.878 20 0 17.121 0 13.571 C 0 10.768 1.792 8.388 4.293 7.504 C 4.478 3.33 7.92 0 12.143 0 Z M 12.143 1.429 C 8.688 1.429 5.871 4.152 5.719 7.567 C 5.696 8.152 5.317 8.621 4.768 8.853 C 2.821 9.54 1.429 11.393 1.429 13.571 C 1.429 16.335 3.667 18.571 6.429 18.571 H 22.857 C 25.223 18.571 27.143 16.652 27.143 14.286 C 27.143 12.643 26.219 11.214 24.857 10.496 C 24.259 10.138 23.964 9.487 24.147 8.799 C 24.237 8.527 24.286 8.201 24.286 7.857 C 24.286 5.884 22.688 4.286 20.714 4.286 C 20.165 4.286 19.647 4.411 19.183 4.629 C 18.558 4.929 17.804 4.728 17.402 4.161 C 16.237 2.505 14.317 1.429 12.143 1.429 Z'
                                    fill='black'
                                 />
                              </svg>
                           </div>{' '}
                           <p className='h-8 text-sm leading-normal m-0 w-[161px]'>
                              Slide I Modulo
                           </p>
                        </div>
                     </div>
                     <div className='w-full'>
                        <div className='w-full gap-5 flex items-center self-stretch rounded-[5px]'>
                           <div className='h-5 w-[29px]'>
                              <svg
                                 width='100%'
                                 height='100%'
                                 preserveAspectRatio='none'
                                 viewBox='0 0 29 20'
                                 fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                 <path
                                    d='M 10.21 11.933 C 9.929 11.656 9.929 11.201 10.21 10.924 C 10.487 10.643 10.942 10.643 11.219 10.924 L 13.571 13.277 V 6.429 C 13.571 6.036 13.893 5.714 14.286 5.714 C 14.679 5.714 15 6.036 15 6.429 V 13.277 L 17.353 10.924 C 17.63 10.643 18.085 10.643 18.362 10.924 C 18.643 11.201 18.643 11.656 18.362 11.933 L 14.79 15.505 C 14.513 15.786 14.058 15.786 13.781 15.505 L 10.21 11.933 Z M 12.143 0 C 14.799 0 17.147 1.319 18.571 3.339 C 19.219 3.03 19.946 2.857 20.714 2.857 C 23.478 2.857 25.714 5.094 25.714 7.857 C 25.714 8.335 25.647 8.754 25.522 9.232 C 27.335 10.188 28.571 12.094 28.571 14.286 C 28.571 17.442 26.013 20 22.857 20 H 6.429 C 2.878 20 0 17.121 0 13.571 C 0 10.768 1.792 8.388 4.293 7.504 C 4.478 3.33 7.92 0 12.143 0 Z M 12.143 1.429 C 8.688 1.429 5.871 4.152 5.719 7.567 C 5.696 8.152 5.317 8.621 4.768 8.853 C 2.821 9.54 1.429 11.393 1.429 13.571 C 1.429 16.335 3.667 18.571 6.429 18.571 H 22.857 C 25.223 18.571 27.143 16.652 27.143 14.286 C 27.143 12.643 26.219 11.214 24.857 10.496 C 24.259 10.138 23.964 9.487 24.147 8.799 C 24.237 8.527 24.286 8.201 24.286 7.857 C 24.286 5.884 22.688 4.286 20.714 4.286 C 20.165 4.286 19.647 4.411 19.183 4.629 C 18.558 4.929 17.804 4.728 17.402 4.161 C 16.237 2.505 14.317 1.429 12.143 1.429 Z'
                                    fill='black'
                                 />
                              </svg>
                           </div>{' '}
                           <p className='h-8 text-sm leading-normal m-0 w-[161px]'>
                              Slide II Modulo
                           </p>
                        </div>
                     </div>
                  </div>
               </DrawerBody>
            </DrawerContent>
         </Drawer>
         {canComment && (
            <CommentsDrawer
               showCommentsDrawer={showCommentsDrawer}
               hanldeCloseComments={hanldeCloseComments}
            />
         )}
      </div>
   )
}
