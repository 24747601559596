import Icons from '../Icons'
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { filterState } from 'Recoil/atoms'

const PriceRange = ({ isOpen }: { isOpen: boolean }) => {
  const [state, setCState] = useRecoilState(filterState)
  const [range, setRange] = useState([0, 500])
  const [firstLoad, setFirstLoad] = useState(false)

  useEffect(() => {
    setRange([state.filter['price[start]'] || 0, state.filter['price[end]'] || 500])
    setFirstLoad(true)
  }, [])

  useEffect(() => {
    if (firstLoad) {
      setRange([state.filter['price[start]'] || 0, state.filter['price[end]'] || 500])
    }
  }, [state])

  const hidePriceRange =
    state.hidePlans &&
    state.filter.plan?.length === 1 &&
    (state.filter.plan.includes(1) || state.filter.plan.includes(2))


  return (
    <AccordionItem border={'none'} style={{
      visibility: hidePriceRange ? "hidden" : "visible",
      height: hidePriceRange ? "0" : "auto",
    }}>
      <AccordionButton
        px={0}
        py={0}
        _hover={{
          bg: ''
        }}>
        <Icons.AnimatedDash isOpen={isOpen}>
          <Box as='span' flex='1' textAlign='left'>
            Prezzo
          </Box>
        </Icons.AnimatedDash>
      </AccordionButton>
      <AccordionPanel pb={8} pl={0}>
        <div className='pl-[20px] md:pl-[38px]'>
          <div className='mt-5 relative'>
            <span className='absolute -top-6 left-0 text-sm'>FREE</span>
            <span className='absolute -top-6 right-0 text-sm'>500,00€</span>
            <RangeSlider
              aria-label={['free', '500,00€']}
              value={[range[0], range[1]]}
              min={0}
              max={500}
              onChange={(e) => setRange([e[0], e[1]])}
              onChangeEnd={(e) =>
                setCState((prev) => ({
                  ...prev,
                  filter: {
                    ...prev.filter,
                    'price[start]': e[0],
                    'price[end]': e[1]
                  },
                  hasFilter: true,
                }))
              }>
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} className='relative visible-hover'>
                <span className='-bottom-[30px] left-1/2 -translate-x-1/2 text-sm p-[2px] px-2 rounded-md bg-active text-white absolute text-center'>
                  {range[0]}€
                </span>
              </RangeSliderThumb>
              <RangeSliderThumb index={1} className='relative visible-hover'>
                <span className='-bottom-[30px] left-1/2 -translate-x-1/2 text-sm p-[2px] rounded-md bg-active text-white absolute text-center'>
                  {range[1]}€
                </span>
              </RangeSliderThumb>
            </RangeSlider>
          </div>
        </div>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default PriceRange
