import stripe from 'Assets/stripe.svg'
import paypal from 'Assets/paypal.svg'
import { useState } from 'react'
import { FormControl, FormLabel, Input, Button, Select } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Checkout = () => {
   const [checkoutType, setcheckoutType] = useState<'stripe' | 'paypal'>('stripe')

   const FormLabelCustom = ({ children }: { children: string }) => (
      <FormLabel color={'#4F5B76'} opacity='0.7' mb='3px' fontSize={'13px'}>
         {children}
      </FormLabel>
   )

   const navigate = useNavigate()

   const goToModule = () => navigate('/module')
   const { t } = useTranslation()

   return (
      <div className='p-7 bg-white rounded-lg w-96 flex flex-col gap-4'>
         <div className='grid grid-cols-2 gap-4'>
            <Button
               onClick={() => setcheckoutType('stripe')}
               height={'64px'}
               width='full'
               border={'2px solid white'}
               borderColor={checkoutType === 'stripe' ? 'blue.400' : ''}
               shadow={'md'}>
               <img src={stripe} />
            </Button>
            <Button
               onClick={() => setcheckoutType('paypal')}
               height={'64px'}
               border={'2px solid white'}
               borderColor={checkoutType === 'paypal' ? 'blue.400' : ''}
               width='full'
               shadow={'md'}>
               <img src={paypal} />
            </Button>
         </div>
         {checkoutType === 'stripe' ? (
            <div className='flex flex-col gap-5'>
               <FormControl>
                  <FormLabelCustom> Nome e cognome sulla carta</FormLabelCustom>
                  <Input
                     height={'43px'}
                     borderColor={'#E0E0E0'}
                     shadow='md'
                     fontSize={'13px'}
                     textColor={'#A5ACB8'}
                     placeholder='Mario Rossi'
                     outline='#E0E0E0 1px solid'
                     type='email'
                  />
               </FormControl>
               <FormControl>
                  <FormLabelCustom>Card number</FormLabelCustom>
                  <Input
                     height={'43px'}
                     borderColor={'#E0E0E0'}
                     shadow='md'
                     fontSize={'13px'}
                     textColor={'#A5ACB8'}
                     placeholder='1234 1234 1234 1234'
                     outline='#E0E0E0 1px solid'
                     type='email'
                  />
               </FormControl>
               <div className='grid grid-cols-2 gap-5'>
                  <FormControl>
                     <FormLabelCustom>Expiry</FormLabelCustom>
                     <Input
                        height={'43px'}
                        borderColor={'#E0E0E0'}
                        shadow='md'
                        fontSize={'13px'}
                        textColor={'#A5ACB8'}
                        placeholder='MM/YY'
                        outline='#E0E0E0 1px solid'
                        type='email'
                     />
                  </FormControl>
                  <FormControl>
                     <FormLabelCustom>CVC</FormLabelCustom>
                     <Input
                        height={'43px'}
                        borderColor={'#E0E0E0'}
                        shadow='md'
                        fontSize={'13px'}
                        textColor={'#A5ACB8'}
                        placeholder='CVC'
                        outline='#E0E0E0 1px solid'
                        type='email'
                     />
                  </FormControl>
                  <FormControl>
                     <FormLabelCustom>Country</FormLabelCustom>
                     <Select
                        height={'43px'}
                        borderColor={'#E0E0E0'}
                        shadow='md'
                        fontSize={'13px'}
                        textColor={'#A5ACB8'}
                        placeholder='Select Country'
                        outline='#E0E0E0 1px solid'>
                        <option>United States</option>
                     </Select>
                  </FormControl>
                  <FormControl>
                     <FormLabelCustom>Postal Code</FormLabelCustom>
                     <Input
                        height={'43px'}
                        borderColor={'#E0E0E0'}
                        shadow='md'
                        fontSize={'13px'}
                        textColor={'#A5ACB8'}
                        placeholder='90210'
                        outline='#E0E0E0 1px solid'
                        type='email'
                     />
                  </FormControl>
               </div>
               <div className='flex gap-5'>
                  <input type='checkbox' />
                  <span className='text-xs'>{t('Hai Bisogno di fattura?')}</span>
               </div>
               <Button bg='#3C8287' onClick={goToModule} variant={'primary'} height='70px'>
                  {t('Paga adesso')}
               </Button>
               <Button height='40px'>{t('Chiudi')}</Button>
            </div>
         ) : (
            <div className='flex flex-col gap-5'>
               <p>
                  {t(
                     'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident'
                  )}
               </p>
               <div className='flex gap-5'>
                  <input type='checkbox' />
                  <span className='text-xs'>{t('Hai Bisogno di fattura?')}</span>
               </div>
               <Button bg='#3C8287' onClick={goToModule} variant={'primary'} height='70px'>
                  {t('Paga adesso')}
               </Button>
               <Button height='40px'>{t('Chiudi')}</Button>
            </div>
         )}
      </div>
   )
}
