import { useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { CourseCard } from 'Components/Cards/CourseCard/CourseCard'
import { Skeleton } from 'Components/Cards/CourseCard/Skeleton'
import CardsCarousel from 'Components/CardsCarousel'
import Container from 'Components/shared/Container'
import SmallBtn from 'Components/shared/SmallBtn'
import SubscriptionStyles from 'Configs/SubscripationStyles'
import { NewTCourse, TPlan } from 'Types/Models'
import PlanTypes from 'Types/Subscripe'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useNavigate } from 'react-router-dom'

const PlanSection = ({ id, name, text }: TPlan) => {
  const [isLargerThan900, , isLargerThan1400, isLargerThan1600] = useMediaQuery([
    '(min-width: 900px)',
    '(min-width: 1026px)',
    '(min-width: 1400px)',
    '(min-width: 1600px)'
  ])

  const navigate = useNavigate()

  const { getPlansCourses } = useApiRequests()

  const { ref, inView } = useInView()

  const [page, setPage] = useState(1)
  const [courses, setCourses] = useState<any[]>([])

  const { isFetching, data } = useQuery(['allPlans', page, id], {
    queryFn: ({ queryKey }) => getPlansCourses({ 'plan': [id], keyword: '' }, +queryKey[1]),
    select: (res) => res.data.data,
    onSuccess: (res) => setCourses((prev) => [...prev, ...res.courses.data])
  })

  useEffect(() => {
    inView && setPage((prev) => prev + 1)
  }, [inView])

  return (
    <Container extraClasses={`${SubscriptionStyles[name as PlanTypes].bg} px-[15px] py-[24px] !min-h-fit gap-0`}>
      <div className='text-white pb-5'>
        <div className='font-saira-normal'>
          <h2 className='text-[45px] font-bold'>{name}</h2>
          <p className='text-[15px]'>{text}</p>
        </div>
        <div className='!text-white text-xs font-nuinto italic mt-[10px]'>
          Scorri con il dito verso sinistra per vedere altri corsi
        </div>
      </div>
      <CardsCarousel
        slidesToShow={
          isLargerThan1600 ? 4.25 : isLargerThan1400 ? 3.25 : isLargerThan900 ? 2.25 : 1.25
        }
        wrap={page === data?.courses.last_page}
      >
        {courses.map((course: NewTCourse) => {
          return <CourseCard key={course.id} {...course} />
        })}

        {isFetching && Array.from(Array(4).keys()).map((item) => <Skeleton key={item} />)}

        {page < data?.courses.last_page && !isFetching && <div ref={ref}>{''}</div>}
      </CardsCarousel>
      <div className='flex justify-end relative -top-8 z-10'>
        <SmallBtn
          extraClasses='!text-white'
          onClick={() =>
            navigate(`/corsi/${name.toLocaleLowerCase()}`)
          }>
          Vedi tutto
        </SmallBtn>
      </div>
    </Container>
  )
}

export default PlanSection
