import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

type Props = {
   destension: string
   title: React.ReactNode | string
}

export function NavbarLink({ title, destension }: Props) {
   const { t } = useTranslation()

   return (
      <NavLink
         to={destension}
         className={({ isActive }) =>
            `${isActive && 'font-bold text-active'} text-base hover:text-active`
         }>
         {(() => {
            if (typeof title === 'string') {
               return t(`${title}`)
            }

            return title
         })()}
      </NavLink>
   )
}
