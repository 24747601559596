import { Button, Textarea, useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useApiRequests } from 'API/ApiRequests'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { dashboardCoursesAtom, userDataAtom } from 'Recoil/atoms'

export const Contacts = () => {
   const { t } = useTranslation()
   const toast = useToast()
   const [data] = useRecoilState(dashboardCoursesAtom)
   const [body, setbody] = useState('')
   const { send_email } = useApiRequests()
   const [userData] = useRecoilState(userDataAtom)
   const { mutate, isLoading } = useMutation({ mutationFn: send_email })

   const hundleSubmit = () => {
      if (body !== '') {
         mutate(body, {
            onSuccess: () => {
               setbody('')
               toast({
                  title: t('Your massage has been send'),
                  status: 'success',
                  duration: 2500,
                  position: 'top'
               })
            },
            onError: () => {
               null
            }
         })
      }
   }

   return (
      <div className='flex px-4 w-full flex-col gap-5'>
         {/* <Notifications /> */}
         <div className='gap-5 flex flex-col pb-5'>
            <span className='font-bold text-xs '>{t('Contatti')}</span>
            <p className='text-xs'>{data?.configurations?.contact_form_message}</p>
            <Textarea
               value={body}
               resize={'none'}
               onChange={(e) => {
                  setbody(e.target.value)
               }}
               border={'solid #F5F5F5 1px'}
               borderColor={'gray.100'}
               className='placeholder:text-xs'
               placeholder={t('Scrivi messaggio') || ''}
            />
            <Button
               onClick={() => {
                  hundleSubmit()
               }}
               isLoading={isLoading}
               disabled={body === ''}
               fontSize='x-small'
               className='bg-blueDark rounded text-white text-xs h-11 hover:bg-blueDark/90 transition-all'>
               {t('Invia Email')}
            </Button>
            {userData?.user_data.subscription.type_text === 'PLUS' && (
               <div className='space-y-2'>
                  <div className='w-full flex justify-between text-xs font-bold'>
                     <span>{t('Telefono')}</span>
                     <span>{data?.configurations?.help_desk_phone}</span>
                  </div>
                  <p className='text-xs'>{data?.configurations?.help_desk_availability}</p>
               </div>
            )}
         </div>
      </div>
   )
}
