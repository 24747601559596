/* eslint-disable no-param-reassign */
import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userDataAtom } from 'Recoil/atoms'

const axiosClient = axios.create()

export const useAxiosInstance = () => {
   // const toast = useToast()
   const navigate = useNavigate()
   const { t } = useTranslation('common')
   const { REACT_APP_API_BASE_URL } = process.env
   axiosClient.defaults.baseURL = REACT_APP_API_BASE_URL
   const [userData, setUserData] = useRecoilState(userDataAtom)
   const location = useLocation()
   // const Toastid = 'test-toast'

   axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
         if (location.pathname !== '/login' && error.response.status === 401) {
            // if (!toast.isActive(Toastid)) {
            //    toast({
            //       title: t('You are not authorized'),
            //       status: 'error'
            //    })
            // }
            if (userData) setUserData(null)
            setTimeout(() => navigate('/login'), 500)
            setTimeout(() => navigate(0), 500)
         }

         return Promise.reject(error)
      }
   )

   /* Adding the token to the header of the request. */
   axiosClient.interceptors.request.use((config) => {
      const token = userData?.plainTextToken
      if (config.headers && token) {
         if (!config?.headers?.Authorization) {
            config.headers.Authorization = `Bearer ${token}`
         }
      }
      return config
   })

   function setAuthToken(tokenP: string) {
      axiosClient.interceptors.request.use((config) => {
         if (config.headers) {
            config.headers.Authorization = `Bearer ${tokenP}`
         }
         return config
      })
   }

   return { axiosClient, setAuthToken }
}
